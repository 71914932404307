export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
export type UnknownJSON = unknown | null

/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  CraftDateTime: { input: any; output: any }
  CraftNumber: { input: any; output: any }
  CraftQueryArgument: { input: any; output: any }
  JSON: { input: UnknownJSON; output: UnknownJSON }
  TODO: { input: any; output: any }
}

export interface AddAdvCommentInput {
  /** Email Address. */
  email?: InputMaybe<Scalars['String']['input']>
  /** Review. */
  message: Scalars['String']['input']
  /** Nickname. */
  nickname?: InputMaybe<Scalars['String']['input']>
  /** Review ID. */
  review_id: Scalars['Int']['input']
}

export interface AddAdvCommentOutput {
  __typename?: 'AddAdvCommentOutput'
  /** Commented Review. */
  review: Maybe<Review>
  /** Status. */
  success: Maybe<Scalars['Boolean']['output']>
}

export interface AddAdvReviewInput {
  /** Review. */
  detail: Scalars['String']['input']
  /** GDPR. */
  gdpr?: InputMaybe<Scalars['Boolean']['input']>
  /** Email Address. */
  guest_email?: InputMaybe<Scalars['String']['input']>
  /** Recommendation. */
  is_recommended?: InputMaybe<Scalars['Boolean']['input']>
  /** Advantages. */
  like_about?: InputMaybe<Scalars['String']['input']>
  /** Nickname. */
  nickname: Scalars['String']['input']
  /** Disadvantages. */
  not_like_about?: InputMaybe<Scalars['String']['input']>
  /** Product UID */
  productUid: Scalars['ID']['input']
  /** Product ID */
  product_id?: InputMaybe<Scalars['Int']['input']>
  /** Rating in Json format. Rating ID => Rating Value */
  ratings: Scalars['String']['input']
  /** Summary. */
  title: Scalars['String']['input']
  /** Path to tmp images location to save review images. */
  tmp_images_path?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface AddAdvReviewOutput {
  __typename?: 'AddAdvReviewOutput'
  /** Status. */
  success: Maybe<Scalars['Boolean']['output']>
}

export interface AddAdvVoteInput {
  /** Review ID. */
  review_id: Scalars['Int']['input']
  /** Nickname. */
  type: Scalars['String']['input']
}

export interface AddAdvVoteOutput {
  __typename?: 'AddAdvVoteOutput'
  /** Commented Review. */
  review: Maybe<Review>
  /** Status. */
  success: Maybe<Scalars['Boolean']['output']>
}

/** Defines the bundle products to add to the cart. */
export interface AddBundleProductsToCartInput {
  /** The ID of the cart. */
  cart_id: Scalars['String']['input']
  /** An array of bundle products to add. */
  cart_items: Array<InputMaybe<BundleProductCartItemInput>>
}

/** Contains details about the cart after adding bundle products. */
export interface AddBundleProductsToCartOutput {
  __typename?: 'AddBundleProductsToCartOutput'
  /** The cart after adding products. */
  cart: Cart
}

/** Defines the configurable products to add to the cart. */
export interface AddConfigurableProductsToCartInput {
  /** The ID of the cart. */
  cart_id: Scalars['String']['input']
  /** An array of configurable products to add. */
  cart_items: Array<InputMaybe<ConfigurableProductCartItemInput>>
}

/** Contains details about the cart after adding configurable products. */
export interface AddConfigurableProductsToCartOutput {
  __typename?: 'AddConfigurableProductsToCartOutput'
  /** The cart after adding products. */
  cart: Cart
}

export interface AddDownloadableProductsToCartInput {
  /** The ID of the cart. */
  cart_id: Scalars['String']['input']
  /** An array of downloadable products to add. */
  cart_items: Array<InputMaybe<DownloadableProductCartItemInput>>
}

/** Contains details about the cart after adding downloadable products. */
export interface AddDownloadableProductsToCartOutput {
  __typename?: 'AddDownloadableProductsToCartOutput'
  /** The cart after adding products. */
  cart: Cart
}

/** Contains details about the cart after adding products to it. */
export interface AddProductsToCartOutput {
  __typename?: 'AddProductsToCartOutput'
  /** The cart after products have been added. */
  cart: Cart
  /** Contains errors encountered while adding an item to the cart. */
  userErrors: Array<Maybe<CartUserInputError>>
}

/** Contains products to add to an existing compare list. */
export interface AddProductsToCompareListInput {
  /** An array of product IDs to add to the compare list. */
  products: Array<InputMaybe<Scalars['ID']['input']>>
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID']['input']
}

/** Contains the customer's wish list and any errors encountered. */
export interface AddProductsToWishlistOutput {
  __typename?: 'AddProductsToWishlistOutput'
  /** An array of errors encountered while adding products to a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Wishlist
}

/** Defines the simple and group products to add to the cart. */
export interface AddSimpleProductsToCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** An array of simple and group items to add. */
  cart_items: Array<InputMaybe<SimpleProductCartItemInput>>
}

/** Contains details about the cart after adding simple or group products. */
export interface AddSimpleProductsToCartOutput {
  __typename?: 'AddSimpleProductsToCartOutput'
  /** The cart after adding products. */
  cart: Cart
}

/** Defines the virtual products to add to the cart. */
export interface AddVirtualProductsToCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** An array of virtual products to add. */
  cart_items: Array<InputMaybe<VirtualProductCartItemInput>>
}

/** Contains details about the cart after adding virtual products. */
export interface AddVirtualProductsToCartOutput {
  __typename?: 'AddVirtualProductsToCartOutput'
  /** The cart after adding products. */
  cart: Cart
}

/** Contains the resultant wish list and any error information. */
export interface AddWishlistItemsToCartOutput {
  __typename?: 'AddWishlistItemsToCartOutput'
  /** An array of errors encountered while adding products to the customer's cart. */
  addWishlistItemsToCartUserErrors: Array<Maybe<WishlistCartUserInputError>>
  /** Indicates whether the attempt to add items to the customer's cart was successful. */
  status: Scalars['Boolean']['output']
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Wishlist
}

export interface AdditionalDataField {
  field: Scalars['String']['input']
  value: Scalars['String']['input']
}

/** Contains information for each filterable option (such as price, category `UID`, and custom attributes). */
export interface Aggregation {
  __typename?: 'Aggregation'
  /** Attribute code of the aggregation group. */
  attributeCode: Scalars['String']['output']
  /** The number of options in the aggregation group. */
  count: Maybe<Scalars['Int']['output']>
  /** The aggregation display name. */
  label: Maybe<Scalars['String']['output']>
  /** Array of options for the aggregation. */
  options: Maybe<Array<Maybe<AggregationOption>>>
  /** The relative position of the attribute in a layered navigation block. */
  position: Maybe<Scalars['Int']['output']>
}

/** An implementation of `AggregationOptionInterface`. */
export interface AggregationOption extends AggregationOptionInterface {
  __typename?: 'AggregationOption'
  /** The number of items that match the aggregation option. */
  count: Maybe<Scalars['Int']['output']>
  /** The display label for an aggregation option. */
  label: Maybe<Scalars['String']['output']>
  /** The internal ID that represents the value of the option. */
  value: Scalars['String']['output']
}

/** Defines aggregation option fields. */
export interface AggregationOptionInterface {
  /** The number of items that match the aggregation option. */
  count: Maybe<Scalars['Int']['output']>
  /** The display label for an aggregation option. */
  label: Maybe<Scalars['String']['output']>
  /** The internal ID that represents the value of the option. */
  value: Scalars['String']['output']
}

/** Filter category aggregations in layered navigation. */
export interface AggregationsCategoryFilterInput {
  /** Indicates whether to include only direct subcategories or all children categories at all levels. */
  includeDirectChildrenOnly?: InputMaybe<Scalars['Boolean']['input']>
}

/** An input object that specifies the filters used in product aggregations. */
export interface AggregationsFilterInput {
  /** Filter category aggregations in layered navigation. */
  category?: InputMaybe<AggregationsCategoryFilterInput>
}

/** Label info */
export interface AmLabel {
  __typename?: 'AmLabel'
  /** Label alt tag. */
  altTag: Maybe<Scalars['String']['output']>
  /** Label customer group */
  customerGroupIds: Maybe<Scalars['String']['output']>
  /** Label image. */
  image: Maybe<Scalars['String']['output']>
  /** Is label visible */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Label id. */
  labelId: Maybe<Scalars['Int']['output']>
  /** Label name. */
  name: Maybe<Scalars['String']['output']>
  /** Label position. */
  position: Maybe<Scalars['String']['output']>
  /** Product id. */
  productId: Maybe<Scalars['Int']['output']>
  /** Label redirect url. */
  redirectUrl: Maybe<Scalars['String']['output']>
  /** Label image size. Percent of the product image. */
  size: Maybe<Scalars['String']['output']>
  /** Label css style. */
  style: Maybe<Scalars['String']['output']>
  /** A settings for label tooltip */
  tooltipSettings: Maybe<AmLabelTooltip>
  /** Label text. */
  txt: Maybe<Scalars['String']['output']>
}

export interface AmLabelList {
  __typename?: 'AmLabelList'
  /** Labels. */
  items: Maybe<Array<Maybe<AmLabel>>>
}

/** Label Mode */
export enum AmLabelMode {
  CATEGORY = 'CATEGORY',
  PRODUCT = 'PRODUCT',
}

export interface AmLabelSetting {
  __typename?: 'AmLabelSetting'
  /** Category Page Label Container. */
  categoryContainer: Maybe<Scalars['String']['output']>
  /** Labels Alignment. */
  labelsAlignment: Maybe<Scalars['Int']['output']>
  /** Margin between labels, px. */
  marginBetween: Maybe<Scalars['Int']['output']>
  /** Max Number of Labels on One Product. */
  maxLabels: Maybe<Scalars['Int']['output']>
  /** Product Page Label Container. */
  productContainer: Maybe<Scalars['String']['output']>
  /** Show Several Labels on the Same Place. */
  showSeveralOnPlace: Maybe<Scalars['Int']['output']>
}

export interface AmLabelTooltip {
  __typename?: 'AmLabelTooltip'
  /** Tooltip color */
  color: Maybe<Scalars['String']['output']>
  /** Tooltip status */
  status: Maybe<Scalars['Int']['output']>
  /** Tooltip text */
  text: Maybe<Scalars['String']['output']>
  /** Tooltip text color */
  textColor: Maybe<Scalars['String']['output']>
}

export interface AmReviewSetting {
  __typename?: 'AmReviewSetting'
  /** Available filter. */
  availableFilters: Maybe<Array<Maybe<ReviewFilter>>>
  /** Available orders. */
  availableOrders: Maybe<Array<Maybe<ReviewOrder>>>
  /** Сonsent Text. */
  getGdprText: Maybe<Scalars['String']['output']>
  /** Review Image Width. */
  getReviewImageWidth: Maybe<Scalars['String']['output']>
  /** Allow answer. */
  isAllowAnswer: Maybe<Scalars['Boolean']['output']>
  /** Allow coupons. */
  isAllowCoupons: Maybe<Scalars['Boolean']['output']>
  /** Allow guest. */
  isAllowGuest: Maybe<Scalars['Boolean']['output']>
  /** Allow helpful. */
  isAllowHelpful: Maybe<Scalars['Boolean']['output']>
  /** Allow images. */
  isAllowImages: Maybe<Scalars['Boolean']['output']>
  /** Review Reminder. */
  isAllowReminder: Maybe<Scalars['Boolean']['output']>
  /** Comments Enabled. */
  isCommentsEnabled: Maybe<Scalars['Boolean']['output']>
  /** Sorting enabled. */
  isFilteringEnabled: Maybe<Scalars['Boolean']['output']>
  /** GDPR Consent. */
  isGdprEnabled: Maybe<Scalars['Boolean']['output']>
  /** Guest Can Comment. */
  isGuestCanComment: Maybe<Scalars['Boolean']['output']>
  /** Is Guest Email Showed. */
  isGuestEmailShow: Maybe<Scalars['Boolean']['output']>
  /** Is Images Required. */
  isImagesRequired: Maybe<Scalars['Boolean']['output']>
  /** Pros and Cons. */
  isProsConsEnabled: Maybe<Scalars['Boolean']['output']>
  /** Recomended field enable. */
  isRecommendFieldEnabled: Maybe<Scalars['Boolean']['output']>
  /** Reminder Enabled. */
  isReminderEnabled: Maybe<Scalars['Boolean']['output']>
  /** Sorting enabled. */
  isSortingEnabled: Maybe<Scalars['Boolean']['output']>
  /** Toolbar displayed. */
  isToolbarDisplayed: Maybe<Scalars['Boolean']['output']>
  /** Reviews Per Page. */
  perPage: Maybe<Scalars['Int']['output']>
  /** Available Ratings. */
  ratings: Maybe<Array<Maybe<Rating>>>
  /** Images to Show. */
  slidesToShow: Maybe<Scalars['Int']['output']>
}

export interface AmReviewWidget {
  __typename?: 'AmReviewWidget'
  /** Reviews from Current Category Only. */
  currentCategory: Maybe<Scalars['Boolean']['output']>
  /** Reviews of Products which belong to the Same Categories Only. */
  currentProductCategory: Maybe<Scalars['Boolean']['output']>
  /** Enable Slider. */
  enableSlider: Maybe<Scalars['Boolean']['output']>
  items: Maybe<Array<Maybe<AmReviewWidgetItem>>>
  /** Reviews Type. */
  reviewType: Maybe<Scalars['Int']['output']>
  /** Number of Reviews to Show. */
  reviewsCount: Maybe<Scalars['Int']['output']>
  title: Maybe<Scalars['String']['output']>
}

export interface AmReviewWidgetItem {
  __typename?: 'AmReviewWidgetItem'
  /** Date. */
  date: Maybe<Scalars['String']['output']>
  image: Maybe<ProductImage>
  /** Product Name. */
  name: Maybe<Scalars['String']['output']>
  /** Product URL. */
  productUrl: Maybe<Scalars['String']['output']>
  /** Rating votes. */
  ratingVotes: Maybe<Array<Maybe<RatingVote>>>
  /** Recommended Html. */
  recommendedHtml: Maybe<Scalars['String']['output']>
  /** Review By. */
  reviewBy: Maybe<Scalars['String']['output']>
  /** Review Message. */
  reviewMessage: Maybe<Scalars['String']['output']>
}

export interface AmbianceGallery {
  __typename?: 'AmbianceGallery'
  /** The status of the image */
  disabled: Maybe<Scalars['Boolean']['output']>
  /** The label for alt and title */
  label: Maybe<Scalars['String']['output']>
  /** The sort order */
  position: Maybe<Scalars['Int']['output']>
  /** The Image URL */
  url: Maybe<Scalars['String']['output']>
}

export interface ApplePayConfig extends PaymentConfigItem {
  __typename?: 'ApplePayConfig'
  /** The styles for the ApplePay Smart Button configuration */
  buttonStyles: Maybe<ButtonStyles>
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>
  /** The payment source for the payment method */
  paymentSource: Maybe<Scalars['String']['output']>
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>
}

/** Apple Pay inputs */
export interface ApplePayMethodInput {
  /** The payment source for the payment method */
  payment_source?: InputMaybe<Scalars['String']['input']>
  /** The payment services order ID */
  payments_order_id?: InputMaybe<Scalars['String']['input']>
  /** PayPal order ID */
  paypal_order_id?: InputMaybe<Scalars['String']['input']>
}

/** Contains the applied coupon code. */
export interface AppliedCoupon {
  __typename?: 'AppliedCoupon'
  /** The coupon code the shopper applied to the card. */
  code: Scalars['String']['output']
}

/** Specifies the coupon code to apply to the cart. */
export interface ApplyCouponToCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** A valid coupon code. */
  coupon_code: Scalars['String']['input']
}

/** Contains details about the cart after applying a coupon. */
export interface ApplyCouponToCartOutput {
  __typename?: 'ApplyCouponToCartOutput'
  /** The cart after applying a coupon. */
  cart: Cart
}

/** Contains the results of the request to assign a compare list. */
export interface AssignCompareListToCustomerOutput {
  __typename?: 'AssignCompareListToCustomerOutput'
  /** The contents of the customer's compare list. */
  compareList: Maybe<CompareList>
  /** Indicates whether the compare list was successfully assigned to the customer. */
  result: Scalars['Boolean']['output']
}

/** Contains details about the attribute, including the code and type. */
export interface Attribute {
  __typename?: 'Attribute'
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attributeCode: Maybe<Scalars['String']['output']>
  /** Attribute options list. */
  attributeOptions: Maybe<Array<Maybe<AttributeOption>>>
  /** The data type of the attribute. */
  attributeType: Maybe<Scalars['String']['output']>
  /** The type of entity that defines the attribute. */
  entityType: Maybe<Scalars['String']['output']>
  /** The frontend input type of the attribute. */
  inputType: Maybe<Scalars['String']['output']>
  /** Details about the storefront properties configured for the attribute. */
  storefrontProperties: Maybe<StorefrontProperties>
}

/** List of all entity types. Populated by the modules introducing EAV entities. */
export enum AttributeEntityTypeEnum {
  CATALOG_CATEGORY = 'CATALOG_CATEGORY',
  CATALOG_PRODUCT = 'CATALOG_PRODUCT',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_ADDRESS = 'CUSTOMER_ADDRESS',
}

/** An input object that specifies the filters used for attributes. */
export interface AttributeFilterInput {
  /** Whether a product or category attribute can be compared against another or not. */
  is_comparable?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute can be filtered or not. */
  is_filterable?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute can be filtered in search or not. */
  is_filterable_in_search?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute can use HTML on front or not. */
  is_html_allowed_on_front?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute can be searched or not. */
  is_searchable?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute can be used for price rules or not. */
  is_used_for_price_rules?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute is used for promo rules or not. */
  is_used_for_promo_rules?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute is visible in advanced search or not. */
  is_visible_in_advanced_search?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute is visible on front or not. */
  is_visible_on_front?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute has WYSIWYG enabled or not. */
  is_wysiwyg_enabled?: InputMaybe<Scalars['Boolean']['input']>
  /** Whether a product or category attribute is used in product listing or not. */
  used_in_product_listing?: InputMaybe<Scalars['Boolean']['input']>
}

/** EAV attribute frontend input types. */
export enum AttributeFrontendInputEnum {
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  FILE = 'FILE',
  GALLERY = 'GALLERY',
  HIDDEN = 'HIDDEN',
  IMAGE = 'IMAGE',
  MEDIA_IMAGE = 'MEDIA_IMAGE',
  MULTILINE = 'MULTILINE',
  MULTISELECT = 'MULTISELECT',
  PRICE = 'PRICE',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  UNDEFINED = 'UNDEFINED',
  WEIGHT = 'WEIGHT',
}

/** Defines the attribute characteristics to search for the `attribute_code` and `entity_type` to search. */
export interface AttributeInput {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: InputMaybe<Scalars['String']['input']>
  /** The type of entity that defines the attribute. */
  entity_type?: InputMaybe<Scalars['String']['input']>
}

/** Specifies selected option for a select or multiselect attribute value. */
export interface AttributeInputSelectedOption {
  /** The attribute option value. */
  value: Scalars['String']['input']
}

/** Base EAV implementation of CustomAttributeMetadataInterface. */
export interface AttributeMetadata extends CustomAttributeMetadataInterface {
  __typename?: 'AttributeMetadata'
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  code: Scalars['ID']['output']
  /** Default attribute value. */
  defaultValue: Maybe<Scalars['String']['output']>
  /** The type of entity that defines the attribute. */
  entityType: AttributeEntityTypeEnum
  /** The frontend class of the attribute. */
  frontendClass: Maybe<Scalars['String']['output']>
  /** The frontend input type of the attribute. */
  frontendInput: Maybe<AttributeFrontendInputEnum>
  /** Whether the attribute value is required. */
  isRequired: Scalars['Boolean']['output']
  /** Whether the attribute value must be unique. */
  isUnique: Scalars['Boolean']['output']
  /** The label assigned to the attribute. */
  label: Maybe<Scalars['String']['output']>
  /** Attribute options. */
  options: Array<Maybe<CustomAttributeOptionInterface>>
}

/** Attribute metadata retrieval error. */
export interface AttributeMetadataError {
  __typename?: 'AttributeMetadataError'
  /** Attribute metadata retrieval error message. */
  message: Scalars['String']['output']
  /** Attribute metadata retrieval error type. */
  type: AttributeMetadataErrorType
}

/** Attribute metadata retrieval error types. */
export enum AttributeMetadataErrorType {
  /** The requested attribute was not found. */
  ATTRIBUTE_NOT_FOUND = 'ATTRIBUTE_NOT_FOUND',
  /** The requested entity was not found. */
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
  /** The filter cannot be applied as it does not belong to the entity */
  FILTER_NOT_FOUND = 'FILTER_NOT_FOUND',
  /** Not categorized error, see the error message. */
  UNDEFINED = 'UNDEFINED',
}

/** Defines an attribute option. */
export interface AttributeOption {
  __typename?: 'AttributeOption'
  /** The color swatch when the attribute is a visual swatch */
  colorSwatch: Maybe<SwatchData>
  /** The label assigned to the attribute option. */
  label: Maybe<Scalars['String']['output']>
  /** The attribute option value. */
  value: Maybe<Scalars['String']['output']>
}

/** Base EAV implementation of CustomAttributeOptionInterface. */
export interface AttributeOptionMetadata
  extends CustomAttributeOptionInterface {
  __typename?: 'AttributeOptionMetadata'
  /** Is the option value default. */
  isDefault: Scalars['Boolean']['output']
  /** The label assigned to the attribute option. */
  label: Scalars['String']['output']
  /** The attribute option value. */
  value: Scalars['String']['output']
}

export interface AttributeSelectedOption
  extends AttributeSelectedOptionInterface {
  __typename?: 'AttributeSelectedOption'
  /** The attribute selected option label. */
  label: Scalars['String']['output']
  /** The attribute selected option value. */
  value: Scalars['String']['output']
}

export interface AttributeSelectedOptionInterface {
  /** The attribute selected option label. */
  label: Scalars['String']['output']
  /** The attribute selected option value. */
  value: Scalars['String']['output']
}

export interface AttributeSelectedOptions extends AttributeValueInterface {
  __typename?: 'AttributeSelectedOptions'
  /** The attribute code. */
  code: Scalars['ID']['output']
  selectedOptions: Array<Maybe<AttributeSelectedOptionInterface>>
}

export interface AttributeValue extends AttributeValueInterface {
  __typename?: 'AttributeValue'
  /** The attribute code. */
  code: Scalars['ID']['output']
  /** The attribute value. */
  value: Scalars['String']['output']
}

/** Specifies the value for attribute. */
export interface AttributeValueInput {
  /** The code of the attribute. */
  attribute_code: Scalars['String']['input']
  /** An array containing selected options for a select or multiselect attribute. */
  selected_options?: InputMaybe<Array<InputMaybe<AttributeInputSelectedOption>>>
  /** The value assigned to the attribute. */
  value?: InputMaybe<Scalars['String']['input']>
}

export interface AttributeValueInterface {
  /** The attribute code. */
  code: Scalars['ID']['output']
}

/** Metadata of EAV attributes associated to form */
export interface AttributesFormOutput {
  __typename?: 'AttributesFormOutput'
  /** Errors of retrieving certain attributes metadata. */
  errors: Array<Maybe<AttributeMetadataError>>
  /** Requested attributes metadata. */
  items: Array<Maybe<CustomAttributeMetadataInterface>>
}

/** Metadata of EAV attributes. */
export interface AttributesMetadataOutput {
  __typename?: 'AttributesMetadataOutput'
  /** Errors of retrieving certain attributes metadata. */
  errors: Array<Maybe<AttributeMetadataError>>
  /** Requested attributes metadata. */
  items: Array<Maybe<CustomAttributeMetadataInterface>>
}

export interface AutocompleteAddressDetails {
  __typename?: 'AutocompleteAddressDetails'
  /** Number and possible addition of the building in the postal address. */
  building: Scalars['String']['output']
  /** Building number of the postal address. Or null if not available. */
  buildingNumber: Maybe<Scalars['Int']['output']>
  /** Building number addition of the postal address, if available. */
  buildingNumberAddition: Maybe<Scalars['String']['output']>
  /** Country name of the of postal address in English. */
  country: Scalars['String']['output']
  /** Name of primary locality used in postal address. */
  locality: Scalars['String']['output']
  /** Postcode used in postal address. */
  postcode: Scalars['String']['output']
  /** Name of primary street used in postal address. */
  street: Scalars['String']['output']
}

export interface AutocompleteAddressDetailsCountry {
  __typename?: 'AutocompleteAddressDetailsCountry'
  /** The ISO 3166-1 Alpha-3 code for the country */
  iso3Code: Scalars['String']['output']
  /** The country name */
  name: Scalars['String']['output']
}

export interface AutocompleteAddressDetailsLocation {
  __typename?: 'AutocompleteAddressDetailsLocation'
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
  /** Precision of location. One of "Address", "Street", "PostalCode", "Locality" */
  precision: Scalars['String']['output']
}

export interface AutocompleteAddressDetailsResult {
  __typename?: 'AutocompleteAddressDetailsResult'
  address: AutocompleteAddressDetails
  country: AutocompleteAddressDetailsCountry
  details: Maybe<Scalars['JSON']['output']>
  isPoBox: Scalars['Boolean']['output']
  language: Scalars['String']['output']
  location: Maybe<AutocompleteAddressDetailsLocation>
  mailLines: Array<Scalars['String']['output']>
}

export enum AutocompleteAddressLanguages {
  DE = 'DE',
  DE_DE = 'DE_DE',
  EN = 'EN',
  EN_GB = 'EN_GB',
  EN_US = 'EN_US',
  NL = 'NL',
  NL_NL = 'NL_NL',
}

export interface AutocompleteAddressResult {
  __typename?: 'AutocompleteAddressResult'
  /** List of matches for the specified context and term. */
  matches: Array<AutocompleteAddressResultMatch>
  /**
   * New context that is required for further autocomplete requests. Null if no context update
   * is required.
   */
  newContext: Maybe<Scalars['String']['output']>
}

export interface AutocompleteAddressResultMatch {
  __typename?: 'AutocompleteAddressResultMatch'
  /**
   * If the user selects this match, use this as the context parameter in subsequent autocomplete
   * call. Contexts may expire and should not be stored.
   */
  context: Scalars['String']['output']
  /**
   * Additional information relevant to this match, helps with disambiguation between similar labels.
   * For example, a postal code associated with a matched street.
   */
  description: Scalars['String']['output']
  /**
   * List of [start, end] character offsets to highlight in the label in order to indicate what
   * was matched.
   */
  highlights: Array<Array<Scalars['Int']['output']>>
  /** Label describing this match. For example, a street or municipality name. */
  label: Scalars['String']['output']
  /**
   * Match precision, used to know what type of address information is available. E.g. "Locality",
   * "PostalCode", "Street", "PartialAddress", "Address"
   */
  precision: Scalars['String']['output']
  /**
   * The value represents all matched address information. If the user selects this match the current
   * term input must be replaced with this value.
   */
  value: Scalars['String']['output']
}

/** Describes a payment method that the shopper can use to pay for the order. */
export interface AvailablePaymentMethod {
  __typename?: 'AvailablePaymentMethod'
  /** The payment method code. */
  code: Scalars['String']['output']
  /** The payment method image. */
  image: Scalars['String']['output']
  /** If the payment method is an online integration */
  isDeferred: Scalars['Boolean']['output']
  /** The payment method title. */
  title: Scalars['String']['output']
}

export interface AvailablePaymentMethods {
  __typename?: 'AvailablePaymentMethods'
  /** Array with payment methods */
  paymentMethods: Maybe<Array<Maybe<AvailablePaymentMethod>>>
}

/** Contains details about the possible shipping methods and carriers. */
export interface AvailableShippingMethod {
  __typename?: 'AvailableShippingMethod'
  /** The cost of shipping using this shipping method. */
  amount: Money
  /** Indicates whether this shipping method can be applied to the cart. */
  available: Scalars['Boolean']['output']
  /** @deprecated The field should not be used on the storefront. */
  baseAmount: Maybe<Money>
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrierCode: Scalars['String']['output']
  /** The label for the carrier code. */
  carrierTitle: Scalars['String']['output']
  /** Describes an error condition. */
  errorMessage: Maybe<Scalars['String']['output']>
  /** A shipping method code associated with a carrier. The value could be null if no method is available. */
  methodCode: Maybe<Scalars['String']['output']>
  /** The label for the shipping method code. The value could be null if no method is available. */
  methodTitle: Maybe<Scalars['String']['output']>
  /** The cost of shipping using this shipping method, excluding tax. */
  priceExclTax: Money
  /** The cost of shipping using this shipping method, including tax. */
  priceInclTax: Money
}

export enum BatchMutationStatus {
  FAILURE = 'FAILURE',
  MIXED_RESULTS = 'MIXED_RESULTS',
  SUCCESS = 'SUCCESS',
}

export interface BergProduct {
  __typename?: 'BergProduct'
  product: ProductInterface
  year: Maybe<Scalars['Int']['output']>
}

/** Defines the billing address. */
export interface BillingAddressInput {
  /** Defines a billing address. */
  address?: InputMaybe<CartAddressInput>
  /** An ID from the customer's address book that uniquely identifies the address to be used for billing. */
  customer_address_id?: InputMaybe<Scalars['Int']['input']>
  /** Indicates whether to set the billing address to be the same as the existing shipping address on the cart. */
  same_as_shipping?: InputMaybe<Scalars['Boolean']['input']>
  /** Indicates whether to set the shipping address to be the same as this billing address. */
  use_for_shipping?: InputMaybe<Scalars['Boolean']['input']>
}

/** Contains details about the billing address. */
export interface BillingCartAddress extends CartAddressInterface {
  __typename?: 'BillingCartAddress'
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['output']
  /** The company specified for the billing or shipping address. */
  company: Maybe<Scalars['String']['output']>
  /** An object containing the country label and code. */
  country: CartAddressCountry
  /** @deprecated The field is used only in shipping address. */
  customerNotes: Maybe<Scalars['String']['output']>
  /** The customer's fax number. */
  fax: Maybe<Scalars['String']['output']>
  /** The first name of the customer or guest. */
  firstname: Scalars['String']['output']
  /** The last name of the customer or guest. */
  lastname: Scalars['String']['output']
  /** The middle name of the person associated with the billing/shipping address. */
  middlename: Maybe<Scalars['String']['output']>
  /** The ZIP or postal code of the billing or shipping address. */
  postcode: Maybe<Scalars['String']['output']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>
  /** An object containing the region label and code. */
  region: Maybe<CartAddressRegion>
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']['output']>>
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>
  /** The telephone number for the billing or shipping address. */
  telephone: Maybe<Scalars['String']['output']>
  /** The unique id of the customer address. */
  uid: Scalars['String']['output']
  /** The VAT company number for billing or shipping address. */
  vatId: Maybe<Scalars['String']['output']>
}

/** Contains details about an individual category that comprises a breadcrumb. */
export interface Breadcrumb {
  __typename?: 'Breadcrumb'
  /**
   * The ID of the category.
   * @deprecated Use `category_uid` instead.
   */
  categoryId: Maybe<Scalars['Int']['output']>
  /** The category level. */
  categoryLevel: Maybe<Scalars['Int']['output']>
  /** The display name of the category. */
  categoryName: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Breadcrumb` object. */
  categoryUid: Scalars['ID']['output']
  /** The URL key of the category. */
  categoryUrlKey: Maybe<Scalars['String']['output']>
  /** The URL path of the category. */
  categoryUrlPath: Maybe<Scalars['String']['output']>
}

/** An implementation for bundle product cart items. */
export interface BundleCartItem extends CartItemInterface {
  __typename?: 'BundleCartItem'
  /** An array containing the bundle options the shopper selected. */
  bundleOptions: Array<Maybe<SelectedBundleOption>>
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** The entered gift message for the cart item */
  giftMessage: Maybe<GiftMessage>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

/** Defines bundle product options for `CreditMemoItemInterface`. */
export interface BundleCreditMemoItem extends CreditMemoItemInterface {
  __typename?: 'BundleCreditMemoItem'
  /** A list of bundle options that are assigned to a bundle product that is part of a credit memo. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
}

/** Defines bundle product options for `InvoiceItemInterface`. */
export interface BundleInvoiceItem extends InvoiceItemInterface {
  __typename?: 'BundleInvoiceItem'
  /** A list of bundle options that are assigned to an invoiced bundle product. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output']
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
}

/** Defines an individual item within a bundle product. */
export interface BundleItem {
  __typename?: 'BundleItem'
  /**
   * An ID assigned to each type of item in a bundle product.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** An array of additional options for this bundle item. */
  options: Maybe<Array<Maybe<BundleItemOption>>>
  /** A number indicating the sequence order of this item compared to the other bundle items. */
  position: Maybe<Scalars['Int']['output']>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** Indicates whether the item must be included in the bundle. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The SKU of the bundle product. */
  sku: Maybe<Scalars['String']['output']>
  /** The display name of the item. */
  title: Maybe<Scalars['String']['output']>
  /** The input type that the customer uses to select the item. Examples include radio button and checkbox. */
  type: Maybe<Scalars['String']['output']>
  /** The unique ID for a `BundleItem` object. */
  uid: Maybe<Scalars['ID']['output']>
}

/** Defines the characteristics that comprise a specific bundle item and its options. */
export interface BundleItemOption {
  __typename?: 'BundleItemOption'
  /** Indicates whether the customer can change the number of items for this option. */
  canChangeQuantity: Maybe<Scalars['Boolean']['output']>
  /**
   * The ID assigned to the bundled item option.
   * @deprecated Use `uid` instead
   */
  id: Maybe<Scalars['Int']['output']>
  /** Indicates whether this option is the default option. */
  isDefault: Maybe<Scalars['Boolean']['output']>
  /** The text that identifies the bundled item option. */
  label: Maybe<Scalars['String']['output']>
  /** When a bundle item contains multiple options, the relative position of this option compared to the other options. */
  position: Maybe<Scalars['Int']['output']>
  /** The price of the selected option. */
  price: Maybe<Scalars['Float']['output']>
  /** One of FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** Contains details about this product option. */
  product: Maybe<ProductInterface>
  /**
   * Indicates the quantity of this specific bundle item.
   * @deprecated Use `quantity` instead.
   */
  qty: Maybe<Scalars['Float']['output']>
  /** The quantity of this specific bundle item. */
  quantity: Maybe<Scalars['Float']['output']>
  /** The unique ID for a `BundleItemOption` object. */
  uid: Scalars['ID']['output']
}

/** Defines the input for a bundle option. */
export interface BundleOptionInput {
  /** The ID of the option. */
  id: Scalars['Int']['input']
  /** The number of the selected item to add to the cart. */
  quantity: Scalars['Float']['input']
  /** An array with the chosen value of the option. */
  value: Array<InputMaybe<Scalars['String']['input']>>
}

/** Defines bundle product options for `OrderItemInterface`. */
export interface BundleOrderItem extends OrderItemInterface {
  __typename?: 'BundleOrderItem'
  /** The total refunded amount */
  amountRefunded: Money
  /** A list of bundle options that are assigned to the bundle product. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output']
  /** The ProductInterface object, which contains details about the base product */
  product: Maybe<ProductInterface>
  /** Product image */
  productImage: Maybe<ProductImage>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>
  /** The total row amount */
  rowTotalIncludingTax: Money
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>
}

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export interface BundleProduct
  extends CustomizableProductInterface,
    PhysicalProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'BundleProduct'
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeBalancebike: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeLgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeRideoncar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeScooter: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeSgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeTrampoline: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  addOn: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSeat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSteeringWheel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  aerowall: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ageRecommended: Maybe<Scalars['Int']['output']>
  /** Ambiance Product Images */
  ambianceGallery: Maybe<Array<Maybe<AmbianceGallery>>>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  categoryPlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ceLabel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  collaborationLicense: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** Color Variants */
  colorVariants: Maybe<Array<Maybe<ColorVariant>>>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  drive: Maybe<Scalars['Int']['output']>
  /** Filterable attribute with Text */
  dynamicAttributes: Maybe<Array<Maybe<DynamicAttribute>>>
  /** Indicates whether the bundle product has a dynamic price. */
  dynamicPrice: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the bundle product has a dynamic SKU. */
  dynamicSku: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the bundle product has a dynamically calculated weight. */
  dynamicWeight: Maybe<Scalars['Boolean']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  electricalSupport: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  entranceHeight: Maybe<Scalars['Int']['output']>
  /** Craft entry type */
  entryType: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  framePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  frameXlGokart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gears: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['Int']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gokartType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  handbrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  heightTrampoline: Maybe<Scalars['Int']['output']>
  /** Highlight attributes */
  highlightedAttributes: Maybe<Array<Maybe<HighlightAttribute>>>
  /** Highlight attributes PLP */
  highlightedAttributesPlp: Maybe<Array<Maybe<HighlightAttribute>>>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Scalars['Int']['output']
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  inclSafetynet: Maybe<Scalars['Int']['output']>
  /** Whether the product is a main product or not. */
  isMainProduct: Scalars['Boolean']['output']
  /** An array containing information about individual bundle items. */
  items: Maybe<Array<Maybe<BundleItem>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  jumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  lengthUser: Maybe<Scalars['Int']['output']>
  /** A number representing the product's manufacturer. */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  material: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  materialTires: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUserWeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUsers: Maybe<Scalars['Int']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgBrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgClamp: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgDeck: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgFrontFork: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGrips: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGriptape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHandlebar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHeadset: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgWheels: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  model: Maybe<Scalars['Int']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Scalars['String']['output']
  /** @deprecated Use the `custom_attributes` field instead. */
  netWeight: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  numberOfSprings: Maybe<Scalars['Int']['output']>
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  pedals: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  playbaseSet: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  possibilityFor2ndSeat: Maybe<Scalars['Int']['output']>
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The price details of the main product */
  priceDetails: Maybe<PriceDetails>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** One of PRICE_RANGE or AS_LOW_AS. */
  priceView: Maybe<PriceViewEnum>
  /** Product downloads */
  productDownloads: Maybe<ProductDownload>
  productLabels: Maybe<Array<Maybe<AmLabelList>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  pushRod: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityLine: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityStars: Maybe<Scalars['Int']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of products to be displayed in a Related Products block. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** @deprecated Use the `custom_attributes` field instead. */
  safetynetType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  seatHeight: Maybe<Scalars['Int']['output']>
  /** Indicates whether to ship bundle items together or individually. */
  shipBundleItems: Maybe<ShipBundleItemsEnum>
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAccessories: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAirtrack: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeBeachwagon: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeClothing: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeGocart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeJumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeStep: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeWheelbarrow: Maybe<Scalars['Int']['output']>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Scalars['String']['output']
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /** Get list of spareparts per product(group), per year */
  sparepartLinks: Maybe<Array<Maybe<BergProduct>>>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  springType: Maybe<Scalars['Int']['output']>
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  testweight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  themePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  thicknessOfProtectiveEdge: Maybe<Scalars['Int']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineShape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  tuevGs: Maybe<Scalars['Int']['output']>
  /** Tweakwise ID */
  tweakwiseItemNumber: Maybe<Scalars['String']['output']>
  /** PreOrder info */
  tweakwiseTemplates: Maybe<TweakwiseTemplates>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /** @deprecated Use the `custom_attributes` field instead. */
  typeAccessory: Maybe<Scalars['Int']['output']>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Scalars['String']['output']
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  uvProtection: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warrantyWoRegistration: Maybe<Scalars['Int']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelLights: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelSize: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  widthPadding: Maybe<Scalars['Int']['output']>
}

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export interface BundleProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export interface BundleProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines a single bundle product. */
export interface BundleProductCartItemInput {
  /** A mandatory array of options for the bundle product, including each chosen option and specified quantity. */
  bundle_options: Array<InputMaybe<BundleOptionInput>>
  /** The ID and value of the option. */
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** The quantity and SKU of the bundle product. */
  data: CartItemInput
}

/** Defines bundle product options for `ShipmentItemInterface`. */
export interface BundleShipmentItem extends ShipmentItemInterface {
  __typename?: 'BundleShipmentItem'
  /** A list of bundle options that are assigned to a shipped product. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of shipped items. */
  quantityShipped: Scalars['Float']['output']
}

/** Defines bundle product options for `WishlistItemInterface`. */
export interface BundleWishlistItem extends WishlistItemInterface {
  __typename?: 'BundleWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** An array containing information about the selected bundle items. */
  bundleOptions: Maybe<Array<Maybe<SelectedBundleOption>>>
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

export interface ButtonStyles {
  __typename?: 'ButtonStyles'
  /** The button color */
  color: Maybe<Scalars['String']['output']>
  /** The button height in pixels */
  height: Maybe<Scalars['Int']['output']>
  /** The button label */
  label: Maybe<Scalars['String']['output']>
  /** The button layout */
  layout: Maybe<Scalars['String']['output']>
  /** The button shape */
  shape: Maybe<Scalars['String']['output']>
  /** Indicates whether the tagline is displayed */
  tagline: Maybe<Scalars['Boolean']['output']>
  /** Defines if the button uses default height. If the value is false, the value of height is used */
  useDefaultHeight: Maybe<Scalars['Boolean']['output']>
}

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

/** Defines the order to cancel. */
export interface CancelOrderInput {
  /** Order ID. */
  order_id: Scalars['ID']['input']
  /** Cancellation reason. */
  reason: Scalars['String']['input']
}

/** Contains the updated customer order and error message if any. */
export interface CancelOrderOutput {
  __typename?: 'CancelOrderOutput'
  /** Error encountered while cancelling the order. */
  error: Maybe<Scalars['String']['output']>
  /** Updated customer order. */
  order: Maybe<CustomerOrder>
}

export interface CancellationReason {
  __typename?: 'CancellationReason'
  description: Scalars['String']['output']
}

export interface Card {
  __typename?: 'Card'
  /** Card bin details */
  binDetails: Maybe<CardBin>
  /** Expiration month of the card */
  cardExpiryMonth: Maybe<Scalars['String']['output']>
  /** Expiration year of the card */
  cardExpiryYear: Maybe<Scalars['String']['output']>
  /** Last four digits of the card */
  lastDigits: Maybe<Scalars['String']['output']>
  /** Name on the card */
  name: Maybe<Scalars['String']['output']>
}

export interface CardBin {
  __typename?: 'CardBin'
  /** Card bin number */
  bin: Maybe<Scalars['String']['output']>
}

/** Contains the contents and other details about a guest or customer cart. */
export interface Cart {
  __typename?: 'Cart'
  /** @deprecated Use `applied_coupons` instead. */
  appliedCoupon: Maybe<AppliedCoupon>
  /** An array of `AppliedCoupon` objects. Each object contains the `code` text attribute, which specifies the coupon code. */
  appliedCoupons: Maybe<Array<Maybe<AppliedCoupon>>>
  /** An array of available payment methods. */
  availablePaymentMethods: Maybe<Array<Maybe<AvailablePaymentMethod>>>
  /** The billing address assigned to the cart. */
  billingAddress: Maybe<BillingCartAddress>
  /** The email address of the guest or customer. */
  email: Maybe<Scalars['String']['output']>
  /** The entered gift message for the cart */
  giftMessage: Maybe<GiftMessage>
  /** The unique ID for a `Cart` object. */
  id: Scalars['ID']['output']
  /** Indicates whether the cart contains only virtual products. */
  isVirtual: Scalars['Boolean']['output']
  itemErrors: Array<Maybe<CartItemError>>
  items: Array<Maybe<CartItemInterface>>
  itemsV2: Maybe<CartItems>
  /** Last Added UID */
  lastItemUid: Maybe<Scalars['ID']['output']>
  /** Pricing details for the quote. */
  prices: Maybe<CartPrices>
  /** Indicates which payment method was applied to the cart. */
  selectedPaymentMethod: Maybe<SelectedPaymentMethod>
  /** An array of shipping addresses assigned to the cart. */
  shippingAddresses: Array<Maybe<ShippingCartAddress>>
  /** The total number of items in the cart. */
  totalQuantity: Scalars['Float']['output']
}

/** Contains the contents and other details about a guest or customer cart. */
export interface CartItemsV2Args {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort: InputMaybe<QuoteItemsSortInput>
}

/** Contains details the country in a billing or shipping address. */
export interface CartAddressCountry {
  __typename?: 'CartAddressCountry'
  /** The country code. */
  code: Scalars['String']['output']
  /** The display label for the country. */
  label: Scalars['String']['output']
}

/** Defines the billing or shipping address to be applied to the cart. */
export interface CartAddressInput {
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['input']
  /** The company specified for the billing or shipping address. */
  company?: InputMaybe<Scalars['String']['input']>
  /** The country code and label for the billing or shipping address. */
  country_code: Scalars['String']['input']
  /** The customer's fax number. */
  fax?: InputMaybe<Scalars['String']['input']>
  /** The first name of the customer or guest. */
  firstname: Scalars['String']['input']
  /** The last name of the customer or guest. */
  lastname: Scalars['String']['input']
  /** The middle name of the person associated with the billing/shipping address. */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** A string that defines the state or province of the billing or shipping address. */
  region?: InputMaybe<Scalars['String']['input']>
  /** An integer that defines the state or province of the billing or shipping address. */
  region_id?: InputMaybe<Scalars['Int']['input']>
  /** Determines whether to save the address in the customer's address book. The default value is true. */
  save_in_address_book?: InputMaybe<Scalars['Boolean']['input']>
  /** An array containing the street for the billing or shipping address. */
  street: Array<InputMaybe<Scalars['String']['input']>>
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The telephone number for the billing or shipping address. */
  telephone?: InputMaybe<Scalars['String']['input']>
  /** The VAT company number for billing or shipping address. */
  vat_id?: InputMaybe<Scalars['String']['input']>
}

export interface CartAddressInterface {
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['output']
  /** The company specified for the billing or shipping address. */
  company: Maybe<Scalars['String']['output']>
  /** An object containing the country label and code. */
  country: CartAddressCountry
  /** The customer's fax number. */
  fax: Maybe<Scalars['String']['output']>
  /** The first name of the customer or guest. */
  firstname: Scalars['String']['output']
  /** The last name of the customer or guest. */
  lastname: Scalars['String']['output']
  /** The middle name of the person associated with the billing/shipping address. */
  middlename: Maybe<Scalars['String']['output']>
  /** The ZIP or postal code of the billing or shipping address. */
  postcode: Maybe<Scalars['String']['output']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>
  /** An object containing the region label and code. */
  region: Maybe<CartAddressRegion>
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']['output']>>
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>
  /** The telephone number for the billing or shipping address. */
  telephone: Maybe<Scalars['String']['output']>
  /** The unique id of the customer address. */
  uid: Scalars['String']['output']
  /** The VAT company number for billing or shipping address. */
  vatId: Maybe<Scalars['String']['output']>
}

/** Contains details about the region in a billing or shipping address. */
export interface CartAddressRegion {
  __typename?: 'CartAddressRegion'
  /** The state or province code. */
  code: Maybe<Scalars['String']['output']>
  /** The display label for the region. */
  label: Maybe<Scalars['String']['output']>
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']['output']>
}

/** Contains information about discounts applied to the cart. */
export interface CartDiscount {
  __typename?: 'CartDiscount'
  /** The amount of the discount applied to the item. */
  amount: Money
  /** The description of the discount. */
  label: Array<Maybe<Scalars['String']['output']>>
}

export enum CartDiscountType {
  ITEM = 'ITEM',
  SHIPPING = 'SHIPPING',
}

export interface CartItemError {
  __typename?: 'CartItemError'
  code: CartItemErrorCode
  id: Scalars['String']['output']
  message: Scalars['String']['output']
}

export enum CartItemErrorCode {
  ITEM_INCREMENTS = 'ITEM_INCREMENTS',
  ITEM_QTY = 'ITEM_QTY',
  NO_SUCH_PRODUCT = 'NO_SUCH_PRODUCT',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  UNDEFINED = 'UNDEFINED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export enum CartItemErrorType {
  ITEM_INCREMENTS = 'ITEM_INCREMENTS',
  ITEM_QTY = 'ITEM_QTY',
  UNDEFINED = 'UNDEFINED',
}

/** Defines an item to be added to the cart. */
export interface CartItemInput {
  /** An array of entered options for the base product, such as personalization text. */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** For a child product, the SKU of its parent product. */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  /** The amount or number of an item to add. */
  quantity: Scalars['Float']['input']
  /** The selected options for the base product, such as color or size, using the unique ID for an object such as `CustomizableRadioOption`, `CustomizableDropDownOption`, or `ConfigurableProductOptionsValues`. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The SKU of the product. */
  sku: Scalars['String']['input']
}

/** An interface for products in a cart. */
export interface CartItemInterface {
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

/** Contains details about the price of the item, including taxes and discounts. */
export interface CartItemPrices {
  __typename?: 'CartItemPrices'
  /** An array of discounts to be applied to the cart item. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** An array of FPTs applied to the cart item. */
  fixedProductTaxes: Maybe<Array<Maybe<FixedProductTax>>>
  /** The price of the item before any discounts were applied. The price that might include tax, depending on the configured display settings for cart. */
  price: Money
  /** The price of the item before any discounts were applied. The price that might include tax, depending on the configured display settings for cart. */
  priceIncludingTax: Money
  /** The value of the price multiplied by the quantity of the item. */
  rowTotal: Money
  /** The value of `row_total` plus the tax applied to the item. */
  rowTotalIncludingTax: Money
  /** The total of all discounts applied to the item. */
  totalItemDiscount: Maybe<Money>
}

/** Deprecated: The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
export interface CartItemQuantity {
  __typename?: 'CartItemQuantity'
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  cartItemId: Scalars['Int']['output']
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  quantity: Scalars['Float']['output']
}

/** Contains details about the price of a selected customizable value. */
export interface CartItemSelectedOptionValuePrice {
  __typename?: 'CartItemSelectedOptionValuePrice'
  /** Indicates whether the price type is fixed, percent, or dynamic. */
  type: PriceTypeEnum
  /** A string that describes the unit of the value. */
  units: Scalars['String']['output']
  /** A price value. */
  value: Scalars['Float']['output']
}

/** A single item to be updated. */
export interface CartItemUpdateInput {
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: InputMaybe<Scalars['Int']['input']>
  /** The unique ID for a `CartItemInterface` object. */
  cart_item_uid?: InputMaybe<Scalars['ID']['input']>
  /** An array that defines customizable options for the product. */
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** Gift message details for the cart item */
  gift_message?: InputMaybe<GiftMessageInput>
  /** The new quantity of the item. */
  quantity?: InputMaybe<Scalars['Float']['input']>
}

export interface CartItems {
  __typename?: 'CartItems'
  /** An array of products that have been added to the cart. */
  items: Array<Maybe<CartItemInterface>>
  /** Metadata for pagination rendering. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The number of returned cart items. */
  totalCount: Scalars['Int']['output']
}

/** Contains details about the final price of items in the cart, including discount and tax information. */
export interface CartPrices {
  __typename?: 'CartPrices'
  /** An array containing the names and amounts of taxes applied to each item in the cart. */
  appliedTaxes: Maybe<Array<Maybe<CartTaxItem>>>
  /** @deprecated Use discounts instead. */
  discount: Maybe<CartDiscount>
  /** An array containing cart rule discounts, store credit and gift cards applied to the cart. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The total, including discounts, taxes, shipping, and other fees. */
  grandTotal: Maybe<Money>
  /** The subtotal without any applied taxes. */
  subtotalExcludingTax: Maybe<Money>
  /** The subtotal including any applied taxes. */
  subtotalIncludingTax: Maybe<Money>
  /** The subtotal with any discounts applied, but not taxes. */
  subtotalWithDiscountExcludingTax: Maybe<Money>
}

/** Contains tax information about an item in the cart. */
export interface CartTaxItem {
  __typename?: 'CartTaxItem'
  /** The amount of tax applied to the item. */
  amount: Money
  /** The description of the tax. */
  label: Scalars['String']['output']
}

/** An error encountered while adding an item to the the cart. */
export interface CartUserInputError {
  __typename?: 'CartUserInputError'
  /** A cart-specific error code. */
  code: CartUserInputErrorType
  /** A localized error message. */
  message: Scalars['String']['output']
}

export enum CartUserInputErrorType {
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  NOT_SALABLE = 'NOT_SALABLE',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  UNDEFINED = 'UNDEFINED',
}

export enum CatalogAttributeApplyToEnum {
  BUNDLE = 'BUNDLE',
  CATEGORY = 'CATEGORY',
  CONFIGURABLE = 'CONFIGURABLE',
  DOWNLOADABLE = 'DOWNLOADABLE',
  GROUPED = 'GROUPED',
  SIMPLE = 'SIMPLE',
  VIRTUAL = 'VIRTUAL',
}

/** Swatch attribute metadata. */
export interface CatalogAttributeMetadata
  extends CustomAttributeMetadataInterface {
  __typename?: 'CatalogAttributeMetadata'
  /** To which catalog types an attribute can be applied. */
  applyTo: Maybe<Array<Maybe<CatalogAttributeApplyToEnum>>>
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  code: Scalars['ID']['output']
  /** Default attribute value. */
  defaultValue: Maybe<Scalars['String']['output']>
  /** The type of entity that defines the attribute. */
  entityType: AttributeEntityTypeEnum
  /** The frontend class of the attribute. */
  frontendClass: Maybe<Scalars['String']['output']>
  /** The frontend input type of the attribute. */
  frontendInput: Maybe<AttributeFrontendInputEnum>
  /** Whether a product or category attribute can be compared against another or not. */
  isComparable: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute can be filtered or not. */
  isFilterable: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute can be filtered in search or not. */
  isFilterableInSearch: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute can use HTML on front or not. */
  isHtmlAllowedOnFront: Maybe<Scalars['Boolean']['output']>
  /** Whether the attribute value is required. */
  isRequired: Scalars['Boolean']['output']
  /** Whether a product or category attribute can be searched or not. */
  isSearchable: Maybe<Scalars['Boolean']['output']>
  /** Whether the attribute value must be unique. */
  isUnique: Scalars['Boolean']['output']
  /** Whether a product or category attribute can be used for price rules or not. */
  isUsedForPriceRules: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute is used for promo rules or not. */
  isUsedForPromoRules: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute is visible in advanced search or not. */
  isVisibleInAdvancedSearch: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute is visible on front or not. */
  isVisibleOnFront: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute has WYSIWYG enabled or not. */
  isWysiwygEnabled: Maybe<Scalars['Boolean']['output']>
  /** The label assigned to the attribute. */
  label: Maybe<Scalars['String']['output']>
  /** Attribute options. */
  options: Array<Maybe<CustomAttributeOptionInterface>>
  /** Input type of the swatch attribute option. */
  swatchInputType: Maybe<SwatchInputTypeEnum>
  /** Whether update product preview image or not. */
  updateProductPreviewImage: Maybe<Scalars['Boolean']['output']>
  /** Whether use product image for swatch or not. */
  useProductImageForSwatch: Maybe<Scalars['Boolean']['output']>
  /** Whether a product or category attribute is used in product listing or not. */
  usedInProductListing: Maybe<Scalars['Boolean']['output']>
}

/** Defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export interface CategoryFilterInput {
  /** Filter by the unique category ID for a `CategoryInterface` object. */
  category_uid?: InputMaybe<FilterEqualTypeInput>
  /** Deprecated: use 'category_uid' to filter uniquely identifiers of categories. */
  ids?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the display name of the category. */
  name?: InputMaybe<FilterMatchTypeInput>
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_category_uid?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_id?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the part of the URL that identifies the category. */
  url_key?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the URL path for the category. */
  url_path?: InputMaybe<FilterEqualTypeInput>
}

/** Contains the full set of attributes that can be returned in a category search. */
export interface CategoryInterface {
  availableSortBy: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** An array of breadcrumb items. */
  breadcrumbs: Maybe<Array<Maybe<Breadcrumb>>>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  channableCatDisableExport: Maybe<Scalars['Int']['output']>
  childrenCount: Maybe<Scalars['String']['output']>
  /** Contains a category CMS block. */
  cmsBlock: Maybe<CmsBlock>
  /** Craft Slug */
  craftSlug: Maybe<Scalars['String']['output']>
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  customLayoutUpdateFile: Maybe<Scalars['String']['output']>
  /** The attribute to use for sorting. */
  defaultSortBy: Maybe<Scalars['String']['output']>
  /** An optional description of the category. */
  description: Maybe<Scalars['String']['output']>
  displayMode: Maybe<Scalars['String']['output']>
  filterPriceRange: Maybe<Scalars['Float']['output']>
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>
  image: Maybe<Scalars['String']['output']>
  includeInMenu: Maybe<Scalars['Int']['output']>
  isAnchor: Maybe<Scalars['Int']['output']>
  landingPage: Maybe<Scalars['Int']['output']>
  /** The depth of the category within the tree. */
  level: Maybe<Scalars['Int']['output']>
  metaDescription: Maybe<Scalars['String']['output']>
  metaKeywords: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** The display name of the category. */
  name: Maybe<Scalars['String']['output']>
  /** The full category path. */
  path: Maybe<Scalars['String']['output']>
  /** The category path within the store. */
  pathInStore: Maybe<Scalars['String']['output']>
  /** The position of the category relative to other categories at the same level in tree. */
  position: Maybe<Scalars['Int']['output']>
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  productCount: Maybe<Scalars['Int']['output']>
  /** The list of products assigned to the category. */
  products: Maybe<CategoryProducts>
  tweakwiseCrosssellGroupCode: Maybe<Scalars['String']['output']>
  tweakwiseCrosssellTemplate: Maybe<Scalars['Int']['output']>
  tweakwiseFeaturedTemplate: Maybe<Scalars['Int']['output']>
  tweakwiseFilterValuesWhitelist: Maybe<Scalars['String']['output']>
  tweakwiseFilterWhitelist: Maybe<Scalars['String']['output']>
  /** Tweakwise ID */
  tweakwiseItemNumber: Maybe<Scalars['String']['output']>
  tweakwiseShoppingcartCrosssellGroupCode: Maybe<Scalars['String']['output']>
  tweakwiseShoppingcartCrosssellTemplate: Maybe<Scalars['Int']['output']>
  tweakwiseUpsellGroupCode: Maybe<Scalars['String']['output']>
  tweakwiseUpsellTemplate: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** The URL key assigned to the category. */
  urlKey: Maybe<Scalars['String']['output']>
  /** The URL path assigned to the category. */
  urlPath: Maybe<Scalars['String']['output']>
  /** The part of the category URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
}

/** Contains the full set of attributes that can be returned in a category search. */
export interface CategoryInterfaceProductsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort: InputMaybe<ProductAttributeSortInput>
}

/** Contains details about the products assigned to a category. */
export interface CategoryProducts {
  __typename?: 'CategoryProducts'
  /** An array of products that are assigned to the category. */
  items: Maybe<Array<Maybe<ProductInterface>>>
  /** Pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Contains a collection of `CategoryTree` objects and pagination information. */
export interface CategoryResult {
  __typename?: 'CategoryResult'
  /** A list of categories that match the filter criteria. */
  items: Maybe<Array<Maybe<CategoryTree>>>
  /** An object that includes the `page_info` and `currentPage` values specified in the query. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The total number of categories that match the criteria. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Contains the hierarchy of categories. */
export interface CategoryTree extends CategoryInterface, RoutableInterface {
  __typename?: 'CategoryTree'
  availableSortBy: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** An array of breadcrumb items. */
  breadcrumbs: Maybe<Array<Maybe<Breadcrumb>>>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  channableCatDisableExport: Maybe<Scalars['Int']['output']>
  /** A tree of child categories. */
  children: Maybe<Array<Maybe<CategoryTree>>>
  childrenCount: Maybe<Scalars['String']['output']>
  /** Contains a category CMS block. */
  cmsBlock: Maybe<CmsBlock>
  /** Craft Slug */
  craftSlug: Maybe<Scalars['String']['output']>
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  customLayoutUpdateFile: Maybe<Scalars['String']['output']>
  /** The attribute to use for sorting. */
  defaultSortBy: Maybe<Scalars['String']['output']>
  /** An optional description of the category. */
  description: Maybe<Scalars['String']['output']>
  displayMode: Maybe<Scalars['String']['output']>
  /** Craft entry type */
  entryType: Maybe<Scalars['String']['output']>
  filterPriceRange: Maybe<Scalars['Float']['output']>
  id: Scalars['Int']['output']
  image: Maybe<Scalars['String']['output']>
  includeInMenu: Maybe<Scalars['Int']['output']>
  isAnchor: Maybe<Scalars['Int']['output']>
  landingPage: Maybe<Scalars['Int']['output']>
  /** The depth of the category within the tree. */
  level: Maybe<Scalars['Int']['output']>
  metaDescription: Maybe<Scalars['String']['output']>
  metaKeywords: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  /** The full category path. */
  path: Maybe<Scalars['String']['output']>
  /** The category path within the store. */
  pathInStore: Maybe<Scalars['String']['output']>
  /** The position of the category relative to other categories at the same level in tree. */
  position: Maybe<Scalars['Int']['output']>
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  productCount: Maybe<Scalars['Int']['output']>
  /** The list of products assigned to the category. */
  products: Maybe<CategoryProducts>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  tweakwiseCrosssellGroupCode: Maybe<Scalars['String']['output']>
  tweakwiseCrosssellTemplate: Maybe<Scalars['Int']['output']>
  tweakwiseFeaturedTemplate: Maybe<Scalars['Int']['output']>
  tweakwiseFilterValuesWhitelist: Maybe<Scalars['String']['output']>
  tweakwiseFilterWhitelist: Maybe<Scalars['String']['output']>
  /** Tweakwise ID */
  tweakwiseItemNumber: Maybe<Scalars['String']['output']>
  tweakwiseShoppingcartCrosssellGroupCode: Maybe<Scalars['String']['output']>
  tweakwiseShoppingcartCrosssellTemplate: Maybe<Scalars['Int']['output']>
  tweakwiseUpsellGroupCode: Maybe<Scalars['String']['output']>
  tweakwiseUpsellTemplate: Maybe<Scalars['Int']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  urlKey: Scalars['String']['output']
  /** The URL path assigned to the category. */
  urlPath: Maybe<Scalars['String']['output']>
  /** The part of the category URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
}

/** Contains the hierarchy of categories. */
export interface CategoryTreeProductsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort: InputMaybe<ProductAttributeSortInput>
}

/** Defines details about an individual checkout agreement. */
export interface CheckoutAgreement {
  __typename?: 'CheckoutAgreement'
  /** The ID for a checkout agreement. */
  agreementId: Scalars['Int']['output']
  /** The checkbox text for the checkout agreement. */
  checkboxText: Scalars['String']['output']
  /** Required. The text of the agreement. */
  content: Scalars['String']['output']
  /** The height of the text box where the Terms and Conditions statement appears during checkout. */
  contentHeight: Maybe<Scalars['String']['output']>
  /** Indicates whether the `content` text is in HTML format. */
  isHtml: Scalars['Boolean']['output']
  /** Indicates whether agreements are accepted automatically or manually. */
  mode: CheckoutAgreementMode
  /** The name given to the condition. */
  name: Scalars['String']['output']
}

/** Indicates how agreements are accepted. */
export enum CheckoutAgreementMode {
  /** Conditions are automatically accepted upon checkout. */
  AUTO = 'AUTO',
  /** Shoppers must manually accept the conditions to place an order. */
  MANUAL = 'MANUAL',
}

/** An error encountered while adding an item to the cart. */
export interface CheckoutUserInputError {
  __typename?: 'CheckoutUserInputError'
  /** An error code that is specific to Checkout. */
  code: CheckoutUserInputErrorCodes
  /** A localized error message. */
  message: Scalars['String']['output']
  /** The path to the input field that caused an error. See the GraphQL specification about path errors for details: http://spec.graphql.org/draft/#sec-Errors */
  path: Array<Maybe<Scalars['String']['output']>>
}

export enum CheckoutUserInputErrorCodes {
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  NOT_SALABLE = 'NOT_SALABLE',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  REORDER_NOT_AVAILABLE = 'REORDER_NOT_AVAILABLE',
  UNDEFINED = 'UNDEFINED',
}

/** Contains details about a specific CMS block. */
export interface CmsBlock {
  __typename?: 'CmsBlock'
  /** The content of the CMS block in raw HTML. */
  content: Maybe<Scalars['String']['output']>
  /** The CMS block identifier. */
  identifier: Maybe<Scalars['String']['output']>
  /** The title assigned to the CMS block. */
  title: Maybe<Scalars['String']['output']>
}

/** Contains an array CMS block items. */
export interface CmsBlocks {
  __typename?: 'CmsBlocks'
  /** An array of CMS blocks. */
  items: Maybe<Array<Maybe<CmsBlock>>>
}

/** Contains details about a CMS page. */
export interface CmsPage extends RoutableInterface {
  __typename?: 'CmsPage'
  /** The content of the CMS page in raw HTML. */
  content: Maybe<Scalars['String']['output']>
  /** The heading that displays at the top of the CMS page. */
  contentHeading: Maybe<Scalars['String']['output']>
  /** Craft entry type */
  entryType: Maybe<Scalars['String']['output']>
  /** The ID of a CMS page. */
  identifier: Maybe<Scalars['String']['output']>
  /** A brief description of the page for search results listings. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A brief description of the page for search results listings. */
  metaKeywords: Maybe<Scalars['String']['output']>
  /** A page title that is indexed by search engines and appears in search results listings. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** The design layout of the page, indicating the number of columns and navigation features used on the page. */
  pageLayout: Maybe<Scalars['String']['output']>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The name that appears in the breadcrumb trail navigation and in the browser title bar and tab. */
  title: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /** The URL key of the CMS page, which is often based on the `content_heading`. */
  urlKey: Maybe<Scalars['String']['output']>
}

/** The status of a unique_code */
export enum CodeStatus {
  EXPORTED = 'EXPORTED',
  NEW = 'NEW',
  USED = 'USED',
}

export interface ColorSwatch {
  __typename?: 'ColorSwatch'
  type: Scalars['Int']['output']
  value: Scalars['String']['output']
}

export interface ColorSwatchData extends SwatchDataInterface {
  __typename?: 'ColorSwatchData'
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']['output']>
}

export interface ColorVariant {
  __typename?: 'ColorVariant'
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** The url to the Color Variant image. If not set, the url to the thumbnail is used */
  image: Maybe<Scalars['String']['output']>
  /** Whether the product is the current product or not. */
  isCurrent: Scalars['Boolean']['output']
  /** Whether the product is a main product or not. */
  isMainProduct: Scalars['Boolean']['output']
  /** Whether the product is salable or not. */
  isSalable: Scalars['Boolean']['output']
  /** The modelname for a color variant */
  modelName: Maybe<Scalars['String']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>
  /** The status of the product; `1 = enabled`, `2 = disabled`. */
  status: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /** The product URL Key */
  url: Maybe<Scalars['String']['output']>
}

export interface Comment {
  __typename?: 'Comment'
  /** Created at. */
  createdAt: Maybe<Scalars['String']['output']>
  /** Customer id. */
  customerId: Maybe<Scalars['Int']['output']>
  /** Email. */
  email: Maybe<Scalars['String']['output']>
  /** Comment id. */
  id: Maybe<Scalars['Int']['output']>
  /** Message. */
  message: Maybe<Scalars['String']['output']>
  /** Nickname. */
  nickname: Maybe<Scalars['String']['output']>
  /** Review id. */
  reviewId: Maybe<Scalars['Int']['output']>
  /** Session id. */
  sessionId: Maybe<Scalars['String']['output']>
  /** Status. */
  status: Maybe<Scalars['Int']['output']>
  /** Store id. */
  storeId: Maybe<Scalars['Int']['output']>
  /** Updated at. */
  updatedAt: Maybe<Scalars['String']['output']>
}

/** Contains an attribute code that is used for product comparisons. */
export interface ComparableAttribute {
  __typename?: 'ComparableAttribute'
  /** An attribute code that is enabled for product comparisons. */
  code: Scalars['String']['output']
  /** The label of the attribute code. */
  label: Scalars['String']['output']
}

/** Defines an object used to iterate through items for product comparisons. */
export interface ComparableItem {
  __typename?: 'ComparableItem'
  /** An array of product attributes that can be used to compare products. */
  attributes: Array<Maybe<ProductAttribute>>
  /** Details about a product in a compare list. */
  product: ProductInterface
  /** The unique ID of an item in a compare list. */
  uid: Scalars['ID']['output']
}

/** Contains iterable information such as the array of items, the count, and attributes that represent the compare list. */
export interface CompareList {
  __typename?: 'CompareList'
  /** An array of attributes that can be used for comparing products. */
  attributes: Maybe<Array<Maybe<ComparableAttribute>>>
  /** The number of items in the compare list. */
  itemCount: Scalars['Int']['output']
  /** An array of products to compare. */
  items: Maybe<Array<Maybe<ComparableItem>>>
  /** The unique ID assigned to the compare list. */
  uid: Scalars['ID']['output']
}

export interface ComplexTextValue {
  __typename?: 'ComplexTextValue'
  /** Text that can contain HTML tags. */
  html: Scalars['String']['output']
}

/** Contains details about a configurable product attribute option. */
export interface ConfigurableAttributeOption {
  __typename?: 'ConfigurableAttributeOption'
  /** The ID assigned to the attribute. */
  code: Maybe<Scalars['String']['output']>
  /** A string that describes the configurable attribute option. */
  label: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ConfigurableAttributeOption` object. */
  uid: Scalars['ID']['output']
  /** A unique index number assigned to the configurable product option. */
  valueIndex: Maybe<Scalars['Int']['output']>
}

/** An implementation for configurable product cart items. */
export interface ConfigurableCartItem extends CartItemInterface {
  __typename?: 'ConfigurableCartItem'
  /** An array containing the configuranle options the shopper selected. */
  configurableOptions: Array<Maybe<SelectedConfigurableOption>>
  /** Product details of the cart item. */
  configuredVariant: ProductInterface
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** The entered gift message for the cart item */
  giftMessage: Maybe<GiftMessage>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

/** Describes configurable options that have been selected and can be selected as a result of the previous selections. */
export interface ConfigurableOptionAvailableForSelection {
  __typename?: 'ConfigurableOptionAvailableForSelection'
  /** An attribute code that uniquely identifies a configurable option. */
  attributeCode: Scalars['String']['output']
  /** An array of selectable option value IDs. */
  optionValueUids: Array<Maybe<Scalars['ID']['output']>>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProduct
  extends CustomizableProductInterface,
    PhysicalProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'ConfigurableProduct'
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeBalancebike: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeLgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeRideoncar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeScooter: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeSgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeTrampoline: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  addOn: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSeat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSteeringWheel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  aerowall: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ageRecommended: Maybe<Scalars['Int']['output']>
  /** Ambiance Product Images */
  ambianceGallery: Maybe<Array<Maybe<AmbianceGallery>>>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  categoryPlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ceLabel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  collaborationLicense: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** Color Variants */
  colorVariants: Maybe<Array<Maybe<ColorVariant>>>
  /** An array of options for the configurable product. */
  configurableOptions: Maybe<Array<Maybe<ConfigurableProductOptions>>>
  /** An array of media gallery items and other details about selected configurable product options as well as details about remaining selectable options. */
  configurableProductOptionsSelection: Maybe<ConfigurableProductOptionsSelection>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  drive: Maybe<Scalars['Int']['output']>
  /** Filterable attribute with Text */
  dynamicAttributes: Maybe<Array<Maybe<DynamicAttribute>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  electricalSupport: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  entranceHeight: Maybe<Scalars['Int']['output']>
  /** Craft entry type */
  entryType: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  framePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  frameXlGokart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gears: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['Int']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gokartType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  handbrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  heightTrampoline: Maybe<Scalars['Int']['output']>
  /** Highlight attributes */
  highlightedAttributes: Maybe<Array<Maybe<HighlightAttribute>>>
  /** Highlight attributes PLP */
  highlightedAttributesPlp: Maybe<Array<Maybe<HighlightAttribute>>>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Scalars['Int']['output']
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  inclSafetynet: Maybe<Scalars['Int']['output']>
  /** Whether the product is a main product or not. */
  isMainProduct: Scalars['Boolean']['output']
  /** @deprecated Use the `custom_attributes` field instead. */
  jumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  lengthUser: Maybe<Scalars['Int']['output']>
  /** A number representing the product's manufacturer. */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  material: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  materialTires: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUserWeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUsers: Maybe<Scalars['Int']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgBrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgClamp: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgDeck: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgFrontFork: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGrips: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGriptape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHandlebar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHeadset: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgWheels: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  model: Maybe<Scalars['Int']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Scalars['String']['output']
  /** @deprecated Use the `custom_attributes` field instead. */
  netWeight: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  numberOfSprings: Maybe<Scalars['Int']['output']>
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  pedals: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  playbaseSet: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  possibilityFor2ndSeat: Maybe<Scalars['Int']['output']>
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** Product downloads */
  productDownloads: Maybe<ProductDownload>
  productLabels: Maybe<Array<Maybe<AmLabelList>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  pushRod: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityLine: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityStars: Maybe<Scalars['Int']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of products to be displayed in a Related Products block. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** @deprecated Use the `custom_attributes` field instead. */
  safetynetType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  seatHeight: Maybe<Scalars['Int']['output']>
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAccessories: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAirtrack: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeBeachwagon: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeClothing: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeGocart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeJumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeStep: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeWheelbarrow: Maybe<Scalars['Int']['output']>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Scalars['String']['output']
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /** Get list of spareparts per product(group), per year */
  sparepartLinks: Maybe<Array<Maybe<BergProduct>>>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  springType: Maybe<Scalars['Int']['output']>
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  testweight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  themePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  thicknessOfProtectiveEdge: Maybe<Scalars['Int']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineShape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  tuevGs: Maybe<Scalars['Int']['output']>
  /** Tweakwise ID */
  tweakwiseItemNumber: Maybe<Scalars['String']['output']>
  /** PreOrder info */
  tweakwiseTemplates: Maybe<TweakwiseTemplates>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /** @deprecated Use the `custom_attributes` field instead. */
  typeAccessory: Maybe<Scalars['Int']['output']>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Scalars['String']['output']
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  uvProtection: Maybe<Scalars['Int']['output']>
  /** An array of simple product variants. */
  variants: Maybe<Array<Maybe<ConfigurableVariant>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warrantyWoRegistration: Maybe<Scalars['Int']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelLights: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelSize: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  widthPadding: Maybe<Scalars['Int']['output']>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProductConfigurableProductOptionsSelectionArgs {
  configurableOptionValueUids: InputMaybe<Array<Scalars['ID']['input']>>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export interface ConfigurableProductCartItemInput {
  /** The ID and value of the option. */
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** The quantity and SKU of the configurable product. */
  data: CartItemInput
  /** The SKU of the parent configurable product. */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  /** Deprecated. Use `CartItemInput.sku` instead. */
  variant_sku?: InputMaybe<Scalars['String']['input']>
}

/** Contains details about configurable product options. */
export interface ConfigurableProductOption {
  __typename?: 'ConfigurableProductOption'
  /** An attribute code that uniquely identifies a configurable option. */
  attributeCode: Scalars['String']['output']
  /** The display name of the option. */
  label: Scalars['String']['output']
  /** The unique ID of the configurable option. */
  uid: Scalars['ID']['output']
  /** An array of values that are applicable for this option. */
  values: Maybe<Array<Maybe<ConfigurableProductOptionValue>>>
}

/** Defines a value for a configurable product option. */
export interface ConfigurableProductOptionValue {
  __typename?: 'ConfigurableProductOptionValue'
  /** Indicates whether the product is available with this selected option. */
  isAvailable: Scalars['Boolean']['output']
  /** Indicates whether the value is the default. */
  isUseDefault: Scalars['Boolean']['output']
  /** The display name of the value. */
  label: Scalars['String']['output']
  /** The URL assigned to the thumbnail of the swatch image. */
  swatch: Maybe<SwatchDataInterface>
  /** The unique ID of the value. */
  uid: Scalars['ID']['output']
}

/** Defines configurable attributes for the specified product. */
export interface ConfigurableProductOptions {
  __typename?: 'ConfigurableProductOptions'
  /** A string that identifies the attribute. */
  attributeCode: Maybe<Scalars['String']['output']>
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attributeId: Maybe<Scalars['String']['output']>
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attributeIdV2: Maybe<Scalars['Int']['output']>
  /** The unique ID for an `Attribute` object. */
  attributeUid: Scalars['ID']['output']
  /**
   * The configurable option ID number assigned by the system.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** A displayed string that describes the configurable product option. */
  label: Maybe<Scalars['String']['output']>
  /** A number that indicates the order in which the attribute is displayed. */
  position: Maybe<Scalars['Int']['output']>
  /**
   * This is the same as a product's `id` field.
   * @deprecated `product_id` is not needed and can be obtained from its parent.
   */
  productId: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `ConfigurableProductOptions` object. */
  uid: Scalars['ID']['output']
  /** Indicates whether the option is the default. */
  useDefault: Maybe<Scalars['Boolean']['output']>
  /** An array that defines the `value_index` codes assigned to the configurable product. */
  values: Maybe<Array<Maybe<ConfigurableProductOptionsValues>>>
}

/** Contains metadata corresponding to the selected configurable options. */
export interface ConfigurableProductOptionsSelection {
  __typename?: 'ConfigurableProductOptionsSelection'
  /** An array of all possible configurable options. */
  configurableOptions: Maybe<Array<Maybe<ConfigurableProductOption>>>
  /** An array of all initial possible configurable options. */
  initialConfigurableOptions: Maybe<Array<Maybe<ConfigurableProductOption>>>
  /** Product images and videos corresponding to the specified configurable options selection. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /** The configurable options available for further selection based on the current selection. */
  optionsAvailableForSelection: Maybe<
    Array<Maybe<ConfigurableOptionAvailableForSelection>>
  >
  /** A variant represented by the specified configurable options selection. The value is expected to be null until selections are made for each configurable option. */
  variant: Maybe<SimpleProduct>
}

/** Contains the index number assigned to a configurable product option. */
export interface ConfigurableProductOptionsValues {
  __typename?: 'ConfigurableProductOptionsValues'
  /** The label of the product on the default store. */
  defaultLabel: Maybe<Scalars['String']['output']>
  /** The label of the product. */
  label: Maybe<Scalars['String']['output']>
  /** The label of the product on the current store. */
  storeLabel: Maybe<Scalars['String']['output']>
  /** Swatch data for a configurable product option. */
  swatchData: Maybe<SwatchDataInterface>
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  uid: Maybe<Scalars['ID']['output']>
  /** Indicates whether to use the default_label. */
  useDefaultValue: Maybe<Scalars['Boolean']['output']>
  /**
   * A unique index number assigned to the configurable product option.
   * @deprecated Use `uid` instead.
   */
  valueIndex: Maybe<Scalars['Int']['output']>
}

/** Contains all the simple product variants of a configurable product. */
export interface ConfigurableVariant {
  __typename?: 'ConfigurableVariant'
  /** An array of configurable attribute options. */
  attributes: Maybe<Array<Maybe<ConfigurableAttributeOption>>>
  /** An array of linked simple products. */
  product: Maybe<SimpleProduct>
}

/** A configurable product wish list item. */
export interface ConfigurableWishlistItem extends WishlistItemInterface {
  __typename?: 'ConfigurableWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /**
   * The SKU of the simple product corresponding to a set of selected configurable options.
   * @deprecated Use `ConfigurableWishlistItem.configured_variant.sku` instead.
   */
  childSku: Scalars['String']['output']
  /** An array of selected configurable options. */
  configurableOptions: Maybe<Array<Maybe<SelectedConfigurableOption>>>
  /** Product details of the selected variant. The value is null if some options are not configured. */
  configuredVariant: Maybe<ProductInterface>
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

/** Contains details about a customer email address to confirm. */
export interface ConfirmEmailInput {
  /** The key to confirm the email address. */
  confirmation_key: Scalars['String']['input']
  /** The email address to be confirmed. */
  email: Scalars['String']['input']
}

/** List of account confirmation statuses. */
export enum ConfirmationStatusEnum {
  /** Account confirmation not required */
  ACCOUNT_CONFIRMATION_NOT_REQUIRED = 'ACCOUNT_CONFIRMATION_NOT_REQUIRED',
  /** Account confirmed */
  ACCOUNT_CONFIRMED = 'ACCOUNT_CONFIRMED',
}

export interface ContactUsInput {
  /** The shopper's comment to the merchant. */
  comment: Scalars['String']['input']
  /** The email address of the shopper. */
  email: Scalars['String']['input']
  /** The full name of the shopper. */
  name: Scalars['String']['input']
  /** The shopper's telephone number. */
  telephone?: InputMaybe<Scalars['String']['input']>
}

/** Contains the status of the request. */
export interface ContactUsOutput {
  __typename?: 'ContactUsOutput'
  /** Indicates whether the request was successful. */
  status: Scalars['Boolean']['output']
}

export interface Country {
  __typename?: 'Country'
  /** An array of regions within a particular country. */
  availableRegions: Maybe<Array<Maybe<Region>>>
  /** The name of the country in English. */
  fullNameEnglish: Maybe<Scalars['String']['output']>
  /** The name of the country in the current locale. */
  fullNameLocale: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Country` object. */
  id: Maybe<Scalars['String']['output']>
  /** The three-letter abbreviation of the country, such as USA. */
  threeLetterAbbreviation: Maybe<Scalars['String']['output']>
  /** The two-letter abbreviation of the country, such as US. */
  twoLetterAbbreviation: Maybe<Scalars['String']['output']>
}

/** The list of country codes. */
export enum CountryCodeEnum {
  /** Andorra */
  AD = 'AD',
  /** United Arab Emirates */
  AE = 'AE',
  /** Afghanistan */
  AF = 'AF',
  /** Antigua & Barbuda */
  AG = 'AG',
  /** Anguilla */
  AI = 'AI',
  /** Albania */
  AL = 'AL',
  /** Armenia */
  AM = 'AM',
  /** Netherlands Antilles */
  AN = 'AN',
  /** Angola */
  AO = 'AO',
  /** Antarctica */
  AQ = 'AQ',
  /** Argentina */
  AR = 'AR',
  /** American Samoa */
  AS = 'AS',
  /** Austria */
  AT = 'AT',
  /** Australia */
  AU = 'AU',
  /** Aruba */
  AW = 'AW',
  /** Åland Islands */
  AX = 'AX',
  /** Azerbaijan */
  AZ = 'AZ',
  /** Bosnia & Herzegovina */
  BA = 'BA',
  /** Barbados */
  BB = 'BB',
  /** Bangladesh */
  BD = 'BD',
  /** Belgium */
  BE = 'BE',
  /** Burkina Faso */
  BF = 'BF',
  /** Bulgaria */
  BG = 'BG',
  /** Bahrain */
  BH = 'BH',
  /** Burundi */
  BI = 'BI',
  /** Benin */
  BJ = 'BJ',
  /** St. Barthélemy */
  BL = 'BL',
  /** Bermuda */
  BM = 'BM',
  /** Brunei */
  BN = 'BN',
  /** Bolivia */
  BO = 'BO',
  /** Brazil */
  BR = 'BR',
  /** Bahamas */
  BS = 'BS',
  /** Bhutan */
  BT = 'BT',
  /** Bouvet Island */
  BV = 'BV',
  /** Botswana */
  BW = 'BW',
  /** Belarus */
  BY = 'BY',
  /** Belize */
  BZ = 'BZ',
  /** Canada */
  CA = 'CA',
  /** Cocos (Keeling) Islands */
  CC = 'CC',
  /** Congo-Kinshasa */
  CD = 'CD',
  /** Central African Republic */
  CF = 'CF',
  /** Congo-Brazzaville */
  CG = 'CG',
  /** Switzerland */
  CH = 'CH',
  /** Côte d’Ivoire */
  CI = 'CI',
  /** Cook Islands */
  CK = 'CK',
  /** Chile */
  CL = 'CL',
  /** Cameroon */
  CM = 'CM',
  /** China */
  CN = 'CN',
  /** Colombia */
  CO = 'CO',
  /** Costa Rica */
  CR = 'CR',
  /** Cuba */
  CU = 'CU',
  /** Cape Verde */
  CV = 'CV',
  /** Christmas Island */
  CX = 'CX',
  /** Cyprus */
  CY = 'CY',
  /** Czech Republic */
  CZ = 'CZ',
  /** Germany */
  DE = 'DE',
  /** Djibouti */
  DJ = 'DJ',
  /** Denmark */
  DK = 'DK',
  /** Dominica */
  DM = 'DM',
  /** Dominican Republic */
  DO = 'DO',
  /** Algeria */
  DZ = 'DZ',
  /** Ecuador */
  EC = 'EC',
  /** Estonia */
  EE = 'EE',
  /** Egypt */
  EG = 'EG',
  /** Western Sahara */
  EH = 'EH',
  /** Eritrea */
  ER = 'ER',
  /** Spain */
  ES = 'ES',
  /** Ethiopia */
  ET = 'ET',
  /** Finland */
  FI = 'FI',
  /** Fiji */
  FJ = 'FJ',
  /** Falkland Islands */
  FK = 'FK',
  /** Micronesia */
  FM = 'FM',
  /** Faroe Islands */
  FO = 'FO',
  /** France */
  FR = 'FR',
  /** Gabon */
  GA = 'GA',
  /** United Kingdom */
  GB = 'GB',
  /** Grenada */
  GD = 'GD',
  /** Georgia */
  GE = 'GE',
  /** French Guiana */
  GF = 'GF',
  /** Guernsey */
  GG = 'GG',
  /** Ghana */
  GH = 'GH',
  /** Gibraltar */
  GI = 'GI',
  /** Greenland */
  GL = 'GL',
  /** Gambia */
  GM = 'GM',
  /** Guinea */
  GN = 'GN',
  /** Guadeloupe */
  GP = 'GP',
  /** Equatorial Guinea */
  GQ = 'GQ',
  /** Greece */
  GR = 'GR',
  /** South Georgia & South Sandwich Islands */
  GS = 'GS',
  /** Guatemala */
  GT = 'GT',
  /** Guam */
  GU = 'GU',
  /** Guinea-Bissau */
  GW = 'GW',
  /** Guyana */
  GY = 'GY',
  /** Hong Kong SAR China */
  HK = 'HK',
  /** Heard &amp; McDonald Islands */
  HM = 'HM',
  /** Honduras */
  HN = 'HN',
  /** Croatia */
  HR = 'HR',
  /** Haiti */
  HT = 'HT',
  /** Hungary */
  HU = 'HU',
  /** Indonesia */
  ID = 'ID',
  /** Ireland */
  IE = 'IE',
  /** Israel */
  IL = 'IL',
  /** Isle of Man */
  IM = 'IM',
  /** India */
  IN = 'IN',
  /** British Indian Ocean Territory */
  IO = 'IO',
  /** Iraq */
  IQ = 'IQ',
  /** Iran */
  IR = 'IR',
  /** Iceland */
  IS = 'IS',
  /** Italy */
  IT = 'IT',
  /** Jersey */
  JE = 'JE',
  /** Jamaica */
  JM = 'JM',
  /** Jordan */
  JO = 'JO',
  /** Japan */
  JP = 'JP',
  /** Kenya */
  KE = 'KE',
  /** Kyrgyzstan */
  KG = 'KG',
  /** Cambodia */
  KH = 'KH',
  /** Kiribati */
  KI = 'KI',
  /** Comoros */
  KM = 'KM',
  /** St. Kitts & Nevis */
  KN = 'KN',
  /** North Korea */
  KP = 'KP',
  /** South Korea */
  KR = 'KR',
  /** Kuwait */
  KW = 'KW',
  /** Cayman Islands */
  KY = 'KY',
  /** Kazakhstan */
  KZ = 'KZ',
  /** Laos */
  LA = 'LA',
  /** Lebanon */
  LB = 'LB',
  /** St. Lucia */
  LC = 'LC',
  /** Liechtenstein */
  LI = 'LI',
  /** Sri Lanka */
  LK = 'LK',
  /** Liberia */
  LR = 'LR',
  /** Lesotho */
  LS = 'LS',
  /** Lithuania */
  LT = 'LT',
  /** Luxembourg */
  LU = 'LU',
  /** Latvia */
  LV = 'LV',
  /** Libya */
  LY = 'LY',
  /** Morocco */
  MA = 'MA',
  /** Monaco */
  MC = 'MC',
  /** Moldova */
  MD = 'MD',
  /** Montenegro */
  ME = 'ME',
  /** St. Martin */
  MF = 'MF',
  /** Madagascar */
  MG = 'MG',
  /** Marshall Islands */
  MH = 'MH',
  /** Macedonia */
  MK = 'MK',
  /** Mali */
  ML = 'ML',
  /** Myanmar (Burma) */
  MM = 'MM',
  /** Mongolia */
  MN = 'MN',
  /** Macau SAR China */
  MO = 'MO',
  /** Northern Mariana Islands */
  MP = 'MP',
  /** Martinique */
  MQ = 'MQ',
  /** Mauritania */
  MR = 'MR',
  /** Montserrat */
  MS = 'MS',
  /** Malta */
  MT = 'MT',
  /** Mauritius */
  MU = 'MU',
  /** Maldives */
  MV = 'MV',
  /** Malawi */
  MW = 'MW',
  /** Mexico */
  MX = 'MX',
  /** Malaysia */
  MY = 'MY',
  /** Mozambique */
  MZ = 'MZ',
  /** Namibia */
  NA = 'NA',
  /** New Caledonia */
  NC = 'NC',
  /** Niger */
  NE = 'NE',
  /** Norfolk Island */
  NF = 'NF',
  /** Nigeria */
  NG = 'NG',
  /** Nicaragua */
  NI = 'NI',
  /** Netherlands */
  NL = 'NL',
  /** Norway */
  NO = 'NO',
  /** Nepal */
  NP = 'NP',
  /** Nauru */
  NR = 'NR',
  /** Niue */
  NU = 'NU',
  /** New Zealand */
  NZ = 'NZ',
  /** Oman */
  OM = 'OM',
  /** Panama */
  PA = 'PA',
  /** Peru */
  PE = 'PE',
  /** French Polynesia */
  PF = 'PF',
  /** Papua New Guinea */
  PG = 'PG',
  /** Philippines */
  PH = 'PH',
  /** Pakistan */
  PK = 'PK',
  /** Poland */
  PL = 'PL',
  /** St. Pierre & Miquelon */
  PM = 'PM',
  /** Pitcairn Islands */
  PN = 'PN',
  /** Palestinian Territories */
  PS = 'PS',
  /** Portugal */
  PT = 'PT',
  /** Palau */
  PW = 'PW',
  /** Paraguay */
  PY = 'PY',
  /** Qatar */
  QA = 'QA',
  /** Réunion */
  RE = 'RE',
  /** Romania */
  RO = 'RO',
  /** Serbia */
  RS = 'RS',
  /** Russia */
  RU = 'RU',
  /** Rwanda */
  RW = 'RW',
  /** Saudi Arabia */
  SA = 'SA',
  /** Solomon Islands */
  SB = 'SB',
  /** Seychelles */
  SC = 'SC',
  /** Sudan */
  SD = 'SD',
  /** Sweden */
  SE = 'SE',
  /** Singapore */
  SG = 'SG',
  /** St. Helena */
  SH = 'SH',
  /** Slovenia */
  SI = 'SI',
  /** Svalbard & Jan Mayen */
  SJ = 'SJ',
  /** Slovakia */
  SK = 'SK',
  /** Sierra Leone */
  SL = 'SL',
  /** San Marino */
  SM = 'SM',
  /** Senegal */
  SN = 'SN',
  /** Somalia */
  SO = 'SO',
  /** Suriname */
  SR = 'SR',
  /** São Tomé & Príncipe */
  ST = 'ST',
  /** El Salvador */
  SV = 'SV',
  /** Syria */
  SY = 'SY',
  /** Eswatini */
  SZ = 'SZ',
  /** Turks & Caicos Islands */
  TC = 'TC',
  /** Chad */
  TD = 'TD',
  /** French Southern Territories */
  TF = 'TF',
  /** Togo */
  TG = 'TG',
  /** Thailand */
  TH = 'TH',
  /** Tajikistan */
  TJ = 'TJ',
  /** Tokelau */
  TK = 'TK',
  /** Timor-Leste */
  TL = 'TL',
  /** Turkmenistan */
  TM = 'TM',
  /** Tunisia */
  TN = 'TN',
  /** Tonga */
  TO = 'TO',
  /** Turkey */
  TR = 'TR',
  /** Trinidad & Tobago */
  TT = 'TT',
  /** Tuvalu */
  TV = 'TV',
  /** Taiwan */
  TW = 'TW',
  /** Tanzania */
  TZ = 'TZ',
  /** Ukraine */
  UA = 'UA',
  /** Uganda */
  UG = 'UG',
  /** U.S. Outlying Islands */
  UM = 'UM',
  /** United States */
  US = 'US',
  /** Uruguay */
  UY = 'UY',
  /** Uzbekistan */
  UZ = 'UZ',
  /** Vatican City */
  VA = 'VA',
  /** St. Vincent & Grenadines */
  VC = 'VC',
  /** Venezuela */
  VE = 'VE',
  /** British Virgin Islands */
  VG = 'VG',
  /** U.S. Virgin Islands */
  VI = 'VI',
  /** Vietnam */
  VN = 'VN',
  /** Vanuatu */
  VU = 'VU',
  /** Wallis & Futuna */
  WF = 'WF',
  /** Samoa */
  WS = 'WS',
  /** Yemen */
  YE = 'YE',
  /** Mayotte */
  YT = 'YT',
  /** South Africa */
  ZA = 'ZA',
  /** Zambia */
  ZM = 'ZM',
  /** Zimbabwe */
  ZW = 'ZW',
}

export interface CraftAccordionInformationEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftAccordionInformationEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blockTitle: Maybe<Scalars['String']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  informationItems: Array<Maybe<CraftInformationItemsMatrixField>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftAccordionInformationEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccordionInformationEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccordionInformationEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftAccordionInformationEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccordionInformationEntryInformationItemsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccordionInformationEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccordionInformationEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccordionInformationEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccordionInformationEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccountEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftAccountEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  iconLinks: Array<Maybe<CraftIconLinksMatrixField>>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftAccountEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccountEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccountEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftAccountEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccountEntryIconLinksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccountEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftAccountEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccountEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccountEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftAccountEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftAccountSectionEntryUnion = CraftAccountEntry

export interface CraftAddress
  extends CraftAddressInterface,
    CraftElementInterface {
  __typename?: 'CraftAddress'
  /** First line of the address */
  addressLine1: Maybe<Scalars['String']['output']>
  /** Second line of the address */
  addressLine2: Maybe<Scalars['String']['output']>
  /** Third line of the address */
  addressLine3: Maybe<Scalars['String']['output']>
  /** Administrative area. */
  administrativeArea: Maybe<Scalars['String']['output']>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** Two-letter country code */
  countryCode: Scalars['String']['output']
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Dependent locality */
  dependentLocality: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The first name on the address. */
  firstName: Maybe<Scalars['String']['output']>
  /** The full name on the address. */
  fullName: Maybe<Scalars['String']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The last name on the address. */
  lastName: Maybe<Scalars['String']['output']>
  /** Latitude */
  latitude: Maybe<Scalars['String']['output']>
  /** Locality */
  locality: Maybe<Scalars['String']['output']>
  /** Longitude */
  longitude: Maybe<Scalars['String']['output']>
  /** Organization name */
  organization: Maybe<Scalars['String']['output']>
  /** Organization tax ID */
  organizationTaxId: Maybe<Scalars['String']['output']>
  /** Postal code */
  postalCode: Maybe<Scalars['String']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** Sorting code */
  sortingCode: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

export interface CraftAddressCountArgs {
  field: Scalars['String']['input']
}

/** This is the interface implemented by all addresses. */
export interface CraftAddressInterface {
  /** First line of the address */
  addressLine1: Maybe<Scalars['String']['output']>
  /** Second line of the address */
  addressLine2: Maybe<Scalars['String']['output']>
  /** Third line of the address */
  addressLine3: Maybe<Scalars['String']['output']>
  /** Administrative area. */
  administrativeArea: Maybe<Scalars['String']['output']>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** Two-letter country code */
  countryCode: Scalars['String']['output']
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Dependent locality */
  dependentLocality: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The first name on the address. */
  firstName: Maybe<Scalars['String']['output']>
  /** The full name on the address. */
  fullName: Maybe<Scalars['String']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The last name on the address. */
  lastName: Maybe<Scalars['String']['output']>
  /** Latitude */
  latitude: Maybe<Scalars['String']['output']>
  /** Locality */
  locality: Maybe<Scalars['String']['output']>
  /** Longitude */
  longitude: Maybe<Scalars['String']['output']>
  /** Organization name */
  organization: Maybe<Scalars['String']['output']>
  /** Organization tax ID */
  organizationTaxId: Maybe<Scalars['String']['output']>
  /** Postal code */
  postalCode: Maybe<Scalars['String']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** Sorting code */
  sortingCode: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

/** This is the interface implemented by all addresses. */
export interface CraftAddressInterfaceCountArgs {
  field: Scalars['String']['input']
}

export interface CraftAssetCriteriaInput {
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the assets’ files’ last-modified dates. */
  dateModified?: InputMaybe<Scalars['String']['input']>
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the assets’ filenames. */
  filename?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the folders the assets belong to, per the folders’ IDs. */
  folderId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on whether the assets have alternative text. */
  hasAlt?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the assets’ image heights. */
  height?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ IDs. */
  id?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Causes the query results to be returned in reverse order. */
  inReverse?: InputMaybe<Scalars['Boolean']['input']>
  /** Broadens the query results to include assets from any of the subfolders of the folder specified by `folderId`. */
  includeSubfolders?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the assets’ file kinds. */
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Sets the limit for paginated results. */
  limit?: InputMaybe<Scalars['Int']['input']>
  /** Sets the offset for paginated results. */
  offset?: InputMaybe<Scalars['Int']['input']>
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: InputMaybe<Scalars['String']['input']>
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on a reference string. */
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: InputMaybe<
    Array<InputMaybe<CraftCategoryCriteriaInput>>
  >
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  /** Narrows the query results to only elements that match a search query. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the assets’ file sizes (in bytes). */
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ slugs. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ titles. */
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the user the assets were uploaded by, per the user’s ID. */
  uploader?: InputMaybe<Scalars['CraftQueryArgument']['input']>
  /** Narrows the query results based on the elements’ URIs. */
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the volumes the assets belong to, per the volumes’ handles. */
  volume?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the volumes the assets belong to, per the volumes’ IDs. */
  volumeId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the assets’ image widths. */
  width?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** A list of transform handles to preload. */
  withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** This is the interface implemented by all assets. */
export interface CraftAssetInterface {
  /** Alternative text for the asset. */
  alt: Maybe<Scalars['String']['output']>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the asset file was last modified. */
  dateModified: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The file extension for the asset file. */
  extension: Scalars['String']['output']
  /** The filename of the asset file. */
  filename: Scalars['String']['output']
  /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
  focalPoint: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  /** The ID of the folder that the asset belongs to. */
  folderId: Scalars['Int']['output']
  /** Returns the file’s format. */
  format: Maybe<Scalars['String']['output']>
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint: Scalars['Boolean']['output']
  /** The height in pixels or null if it’s not an image. */
  height: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** An `<img>` tag based on this asset. */
  img: Maybe<Scalars['String']['output']>
  /** The file kind. */
  kind: Scalars['String']['output']
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The file’s MIME type, if it can be determined. */
  mimeType: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftAssetInterface>
  /** The asset’s path in the volume. */
  path: Scalars['String']['output']
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftAssetInterface>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The file size in bytes. */
  size: Maybe<Scalars['String']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url: Maybe<Scalars['String']['output']>
  /** The ID of the volume that the asset belongs to. */
  volumeId: Maybe<Scalars['Int']['output']>
  /** The width in pixels or null if it’s not an image. */
  width: Maybe<Scalars['Int']['output']>
}

/** This is the interface implemented by all assets. */
export interface CraftAssetInterfaceCountArgs {
  field: Scalars['String']['input']
}

/** This is the interface implemented by all assets. */
export interface CraftAssetInterfaceFormatArgs {
  format: InputMaybe<Scalars['String']['input']>
  handle: InputMaybe<Scalars['String']['input']>
  height: InputMaybe<Scalars['Int']['input']>
  immediately: InputMaybe<Scalars['Boolean']['input']>
  interlace: InputMaybe<Scalars['String']['input']>
  mode: InputMaybe<Scalars['String']['input']>
  position: InputMaybe<Scalars['String']['input']>
  quality: InputMaybe<Scalars['Int']['input']>
  transform: InputMaybe<Scalars['String']['input']>
  width: InputMaybe<Scalars['Int']['input']>
}

/** This is the interface implemented by all assets. */
export interface CraftAssetInterfaceHeightArgs {
  format: InputMaybe<Scalars['String']['input']>
  handle: InputMaybe<Scalars['String']['input']>
  height: InputMaybe<Scalars['Int']['input']>
  immediately: InputMaybe<Scalars['Boolean']['input']>
  interlace: InputMaybe<Scalars['String']['input']>
  mode: InputMaybe<Scalars['String']['input']>
  position: InputMaybe<Scalars['String']['input']>
  quality: InputMaybe<Scalars['Int']['input']>
  transform: InputMaybe<Scalars['String']['input']>
  width: InputMaybe<Scalars['Int']['input']>
}

/** This is the interface implemented by all assets. */
export interface CraftAssetInterfaceNextArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** This is the interface implemented by all assets. */
export interface CraftAssetInterfacePrevArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** This is the interface implemented by all assets. */
export interface CraftAssetInterfaceSrcsetArgs {
  sizes: Array<Scalars['String']['input']>
}

/** This is the interface implemented by all assets. */
export interface CraftAssetInterfaceUrlArgs {
  format: InputMaybe<Scalars['String']['input']>
  handle: InputMaybe<Scalars['String']['input']>
  height: InputMaybe<Scalars['Int']['input']>
  immediately: InputMaybe<Scalars['Boolean']['input']>
  interlace: InputMaybe<Scalars['String']['input']>
  mode: InputMaybe<Scalars['String']['input']>
  position: InputMaybe<Scalars['String']['input']>
  quality: InputMaybe<Scalars['Int']['input']>
  transform: InputMaybe<Scalars['String']['input']>
  width: InputMaybe<Scalars['Int']['input']>
}

/** This is the interface implemented by all assets. */
export interface CraftAssetInterfaceWidthArgs {
  format: InputMaybe<Scalars['String']['input']>
  handle: InputMaybe<Scalars['String']['input']>
  height: InputMaybe<Scalars['Int']['input']>
  immediately: InputMaybe<Scalars['Boolean']['input']>
  interlace: InputMaybe<Scalars['String']['input']>
  mode: InputMaybe<Scalars['String']['input']>
  position: InputMaybe<Scalars['String']['input']>
  quality: InputMaybe<Scalars['Int']['input']>
  transform: InputMaybe<Scalars['String']['input']>
  width: InputMaybe<Scalars['Int']['input']>
}

export interface CraftBlockSeparationEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftBlockSeparationEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  showIcon: Maybe<Scalars['String']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftBlockSeparationEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockSeparationEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockSeparationEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftBlockSeparationEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockSeparationEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockSeparationEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockSeparationEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockSeparationEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockTitleWithStylingBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftBlockTitleWithStylingBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  fontSize: Maybe<Scalars['String']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftBlockTitleWithStylingBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockTitleWithStylingBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockTitleWithStylingBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftBlockTitleWithStylingBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockTitleWithStylingBlockEntryFontSizeArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftBlockTitleWithStylingBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockTitleWithStylingBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockTitleWithStylingBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlockTitleWithStylingBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftBlockTitleWithStylingMatrixField =
  CraftBlockTitleWithStylingBlockEntry

export interface CraftBlogOverviewEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftBlogOverviewEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blogPostCards: Array<Maybe<CraftBlogPostCardsMatrixField>>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftBlogOverviewEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogOverviewEntryBlogPostCardsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogOverviewEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogOverviewEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftBlogOverviewEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogOverviewEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftBlogOverviewEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogOverviewEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogOverviewEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogOverviewEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftBlogOverviewSectionEntryUnion = CraftBlogOverviewEntry

export interface CraftBlogPostCardsBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftBlogPostCardsBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blockTitle: Maybe<Scalars['String']['output']>
  blogPosts: Array<Maybe<CraftEntryInterface>>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftBlogPostCardsBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostCardsBlockEntryBlogPostsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostCardsBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostCardsBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftBlogPostCardsBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostCardsBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostCardsBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostCardsBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostCardsBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftBlogPostCardsMatrixField = CraftBlogPostCardsBlockEntry

export interface CraftBlogPostEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftBlogPostEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  pageBlocks: Array<Maybe<CraftPageBlocksMatrixField>>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richText1: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text1: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftBlogPostEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftBlogPostEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftBlogPostEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostEntryPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftBlogPostEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftBlogSectionEntryUnion = CraftBlogPostEntry

export interface CraftCategoryBannersBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftCategoryBannersBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  bannerLink: Maybe<CraftLinkFieldLink>
  bannerTitle: Maybe<Scalars['String']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  isVisibleOnFilter: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  position: Maybe<Scalars['CraftNumber']['output']>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftCategoryBannersBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryBannersBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryBannersBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftCategoryBannersBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryBannersBlockEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftCategoryBannersBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryBannersBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryBannersBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryBannersBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftCategoryBannersMatrixField = CraftCategoryBannersBlockEntry

export interface CraftCategoryCategoryEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftCategoryCategoryEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  pageBlocks: Array<Maybe<CraftPageBlocksMatrixField>>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftCategoryCategoryEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryCategoryEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryCategoryEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftCategoryCategoryEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryCategoryEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryCategoryEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryCategoryEntryPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryCategoryEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryCategoryEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryCriteriaInput {
  /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
  ancestorDist?: InputMaybe<Scalars['Int']['input']>
  /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
  ancestorOf?: InputMaybe<Scalars['Int']['input']>
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
  descendantDist?: InputMaybe<Scalars['Int']['input']>
  /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
  descendantOf?: InputMaybe<Scalars['Int']['input']>
  /** Whether to only return categories that the user has permission to edit. */
  editable?: InputMaybe<Scalars['Boolean']['input']>
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the category groups the categories belong to per the group’s handles. */
  group?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the category groups the categories belong to, per the groups’ IDs. */
  groupId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on whether the elements have any descendants in their structure. */
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the elements’ IDs. */
  id?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Causes the query results to be returned in reverse order. */
  inReverse?: InputMaybe<Scalars['Boolean']['input']>
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
  leaves?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the elements’ level within the structure. */
  level?: InputMaybe<Scalars['Int']['input']>
  /** Sets the limit for paginated results. */
  limit?: InputMaybe<Scalars['Int']['input']>
  /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>
  /** Sets the offset for paginated results. */
  offset?: InputMaybe<Scalars['Int']['input']>
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: InputMaybe<Scalars['String']['input']>
  /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
  positionedAfter?: InputMaybe<Scalars['Int']['input']>
  /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
  positionedBefore?: InputMaybe<Scalars['Int']['input']>
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>
  /** Narrows the query results based on a reference string. */
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: InputMaybe<
    Array<InputMaybe<CraftCategoryCriteriaInput>>
  >
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  /** Narrows the query results to only elements that match a search query. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the elements’ slugs. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines which structure data should be joined into the query. */
  structureId?: InputMaybe<Scalars['Int']['input']>
  /** Narrows the query results based on the elements’ titles. */
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the elements’ URIs. */
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Explicitly determines whether the query should join in the structure data. */
  withStructure?: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftCategoryEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftCategoryEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  categoryBanners: Array<Maybe<CraftCategoryBannersMatrixField>>
  categoryProductPageBlocks: Array<
    Maybe<CraftCategoryProductPageBlocksMatrixField>
  >
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  collapsibleRichTexts: Array<Maybe<CraftCollapsibleRichTextsMatrixField>>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  quickFilters: Array<Maybe<CraftQuickFiltersMatrixField>>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richText1: Maybe<Scalars['String']['output']>
  richText2: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  seoIntroductionRichText: Maybe<Scalars['String']['output']>
  seoIntroductionTitle: Maybe<Scalars['String']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  title2: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftCategoryEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryEntryCategoryBannersArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryEntryCategoryProductPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryEntryCollapsibleRichTextsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftCategoryEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftCategoryEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryEntryQuickFiltersArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftCategoryFilterExplanationsSectionEntryUnion =
  CraftFilterExplanationEntry

export interface CraftCategoryImageWithProductOverviewEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftCategoryImageWithProductOverviewEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blockTitle: Maybe<Scalars['String']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  items: Array<Maybe<CraftitemsMatrixField>>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftCategoryImageWithProductOverviewEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryImageWithProductOverviewEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryImageWithProductOverviewEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftCategoryImageWithProductOverviewEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryImageWithProductOverviewEntryItemsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryImageWithProductOverviewEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryImageWithProductOverviewEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryImageWithProductOverviewEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryImageWithProductOverviewEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryLandingEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftCategoryLandingEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  collapsibleRichTexts: Array<Maybe<CraftCollapsibleRichTextsMatrixField>>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  quickFilters: Array<Maybe<CraftQuickFiltersMatrixField>>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richText1: Maybe<Scalars['String']['output']>
  richText2: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  seoIntroductionRichText: Maybe<Scalars['String']['output']>
  seoIntroductionTitle: Maybe<Scalars['String']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftCategoryLandingEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryLandingEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryLandingEntryCollapsibleRichTextsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryLandingEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftCategoryLandingEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryLandingEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryLandingEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryLandingEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryLandingEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCategoryLandingEntryQuickFiltersArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftCategoryPageContentSectionEntryUnion =
  | CraftCategoryEntry
  | CraftCategoryLandingEntry

export type CraftCategoryProductPageBlocksMatrixField =
  | CraftCategoryImageWithProductOverviewEntry
  | CraftHtml2Entry

export type CraftCategorySectionEntryUnion = CraftCategoryCategoryEntry

export interface CraftCheckoutFooterGlobalSet
  extends CraftElementInterface,
    CraftGlobalSetInterface {
  __typename?: 'CraftCheckoutFooterGlobalSet'
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The handle of the global set. */
  handle: Scalars['String']['output']
  iconLinks: Array<Maybe<CraftIconLinksMatrixField>>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The name of the global set. */
  name: Scalars['String']['output']
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

export interface CraftCheckoutFooterGlobalSetCountArgs {
  field: Scalars['String']['input']
}

export interface CraftCheckoutFooterGlobalSetIconLinksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCheckoutGlobalSet
  extends CraftElementInterface,
    CraftGlobalSetInterface {
  __typename?: 'CraftCheckoutGlobalSet'
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The handle of the global set. */
  handle: Scalars['String']['output']
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The name of the global set. */
  name: Scalars['String']['output']
  richText1: Maybe<Scalars['String']['output']>
  richText2: Maybe<Scalars['String']['output']>
  richText3: Maybe<Scalars['String']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  text1: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  title2: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

export interface CraftCheckoutGlobalSetCountArgs {
  field: Scalars['String']['input']
}

export interface CraftCollapsibleRichTextEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftCollapsibleRichTextEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richText: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftCollapsibleRichTextEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCollapsibleRichTextEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCollapsibleRichTextEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftCollapsibleRichTextEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCollapsibleRichTextEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCollapsibleRichTextEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCollapsibleRichTextEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCollapsibleRichTextEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftCollapsibleRichTextsMatrixField = CraftCollapsibleRichTextEntry

export interface CraftCollapsibleTextsBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftCollapsibleTextsBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  answer: Maybe<Scalars['String']['output']>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  question: Maybe<Scalars['String']['output']>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftCollapsibleTextsBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCollapsibleTextsBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCollapsibleTextsBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftCollapsibleTextsBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCollapsibleTextsBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCollapsibleTextsBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCollapsibleTextsBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCollapsibleTextsBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftCollapsibleTextsMatrixField = CraftCollapsibleTextsBlockEntry

export interface CraftColumnsBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftColumnsBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  columnLink: Maybe<CraftLinkFieldLink>
  columnTitle: Maybe<Scalars['String']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  specifications: Array<Maybe<CraftSpecificationsMatrixField>>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftColumnsBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftColumnsBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftColumnsBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftColumnsBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftColumnsBlockEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftColumnsBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftColumnsBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftColumnsBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftColumnsBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftColumnsBlockEntrySpecificationsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftColumnsMatrixField = CraftColumnsBlockEntry

export interface CraftCompareSpecificationsEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftCompareSpecificationsEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  columns: Array<Maybe<CraftColumnsMatrixField>>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  showLabels: Maybe<Scalars['Boolean']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftCompareSpecificationsEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCompareSpecificationsEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCompareSpecificationsEntryColumnsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCompareSpecificationsEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftCompareSpecificationsEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCompareSpecificationsEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCompareSpecificationsEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCompareSpecificationsEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCompareSpecificationsEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContactEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftContactEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richTextSimple1: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  text1: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  title2: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftContactEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContactEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContactEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftContactEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContactEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftContactEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContactEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContactEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContactEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContactGlobalGlobalSet
  extends CraftElementInterface,
    CraftGlobalSetInterface {
  __typename?: 'CraftContactGlobalGlobalSet'
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The handle of the global set. */
  handle: Scalars['String']['output']
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The name of the global set. */
  name: Scalars['String']['output']
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  siteGlobalContact: Array<Maybe<CraftSiteGlobalContactMatrixField>>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

export interface CraftContactGlobalGlobalSetCountArgs {
  field: Scalars['String']['input']
}

export interface CraftContactGlobalGlobalSetSiteGlobalContactArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftContactSectionEntryUnion = CraftContactEntry

export interface CraftContentBlocksEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftContentBlocksEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  productPageBlocks: Array<Maybe<CraftProductPageBlocksMatrixField>>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
  usps: Array<Maybe<CraftUspsMatrixField>>
}

export interface CraftContentBlocksEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContentBlocksEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContentBlocksEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftContentBlocksEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContentBlocksEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContentBlocksEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContentBlocksEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContentBlocksEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContentBlocksEntryProductPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftContentBlocksEntryUspsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCustomerServiceOverviewEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftCustomerServiceOverviewEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  email: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  phoneNumber: Maybe<Scalars['String']['output']>
  phoneNumberLink: Maybe<CraftLinkFieldLink>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  subtitle2: Maybe<Scalars['String']['output']>
  text1: Maybe<Scalars['String']['output']>
  text2: Maybe<Scalars['String']['output']>
  time1: Maybe<Scalars['CraftDateTime']['output']>
  time2: Maybe<Scalars['CraftDateTime']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  title2: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
  weekDays: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export interface CraftCustomerServiceOverviewEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCustomerServiceOverviewEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCustomerServiceOverviewEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftCustomerServiceOverviewEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCustomerServiceOverviewEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftCustomerServiceOverviewEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCustomerServiceOverviewEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCustomerServiceOverviewEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCustomerServiceOverviewEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftCustomerServiceOverviewEntryWeekDaysArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export type CraftCustomerServiceOverviewSectionEntryUnion =
  CraftCustomerServiceOverviewEntry

export type CraftCustomerServiceSectionEntryUnion = CraftFaqPageEntry

export interface CraftDefaultEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftDefaultEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  lightswitchChecked: Maybe<Scalars['Boolean']['output']>
  lightswitchChecked2: Maybe<Scalars['Boolean']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  pageBlocks: Array<Maybe<CraftPageBlocksMatrixField>>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftDefaultEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftDefaultEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftDefaultEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftDefaultEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftDefaultEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftDefaultEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftDefaultEntryPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftDefaultEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftDefaultEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftDisclaimersGlobalGlobalSet
  extends CraftElementInterface,
    CraftGlobalSetInterface {
  __typename?: 'CraftDisclaimersGlobalGlobalSet'
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The handle of the global set. */
  handle: Scalars['String']['output']
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The name of the global set. */
  name: Scalars['String']['output']
  richTextSimple1: Maybe<Scalars['String']['output']>
  richTextSimple2: Maybe<Scalars['String']['output']>
  richTextSimple3: Maybe<Scalars['String']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

export interface CraftDisclaimersGlobalGlobalSetCountArgs {
  field: Scalars['String']['input']
}

export interface CraftEcommerceGlobalSet
  extends CraftElementInterface,
    CraftGlobalSetInterface {
  __typename?: 'CraftEcommerceGlobalSet'
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The handle of the global set. */
  handle: Scalars['String']['output']
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  linkField: Maybe<CraftLinkFieldLink>
  /** The name of the global set. */
  name: Scalars['String']['output']
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

export interface CraftEcommerceGlobalSetCountArgs {
  field: Scalars['String']['input']
}

export interface CraftElement extends CraftElementInterface {
  __typename?: 'CraftElement'
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

export interface CraftElementCountArgs {
  field: Scalars['String']['input']
}

/** This is the interface implemented by all elements. */
export interface CraftElementInterface {
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

/** This is the interface implemented by all elements. */
export interface CraftElementInterfaceCountArgs {
  field: Scalars['String']['input']
}

export interface CraftEntryCriteriaInput {
  SpecificationValue?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to only entries that were posted on or after a certain date. */
  after?: InputMaybe<Scalars['String']['input']>
  /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
  ancestorDist?: InputMaybe<Scalars['Int']['input']>
  /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
  ancestorOf?: InputMaybe<Scalars['Int']['input']>
  anchorLinkId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Narrows the query results based on the user group the entries’ authors belong to. */
  authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the user group the entries’ authors belong to, per the groups’ IDs. */
  authorGroupId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the entries’ authors. */
  authorId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to only entries that were posted before a certain date. */
  before?: InputMaybe<Scalars['String']['input']>
  blockLink?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType?: InputMaybe<Scalars['Boolean']['input']>
  categories?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
  descendantDist?: InputMaybe<Scalars['Int']['input']>
  /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
  descendantOf?: InputMaybe<Scalars['Int']['input']>
  description?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Whether to only return entries that the user has permission to edit. */
  editable?: InputMaybe<Scalars['Boolean']['input']>
  email?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the entries’ expiry dates. */
  expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the field the entries are contained by. */
  field?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the field the entries are contained by, per the fields’ IDs. */
  fieldId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>
  fontSize?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth?: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on whether the elements have any descendants in their structure. */
  hasDescendants?: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the elements’ IDs. */
  id?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment?: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile?: InputMaybe<Scalars['Boolean']['input']>
  /** Causes the query results to be returned in reverse order. */
  inReverse?: InputMaybe<Scalars['Boolean']['input']>
  informationSubject?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter?: InputMaybe<Scalars['Boolean']['input']>
  itemLink?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
  leaves?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the elements’ level within the structure. */
  level?: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked?: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2?: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked?: InputMaybe<Scalars['Boolean']['input']>
  /** Sets the limit for paginated results. */
  limit?: InputMaybe<Scalars['Int']['input']>
  linkField?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
  nextSiblingOf?: InputMaybe<Scalars['Int']['input']>
  number?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Sets the offset for paginated results. */
  offset?: InputMaybe<Scalars['Int']['input']>
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: InputMaybe<Scalars['String']['input']>
  organizationUrl?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the owner element of the entries, per the owners’ IDs. */
  ownerId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
  positionedAfter?: InputMaybe<Scalars['Int']['input']>
  /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
  positionedBefore?: InputMaybe<Scalars['Int']['input']>
  /** Narrows the query results based on the entries’ post dates. */
  postDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
  prevSiblingOf?: InputMaybe<Scalars['Int']['input']>
  /** Narrows the query results based on the primary owner element of the entries, per the owners’ IDs. */
  primaryOwnerId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile?: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop?: InputMaybe<Scalars['Boolean']['input']>
  readMoreText?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on a reference string. */
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: InputMaybe<
    Array<InputMaybe<CraftCategoryCriteriaInput>>
  >
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to only elements that match a search query. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Narrows the query results based on the section handles the entries belong to. */
  section?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the sections the entries belong to, per the sections’ IDs. */
  sectionId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show?: InputMaybe<Scalars['Boolean']['input']>
  showIcon?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels?: InputMaybe<Scalars['Boolean']['input']>
  showLink?: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview?: InputMaybe<Scalars['Boolean']['input']>
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the elements’ slugs. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine?: InputMaybe<Scalars['Boolean']['input']>
  stepTitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Determines which structure data should be joined into the query. */
  structureId?: InputMaybe<Scalars['Int']['input']>
  subtitle?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Narrows the query results based on the elements’ titles. */
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the entries’ entry type handles. */
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the entries’ entry types, per the types’ IDs. */
  typeId?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: InputMaybe<Scalars['Boolean']['input']>
  uppercase?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the elements’ URIs. */
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  variant?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  website?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  weekDays?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Explicitly determines whether the query should join in the structure data. */
  withStructure?: InputMaybe<Scalars['Boolean']['input']>
  youtube?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  youtubeId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
}

/** This is the interface implemented by all entries. */
export interface CraftEntryInterface {
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

/** This is the interface implemented by all entries. */
export interface CraftEntryInterfaceAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

/** This is the interface implemented by all entries. */
export interface CraftEntryInterfaceChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

/** This is the interface implemented by all entries. */
export interface CraftEntryInterfaceCountArgs {
  field: Scalars['String']['input']
}

/** This is the interface implemented by all entries. */
export interface CraftEntryInterfaceDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

/** This is the interface implemented by all entries. */
export interface CraftEntryInterfaceLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

/** This is the interface implemented by all entries. */
export interface CraftEntryInterfaceNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

/** This is the interface implemented by all entries. */
export interface CraftEntryInterfaceParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

/** This is the interface implemented by all entries. */
export interface CraftEntryInterfacePrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftFaqItemEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  faqTitle: Maybe<Scalars['String']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  showOnFaqOverview: Maybe<Scalars['Boolean']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
  urlKey: Maybe<Scalars['String']['output']>
}

export interface CraftFaqItemEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftFaqItemEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemsEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftFaqItemsEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  genericfaqitems: Array<Maybe<CraftEntryInterface>>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftFaqItemsEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemsEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemsEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftFaqItemsEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemsEntryGenericfaqitemsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemsEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemsEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemsEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqItemsEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftFaqItemsMatrixField = CraftFaqItemEntry

export interface CraftFaqPageEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftFaqPageEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  faqItems: Array<Maybe<CraftFaqItemsMatrixField>>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  image2: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  number: Maybe<Scalars['CraftNumber']['output']>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftFaqPageEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqPageEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqPageEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftFaqPageEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqPageEntryFaqItemsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqPageEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftFaqPageEntryImage2Args {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftFaqPageEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqPageEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqPageEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFaqPageEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFeaturedCategoriesEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftFeaturedCategoriesEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  anchorLinkId: Maybe<Scalars['String']['output']>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  categories: Array<Maybe<CraftEntryInterface>>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  featuredCategoriesTitle: Maybe<Scalars['String']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftFeaturedCategoriesEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFeaturedCategoriesEntryCategoriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFeaturedCategoriesEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFeaturedCategoriesEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftFeaturedCategoriesEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFeaturedCategoriesEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFeaturedCategoriesEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFeaturedCategoriesEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFeaturedCategoriesEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftFilterExplanationEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richTextSimple1: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftFilterExplanationEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftFilterExplanationEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationItemsBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftFilterExplanationItemsBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  explanation: Maybe<Scalars['String']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  filterTitle: Maybe<Scalars['String']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftFilterExplanationItemsBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationItemsBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationItemsBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftFilterExplanationItemsBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationItemsBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationItemsBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationItemsBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationItemsBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftFilterExplanationItemsMatrixField =
  CraftFilterExplanationItemsBlockEntry

export interface CraftFilterExplanationListEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftFilterExplanationListEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  filterExplanationItems: Array<Maybe<CraftFilterExplanationItemsMatrixField>>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  numberUnformatted: Maybe<Scalars['CraftNumber']['output']>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftFilterExplanationListEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationListEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationListEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftFilterExplanationListEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationListEntryFilterExplanationItemsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationListEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationListEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationListEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFilterExplanationListEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftFilterExplanationListsSectionEntryUnion =
  CraftFilterExplanationListEntry

export interface CraftFooterGlobalSet
  extends CraftElementInterface,
    CraftGlobalSetInterface {
  __typename?: 'CraftFooterGlobalSet'
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  footerLinks: Array<Maybe<CraftFooterLinksMatrixField>>
  /** The handle of the global set. */
  handle: Scalars['String']['output']
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  linkGroups: Array<Maybe<CraftlinkGroupsMatrixField>>
  /** The name of the global set. */
  name: Scalars['String']['output']
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  text1: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  title2: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

export interface CraftFooterGlobalSetCountArgs {
  field: Scalars['String']['input']
}

export interface CraftFooterGlobalSetFooterLinksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftFooterGlobalSetLinkGroupsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftFooterLinksMatrixField = CraftLinksBlockEntry

export interface CraftForgotPasswordEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftForgotPasswordEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text1: Maybe<Scalars['String']['output']>
  text2: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  title2: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftForgotPasswordEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftForgotPasswordEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftForgotPasswordEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftForgotPasswordEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftForgotPasswordEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftForgotPasswordEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftForgotPasswordEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftForgotPasswordEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftForgotPasswordSectionEntryUnion = CraftForgotPasswordEntry

export interface CraftGenericBlockLinkEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftGenericBlockLinkEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  linkField: Maybe<CraftLinkFieldLink>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  text1: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftGenericBlockLinkEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftGenericBlockLinkEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftGenericBlockLinkEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftGenericBlockLinkEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftGenericBlockLinkEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftGenericBlockLinkEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftGenericBlockLinkEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftGenericBlockLinkEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftGenericBlockLinkEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftGenericBlockLinksSectionEntryUnion = CraftGenericBlockLinkEntry

/** This is the interface implemented by all global sets. */
export interface CraftGlobalSetInterface {
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The handle of the global set. */
  handle: Scalars['String']['output']
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The name of the global set. */
  name: Scalars['String']['output']
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

/** This is the interface implemented by all global sets. */
export interface CraftGlobalSetInterfaceCountArgs {
  field: Scalars['String']['input']
}

export interface CraftHeroEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftHeroEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  background: Array<Maybe<CraftAssetInterface>>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  heroLinks: Array<Maybe<CraftHeroLinksMatrixField>>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  titleWithPartialStyling: Array<Maybe<CraftTitleWithPartialStylingMatrixField>>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
  variant: Maybe<Scalars['String']['output']>
}

export interface CraftHeroEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroEntryBackgroundArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftHeroEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftHeroEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroEntryHeroLinksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroEntryTitleWithPartialStylingArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroEntryVariantArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftHeroLinksBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftHeroLinksBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  buttonType: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  linkItem: Maybe<CraftLinkFieldLink>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftHeroLinksBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroLinksBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroLinksBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftHeroLinksBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroLinksBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroLinksBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroLinksBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHeroLinksBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftHeroLinksMatrixField = CraftHeroLinksBlockEntry

export interface CraftHighlightBannerLink2Entry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftHighlightBannerLink2Entry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  fullWidth: Maybe<Scalars['Boolean']['output']>
  highlightedItem: Array<Maybe<CraftEntryInterface>>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  showIcon: Maybe<Scalars['Boolean']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
  variant: Maybe<Scalars['String']['output']>
}

export interface CraftHighlightBannerLink2EntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLink2EntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLink2EntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftHighlightBannerLink2EntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLink2EntryHighlightedItemArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLink2EntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLink2EntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLink2EntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLink2EntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLink2EntryVariantArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftHighlightBannerLinkEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftHighlightBannerLinkEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  highlightedItem: Array<Maybe<CraftEntryInterface>>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  showIcon: Maybe<Scalars['Boolean']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
  variant: Maybe<Scalars['String']['output']>
}

export interface CraftHighlightBannerLinkEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLinkEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLinkEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftHighlightBannerLinkEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLinkEntryHighlightedItemArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLinkEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLinkEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLinkEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLinkEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHighlightBannerLinkEntryVariantArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftHomepageEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftHomepageEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  lightswitchChecked: Maybe<Scalars['Boolean']['output']>
  lightswitchChecked2: Maybe<Scalars['Boolean']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  pageBlocks: Array<Maybe<CraftPageBlocksMatrixField>>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftHomepageEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHomepageEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHomepageEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftHomepageEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHomepageEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHomepageEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHomepageEntryPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHomepageEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHomepageEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftHomepageSectionEntryUnion = CraftHomepageEntry

export interface CraftHtml2Entry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftHtml2Entry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blockTitle: Maybe<Scalars['String']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  code: Maybe<Scalars['String']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftHtml2EntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtml2EntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtml2EntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftHtml2EntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtml2EntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtml2EntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtml2EntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtml2EntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtmlEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftHtmlEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blockTitle: Maybe<Scalars['String']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  code: Maybe<Scalars['String']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftHtmlEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtmlEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtmlEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftHtmlEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtmlEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtmlEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtmlEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftHtmlEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftIconLinksBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftIconLinksBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  linkIcon: Array<Maybe<CraftAssetInterface>>
  linkItem: Maybe<CraftLinkFieldLink>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftIconLinksBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftIconLinksBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftIconLinksBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftIconLinksBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftIconLinksBlockEntryLinkIconArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftIconLinksBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftIconLinksBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftIconLinksBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftIconLinksBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftIconLinksMatrixField = CraftIconLinksBlockEntry

export interface CraftImageContentBanner2Entry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftImageContentBanner2Entry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  anchorLinkId: Maybe<Scalars['String']['output']>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blockLink: Maybe<CraftLinkFieldLink>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  colorTheme: Maybe<Scalars['String']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  imageAlignment: Maybe<Scalars['Boolean']['output']>
  imageAlignmentMobile: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richText: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  titleWithPartialStyling2: Array<
    Maybe<CraftTitleWithPartialStyling2MatrixField>
  >
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftImageContentBanner2EntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBanner2EntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBanner2EntryColorThemeArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftImageContentBanner2EntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftImageContentBanner2EntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBanner2EntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftImageContentBanner2EntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBanner2EntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBanner2EntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBanner2EntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBanner2EntryTitleWithPartialStyling2Args {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBannerEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftImageContentBannerEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  genericItem: Array<Maybe<CraftEntryInterface>>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  imageAlignment: Maybe<Scalars['Boolean']['output']>
  imageAlignmentMobile: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftImageContentBannerEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBannerEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBannerEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftImageContentBannerEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBannerEntryGenericItemArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBannerEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftImageContentBannerEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBannerEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBannerEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentBannerEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentItemEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftImageContentItemEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richText: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  text1: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftImageContentItemEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentItemEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentItemEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftImageContentItemEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentItemEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentItemEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentItemEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageContentItemEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftImageEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftImageEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftImageEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftImageEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverview2Entry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftImageWithProductOverview2Entry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blockTitle: Maybe<Scalars['String']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  items2: Array<Maybe<CraftItems2MatrixField>>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftImageWithProductOverview2EntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverview2EntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverview2EntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftImageWithProductOverview2EntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverview2EntryItems2Args {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverview2EntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverview2EntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverview2EntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverview2EntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverviewEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftImageWithProductOverviewEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blockTitle: Maybe<Scalars['String']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  items: Array<Maybe<CraftItemsMatrixField>>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftImageWithProductOverviewEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverviewEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverviewEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftImageWithProductOverviewEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverviewEntryItemsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverviewEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverviewEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverviewEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftImageWithProductOverviewEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftImageWithProductOverviewsMatrixField =
  CraftImageWithProductOverview2Entry

export interface CraftInformationBalanceBikesEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationBalanceBikesEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  informationItemShort: Array<Maybe<CraftInformationItemShortMatrixField>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  lightswitchChecked: Maybe<Scalars['Boolean']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  pageBlocks: Array<Maybe<CraftPageBlocksMatrixField>>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationBalanceBikesEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationBalanceBikesEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationBalanceBikesEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationBalanceBikesEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationBalanceBikesEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationBalanceBikesEntryInformationItemShortArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationBalanceBikesEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationBalanceBikesEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationBalanceBikesEntryPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationBalanceBikesEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationBalanceBikesEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationBalanceBikesSectionEntryUnion =
  CraftInformationBalanceBikesEntry

export interface CraftInformationGoCartsEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationGoCartsEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  informationItemShort: Array<Maybe<CraftInformationItemShortMatrixField>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  lightswitchChecked: Maybe<Scalars['Boolean']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  pageBlocks: Array<Maybe<CraftPageBlocksMatrixField>>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationGoCartsEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationGoCartsEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationGoCartsEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationGoCartsEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationGoCartsEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationGoCartsEntryInformationItemShortArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationGoCartsEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationGoCartsEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationGoCartsEntryPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationGoCartsEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationGoCartsEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationGoCartsSectionEntryUnion =
  CraftInformationGoCartsEntry

export interface CraftInformationItemFieldsEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationItemFieldsEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  informationSubject: Array<Maybe<CraftEntryInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  itemTitle: Maybe<Scalars['String']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richText: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  showLink: Maybe<Scalars['Boolean']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationItemFieldsEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemFieldsEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemFieldsEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationItemFieldsEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemFieldsEntryInformationSubjectArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemFieldsEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemFieldsEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemFieldsEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemFieldsEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationItemShortMatrixField =
  CraftInformationItemFieldsEntry

export interface CraftInformationItemSubjectEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationItemSubjectEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationItemSubjectEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemSubjectEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemSubjectEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationItemSubjectEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemSubjectEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemSubjectEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemSubjectEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemSubjectEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationItemSubjectsSectionEntryUnion =
  CraftInformationItemSubjectEntry

export interface CraftInformationItemsBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationItemsBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  itemLink: Maybe<CraftLinkFieldLink>
  itemTitle: Maybe<Scalars['String']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
  youtubeId: Maybe<Scalars['String']['output']>
}

export interface CraftInformationItemsBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemsBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemsBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationItemsBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemsBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemsBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemsBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationItemsBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationItemsMatrixField = CraftInformationItemsBlockEntry

export interface CraftInformationOverviewEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationOverviewEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationOverviewEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationOverviewEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationOverviewEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationOverviewEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationOverviewEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationOverviewEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationOverviewEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationOverviewEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationOverviewEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationOverviewSectionEntryUnion =
  CraftInformationOverviewEntry

export interface CraftInformationPlaygroundEquipmentEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationPlaygroundEquipmentEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  informationItemShort: Array<Maybe<CraftInformationItemShortMatrixField>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  lightswitchChecked: Maybe<Scalars['Boolean']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  pageBlocks: Array<Maybe<CraftPageBlocksMatrixField>>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationPlaygroundEquipmentEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationPlaygroundEquipmentEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationPlaygroundEquipmentEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationPlaygroundEquipmentEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationPlaygroundEquipmentEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationPlaygroundEquipmentEntryInformationItemShortArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationPlaygroundEquipmentEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationPlaygroundEquipmentEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationPlaygroundEquipmentEntryPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationPlaygroundEquipmentEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationPlaygroundEquipmentEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationPlaygroundEquipmentSectionEntryUnion =
  CraftInformationPlaygroundEquipmentEntry

export interface CraftInformationRideOnCarsEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationRideOnCarsEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  informationItemShort: Array<Maybe<CraftInformationItemShortMatrixField>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  lightswitchChecked: Maybe<Scalars['Boolean']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  pageBlocks: Array<Maybe<CraftPageBlocksMatrixField>>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationRideOnCarsEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationRideOnCarsEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationRideOnCarsEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationRideOnCarsEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationRideOnCarsEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationRideOnCarsEntryInformationItemShortArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationRideOnCarsEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationRideOnCarsEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationRideOnCarsEntryPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationRideOnCarsEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationRideOnCarsEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationRideOnCarsSectionEntryUnion =
  CraftInformationRideOnCarsEntry

export interface CraftInformationScootersEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationScootersEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  informationItemShort: Array<Maybe<CraftInformationItemShortMatrixField>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  lightswitchChecked: Maybe<Scalars['Boolean']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  pageBlocks: Array<Maybe<CraftPageBlocksMatrixField>>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationScootersEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationScootersEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationScootersEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationScootersEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationScootersEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationScootersEntryInformationItemShortArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationScootersEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationScootersEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationScootersEntryPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationScootersEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationScootersEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationScootersSectionEntryUnion =
  CraftInformationScootersEntry

export interface CraftInformationSubjectBalanceBikesEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationSubjectBalanceBikesEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  number: Maybe<Scalars['CraftNumber']['output']>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationSubjectBalanceBikesEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectBalanceBikesEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectBalanceBikesEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationSubjectBalanceBikesEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectBalanceBikesEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationSubjectBalanceBikesEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectBalanceBikesEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectBalanceBikesEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectBalanceBikesEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationSubjectBalanceBikesSectionEntryUnion =
  CraftInformationSubjectBalanceBikesEntry

export interface CraftInformationSubjectGoCartsEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationSubjectGoCartsEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  number: Maybe<Scalars['CraftNumber']['output']>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationSubjectGoCartsEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectGoCartsEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectGoCartsEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationSubjectGoCartsEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectGoCartsEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationSubjectGoCartsEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectGoCartsEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectGoCartsEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectGoCartsEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationSubjectGoCartsSectionEntryUnion =
  CraftInformationSubjectGoCartsEntry

export interface CraftInformationSubjectPlaygroundEquipmentEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationSubjectPlaygroundEquipmentEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  number: Maybe<Scalars['CraftNumber']['output']>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationSubjectPlaygroundEquipmentEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectPlaygroundEquipmentEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectPlaygroundEquipmentEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationSubjectPlaygroundEquipmentEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectPlaygroundEquipmentEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationSubjectPlaygroundEquipmentEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectPlaygroundEquipmentEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectPlaygroundEquipmentEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectPlaygroundEquipmentEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationSubjectPlaygroundEquipmentSectionEntryUnion =
  CraftInformationSubjectPlaygroundEquipmentEntry

export interface CraftInformationSubjectRideOnCarsEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationSubjectRideOnCarsEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  number: Maybe<Scalars['CraftNumber']['output']>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationSubjectRideOnCarsEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectRideOnCarsEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectRideOnCarsEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationSubjectRideOnCarsEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectRideOnCarsEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationSubjectRideOnCarsEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectRideOnCarsEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectRideOnCarsEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectRideOnCarsEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationSubjectRideOnCarsSectionEntryUnion =
  CraftInformationSubjectRideOnCarsEntry

export interface CraftInformationSubjectScootersEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationSubjectScootersEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  number: Maybe<Scalars['CraftNumber']['output']>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationSubjectScootersEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectScootersEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectScootersEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationSubjectScootersEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectScootersEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationSubjectScootersEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectScootersEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectScootersEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectScootersEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationSubjectScootersSectionEntryUnion =
  CraftInformationSubjectScootersEntry

export interface CraftInformationSubjectTrampolinesEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationSubjectTrampolinesEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  number: Maybe<Scalars['CraftNumber']['output']>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationSubjectTrampolinesEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectTrampolinesEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectTrampolinesEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationSubjectTrampolinesEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectTrampolinesEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationSubjectTrampolinesEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectTrampolinesEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectTrampolinesEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationSubjectTrampolinesEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationSubjectTrampolinesSectionEntryUnion =
  CraftInformationSubjectTrampolinesEntry

export interface CraftInformationTrampolinesEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftInformationTrampolinesEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  informationItemShort: Array<Maybe<CraftInformationItemShortMatrixField>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  lightswitchChecked: Maybe<Scalars['Boolean']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  pageBlocks: Array<Maybe<CraftPageBlocksMatrixField>>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftInformationTrampolinesEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationTrampolinesEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationTrampolinesEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInformationTrampolinesEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationTrampolinesEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftInformationTrampolinesEntryInformationItemShortArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationTrampolinesEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationTrampolinesEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationTrampolinesEntryPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationTrampolinesEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftInformationTrampolinesEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftInformationTrampolinesSectionEntryUnion =
  CraftInformationTrampolinesEntry

export interface CraftInstagramImagesGlobalGlobalSet
  extends CraftElementInterface,
    CraftGlobalSetInterface {
  __typename?: 'CraftInstagramImagesGlobalGlobalSet'
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The handle of the global set. */
  handle: Scalars['String']['output']
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  imageWithProductOverviews: Array<
    Maybe<CraftImageWithProductOverviewsMatrixField>
  >
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The name of the global set. */
  name: Scalars['String']['output']
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

export interface CraftInstagramImagesGlobalGlobalSetCountArgs {
  field: Scalars['String']['input']
}

export interface CraftInstagramImagesGlobalGlobalSetImageWithProductOverviewsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems2BlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftItems2BlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  description: Maybe<Scalars['String']['output']>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  itemTitle: Maybe<Scalars['String']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  products: Array<Maybe<CraftEntryInterface>>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftItems2BlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems2BlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems2BlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftItems2BlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems2BlockEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftItems2BlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems2BlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems2BlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems2BlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems2BlockEntryProductsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftItems2MatrixField = CraftItems2BlockEntry

export interface CraftItems3BlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftItems3BlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  description: Maybe<Scalars['String']['output']>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  itemTitle: Maybe<Scalars['String']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  products: Array<Maybe<CraftEntryInterface>>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftItems3BlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems3BlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems3BlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftItems3BlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems3BlockEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftItems3BlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems3BlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems3BlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems3BlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItems3BlockEntryProductsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItemsBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftItemsBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  description: Maybe<Scalars['String']['output']>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  itemTitle: Maybe<Scalars['String']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  products: Array<Maybe<CraftEntryInterface>>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftItemsBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItemsBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItemsBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftItemsBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItemsBlockEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftItemsBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItemsBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItemsBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItemsBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftItemsBlockEntryProductsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftItemsMatrixField = CraftItemsBlockEntry

/** This is the interface implemented by all links. */
export interface CraftLinkFieldLink {
  __typename?: 'CraftLinkFieldLink'
  ariaLabel: Maybe<Scalars['String']['output']>
  customText: Maybe<Scalars['String']['output']>
  element: Maybe<CraftElementInterface>
  target: Maybe<Scalars['String']['output']>
  text: Maybe<Scalars['String']['output']>
  title: Maybe<Scalars['String']['output']>
  type: Maybe<Scalars['String']['output']>
  url: Maybe<Scalars['String']['output']>
}

export interface CraftLinkGroupEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftLinkGroupEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  groupTitle: Maybe<Scalars['String']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  links: Array<Maybe<CraftlinksMatrixField>>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftLinkGroupEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftLinkGroupEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupEntryLinksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupLinkEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftLinkGroupLinkEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  linkItem: Maybe<CraftLinkFieldLink>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftLinkGroupLinkEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupLinkEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupLinkEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftLinkGroupLinkEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupLinkEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupLinkEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupLinkEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroupLinkEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftLinkGroupLinksMatrixField = CraftLinkGroupLinkEntry

export interface CraftLinkGroups2BlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftLinkGroups2BlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  linkGroupLinks: Array<Maybe<CraftLinkGroupLinksMatrixField>>
  linkGroupTitleLink: Maybe<CraftLinkFieldLink>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftLinkGroups2BlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroups2BlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroups2BlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftLinkGroups2BlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroups2BlockEntryLinkGroupLinksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroups2BlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroups2BlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroups2BlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinkGroups2BlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftLinkGroupsMatrixField = CraftLinkGroups2BlockEntry

export interface CraftLinks2BlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftLinks2BlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  linkItem: Maybe<CraftLinkFieldLink>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftLinks2BlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks2BlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks2BlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftLinks2BlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks2BlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks2BlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks2BlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks2BlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks3BlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftLinks3BlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  linkItem: Maybe<CraftLinkFieldLink>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftLinks3BlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks3BlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks3BlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftLinks3BlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks3BlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks3BlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks3BlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinks3BlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinksBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftLinksBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  linkItem: Maybe<CraftLinkFieldLink>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftLinksBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinksBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinksBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftLinksBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinksBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinksBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinksBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLinksBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftLinksMatrixField = CraftLinks2BlockEntry

export interface CraftLoginEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftLoginEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richTextSimple1: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text1: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftLoginEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLoginEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLoginEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftLoginEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLoginEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLoginEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLoginEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftLoginEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftLoginSectionEntryUnion = CraftLoginEntry

export interface CraftMediaAsset
  extends CraftAssetInterface,
    CraftElementInterface {
  __typename?: 'CraftMediaAsset'
  /** Alternative text for the asset. */
  alt: Maybe<Scalars['String']['output']>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the asset file was last modified. */
  dateModified: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The file extension for the asset file. */
  extension: Scalars['String']['output']
  /** The filename of the asset file. */
  filename: Scalars['String']['output']
  /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
  focalPoint: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  /** The ID of the folder that the asset belongs to. */
  folderId: Scalars['Int']['output']
  /** Returns the file’s format. */
  format: Maybe<Scalars['String']['output']>
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint: Scalars['Boolean']['output']
  /** The height in pixels or null if it’s not an image. */
  height: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** An `<img>` tag based on this asset. */
  img: Maybe<Scalars['String']['output']>
  /** The file kind. */
  kind: Scalars['String']['output']
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The file’s MIME type, if it can be determined. */
  mimeType: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftAssetInterface>
  /** The asset’s path in the volume. */
  path: Scalars['String']['output']
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftAssetInterface>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The file size in bytes. */
  size: Maybe<Scalars['String']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url: Maybe<Scalars['String']['output']>
  /** The ID of the volume that the asset belongs to. */
  volumeId: Maybe<Scalars['Int']['output']>
  /** The width in pixels or null if it’s not an image. */
  width: Maybe<Scalars['Int']['output']>
}

export interface CraftMediaAssetCountArgs {
  field: Scalars['String']['input']
}

export interface CraftMediaAssetFormatArgs {
  format: InputMaybe<Scalars['String']['input']>
  handle: InputMaybe<Scalars['String']['input']>
  height: InputMaybe<Scalars['Int']['input']>
  immediately: InputMaybe<Scalars['Boolean']['input']>
  interlace: InputMaybe<Scalars['String']['input']>
  mode: InputMaybe<Scalars['String']['input']>
  position: InputMaybe<Scalars['String']['input']>
  quality: InputMaybe<Scalars['Int']['input']>
  transform: InputMaybe<Scalars['String']['input']>
  width: InputMaybe<Scalars['Int']['input']>
}

export interface CraftMediaAssetHeightArgs {
  format: InputMaybe<Scalars['String']['input']>
  handle: InputMaybe<Scalars['String']['input']>
  height: InputMaybe<Scalars['Int']['input']>
  immediately: InputMaybe<Scalars['Boolean']['input']>
  interlace: InputMaybe<Scalars['String']['input']>
  mode: InputMaybe<Scalars['String']['input']>
  position: InputMaybe<Scalars['String']['input']>
  quality: InputMaybe<Scalars['Int']['input']>
  transform: InputMaybe<Scalars['String']['input']>
  width: InputMaybe<Scalars['Int']['input']>
}

export interface CraftMediaAssetNextArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftMediaAssetPrevArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftMediaAssetSrcsetArgs {
  sizes: Array<Scalars['String']['input']>
}

export interface CraftMediaAssetUrlArgs {
  format: InputMaybe<Scalars['String']['input']>
  handle: InputMaybe<Scalars['String']['input']>
  height: InputMaybe<Scalars['Int']['input']>
  immediately: InputMaybe<Scalars['Boolean']['input']>
  interlace: InputMaybe<Scalars['String']['input']>
  mode: InputMaybe<Scalars['String']['input']>
  position: InputMaybe<Scalars['String']['input']>
  quality: InputMaybe<Scalars['Int']['input']>
  transform: InputMaybe<Scalars['String']['input']>
  width: InputMaybe<Scalars['Int']['input']>
}

export interface CraftMediaAssetWidthArgs {
  format: InputMaybe<Scalars['String']['input']>
  handle: InputMaybe<Scalars['String']['input']>
  height: InputMaybe<Scalars['Int']['input']>
  immediately: InputMaybe<Scalars['Boolean']['input']>
  interlace: InputMaybe<Scalars['String']['input']>
  mode: InputMaybe<Scalars['String']['input']>
  position: InputMaybe<Scalars['String']['input']>
  quality: InputMaybe<Scalars['Int']['input']>
  transform: InputMaybe<Scalars['String']['input']>
  width: InputMaybe<Scalars['Int']['input']>
}

export interface CraftMenuColumnEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftMenuColumnEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  linkGroups: Array<Maybe<CraftLinkGroupsMatrixField>>
  links: Array<Maybe<CraftLinksMatrixField>>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftMenuColumnEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuColumnEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuColumnEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftMenuColumnEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuColumnEntryLinkGroupsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuColumnEntryLinksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuColumnEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuColumnEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuColumnEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuColumnEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuGlobalSet
  extends CraftElementInterface,
    CraftGlobalSetInterface {
  __typename?: 'CraftMenuGlobalSet'
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The handle of the global set. */
  handle: Scalars['String']['output']
  iconLinks: Array<Maybe<CraftIconLinksMatrixField>>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  menuItems: Array<Maybe<CraftMenuItemsMatrixField>>
  menuLinks: Array<Maybe<CraftMenuLinksMatrixField>>
  /** The name of the global set. */
  name: Scalars['String']['output']
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

export interface CraftMenuGlobalSetCountArgs {
  field: Scalars['String']['input']
}

export interface CraftMenuGlobalSetIconLinksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuGlobalSetImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftMenuGlobalSetMenuItemsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuGlobalSetMenuLinksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItem2Entry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftMenuItem2Entry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  categoryId: Maybe<Scalars['CraftNumber']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  deviatingUrl: Maybe<CraftLinkFieldLink>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  menuItem: Array<Maybe<CraftEntryInterface>>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftMenuItem2EntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItem2EntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItem2EntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftMenuItem2EntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItem2EntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItem2EntryMenuItemArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItem2EntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItem2EntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItem2EntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItemEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftMenuItemEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  genericBlockLink: Array<Maybe<CraftEntryInterface>>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  menuLists: Array<Maybe<CraftMenuListsMatrixField>>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftMenuItemEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItemEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItemEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftMenuItemEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItemEntryGenericBlockLinkArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItemEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftMenuItemEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItemEntryMenuListsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItemEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItemEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftMenuItemEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftMenuItemsMatrixField = CraftMenuItem2Entry

export type CraftMenuItemsSectionEntryUnion = CraftMenuItemEntry

export type CraftMenuLinksMatrixField = CraftLinksBlockEntry

export type CraftMenuListsMatrixField = CraftMenuColumnEntry

export interface CraftNewsEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftNewsEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  newsItems: Array<Maybe<CraftEntryInterface>>
  newsSubtitle: Maybe<Scalars['String']['output']>
  newsTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftNewsEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftNewsEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftNewsEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftNewsEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftNewsEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftNewsEntryNewsItemsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftNewsEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftNewsEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftNewsEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftPageBlocksMatrixField =
  | CraftAccordionInformationEntry
  | CraftCompareSpecificationsEntry
  | CraftFeaturedCategoriesEntry
  | CraftHeroEntry
  | CraftHighlightBannerLink2Entry
  | CraftHtmlEntry
  | CraftImageContentBanner2Entry
  | CraftImageEntry
  | CraftImageWithProductOverviewEntry
  | CraftNewsEntry
  | CraftProductRecommendationsEntry
  | CraftSimpleContentEntry
  | CraftTextColumnsEntry
  | CraftWizardSliderEntry
  | CraftYoutubeVideoEntry

export type CraftPageSectionEntryUnion = CraftDefaultEntry

export interface CraftProductEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftProductEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text1: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftProductEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftProductEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftProductPageBlocksMatrixField =
  | CraftBlockSeparationEntry
  | CraftFaqItemsEntry
  | CraftHighlightBannerLinkEntry
  | CraftImageContentBannerEntry
  | CraftUspsEntry

export type CraftProductPageContentSectionEntryUnion = CraftContentBlocksEntry

export interface CraftProductPageFaqItemEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftProductPageFaqItemEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  answer: Maybe<Scalars['String']['output']>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  linkField: Maybe<CraftLinkFieldLink>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftProductPageFaqItemEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductPageFaqItemEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductPageFaqItemEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftProductPageFaqItemEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductPageFaqItemEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductPageFaqItemEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductPageFaqItemEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductPageFaqItemEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftProductPageFaqItemsSectionEntryUnion =
  CraftProductPageFaqItemEntry

export type CraftProductPageGenericItemsSectionEntryUnion =
  | CraftImageContentItemEntry
  | CraftUspEntry

export interface CraftProductRecommendationsEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftProductRecommendationsEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blockTitle: Maybe<Scalars['String']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  tweakwiseTemplateId: Maybe<Scalars['CraftNumber']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftProductRecommendationsEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRecommendationsEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRecommendationsEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftProductRecommendationsEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRecommendationsEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRecommendationsEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRecommendationsEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRecommendationsEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRegistrationEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftProductRegistrationEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  collapsibleTexts: Array<Maybe<CraftCollapsibleTextsMatrixField>>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richText1: Maybe<Scalars['String']['output']>
  richTextSimple1: Maybe<Scalars['String']['output']>
  richTextSimple2: Maybe<Scalars['String']['output']>
  richTextSimple3: Maybe<Scalars['String']['output']>
  richTextSimple4: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  subtitle2: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  title2: Maybe<Scalars['String']['output']>
  title3: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftProductRegistrationEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRegistrationEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRegistrationEntryCollapsibleTextsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRegistrationEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftProductRegistrationEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRegistrationEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftProductRegistrationEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRegistrationEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRegistrationEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductRegistrationEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftProductRegistrationSectionEntryUnion =
  CraftProductRegistrationEntry

export interface CraftProductTypeEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftProductTypeEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  lightswitchUnchecked: Maybe<Scalars['Boolean']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftProductTypeEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductTypeEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductTypeEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftProductTypeEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductTypeEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductTypeEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductTypeEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftProductTypeEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftProductTypesSectionEntryUnion = CraftProductTypeEntry

export type CraftProductsSectionEntryUnion = CraftProductEntry

export interface CraftQuickFiltersBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftQuickFiltersBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  filterLink: Maybe<CraftLinkFieldLink>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  quickFilterName: Maybe<Scalars['String']['output']>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftQuickFiltersBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftQuickFiltersBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftQuickFiltersBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftQuickFiltersBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftQuickFiltersBlockEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftQuickFiltersBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftQuickFiltersBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftQuickFiltersBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftQuickFiltersBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftQuickFiltersMatrixField = CraftQuickFiltersBlockEntry

export interface CraftRequestLicensePlateEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftRequestLicensePlateEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richText1: Maybe<Scalars['String']['output']>
  richText2: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  subtitle2: Maybe<Scalars['String']['output']>
  text1: Maybe<Scalars['String']['output']>
  text2: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  title2: Maybe<Scalars['String']['output']>
  title3: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftRequestLicensePlateEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftRequestLicensePlateEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftRequestLicensePlateEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftRequestLicensePlateEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftRequestLicensePlateEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftRequestLicensePlateEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftRequestLicensePlateEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftRequestLicensePlateEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftRequestLicensePlateEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftRequestLicensePlateSectionEntryUnion =
  CraftRequestLicensePlateEntry

export interface CraftReviewEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftReviewEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richTextSimple1: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftReviewEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftReviewEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftReviewEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftReviewEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftReviewEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftReviewEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftReviewEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftReviewEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftReviewSectionEntryUnion = CraftReviewEntry

export interface CraftSearchEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftSearchEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  pageBlocks: Array<Maybe<CraftPageBlocksMatrixField>>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftSearchEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSearchEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSearchEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftSearchEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSearchEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSearchEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSearchEntryPageBlocksArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSearchEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSearchEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftSearchSectionEntryUnion = CraftSearchEntry

export interface CraftSeoGlobalGlobalSet
  extends CraftElementInterface,
    CraftGlobalSetInterface {
  __typename?: 'CraftSeoGlobalGlobalSet'
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The handle of the global set. */
  handle: Scalars['String']['output']
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  metaDescription: Maybe<Scalars['String']['output']>
  metaKeywords: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** The name of the global set. */
  name: Scalars['String']['output']
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
}

export interface CraftSeoGlobalGlobalSetCountArgs {
  field: Scalars['String']['input']
}

export interface CraftSimpleContentEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftSimpleContentEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blockLink: Maybe<CraftLinkFieldLink>
  blockTitleWithStyling: Array<Maybe<CraftBlockTitleWithStylingMatrixField>>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  containerWidth: Maybe<Scalars['String']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  readLessText: Maybe<Scalars['String']['output']>
  readMoreLinesMobile: Maybe<Scalars['CraftNumber']['output']>
  readMoreLinesTabletAndDesktop: Maybe<Scalars['CraftNumber']['output']>
  readMoreMobile: Maybe<Scalars['Boolean']['output']>
  readMoreTabletAndDesktop: Maybe<Scalars['Boolean']['output']>
  readMoreText: Maybe<Scalars['String']['output']>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richText: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftSimpleContentEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSimpleContentEntryBlockTitleWithStylingArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSimpleContentEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSimpleContentEntryContainerWidthArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftSimpleContentEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftSimpleContentEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSimpleContentEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSimpleContentEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSimpleContentEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSimpleContentEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSiteGlobalContactBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftSiteGlobalContactBlockEntry'
  addressLocality: Maybe<Scalars['String']['output']>
  addressPostalCode: Maybe<Scalars['String']['output']>
  addressStreet: Maybe<Scalars['String']['output']>
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  emailCustomerService: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  facebook: Maybe<CraftLinkFieldLink>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  instagram: Maybe<CraftLinkFieldLink>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  linkedin: Maybe<CraftLinkFieldLink>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  organizationUrl: Maybe<Scalars['String']['output']>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  telephoneCustomerService: Maybe<Scalars['String']['output']>
  telephoneHeadquarters: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
  youtube: Maybe<CraftLinkFieldLink>
}

export interface CraftSiteGlobalContactBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSiteGlobalContactBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSiteGlobalContactBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftSiteGlobalContactBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSiteGlobalContactBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSiteGlobalContactBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSiteGlobalContactBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSiteGlobalContactBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftSiteGlobalContactMatrixField = CraftSiteGlobalContactBlockEntry

export interface CraftSparepartsEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftSparepartsEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  richText1: Maybe<Scalars['String']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftSparepartsEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSparepartsEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSparepartsEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftSparepartsEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSparepartsEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftSparepartsEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSparepartsEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSparepartsEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSparepartsEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftSparepartsSectionEntryUnion = CraftSparepartsEntry

export interface CraftSpecificationEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftSpecificationEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  specificationLabel: Maybe<Scalars['String']['output']>
  specificationValue: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftSpecificationEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSpecificationEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSpecificationEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftSpecificationEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSpecificationEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSpecificationEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSpecificationEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftSpecificationEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftSpecificationsMatrixField = CraftSpecificationEntry

export interface CraftStoreEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftStoreEntry'
  address1: Maybe<Scalars['String']['output']>
  address2: Maybe<Scalars['String']['output']>
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  city: Maybe<Scalars['String']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  country: Maybe<Scalars['String']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  emailStatic: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  latitude: Maybe<Scalars['CraftNumber']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  longitude: Maybe<Scalars['CraftNumber']['output']>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  phoneNumberLinkStatic: Maybe<CraftLinkFieldLink>
  phoneNumberStatic: Maybe<Scalars['String']['output']>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  productTypes: Array<Maybe<CraftEntryInterface>>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  show: Maybe<Scalars['Boolean']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  storeId: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  textStatic1: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
  website: Maybe<Scalars['String']['output']>
  zipcode: Maybe<Scalars['String']['output']>
}

export interface CraftStoreEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftStoreEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftStoreEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreEntryProductTypesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreLocatorEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftStoreLocatorEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  disclaimer: Maybe<Scalars['String']['output']>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  genericBlockLink: Array<Maybe<CraftEntryInterface>>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  metaDescription: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  subtitle: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftStoreLocatorEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreLocatorEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreLocatorEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftStoreLocatorEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreLocatorEntryGenericBlockLinkArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreLocatorEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftStoreLocatorEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreLocatorEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreLocatorEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftStoreLocatorEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftStoreLocatorSectionEntryUnion = CraftStoreLocatorEntry

export type CraftStoresSectionEntryUnion = CraftStoreEntry

export interface CraftTagCriteriaInput {
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the tag groups the tags belong to per the group’s handles. */
  group?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the tag groups the tags belong to, per the groups’ IDs. */
  groupId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the elements’ IDs. */
  id?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Causes the query results to be returned in reverse order. */
  inReverse?: InputMaybe<Scalars['Boolean']['input']>
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Sets the limit for paginated results. */
  limit?: InputMaybe<Scalars['Int']['input']>
  /** Sets the offset for paginated results. */
  offset?: InputMaybe<Scalars['Int']['input']>
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: InputMaybe<Scalars['String']['input']>
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on a reference string. */
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: InputMaybe<
    Array<InputMaybe<CraftCategoryCriteriaInput>>
  >
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  /** Narrows the query results to only elements that match a search query. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the elements’ slugs. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ titles. */
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the elements’ URIs. */
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftTextColumnsBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftTextColumnsBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  columnTitle: Maybe<Scalars['String']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftTextColumnsBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftTextColumnsBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftTextColumnsEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  colorTheme: Maybe<Scalars['String']['output']>
  containerWidth: Maybe<Scalars['String']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  readLessText: Maybe<Scalars['String']['output']>
  readMoreLinesMobile: Maybe<Scalars['CraftNumber']['output']>
  readMoreMobile: Maybe<Scalars['Boolean']['output']>
  readMoreText: Maybe<Scalars['String']['output']>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  textColumns: Array<Maybe<CraftTextColumnsMatrixField>>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftTextColumnsEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsEntryColorThemeArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftTextColumnsEntryContainerWidthArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftTextColumnsEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftTextColumnsEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTextColumnsEntryTextColumnsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftTextColumnsMatrixField = CraftTextColumnsBlockEntry

export interface CraftTitleWithPartialStyling2BlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftTitleWithPartialStyling2BlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  fontSize: Maybe<Scalars['String']['output']>
  fontWeight: Maybe<Scalars['String']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  startOnNewLine: Maybe<Scalars['Boolean']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  uppercase: Maybe<Scalars['Boolean']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftTitleWithPartialStyling2BlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTitleWithPartialStyling2BlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTitleWithPartialStyling2BlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftTitleWithPartialStyling2BlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTitleWithPartialStyling2BlockEntryFontSizeArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftTitleWithPartialStyling2BlockEntryFontWeightArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftTitleWithPartialStyling2BlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTitleWithPartialStyling2BlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTitleWithPartialStyling2BlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTitleWithPartialStyling2BlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftTitleWithPartialStyling2MatrixField =
  CraftTitleWithPartialStylingBlockEntry

export interface CraftTitleWithPartialStylingBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftTitleWithPartialStylingBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  fontSize: Maybe<Scalars['String']['output']>
  fontWeight: Maybe<Scalars['String']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  startOnNewLine: Maybe<Scalars['Boolean']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  uppercase: Maybe<Scalars['Boolean']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftTitleWithPartialStylingBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTitleWithPartialStylingBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTitleWithPartialStylingBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftTitleWithPartialStylingBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTitleWithPartialStylingBlockEntryFontSizeArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftTitleWithPartialStylingBlockEntryFontWeightArgs {
  label: InputMaybe<Scalars['Boolean']['input']>
}

export interface CraftTitleWithPartialStylingBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTitleWithPartialStylingBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTitleWithPartialStylingBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftTitleWithPartialStylingBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftTitleWithPartialStylingMatrixField =
  CraftTitleWithPartialStyling2BlockEntry

export interface CraftUser extends CraftElementInterface, CraftUserInterface {
  __typename?: 'CraftUser'
  /** The user’s addresses. */
  addresses: Maybe<Array<Maybe<CraftAddressInterface>>>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The user’s email. */
  email: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The user’s first name. */
  firstName: Maybe<Scalars['String']['output']>
  /** The user’s first name or username. */
  friendlyName: Maybe<Scalars['String']['output']>
  /** The user’s full name. */
  fullName: Maybe<Scalars['String']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The user’s last name. */
  lastName: Maybe<Scalars['String']['output']>
  /** The user’s full name or username. */
  name: Scalars['String']['output']
  /** The user’s photo. */
  photo: Maybe<CraftAssetInterface>
  /** The user’s preferences. */
  preferences: Scalars['String']['output']
  /** The user’s preferred language. */
  preferredLanguage: Maybe<Scalars['String']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The username. */
  username: Maybe<Scalars['String']['output']>
}

export interface CraftUserAddressesArgs {
  administrativeArea: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  countryCode: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftUserCountArgs {
  field: Scalars['String']['input']
}

export interface CraftUserCriteriaInput {
  /** Narrows the query results based on whether the users have uploaded any assets. */
  assetUploaders?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on whether the users are listed as the author of any entries. */
  authors?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the users’ email addresses. */
  email?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the users’ first names. */
  firstName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the users’ full names. */
  fullName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the user group the users belong to. */
  group?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Narrows the query results based on the user group the users belong to, per the groups’ IDs. */
  groupId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to only users that have (or don’t have) a user photo. */
  hasPhoto?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the elements’ IDs. */
  id?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Causes the query results to be returned in reverse order. */
  inReverse?: InputMaybe<Scalars['Boolean']['input']>
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the users’ last names. */
  lastName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Sets the limit for paginated results. */
  limit?: InputMaybe<Scalars['Int']['input']>
  /** Sets the offset for paginated results. */
  offset?: InputMaybe<Scalars['Int']['input']>
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: InputMaybe<Scalars['String']['input']>
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on a reference string. */
  ref?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: InputMaybe<
    Array<InputMaybe<CraftCategoryCriteriaInput>>
  >
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  /** Narrows the query results to only elements that match a search query. */
  search?: InputMaybe<Scalars['String']['input']>
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  /** Narrows the query results based on the elements’ slugs. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ titles. */
  title?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: InputMaybe<Scalars['Boolean']['input']>
  /** Narrows the query results based on the elements’ URIs. */
  uri?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Narrows the query results based on the users’ usernames. */
  username?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** This is the interface implemented by all users. */
export interface CraftUserInterface {
  /** The user’s addresses. */
  addresses: Maybe<Array<Maybe<CraftAddressInterface>>>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The user’s email. */
  email: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The user’s first name. */
  firstName: Maybe<Scalars['String']['output']>
  /** The user’s first name or username. */
  friendlyName: Maybe<Scalars['String']['output']>
  /** The user’s full name. */
  fullName: Maybe<Scalars['String']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The user’s last name. */
  lastName: Maybe<Scalars['String']['output']>
  /** The user’s full name or username. */
  name: Scalars['String']['output']
  /** The user’s photo. */
  photo: Maybe<CraftAssetInterface>
  /** The user’s preferences. */
  preferences: Scalars['String']['output']
  /** The user’s preferred language. */
  preferredLanguage: Maybe<Scalars['String']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The username. */
  username: Maybe<Scalars['String']['output']>
}

/** This is the interface implemented by all users. */
export interface CraftUserInterfaceAddressesArgs {
  administrativeArea: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  countryCode: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** This is the interface implemented by all users. */
export interface CraftUserInterfaceCountArgs {
  field: Scalars['String']['input']
}

export interface CraftUspEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftUspEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text1: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftUspEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftUspEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftUspEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftUspsBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  showIcon: Maybe<Scalars['Boolean']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
  uspItem: Maybe<Scalars['String']['output']>
}

export interface CraftUspsBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftUspsBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftUspsEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blockTitle: Maybe<Scalars['String']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  genericItems: Array<Maybe<CraftEntryInterface>>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftUspsEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftUspsEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsEntryGenericItemsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftUspsGlobalGlobalSet
  extends CraftElementInterface,
    CraftGlobalSetInterface {
  __typename?: 'CraftUspsGlobalGlobalSet'
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The handle of the global set. */
  handle: Scalars['String']['output']
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The name of the global set. */
  name: Scalars['String']['output']
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  usps: Array<Maybe<CraftUspsMatrixField>>
}

export interface CraftUspsGlobalGlobalSetCountArgs {
  field: Scalars['String']['input']
}

export interface CraftUspsGlobalGlobalSetUspsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftUspsMatrixField = CraftUspsBlockEntry

export interface CraftUspsProductPageGlobalSet
  extends CraftElementInterface,
    CraftGlobalSetInterface {
  __typename?: 'CraftUspsProductPageGlobalSet'
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** The handle of the global set. */
  handle: Scalars['String']['output']
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The name of the global set. */
  name: Scalars['String']['output']
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  usps: Array<Maybe<CraftUspsMatrixField>>
}

export interface CraftUspsProductPageGlobalSetCountArgs {
  field: Scalars['String']['input']
}

export interface CraftUspsProductPageGlobalSetUspsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardSliderEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftWizardSliderEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  blockTitle: Maybe<Scalars['String']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
  wizardSteps: Array<Maybe<CraftWizardStepsMatrixField>>
}

export interface CraftWizardSliderEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardSliderEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardSliderEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftWizardSliderEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardSliderEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardSliderEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardSliderEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardSliderEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardSliderEntryWizardStepsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardStepsBlockEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftWizardStepsBlockEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  image: Array<Maybe<CraftAssetInterface>>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  stepTitle: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  text: Maybe<Scalars['String']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
}

export interface CraftWizardStepsBlockEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardStepsBlockEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardStepsBlockEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftWizardStepsBlockEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardStepsBlockEntryImageArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftWizardStepsBlockEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardStepsBlockEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardStepsBlockEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftWizardStepsBlockEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftWizardStepsMatrixField = CraftWizardStepsBlockEntry

export interface CraftYoutubeVideoEntry
  extends CraftElementInterface,
    CraftEntryInterface {
  __typename?: 'CraftYoutubeVideoEntry'
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<CraftEntryInterface>
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<CraftEntryInterface>
  /** Return a number of related elements for a field. */
  count: Maybe<Scalars['Int']['output']>
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['CraftDateTime']['output']>
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['CraftDateTime']['output']>
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<CraftEntryInterface>
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['CraftDateTime']['output']>
  /** The handle of the field that contains the entry. */
  fieldHandle: Maybe<Scalars['String']['output']>
  /** The ID of the field that contains the entry. */
  fieldId: Maybe<Scalars['Int']['output']>
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>
  /** The same element in other locales. */
  localized: Array<CraftEntryInterface>
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<CraftEntryInterface>
  /** The ID of the entry’s owner elementt. */
  ownerId: Maybe<Scalars['Int']['output']>
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<CraftEntryInterface>
  placeholder: Array<Maybe<CraftAssetInterface>>
  /** The entry’s post date. */
  postDate: Maybe<Scalars['CraftDateTime']['output']>
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<CraftEntryInterface>
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>
  /** The handle of the section that contains the entry. */
  sectionHandle: Maybe<Scalars['String']['output']>
  /** The ID of the section that contains the entry. */
  sectionId: Maybe<Scalars['Int']['output']>
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>
  /** The entry’s position within the field that contains it. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output']
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output']
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>
  youtubeId: Maybe<Scalars['String']['output']>
}

export interface CraftYoutubeVideoEntryAncestorsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftYoutubeVideoEntryChildrenArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftYoutubeVideoEntryCountArgs {
  field: Scalars['String']['input']
}

export interface CraftYoutubeVideoEntryDescendantsArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftYoutubeVideoEntryLocalizedArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftYoutubeVideoEntryNextArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftYoutubeVideoEntryParentArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface CraftYoutubeVideoEntryPlaceholderArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface CraftYoutubeVideoEntryPrevArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export type CraftitemsMatrixField = CraftItems3BlockEntry

export type CraftlinkGroupsMatrixField = CraftLinkGroupEntry

export type CraftlinksMatrixField = CraftLinks3BlockEntry

/** Contains an array of product IDs to use for creating a compare list. */
export interface CreateCompareListInput {
  /** An array of product IDs to add to the compare list. */
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

/** Assigns a specific `cart_id` to the empty cart. */
export interface CreateEmptyCartInput {
  /** The ID to assign to the cart. */
  cart_id?: InputMaybe<Scalars['String']['input']>
}

export interface CreateGuestCartInput {
  /** Optional client-generated ID */
  cart_uid?: InputMaybe<Scalars['ID']['input']>
}

export interface CreateGuestCartOutput {
  __typename?: 'CreateGuestCartOutput'
  /** The newly created cart. */
  cart: Maybe<Cart>
}

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export interface CreatePayflowProTokenOutput {
  __typename?: 'CreatePayflowProTokenOutput'
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  responseMessage: Scalars['String']['output']
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int']['output']
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  resultCode: Scalars['Int']['output']
  /** A secure token generated by PayPal. */
  secureToken: Scalars['String']['output']
  /** A secure token ID generated by PayPal. */
  secureTokenId: Scalars['String']['output']
}

/** Contains payment order details that are used while processing the payment order */
export interface CreatePaymentOrderInput {
  /** The customer cart ID */
  cartId: Scalars['String']['input']
  /** Defines the origin location for that payment request */
  location: PaymentLocation
  /** The code for the payment method used in the order */
  methodCode: Scalars['String']['input']
  /** The identifiable payment source for the payment method */
  paymentSource: Scalars['String']['input']
  /** Indicates whether the payment information should be vaulted */
  vaultIntent?: InputMaybe<Scalars['Boolean']['input']>
}

/** Contains payment order details that are used while processing the payment order */
export interface CreatePaymentOrderOutput {
  __typename?: 'CreatePaymentOrderOutput'
  /** The amount of the payment order */
  amount: Maybe<Scalars['Float']['output']>
  /** The currency of the payment order */
  currencyCode: Maybe<Scalars['String']['output']>
  /** PayPal order ID */
  id: Maybe<Scalars['String']['output']>
  /** The order ID generated by Payment Services */
  mpOrderId: Maybe<Scalars['String']['output']>
  /** The status of the payment order */
  status: Maybe<Scalars['String']['output']>
}

/** Defines a new product review. */
export interface CreateProductReviewInput {
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String']['input']
  /** The ratings details by category. For example, Price: 5 stars, Quality: 4 stars, etc. */
  ratings: Array<InputMaybe<ProductReviewRatingInput>>
  /** The SKU of the reviewed product. */
  sku: Scalars['String']['input']
  /** The summary (title) of the review. */
  summary: Scalars['String']['input']
  /** The review text. */
  text: Scalars['String']['input']
}

/** Contains the completed product review. */
export interface CreateProductReviewOutput {
  __typename?: 'CreateProductReviewOutput'
  /** Product review details. */
  review: ProductReview
}

/** Required fields for Payflow Pro and Payments Pro credit card payments. */
export interface CreditCardDetailsInput {
  /** The credit card expiration month. */
  cc_exp_month: Scalars['Int']['input']
  /** The credit card expiration year. */
  cc_exp_year: Scalars['Int']['input']
  /** The last 4 digits of the credit card. */
  cc_last_4: Scalars['Int']['input']
  /** The credit card type. */
  cc_type: Scalars['String']['input']
}

/** Contains credit memo details. */
export interface CreditMemo {
  __typename?: 'CreditMemo'
  /** Comments on the credit memo. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>
  /** The unique ID for a `CreditMemo` object. */
  id: Scalars['ID']['output']
  /** An array containing details about refunded items. */
  items: Maybe<Array<Maybe<CreditMemoItemInterface>>>
  /** The sequential credit memo number. */
  number: Scalars['String']['output']
  /** Details about the total refunded amount. */
  total: Maybe<CreditMemoTotal>
}

export interface CreditMemoItem extends CreditMemoItemInterface {
  __typename?: 'CreditMemoItem'
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
}

/** Credit memo item details. */
export interface CreditMemoItemInterface {
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
}

/** Contains credit memo price details. */
export interface CreditMemoTotal {
  __typename?: 'CreditMemoTotal'
  /** An adjustment manually applied to the order. */
  adjustment: Money
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Money
  /** The applied discounts to the credit memo. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Money
  /** Details about the shipping and handling costs for the credit memo. */
  shippingHandling: Maybe<ShippingHandling>
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Money
  /** The credit memo tax details. */
  taxes: Maybe<Array<Maybe<TaxItem>>>
  /** The shipping amount for the credit memo. */
  totalShipping: Money
  /** The amount of tax applied to the credit memo. */
  totalTax: Money
}

export interface Currency {
  __typename?: 'Currency'
  /** An array of three-letter currency codes accepted by the store, such as USD and EUR. */
  availableCurrencyCodes: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The base currency set for the store, such as USD. */
  baseCurrencyCode: Maybe<Scalars['String']['output']>
  /** The symbol for the specified base currency, such as $. */
  baseCurrencySymbol: Maybe<Scalars['String']['output']>
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  defaultDisplayCurrecyCode: Maybe<Scalars['String']['output']>
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  defaultDisplayCurrecySymbol: Maybe<Scalars['String']['output']>
  /** The currency that is displayed by default, such as USD. */
  defaultDisplayCurrencyCode: Maybe<Scalars['String']['output']>
  /** The currency symbol that is displayed by default, such as $. */
  defaultDisplayCurrencySymbol: Maybe<Scalars['String']['output']>
  /** An array of exchange rates for currencies defined in the store. */
  exchangeRates: Maybe<Array<Maybe<ExchangeRate>>>
}

/** The list of available currency codes. */
export enum CurrencyEnum {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZM = 'AZM',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BUK = 'BUK',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHE = 'CHE',
  CHF = 'CHF',
  CHW = 'CHW',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EEK = 'EEK',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEK = 'GEK',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GQE = 'GQE',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LSM = 'LSM',
  LTL = 'LTL',
  LVL = 'LVL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIC = 'NIC',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RHD = 'RHD',
  ROL = 'ROL',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SKK = 'SKK',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  STD = 'STD',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMM = 'TMM',
  TND = 'TND',
  TOP = 'TOP',
  TRL = 'TRL',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VEB = 'VEB',
  VEF = 'VEF',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XCD = 'XCD',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  YTL = 'YTL',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZWD = 'ZWD',
}

/** Defines an array of custom attributes. */
export interface CustomAttributeMetadata {
  __typename?: 'CustomAttributeMetadata'
  /** An array of attributes. */
  items: Maybe<Array<Maybe<Attribute>>>
}

/** An interface containing fields that define the EAV attribute. */
export interface CustomAttributeMetadataInterface {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  code: Scalars['ID']['output']
  /** Default attribute value. */
  defaultValue: Maybe<Scalars['String']['output']>
  /** The type of entity that defines the attribute. */
  entityType: AttributeEntityTypeEnum
  /** The frontend class of the attribute. */
  frontendClass: Maybe<Scalars['String']['output']>
  /** The frontend input type of the attribute. */
  frontendInput: Maybe<AttributeFrontendInputEnum>
  /** Whether the attribute value is required. */
  isRequired: Scalars['Boolean']['output']
  /** Whether the attribute value must be unique. */
  isUnique: Scalars['Boolean']['output']
  /** The label assigned to the attribute. */
  label: Maybe<Scalars['String']['output']>
  /** Attribute options. */
  options: Array<Maybe<CustomAttributeOptionInterface>>
}

export interface CustomAttributeOptionInterface {
  /** Is the option value default. */
  isDefault: Scalars['Boolean']['output']
  /** The label assigned to the attribute option. */
  label: Scalars['String']['output']
  /** The attribute option value. */
  value: Scalars['String']['output']
}

/** Defines the customer name, addresses, and other details. */
export interface Customer {
  __typename?: 'Customer'
  /** An array containing the customer's shipping and billing addresses. */
  addresses: Maybe<Array<Maybe<CustomerAddress>>>
  /** The contents of the customer's compare list. */
  compareList: Maybe<CompareList>
  /** The customer's confirmation status. */
  confirmationStatus: ConfirmationStatusEnum
  /** Timestamp indicating when the account was created. */
  createdAt: Maybe<Scalars['String']['output']>
  /** Customer's custom attributes. */
  customAttributes: Maybe<Array<Maybe<AttributeValueInterface>>>
  /** The customer's date of birth. */
  dateOfBirth: Maybe<Scalars['String']['output']>
  /** The ID assigned to the billing address. */
  defaultBilling: Maybe<Scalars['String']['output']>
  /** The ID assigned to the shipping address. */
  defaultShipping: Maybe<Scalars['String']['output']>
  /**
   * The customer's date of birth.
   * @deprecated Use `date_of_birth` instead.
   */
  dob: Maybe<Scalars['String']['output']>
  /** The customer's email address. Required. */
  email: Maybe<Scalars['String']['output']>
  /** The customer's first name. */
  firstname: Maybe<Scalars['String']['output']>
  /** The customer's gender (Male - 1, Female - 2). */
  gender: Maybe<Scalars['Int']['output']>
  /** @deprecated Customer group should not be exposed in the storefront scenarios. */
  groupId: Maybe<Scalars['Int']['output']>
  /**
   * The ID assigned to the customer.
   * @deprecated `id` is not needed as part of `Customer`, because on the server side, it can be identified based on the customer token used for authentication. There is no need to know customer ID on the client side.
   */
  id: Maybe<Scalars['Int']['output']>
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  isSubscribed: Maybe<Scalars['Boolean']['output']>
  /** The customer's family name. */
  lastname: Maybe<Scalars['String']['output']>
  /** The customer's middle name. */
  middlename: Maybe<Scalars['String']['output']>
  orders: Maybe<CustomerOrders>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>
  /** Contains the customer's product reviews. */
  reviews: ProductReviews
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  taxvat: Maybe<Scalars['String']['output']>
  /**
   * Return a customer's wish lists.
   * @deprecated Use `Customer.wishlists` or `Customer.wishlist_v2` instead.
   */
  wishlist: Wishlist
  /** Retrieve the wish list identified by the unique ID for a `Wishlist` object. */
  wishlistV2: Maybe<Wishlist>
  /** An array of wishlists. In Magento Open Source, customers are limited to one wish list. The number of wish lists is configurable for Adobe Commerce. */
  wishlists: Array<Maybe<Wishlist>>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerCustomAttributesArgs {
  attributeCodes: InputMaybe<Array<Scalars['ID']['input']>>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerOrdersArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<CustomerOrdersFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  scope: InputMaybe<ScopeTypeEnum>
  sort: InputMaybe<CustomerOrderSortInput>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerWishlistV2Args {
  id: Scalars['ID']['input']
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerWishlistsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Contains detailed information about a customer's billing or shipping address. */
export interface CustomerAddress {
  __typename?: 'CustomerAddress'
  /** The customer's city or town. */
  city: Maybe<Scalars['String']['output']>
  /** The customer's company. */
  company: Maybe<Scalars['String']['output']>
  /** The customer's country. */
  countryCode: Maybe<CountryCodeEnum>
  /**
   * The customer's country.
   * @deprecated Use `country_code` instead.
   */
  countryId: Maybe<Scalars['String']['output']>
  /** @deprecated Use custom_attributesV2 instead. */
  customAttributes: Maybe<Array<Maybe<CustomerAddressAttribute>>>
  /** Custom attributes assigned to the customer address. */
  customAttributesV2: Array<Maybe<AttributeValueInterface>>
  /**
   * The customer ID
   * @deprecated `customer_id` is not needed as part of `CustomerAddress`. The `id` is a unique identifier for the addresses.
   */
  customerId: Maybe<Scalars['Int']['output']>
  /** Indicates whether the address is the customer's default billing address. */
  defaultBilling: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the address is the customer's default shipping address. */
  defaultShipping: Maybe<Scalars['Boolean']['output']>
  /** Contains any extension attributes for the address. */
  extensionAttributes: Maybe<Array<Maybe<CustomerAddressAttribute>>>
  /** The customer's fax number. */
  fax: Maybe<Scalars['String']['output']>
  /** The first name of the person associated with the shipping/billing address. */
  firstname: Maybe<Scalars['String']['output']>
  /** The ID of a `CustomerAddress` object. */
  id: Maybe<Scalars['Int']['output']>
  /** The family name of the person associated with the shipping/billing address. */
  lastname: Maybe<Scalars['String']['output']>
  /** The middle name of the person associated with the shipping/billing address. */
  middlename: Maybe<Scalars['String']['output']>
  /** The customer's ZIP or postal code. */
  postcode: Maybe<Scalars['String']['output']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>
  /** An object containing the region name, region code, and region ID. */
  region: Maybe<CustomerAddressRegion>
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']['output']>
  /** An array of strings that define the street number and name. */
  street: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>
  /** The customer's telephone number. */
  telephone: Maybe<Scalars['String']['output']>
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vatId: Maybe<Scalars['String']['output']>
}

/** Contains detailed information about a customer's billing or shipping address. */
export interface CustomerAddressCustomAttributesV2Args {
  attributeCodes: InputMaybe<Array<Scalars['ID']['input']>>
}

/** Specifies the attribute code and value of a customer address attribute. */
export interface CustomerAddressAttribute {
  __typename?: 'CustomerAddressAttribute'
  /** The name assigned to the customer address attribute. */
  attributeCode: Maybe<Scalars['String']['output']>
  /** The value assigned to the customer address attribute. */
  value: Maybe<Scalars['String']['output']>
}

/** Specifies the attribute code and value of a customer attribute. */
export interface CustomerAddressAttributeInput {
  /** The name assigned to the attribute. */
  attribute_code: Scalars['String']['input']
  /** The value assigned to the attribute. */
  value: Scalars['String']['input']
}

/** Contains details about a billing or shipping address. */
export interface CustomerAddressInput {
  /** The customer's city or town. */
  city?: InputMaybe<Scalars['String']['input']>
  /** The customer's company. */
  company?: InputMaybe<Scalars['String']['input']>
  /** The two-letter code representing the customer's country. */
  country_code?: InputMaybe<CountryCodeEnum>
  /** Deprecated: use `country_code` instead. */
  country_id?: InputMaybe<CountryCodeEnum>
  /** Deprecated. Use custom_attributesV2 instead. */
  custom_attributes?: InputMaybe<
    Array<InputMaybe<CustomerAddressAttributeInput>>
  >
  /** Custom attributes assigned to the customer address. */
  custom_attributesV2?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>
  /** Indicates whether the address is the default billing address. */
  default_billing?: InputMaybe<Scalars['Boolean']['input']>
  /** Indicates whether the address is the default shipping address. */
  default_shipping?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's fax number. */
  fax?: InputMaybe<Scalars['String']['input']>
  /** The first name of the person associated with the billing/shipping address. */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The family name of the person associated with the billing/shipping address. */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** The middle name of the person associated with the billing/shipping address. */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** The customer's ZIP or postal code. */
  postcode?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** An object containing the region name, region code, and region ID. */
  region?: InputMaybe<CustomerAddressRegionInput>
  /** An array of strings that define the street number and name. */
  street?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The customer's telephone number. */
  telephone?: InputMaybe<Scalars['String']['input']>
  /** The customer's Tax/VAT number (for corporate customers). */
  vat_id?: InputMaybe<Scalars['String']['input']>
}

/** Defines the customer's state or province. */
export interface CustomerAddressRegion {
  __typename?: 'CustomerAddressRegion'
  /** The state or province name. */
  region: Maybe<Scalars['String']['output']>
  /** The address region code. */
  regionCode: Maybe<Scalars['String']['output']>
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']['output']>
}

/** Defines the customer's state or province. */
export interface CustomerAddressRegionInput {
  /** The state or province name. */
  region?: InputMaybe<Scalars['String']['input']>
  /** The address region code. */
  region_code?: InputMaybe<Scalars['String']['input']>
  /** The unique ID for a pre-defined region. */
  region_id?: InputMaybe<Scalars['Int']['input']>
}

/** Customer attribute metadata. */
export interface CustomerAttributeMetadata
  extends CustomAttributeMetadataInterface {
  __typename?: 'CustomerAttributeMetadata'
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  code: Scalars['ID']['output']
  /** Default attribute value. */
  defaultValue: Maybe<Scalars['String']['output']>
  /** The type of entity that defines the attribute. */
  entityType: AttributeEntityTypeEnum
  /** The frontend class of the attribute. */
  frontendClass: Maybe<Scalars['String']['output']>
  /** The frontend input type of the attribute. */
  frontendInput: Maybe<AttributeFrontendInputEnum>
  /** The template used for the input of the attribute (e.g., 'date'). */
  inputFilter: Maybe<InputFilterEnum>
  /** Whether the attribute value is required. */
  isRequired: Scalars['Boolean']['output']
  /** Whether the attribute value must be unique. */
  isUnique: Scalars['Boolean']['output']
  /** The label assigned to the attribute. */
  label: Maybe<Scalars['String']['output']>
  /** The number of lines of the attribute value. */
  multilineCount: Maybe<Scalars['Int']['output']>
  /** Attribute options. */
  options: Array<Maybe<CustomAttributeOptionInterface>>
  /** The position of the attribute in the form. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The validation rules of the attribute value. */
  validateRules: Maybe<Array<Maybe<ValidationRule>>>
}

/** An input object for creating a customer. */
export interface CustomerCreateInput {
  /** The customer's custom attributes. */
  custom_attributes?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>
  /** The customer's date of birth. */
  date_of_birth?: InputMaybe<Scalars['String']['input']>
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: InputMaybe<Scalars['String']['input']>
  /** The customer's email address. */
  email: Scalars['String']['input']
  /** The customer's first name. */
  firstname: Scalars['String']['input']
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: InputMaybe<Scalars['Int']['input']>
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's family name. */
  lastname: Scalars['String']['input']
  /** The customer's middle name. */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** The customer's password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: InputMaybe<Scalars['String']['input']>
}

/** Contains details about a single downloadable product. */
export interface CustomerDownloadableProduct {
  __typename?: 'CustomerDownloadableProduct'
  /** The date and time the purchase was made. */
  date: Maybe<Scalars['String']['output']>
  /** The fully qualified URL to the download file. */
  downloadUrl: Maybe<Scalars['String']['output']>
  /** The unique ID assigned to the item. */
  orderIncrementId: Maybe<Scalars['String']['output']>
  /** The remaining number of times the customer can download the product. */
  remainingDownloads: Maybe<Scalars['String']['output']>
  /** Indicates when the product becomes available for download. Options are `Pending` and `Invoiced`. */
  status: Maybe<Scalars['String']['output']>
}

/** Contains a list of downloadable products. */
export interface CustomerDownloadableProducts {
  __typename?: 'CustomerDownloadableProducts'
  /** An array of purchased downloadable items. */
  items: Maybe<Array<Maybe<CustomerDownloadableProduct>>>
}

/** An input object that assigns or updates customer attributes. */
export interface CustomerInput {
  /** The customer's date of birth. */
  date_of_birth?: InputMaybe<Scalars['String']['input']>
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: InputMaybe<Scalars['String']['input']>
  /** The customer's email address. Required when creating a customer. */
  email?: InputMaybe<Scalars['String']['input']>
  /** The customer's first name. */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: InputMaybe<Scalars['Int']['input']>
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's family name. */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** The customer's middle name. */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** The customer's password. */
  password?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: InputMaybe<Scalars['String']['input']>
}

/** Contains details about each of the customer's orders. */
export interface CustomerOrder {
  __typename?: 'CustomerOrder'
  /** Coupons applied to the order. */
  appliedCoupons: Array<Maybe<AppliedCoupon>>
  /** The billing address for the order. */
  billingAddress: Maybe<OrderAddress>
  /** The shipping carrier for the order delivery. */
  carrier: Maybe<Scalars['String']['output']>
  /** Comments about the order. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>
  /** The coupon code that is used */
  couponCode: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `order_date` field instead. */
  createdAt: Maybe<Scalars['String']['output']>
  /** A list of credit memos. */
  creditMemos: Maybe<Array<Maybe<CreditMemo>>>
  /** Order customer email. */
  email: Maybe<Scalars['String']['output']>
  /** The entered gift message for the order */
  giftMessage: Maybe<GiftMessage>
  /** @deprecated Use the `totals.grand_total` field instead. */
  grandTotal: Maybe<Scalars['Float']['output']>
  /** The unique ID for a `CustomerOrder` object. */
  id: Scalars['ID']['output']
  /** @deprecated Use the `id` field instead. */
  incrementId: Maybe<Scalars['String']['output']>
  /** A list of invoices for the order. */
  invoices: Array<Maybe<Invoice>>
  /** Indicates whether the order can be returned */
  isReturnable: Scalars['Boolean']['output']
  /** An array containing the items purchased in this order. */
  items: Maybe<Array<Maybe<OrderItemInterface>>>
  /** The order number. */
  number: Scalars['String']['output']
  /** The date the order was placed. */
  orderDate: Scalars['String']['output']
  /** @deprecated Use the `number` field instead. */
  orderNumber: Scalars['String']['output']
  /** Payment details for the order. */
  paymentMethods: Maybe<Array<Maybe<OrderPaymentMethod>>>
  /** A list of shipments for the order. */
  shipments: Maybe<Array<Maybe<OrderShipment>>>
  /** The shipping address for the order. */
  shippingAddress: Maybe<OrderAddress>
  /** The delivery method for the order. */
  shippingMethod: Maybe<Scalars['String']['output']>
  /** The current status of the order. */
  status: Scalars['String']['output']
  /** The token that can be used to retrieve the order using order query. */
  token: Scalars['String']['output']
  /** Details about the calculated totals for this order. */
  total: Maybe<OrderTotal>
}

/** CustomerOrderSortInput specifies the field to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export interface CustomerOrderSortInput {
  /** This enumeration indicates whether to return results in ascending or descending order */
  sort_direction: SortEnum
  /** Specifies the field to use for sorting */
  sort_field: CustomerOrderSortableField
}

/** Specifies the field to use for sorting */
export enum CustomerOrderSortableField {
  /** Sorts customer orders by created_at field */
  CREATED_AT = 'CREATED_AT',
  /** Sorts customer orders by number */
  NUMBER = 'NUMBER',
}

/** The collection of orders that match the conditions defined in the filter. */
export interface CustomerOrders {
  __typename?: 'CustomerOrders'
  /** An array of customer orders. */
  items: Array<Maybe<CustomerOrder>>
  /** Contains pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** The total count of customer orders. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Identifies the filter to use for filtering orders. */
export interface CustomerOrdersFilterInput {
  /** Filters by order number. */
  number?: InputMaybe<FilterStringTypeInput>
}

/** Contains details about a newly-created or updated customer. */
export interface CustomerOutput {
  __typename?: 'CustomerOutput'
  /** Customer details after creating or updating a customer. */
  customer: Customer
}

/** Contains payment tokens stored in the customer's vault. */
export interface CustomerPaymentTokens {
  __typename?: 'CustomerPaymentTokens'
  /** An array of payment tokens. */
  items: Array<Maybe<PaymentToken>>
}

/** Contains a customer authorization token. */
export interface CustomerToken {
  __typename?: 'CustomerToken'
  /** Refresh token to generate a new token for the customer before it expires. */
  refreshToken: Maybe<Scalars['String']['output']>
  /** The customer authorization token. */
  token: Maybe<Scalars['String']['output']>
}

/** An input object for updating a customer. */
export interface CustomerUpdateInput {
  /** The customer's custom attributes. */
  custom_attributes?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>
  /** The customer's date of birth. */
  date_of_birth?: InputMaybe<Scalars['String']['input']>
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: InputMaybe<Scalars['String']['input']>
  /** The customer's first name. */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: InputMaybe<Scalars['Int']['input']>
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's family name. */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** The customer's middle name. */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: InputMaybe<Scalars['String']['input']>
}

/** Contains information about a text area that is defined as part of a customizable option. */
export interface CustomizableAreaOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableAreaOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An object that defines a text area. */
  value: Maybe<CustomizableAreaValue>
}

/** Defines the price and sku of a product whose page contains a customized text area. */
export interface CustomizableAreaValue {
  __typename?: 'CustomizableAreaValue'
  /** The maximum number of characters that can be entered for this customizable option. */
  maxCharacters: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableAreaValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about a set of checkbox values that are defined as part of a customizable option. */
export interface CustomizableCheckboxOption
  extends CustomizableOptionInterface {
  __typename?: 'CustomizableCheckboxOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An array that defines a set of checkbox values. */
  value: Maybe<Array<Maybe<CustomizableCheckboxValue>>>
}

/** Defines the price and sku of a product whose page contains a customized set of checkbox values. */
export interface CustomizableCheckboxValue {
  __typename?: 'CustomizableCheckboxValue'
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The order in which the checkbox value is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableCheckboxValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about a date picker that is defined as part of a customizable option. */
export interface CustomizableDateOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableDateOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An object that defines a date field in a customizable option. */
  value: Maybe<CustomizableDateValue>
}

/** Defines the customizable date type. */
export enum CustomizableDateTypeEnum {
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  TIME = 'TIME',
}

/** Defines the price and sku of a product whose page contains a customized date picker. */
export interface CustomizableDateValue {
  __typename?: 'CustomizableDateValue'
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** DATE, DATE_TIME or TIME */
  type: Maybe<CustomizableDateTypeEnum>
  /** The unique ID for a `CustomizableDateValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about a drop down menu that is defined as part of a customizable option. */
export interface CustomizableDropDownOption
  extends CustomizableOptionInterface {
  __typename?: 'CustomizableDropDownOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An array that defines the set of options for a drop down menu. */
  value: Maybe<Array<Maybe<CustomizableDropDownValue>>>
}

/** Defines the price and sku of a product whose page contains a customized drop down menu. */
export interface CustomizableDropDownValue {
  __typename?: 'CustomizableDropDownValue'
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableDropDownValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about a text field that is defined as part of a customizable option. */
export interface CustomizableFieldOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableFieldOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An object that defines a text field. */
  value: Maybe<CustomizableFieldValue>
}

/** Defines the price and sku of a product whose page contains a customized text field. */
export interface CustomizableFieldValue {
  __typename?: 'CustomizableFieldValue'
  /** The maximum number of characters that can be entered for this customizable option. */
  maxCharacters: Maybe<Scalars['Int']['output']>
  /** The price of the custom value. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableFieldValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about a file picker that is defined as part of a customizable option. */
export interface CustomizableFileOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableFileOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An object that defines a file value. */
  value: Maybe<CustomizableFileValue>
}

/** Defines the price and sku of a product whose page contains a customized file picker. */
export interface CustomizableFileValue {
  __typename?: 'CustomizableFileValue'
  /** The file extension to accept. */
  fileExtension: Maybe<Scalars['String']['output']>
  /** The maximum width of an image. */
  imageSizeX: Maybe<Scalars['Int']['output']>
  /** The maximum height of an image. */
  imageSizeY: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableFileValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about a multiselect that is defined as part of a customizable option. */
export interface CustomizableMultipleOption
  extends CustomizableOptionInterface {
  __typename?: 'CustomizableMultipleOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An array that defines the set of options for a multiselect. */
  value: Maybe<Array<Maybe<CustomizableMultipleValue>>>
}

/** Defines the price and sku of a product whose page contains a customized multiselect. */
export interface CustomizableMultipleValue {
  __typename?: 'CustomizableMultipleValue'
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableMultipleValue` object. */
  uid: Scalars['ID']['output']
}

/** Defines a customizable option. */
export interface CustomizableOptionInput {
  /** The customizable option ID of the product. */
  id?: InputMaybe<Scalars['Int']['input']>
  /** The unique ID for a `CartItemInterface` object. */
  uid?: InputMaybe<Scalars['ID']['input']>
  /** The string value of the option. */
  value_string: Scalars['String']['input']
}

/** Contains basic information about a customizable option. It can be implemented by several types of configurable options. */
export interface CustomizableOptionInterface {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
}

/** Contains information about customizable product options. */
export interface CustomizableProductInterface {
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
}

/** Contains information about a set of radio buttons that are defined as part of a customizable option. */
export interface CustomizableRadioOption extends CustomizableOptionInterface {
  __typename?: 'CustomizableRadioOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An array that defines a set of radio buttons. */
  value: Maybe<Array<Maybe<CustomizableRadioValue>>>
}

/** Defines the price and sku of a product whose page contains a customized set of radio buttons. */
export interface CustomizableRadioValue {
  __typename?: 'CustomizableRadioValue'
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>
  /** The order in which the radio button is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableRadioValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains the results of the request to delete a compare list. */
export interface DeleteCompareListOutput {
  __typename?: 'DeleteCompareListOutput'
  /** Indicates whether the compare list was successfully deleted. */
  result: Scalars['Boolean']['output']
}

/** Indicates whether the request succeeded and returns the remaining customer payment tokens. */
export interface DeletePaymentTokenOutput {
  __typename?: 'DeletePaymentTokenOutput'
  /** A container for the customer's remaining payment tokens. */
  customerPaymentTokens: Maybe<CustomerPaymentTokens>
  /** Indicates whether the request succeeded. */
  result: Scalars['Boolean']['output']
}

export interface DetailedSummary {
  __typename?: 'DetailedSummary'
  /** Five stars. */
  five: Maybe<Scalars['Int']['output']>
  /** Four stars. */
  four: Maybe<Scalars['Int']['output']>
  /** One star. */
  one: Maybe<Scalars['Int']['output']>
  /** Three stars. */
  three: Maybe<Scalars['Int']['output']>
  /** Two stars. */
  two: Maybe<Scalars['Int']['output']>
}

/** Defines an individual discount. A discount can be applied to the cart as a whole or to an item, shipping. */
export interface Discount {
  __typename?: 'Discount'
  /** The amount of the discount. */
  amount: Money
  /** The type of the entity the discount is applied to. */
  appliedTo: CartDiscountType
  /** The coupon related to the discount. */
  coupon: Maybe<AppliedCoupon>
  /** A description of the discount. */
  label: Scalars['String']['output']
}

/** An implementation for downloadable product cart items. */
export interface DownloadableCartItem extends CartItemInterface {
  __typename?: 'DownloadableCartItem'
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** An array containing information about the links for the downloadable product added to the cart. */
  links: Maybe<Array<Maybe<DownloadableProductLinks>>>
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** An array containing information about samples of the selected downloadable product. */
  samples: Maybe<Array<Maybe<DownloadableProductSamples>>>
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

/** Defines downloadable product options for `CreditMemoItemInterface`. */
export interface DownloadableCreditMemoItem extends CreditMemoItemInterface {
  __typename?: 'DownloadableCreditMemoItem'
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** A list of downloadable links that are refunded from the downloadable product. */
  downloadableLinks: Maybe<Array<Maybe<DownloadableItemsLinks>>>
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
}

export enum DownloadableFileTypeEnum {
  /** @deprecated `sample_url` serves to get the downloadable sample */
  FILE = 'FILE',
  /** @deprecated `sample_url` serves to get the downloadable sample */
  URL = 'URL',
}

/** Defines downloadable product options for `InvoiceItemInterface`. */
export interface DownloadableInvoiceItem extends InvoiceItemInterface {
  __typename?: 'DownloadableInvoiceItem'
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** A list of downloadable links that are invoiced from the downloadable product. */
  downloadableLinks: Maybe<Array<Maybe<DownloadableItemsLinks>>>
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output']
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
}

/** Defines characteristics of the links for downloadable product. */
export interface DownloadableItemsLinks {
  __typename?: 'DownloadableItemsLinks'
  /** A number indicating the sort order. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name of the link. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `DownloadableItemsLinks` object. */
  uid: Scalars['ID']['output']
}

/** Defines downloadable product options for `OrderItemInterface`. */
export interface DownloadableOrderItem extends OrderItemInterface {
  __typename?: 'DownloadableOrderItem'
  /** The total refunded amount */
  amountRefunded: Money
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** A list of downloadable links that are ordered from the downloadable product. */
  downloadableLinks: Maybe<Array<Maybe<DownloadableItemsLinks>>>
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output']
  /** The ProductInterface object, which contains details about the base product */
  product: Maybe<ProductInterface>
  /** Product image */
  productImage: Maybe<ProductImage>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>
  /** The total row amount */
  rowTotalIncludingTax: Money
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>
}

/** Defines a product that the shopper downloads. */
export interface DownloadableProduct
  extends CustomizableProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'DownloadableProduct'
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeBalancebike: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeLgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeRideoncar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeScooter: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeSgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeTrampoline: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  addOn: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSeat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSteeringWheel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  aerowall: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ageRecommended: Maybe<Scalars['Int']['output']>
  /** Ambiance Product Images */
  ambianceGallery: Maybe<Array<Maybe<AmbianceGallery>>>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  categoryPlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ceLabel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  collaborationLicense: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** Color Variants */
  colorVariants: Maybe<Array<Maybe<ColorVariant>>>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** An array containing information about the links for this downloadable product. */
  downloadableProductLinks: Maybe<Array<Maybe<DownloadableProductLinks>>>
  /** An array containing information about samples of this downloadable product. */
  downloadableProductSamples: Maybe<Array<Maybe<DownloadableProductSamples>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  drive: Maybe<Scalars['Int']['output']>
  /** Filterable attribute with Text */
  dynamicAttributes: Maybe<Array<Maybe<DynamicAttribute>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  electricalSupport: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  entranceHeight: Maybe<Scalars['Int']['output']>
  /** Craft entry type */
  entryType: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  framePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  frameXlGokart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gears: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['Int']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gokartType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  handbrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  heightTrampoline: Maybe<Scalars['Int']['output']>
  /** Highlight attributes */
  highlightedAttributes: Maybe<Array<Maybe<HighlightAttribute>>>
  /** Highlight attributes PLP */
  highlightedAttributesPlp: Maybe<Array<Maybe<HighlightAttribute>>>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Scalars['Int']['output']
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  inclSafetynet: Maybe<Scalars['Int']['output']>
  /** Whether the product is a main product or not. */
  isMainProduct: Scalars['Boolean']['output']
  /** @deprecated Use the `custom_attributes` field instead. */
  jumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  lengthUser: Maybe<Scalars['Int']['output']>
  /** A value of 1 indicates that each link in the array must be purchased separately. */
  linksPurchasedSeparately: Maybe<Scalars['Int']['output']>
  /** The heading above the list of downloadable products. */
  linksTitle: Maybe<Scalars['String']['output']>
  /** A number representing the product's manufacturer. */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  material: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  materialTires: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUserWeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUsers: Maybe<Scalars['Int']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgBrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgClamp: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgDeck: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgFrontFork: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGrips: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGriptape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHandlebar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHeadset: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgWheels: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  model: Maybe<Scalars['Int']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Scalars['String']['output']
  /** @deprecated Use the `custom_attributes` field instead. */
  netWeight: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  numberOfSprings: Maybe<Scalars['Int']['output']>
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  pedals: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  playbaseSet: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  possibilityFor2ndSeat: Maybe<Scalars['Int']['output']>
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** Product downloads */
  productDownloads: Maybe<ProductDownload>
  productLabels: Maybe<Array<Maybe<AmLabelList>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  pushRod: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityLine: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityStars: Maybe<Scalars['Int']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of products to be displayed in a Related Products block. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** @deprecated Use the `custom_attributes` field instead. */
  safetynetType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  seatHeight: Maybe<Scalars['Int']['output']>
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAccessories: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAirtrack: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeBeachwagon: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeClothing: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeGocart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeJumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeStep: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeWheelbarrow: Maybe<Scalars['Int']['output']>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Scalars['String']['output']
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /** Get list of spareparts per product(group), per year */
  sparepartLinks: Maybe<Array<Maybe<BergProduct>>>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  springType: Maybe<Scalars['Int']['output']>
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  testweight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  themePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  thicknessOfProtectiveEdge: Maybe<Scalars['Int']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineShape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  tuevGs: Maybe<Scalars['Int']['output']>
  /** Tweakwise ID */
  tweakwiseItemNumber: Maybe<Scalars['String']['output']>
  /** PreOrder info */
  tweakwiseTemplates: Maybe<TweakwiseTemplates>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /** @deprecated Use the `custom_attributes` field instead. */
  typeAccessory: Maybe<Scalars['Int']['output']>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Scalars['String']['output']
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  uvProtection: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warrantyWoRegistration: Maybe<Scalars['Int']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelLights: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelSize: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  widthPadding: Maybe<Scalars['Int']['output']>
}

/** Defines a product that the shopper downloads. */
export interface DownloadableProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines a product that the shopper downloads. */
export interface DownloadableProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines a single downloadable product. */
export interface DownloadableProductCartItemInput {
  /** The ID and value of the option. */
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** The quantity and SKU of the downloadable product. */
  data: CartItemInput
  /** An array of objects containing the link_id of the downloadable product link. */
  downloadable_product_links?: InputMaybe<
    Array<InputMaybe<DownloadableProductLinksInput>>
  >
}

/** Defines characteristics of a downloadable product. */
export interface DownloadableProductLinks {
  __typename?: 'DownloadableProductLinks'
  /** @deprecated This information should not be exposed on frontend. */
  id: Maybe<Scalars['Int']['output']>
  /** @deprecated This information should not be exposed on frontend. */
  isShareable: Maybe<Scalars['Boolean']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  linkType: Maybe<DownloadableFileTypeEnum>
  /** @deprecated This information should not be exposed on frontend. */
  numberOfDownloads: Maybe<Scalars['Int']['output']>
  /** The price of the downloadable product. */
  price: Maybe<Scalars['Float']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleFile: Maybe<Scalars['String']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleType: Maybe<DownloadableFileTypeEnum>
  /** The full URL to the downloadable sample. */
  sampleUrl: Maybe<Scalars['String']['output']>
  /** A number indicating the sort order. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name of the link. */
  title: Maybe<Scalars['String']['output']>
  /** The unique ID for a `DownloadableProductLinks` object. */
  uid: Scalars['ID']['output']
}

/** Contains the link ID for the downloadable product. */
export interface DownloadableProductLinksInput {
  /** The unique ID of the downloadable product link. */
  link_id: Scalars['Int']['input']
}

/** Defines characteristics of a downloadable product. */
export interface DownloadableProductSamples {
  __typename?: 'DownloadableProductSamples'
  /** @deprecated This information should not be exposed on frontend. */
  id: Maybe<Scalars['Int']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleFile: Maybe<Scalars['String']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleType: Maybe<DownloadableFileTypeEnum>
  /** The full URL to the downloadable sample. */
  sampleUrl: Maybe<Scalars['String']['output']>
  /** A number indicating the sort order. */
  sortOrder: Maybe<Scalars['Int']['output']>
  /** The display name of the sample. */
  title: Maybe<Scalars['String']['output']>
}

/** A downloadable product wish list item. */
export interface DownloadableWishlistItem extends WishlistItemInterface {
  __typename?: 'DownloadableWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** An array containing information about the selected links. */
  linksV2: Maybe<Array<Maybe<DownloadableProductLinks>>>
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
  /** An array containing information about the selected samples. */
  samples: Maybe<Array<Maybe<DownloadableProductSamples>>>
}

export interface Downloads {
  __typename?: 'Downloads'
  /** The download link value */
  buttonText: Maybe<Scalars['String']['output']>
  /** The product filename */
  filename: Maybe<Scalars['String']['output']>
  /** The file url */
  url: Maybe<Scalars['String']['output']>
}

export interface DynamicAttribute {
  __typename?: 'DynamicAttribute'
  /** The attribute label */
  attributeLabel: Maybe<Scalars['String']['output']>
  /** The attribute value */
  attributeValue: Maybe<Scalars['String']['output']>
}

/** Defines a customer-entered option. */
export interface EnteredOptionInput {
  /** The unique ID for a `CustomizableOptionInterface` object, such as a `CustomizableFieldOption`, `CustomizableFileOption`, or `CustomizableAreaOption` object. */
  uid: Scalars['ID']['input']
  /** Text the customer entered. */
  value: Scalars['String']['input']
}

/** Contains the `uid`, `relative_url`, and `type` attributes. */
export interface EntityUrl {
  __typename?: 'EntityUrl'
  /** @deprecated Use `relative_url` instead. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface`, `CategoryInterface`, `CmsPage`, or similar object associated with the specified URL. This could be a product, category, or CMS page UID. */
  entityUid: Maybe<Scalars['ID']['output']>
  /** Craft entry type */
  entryType: Maybe<Scalars['String']['output']>
  /**
   * The ID assigned to the object associated with the specified url. This could be a product ID, category ID, or page ID.
   * @deprecated Use `entity_uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Maybe<Scalars['Int']['output']>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
}

export interface ErrorInterface {
  /** The returned error message. */
  message: Scalars['String']['output']
}

/** Contains details about an address. */
export interface EstimateAddressInput {
  /** The two-letter code representing the customer's country. */
  country_code: CountryCodeEnum
  /** The customer's ZIP or postal code. */
  postcode?: InputMaybe<Scalars['String']['input']>
  /** An object containing the region name, region code, and region ID. */
  region?: InputMaybe<CustomerAddressRegionInput>
}

export interface EstimateTotalsInput {
  /** Customer's address to estimate totals. */
  address: EstimateAddressInput
  /** The unique ID of the cart to query. */
  cart_id: Scalars['String']['input']
  /** Selected shipping method to estimate totals. */
  shipping_method?: InputMaybe<ShippingMethodInput>
}

/** Estimate totals output. */
export interface EstimateTotalsOutput {
  __typename?: 'EstimateTotalsOutput'
  /** Cart after totals estimation */
  cart: Maybe<Cart>
}

/** Lists the exchange rate. */
export interface ExchangeRate {
  __typename?: 'ExchangeRate'
  /** Specifies the store’s default currency to exchange to. */
  currencyTo: Maybe<Scalars['String']['output']>
  /** The exchange rate for the store’s default currency. */
  rate: Maybe<Scalars['Float']['output']>
}

export interface FacetInput {
  key?: InputMaybe<Scalars['String']['input']>
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface FacetSettings {
  __typename?: 'FacetSettings'
  attributeName: Scalars['ID']['output']
  collapseText: Scalars['String']['output']
  containsBuckets: Scalars['Boolean']['output']
  containsClickPoints: Scalars['Boolean']['output']
  cssClass: Maybe<Scalars['String']['output']>
  expandText: Scalars['String']['output']
  facetId: Scalars['Int']['output']
  infoText: Maybe<Scalars['String']['output']>
  isCollapsed: Scalars['Boolean']['output']
  isCollapsible: Scalars['Boolean']['output']
  isInfoVisible: Scalars['Boolean']['output']
  isMultiSelect: Scalars['Boolean']['output']
  isNrOfResultsVisible: Scalars['Boolean']['output']
  isVisible: Scalars['Boolean']['output']
  multiSelectLogic: Maybe<Scalars['TODO']['output']>
  nrOfColumns: Scalars['Int']['output']
  nrOfShownAttributes: Scalars['Int']['output']
  postfix: Maybe<Scalars['String']['output']>
  prefix: Maybe<Scalars['String']['output']>
  selectionType: Scalars['String']['output']
  source: Scalars['String']['output']
  title: Scalars['String']['output']
  urlKey: Scalars['String']['output']
}

export interface FilterAttribute {
  __typename?: 'FilterAttribute'
  attribute: Scalars['String']['output']
  value: Scalars['String']['output']
}

/** Defines a filter that matches the input exactly. */
export interface FilterEqualTypeInput {
  /** Use this attribute to exactly match the specified string. For example, to filter on a specific category ID, specify a value such as `5`. */
  eq?: InputMaybe<Scalars['String']['input']>
  /** Use this attribute to filter on an array of values. For example, to filter on category IDs 4, 5, and 6, specify a value of `["4", "5", "6"]`. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum FilterMatchTypeEnum {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}

/** Defines a filter that performs a fuzzy search. */
export interface FilterMatchTypeInput {
  /** Use this attribute to fuzzy match the specified string. For example, to filter on a specific SKU, specify a value such as `24-MB01`. */
  match?: InputMaybe<Scalars['String']['input']>
  /** Filter match type for fine-tuned results. Possible values FULL or PARTIAL. If match_type is not provided, returned results will default to FULL match. */
  match_type?: InputMaybe<FilterMatchTypeEnum>
}

/** Defines a filter that matches a range of values, such as prices or dates. */
export interface FilterRangeTypeInput {
  /** Use this attribute to specify the lowest possible value in the range. */
  from?: InputMaybe<Scalars['String']['input']>
  /** Use this attribute to specify the highest possible value in the range. */
  to?: InputMaybe<Scalars['String']['input']>
}

/** Defines a filter for an input string. */
export interface FilterStringTypeInput {
  /** Filters items that are exactly the same as the specified string. */
  eq?: InputMaybe<Scalars['String']['input']>
  /** Filters items that are exactly the same as entries specified in an array of strings. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Defines a filter that performs a fuzzy search using the specified string. */
  match?: InputMaybe<Scalars['String']['input']>
}

/** Defines the comparison operators that can be used in a filter. */
export interface FilterTypeInput {
  /** Equals. */
  eq?: InputMaybe<Scalars['String']['input']>
  finset?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** From. Must be used with the `to` field. */
  from?: InputMaybe<Scalars['String']['input']>
  /** Greater than. */
  gt?: InputMaybe<Scalars['String']['input']>
  /** Greater than or equal to. */
  gteq?: InputMaybe<Scalars['String']['input']>
  /** In. The value can contain a set of comma-separated values. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Like. The specified value can contain % (percent signs) to allow matching of 0 or more characters. */
  like?: InputMaybe<Scalars['String']['input']>
  /** Less than. */
  lt?: InputMaybe<Scalars['String']['input']>
  /** Less than or equal to. */
  lteq?: InputMaybe<Scalars['String']['input']>
  /** More than or equal to. */
  moreq?: InputMaybe<Scalars['String']['input']>
  /** Not equal to. */
  neq?: InputMaybe<Scalars['String']['input']>
  /** Not in. The value can contain a set of comma-separated values. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Not null. */
  notnull?: InputMaybe<Scalars['String']['input']>
  /** Is null. */
  null?: InputMaybe<Scalars['String']['input']>
  /** To. Must be used with the `from` field. */
  to?: InputMaybe<Scalars['String']['input']>
}

/** A single FPT that can be applied to a product price. */
export interface FixedProductTax {
  __typename?: 'FixedProductTax'
  /** The amount of the Fixed Product Tax. */
  amount: Maybe<Money>
  /** The display label assigned to the Fixed Product Tax. */
  label: Maybe<Scalars['String']['output']>
}

/** Lists display settings for the Fixed Product Tax. */
export enum FixedProductTaxDisplaySettings {
  /** The displayed price does not include the FPT amount. The values of `ProductPrice.fixed_product_taxes` and the price including the FPT are displayed separately. This value corresponds to 'Excluding FPT, Including FPT description and final price.' */
  EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS = 'EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS',
  /** The displayed price does not include the FPT amount. The values from `ProductPrice.fixed_product_taxes` are not displayed. This value corresponds to 'Excluding FPT'. */
  EXCLUDE_FPT_WITHOUT_DETAILS = 'EXCLUDE_FPT_WITHOUT_DETAILS',
  /** The FPT feature is not enabled. You can omit `ProductPrice.fixed_product_taxes` from your query. */
  FPT_DISABLED = 'FPT_DISABLED',
  /** The displayed price includes the FPT amount without displaying the `ProductPrice.fixed_product_taxes` values. This value corresponds to 'Including FPT only'. */
  INCLUDE_FPT_WITHOUT_DETAILS = 'INCLUDE_FPT_WITHOUT_DETAILS',
  /** The displayed price includes the FPT amount while displaying the values of `ProductPrice.fixed_product_taxes` separately. This value corresponds to 'Including FPT and FPT description'. */
  INCLUDE_FPT_WITH_DETAILS = 'INCLUDE_FPT_WITH_DETAILS',
}

export interface GatewayOrderResultFormField {
  __typename?: 'GatewayOrderResultFormField'
  field: Scalars['String']['output']
  value: Scalars['String']['output']
}

/** Gets the payment SDK URLs and values */
export interface GetPaymentSdkOutput {
  __typename?: 'GetPaymentSdkOutput'
  /** The payment SDK parameters */
  sdkParams: Maybe<Array<Maybe<PaymentSdkParamsItem>>>
}

/** Contains the text of a gift message, its sender, and recipient */
export interface GiftMessage {
  __typename?: 'GiftMessage'
  /** Sender name */
  from: Scalars['String']['output']
  /** Gift message text */
  message: Scalars['String']['output']
  /** Recipient name */
  to: Scalars['String']['output']
}

/** Contains the text of a gift message, its sender, and recipient */
export interface GiftMessageInput {
  /** Sender name */
  from: Scalars['String']['input']
  /** Gift message text */
  message: Scalars['String']['input']
  /** Recipient name */
  to: Scalars['String']['input']
}

export interface GoogleMapsDistanceDestination {
  __typename?: 'GoogleMapsDistanceDestination'
  destination: Maybe<Scalars['String']['output']>
  distances: Maybe<Array<Maybe<GoogleMapsTravelDistanceElements>>>
}

export interface GoogleMapsDistanceDestinationInput {
  destination: Scalars['String']['input']
  lat: Scalars['Float']['input']
  lng: Scalars['Float']['input']
}

export interface GoogleMapsDistanceDestinationsInput {
  destinations: Array<GoogleMapsDistanceDestinationInput>
  origin: GoogleMapsGeocodeLocationInput
}

export interface GoogleMapsGeocodeGeometry {
  __typename?: 'GoogleMapsGeocodeGeometry'
  location: Maybe<GoogleMapsGeocodeLocation>
}

export interface GoogleMapsGeocodeLocation {
  __typename?: 'GoogleMapsGeocodeLocation'
  lat: Maybe<Scalars['Float']['output']>
  lng: Maybe<Scalars['Float']['output']>
}

export interface GoogleMapsGeocodeLocationInput {
  lat?: InputMaybe<Scalars['Float']['input']>
  lng?: InputMaybe<Scalars['Float']['input']>
}

export interface GoogleMapsGeocodeResult {
  __typename?: 'GoogleMapsGeocodeResult'
  formattedAddress: Maybe<Scalars['String']['output']>
  geometry: Maybe<GoogleMapsGeocodeGeometry>
  placeId: Maybe<Scalars['String']['output']>
}

export interface GoogleMapsTravelDistance {
  __typename?: 'GoogleMapsTravelDistance'
  elements: Maybe<Array<Maybe<GoogleMapsTravelDistanceElements>>>
}

export interface GoogleMapsTravelDistanceDuration {
  __typename?: 'GoogleMapsTravelDistanceDuration'
  text: Maybe<Scalars['String']['output']>
  value: Maybe<Scalars['Int']['output']>
}

export interface GoogleMapsTravelDistanceElements {
  __typename?: 'GoogleMapsTravelDistanceElements'
  distance: Maybe<GoogleMapsTravelDistanceLength>
  duration: Maybe<GoogleMapsTravelDistanceDuration>
  status: Maybe<Scalars['String']['output']>
}

export interface GoogleMapsTravelDistanceLength {
  __typename?: 'GoogleMapsTravelDistanceLength'
  text: Maybe<Scalars['String']['output']>
  value: Maybe<Scalars['Int']['output']>
}

export interface GooglePayButtonStyles {
  __typename?: 'GooglePayButtonStyles'
  /** The button color */
  color: Maybe<Scalars['String']['output']>
  /** The button height in pixels */
  height: Maybe<Scalars['Int']['output']>
  /** The button type */
  type: Maybe<Scalars['String']['output']>
}

export interface GooglePayConfig extends PaymentConfigItem {
  __typename?: 'GooglePayConfig'
  /** The styles for the GooglePay Button configuration */
  buttonStyles: Maybe<GooglePayButtonStyles>
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>
  /** The payment source for the payment method */
  paymentSource: Maybe<Scalars['String']['output']>
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>
}

/** Google Pay inputs */
export interface GooglePayMethodInput {
  /** The payment source for the payment method */
  payment_source?: InputMaybe<Scalars['String']['input']>
  /** The payment services order ID */
  payments_order_id?: InputMaybe<Scalars['String']['input']>
  /** PayPal order ID */
  paypal_order_id?: InputMaybe<Scalars['String']['input']>
}

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export interface GroupedProduct
  extends PhysicalProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'GroupedProduct'
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeBalancebike: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeLgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeRideoncar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeScooter: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeSgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeTrampoline: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  addOn: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSeat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSteeringWheel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  aerowall: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ageRecommended: Maybe<Scalars['Int']['output']>
  /** Ambiance Product Images */
  ambianceGallery: Maybe<Array<Maybe<AmbianceGallery>>>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  categoryPlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ceLabel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  collaborationLicense: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** Color Variants */
  colorVariants: Maybe<Array<Maybe<ColorVariant>>>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  drive: Maybe<Scalars['Int']['output']>
  /** Filterable attribute with Text */
  dynamicAttributes: Maybe<Array<Maybe<DynamicAttribute>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  electricalSupport: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  entranceHeight: Maybe<Scalars['Int']['output']>
  /** Craft entry type */
  entryType: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  framePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  frameXlGokart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gears: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['Int']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gokartType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  handbrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  heightTrampoline: Maybe<Scalars['Int']['output']>
  /** Highlight attributes */
  highlightedAttributes: Maybe<Array<Maybe<HighlightAttribute>>>
  /** Highlight attributes PLP */
  highlightedAttributesPlp: Maybe<Array<Maybe<HighlightAttribute>>>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Scalars['Int']['output']
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  inclSafetynet: Maybe<Scalars['Int']['output']>
  /** Whether the product is a main product or not. */
  isMainProduct: Scalars['Boolean']['output']
  /** An array containing grouped product items. */
  items: Maybe<Array<Maybe<GroupedProductItem>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  jumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  lengthUser: Maybe<Scalars['Int']['output']>
  /** A number representing the product's manufacturer. */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  material: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  materialTires: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUserWeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUsers: Maybe<Scalars['Int']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgBrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgClamp: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgDeck: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgFrontFork: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGrips: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGriptape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHandlebar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHeadset: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgWheels: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  model: Maybe<Scalars['Int']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Scalars['String']['output']
  /** @deprecated Use the `custom_attributes` field instead. */
  netWeight: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  numberOfSprings: Maybe<Scalars['Int']['output']>
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  pedals: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  playbaseSet: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  possibilityFor2ndSeat: Maybe<Scalars['Int']['output']>
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** Product downloads */
  productDownloads: Maybe<ProductDownload>
  productLabels: Maybe<Array<Maybe<AmLabelList>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  pushRod: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityLine: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityStars: Maybe<Scalars['Int']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of products to be displayed in a Related Products block. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** @deprecated Use the `custom_attributes` field instead. */
  safetynetType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  seatHeight: Maybe<Scalars['Int']['output']>
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAccessories: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAirtrack: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeBeachwagon: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeClothing: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeGocart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeJumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeStep: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeWheelbarrow: Maybe<Scalars['Int']['output']>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Scalars['String']['output']
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /** Get list of spareparts per product(group), per year */
  sparepartLinks: Maybe<Array<Maybe<BergProduct>>>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  springType: Maybe<Scalars['Int']['output']>
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  testweight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  themePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  thicknessOfProtectiveEdge: Maybe<Scalars['Int']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineShape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  tuevGs: Maybe<Scalars['Int']['output']>
  /** Tweakwise ID */
  tweakwiseItemNumber: Maybe<Scalars['String']['output']>
  /** PreOrder info */
  tweakwiseTemplates: Maybe<TweakwiseTemplates>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /** @deprecated Use the `custom_attributes` field instead. */
  typeAccessory: Maybe<Scalars['Int']['output']>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Scalars['String']['output']
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  uvProtection: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warrantyWoRegistration: Maybe<Scalars['Int']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelLights: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelSize: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  widthPadding: Maybe<Scalars['Int']['output']>
}

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export interface GroupedProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export interface GroupedProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Contains information about an individual grouped product item. */
export interface GroupedProductItem {
  __typename?: 'GroupedProductItem'
  /** The relative position of this item compared to the other group items. */
  position: Maybe<Scalars['Int']['output']>
  /** Details about this product option. */
  product: Maybe<ProductInterface>
  /** The quantity of this grouped product item. */
  qty: Maybe<Scalars['Float']['output']>
}

/** A grouped product wish list item. */
export interface GroupedProductWishlistItem extends WishlistItemInterface {
  __typename?: 'GroupedProductWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

export interface HighlightAttribute {
  __typename?: 'HighlightAttribute'
  /** The attribute code */
  attributeCode: Maybe<Scalars['String']['output']>
  /** The attribute label */
  attributeLabel: Maybe<Scalars['String']['output']>
  /** The attribute sort order */
  attributePosition: Maybe<Scalars['Int']['output']>
  /** The attribute value */
  attributeValue: Maybe<Scalars['String']['output']>
}

export interface HostedFieldsConfig extends PaymentConfigItem {
  __typename?: 'HostedFieldsConfig'
  /** Vault payment method code */
  ccVaultCode: Maybe<Scalars['String']['output']>
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>
  /** Card vault enabled */
  isVaultEnabled: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>
  /** The payment source for the payment method */
  paymentSource: Maybe<Scalars['String']['output']>
  /** Card and bin details required */
  requiresCardDetails: Maybe<Scalars['Boolean']['output']>
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>
  /** 3DS mode */
  threeDs: Maybe<Scalars['Boolean']['output']>
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>
}

/** Hosted Fields payment inputs */
export interface HostedFieldsInput {
  /** Card bin number */
  cardBin?: InputMaybe<Scalars['String']['input']>
  /** Expiration month of the card */
  cardExpiryMonth?: InputMaybe<Scalars['String']['input']>
  /** Expiration year of the card */
  cardExpiryYear?: InputMaybe<Scalars['String']['input']>
  /** Last four digits of the card */
  cardLast4?: InputMaybe<Scalars['String']['input']>
  /** Name on the card */
  holderName?: InputMaybe<Scalars['String']['input']>
  /** Indicates whether details about the shopper's credit/debit card should be tokenized for later usage. Required only if Vault is enabled for the Payment Services payment integration. */
  is_active_payment_token_enabler?: InputMaybe<Scalars['Boolean']['input']>
  /** The payment source for the payment method */
  payment_source?: InputMaybe<Scalars['String']['input']>
  /** The payment services order ID */
  payments_order_id?: InputMaybe<Scalars['String']['input']>
  /** PayPal order ID */
  paypal_order_id?: InputMaybe<Scalars['String']['input']>
}

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payments Pro Hosted Solution payment method. */
export interface HostedProInput {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. For example, if the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']['input']
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. For example, if the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']['input']
}

/** Contains the secure URL used for the Payments Pro Hosted Solution payment method. */
export interface HostedProUrl {
  __typename?: 'HostedProUrl'
  /** The secure URL generated by PayPal. */
  secureFormUrl: Maybe<Scalars['String']['output']>
}

/** Contains the required input to request the secure URL for Payments Pro Hosted Solution payment. */
export interface HostedProUrlInput {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String']['input']
}

/** Contains target path parameters. */
export interface HttpQueryParameter {
  __typename?: 'HttpQueryParameter'
  /** A parameter name. */
  name: Maybe<Scalars['String']['output']>
  /** A parameter value. */
  value: Maybe<Scalars['String']['output']>
}

export interface ImageSwatchData extends SwatchDataInterface {
  __typename?: 'ImageSwatchData'
  /** The URL assigned to the thumbnail of the swatch image. */
  thumbnail: Maybe<Scalars['String']['output']>
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']['output']>
}

export interface Images {
  __typename?: 'Images'
  /** Image full path. */
  fullPath: Maybe<Scalars['String']['output']>
  /** Resized image path. */
  resizedPath: Maybe<Scalars['String']['output']>
}

/** List of templates/filters applied to customer attribute input. */
export enum InputFilterEnum {
  /** Forces attribute input to follow the date format. */
  DATE = 'DATE',
  /** Escape HTML Entities. */
  ESCAPEHTML = 'ESCAPEHTML',
  /** There are no templates or filters to be applied. */
  NONE = 'NONE',
  /** Strip HTML Tags. */
  STRIPTAGS = 'STRIPTAGS',
  /** Strip whitespace (or other characters) from the beginning and end of the input. */
  TRIM = 'TRIM',
}

/** Contains an error message when an internal error occurred. */
export interface InternalError extends ErrorInterface {
  __typename?: 'InternalError'
  /** The returned error message. */
  message: Scalars['String']['output']
}

/** Contains invoice details. */
export interface Invoice {
  __typename?: 'Invoice'
  /** Comments on the invoice. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>
  /** The unique ID for a `Invoice` object. */
  id: Scalars['ID']['output']
  /** Invoiced product details. */
  items: Maybe<Array<Maybe<InvoiceItemInterface>>>
  /** Sequential invoice number. */
  number: Scalars['String']['output']
  /** Invoice total amount details. */
  total: Maybe<InvoiceTotal>
}

export interface InvoiceItem extends InvoiceItemInterface {
  __typename?: 'InvoiceItem'
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output']
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
}

/** Contains detailes about invoiced items. */
export interface InvoiceItemInterface {
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output']
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
}

/** Contains price details from an invoice. */
export interface InvoiceTotal {
  __typename?: 'InvoiceTotal'
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Money
  /** The applied discounts to the invoice. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Money
  /** Details about the shipping and handling costs for the invoice. */
  shippingHandling: Maybe<ShippingHandling>
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Money
  /** The invoice tax details. */
  taxes: Maybe<Array<Maybe<TaxItem>>>
  /** The shipping amount for the invoice. */
  totalShipping: Money
  /** The amount of tax applied to the invoice. */
  totalTax: Money
}

/** Contains the result of the `isEmailAvailable` query. */
export interface IsEmailAvailableOutput {
  __typename?: 'IsEmailAvailableOutput'
  /** Indicates whether the specified email address can be used to create a customer. */
  isEmailAvailable: Maybe<Scalars['Boolean']['output']>
}

export interface Issuer {
  __typename?: 'Issuer'
  code: Maybe<Scalars['String']['output']>
  description: Maybe<Scalars['String']['output']>
}

/** A list of options of the selected bundle product. */
export interface ItemSelectedBundleOption {
  __typename?: 'ItemSelectedBundleOption'
  /**
   * The unique ID for a `ItemSelectedBundleOption` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID']['output']
  /** The label of the option. */
  label: Scalars['String']['output']
  /** The unique ID for a `ItemSelectedBundleOption` object. */
  uid: Scalars['ID']['output']
  /** A list of products that represent the values of the parent option. */
  values: Maybe<Array<Maybe<ItemSelectedBundleOptionValue>>>
}

/** A list of values for the selected bundle product. */
export interface ItemSelectedBundleOptionValue {
  __typename?: 'ItemSelectedBundleOptionValue'
  /**
   * The unique ID for a `ItemSelectedBundleOptionValue` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID']['output']
  /** The price of the child bundle product. */
  price: Money
  /** The name of the child bundle product. */
  productName: Scalars['String']['output']
  /** The SKU of the child bundle product. */
  productSku: Scalars['String']['output']
  /** The number of this bundle product that were ordered. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `ItemSelectedBundleOptionValue` object. */
  uid: Scalars['ID']['output']
}

/** Contains a key-value pair. */
export interface KeyValue {
  __typename?: 'KeyValue'
  /** The name part of the key/value pair. */
  name: Maybe<Scalars['String']['output']>
  /** The value part of the key/value pair. */
  value: Maybe<Scalars['String']['output']>
}

/** Contains details about a Landing page. */
export interface LandingPage extends RoutableInterface {
  __typename?: 'LandingPage'
  canonicalUrl: Maybe<Scalars['String']['output']>
  categoryId: Scalars['ID']['output']
  contentFirst: Maybe<Scalars['String']['output']>
  contentLast: Maybe<Scalars['String']['output']>
  entryType: Maybe<Scalars['String']['output']>
  filterAttributes: Maybe<Array<Maybe<FilterAttribute>>>
  heading: Maybe<Scalars['String']['output']>
  hideSelectedFilters: Scalars['Boolean']['output']
  initialFacets: Scalars['String']['output']
  isActive: Scalars['Boolean']['output']
  isFilterLinkAllowed: Scalars['Boolean']['output']
  metaDescription: Maybe<Scalars['String']['output']>
  metaKeywords: Maybe<Scalars['String']['output']>
  metaTitle: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  overviewPageId: Maybe<Scalars['ID']['output']>
  overviewPageImage: Maybe<Scalars['String']['output']>
  pageId: Scalars['ID']['output']
  redirectCode: Scalars['Int']['output']
  relativeUrl: Maybe<Scalars['String']['output']>
  storeIds: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  tweakwiseFilterTemplate: Scalars['Int']['output']
  tweakwiseSortTemplate: Scalars['Int']['output']
  type: Maybe<UrlRewriteEntityTypeEnum>
  urlPath: Scalars['String']['output']
}

export interface LandingPageUrl {
  __typename?: 'LandingPageUrl'
  currentLandingPage: Maybe<Scalars['String']['output']>
  defaultFallbackPage: Maybe<Scalars['String']['output']>
  hashKey: Scalars['String']['output']
  key: Scalars['String']['output']
  landingPageUrl: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
  value: Scalars['String']['output']
}

/** Contains information for rendering layered navigation. */
export interface LayerFilter {
  __typename?: 'LayerFilter'
  /**
   * An array of filter items.
   * @deprecated Use `Aggregation.options` instead.
   */
  filterItems: Maybe<Array<Maybe<LayerFilterItemInterface>>>
  /**
   * The count of filter items in filter group.
   * @deprecated Use `Aggregation.count` instead.
   */
  filterItemsCount: Maybe<Scalars['Int']['output']>
  /**
   * The name of a layered navigation filter.
   * @deprecated Use `Aggregation.label` instead.
   */
  name: Maybe<Scalars['String']['output']>
  /**
   * The request variable name for a filter query.
   * @deprecated Use `Aggregation.attribute_code` instead.
   */
  requestVar: Maybe<Scalars['String']['output']>
}

export interface LayerFilterItem extends LayerFilterItemInterface {
  __typename?: 'LayerFilterItem'
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount: Maybe<Scalars['Int']['output']>
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label: Maybe<Scalars['String']['output']>
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString: Maybe<Scalars['String']['output']>
}

export interface LayerFilterItemInterface {
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount: Maybe<Scalars['Int']['output']>
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label: Maybe<Scalars['String']['output']>
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString: Maybe<Scalars['String']['output']>
}

/** Defines characteristics about images and videos associated with a specific product. */
export interface MediaGalleryEntry {
  __typename?: 'MediaGalleryEntry'
  /** Details about the content of the media gallery item. */
  content: Maybe<ProductMediaGalleryEntriesContent>
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']['output']>
  /** The path of the image on the server. */
  file: Maybe<Scalars['String']['output']>
  /**
   * The identifier assigned to the object.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** The alt text displayed on the storefront when the user points to the image. */
  label: Maybe<Scalars['String']['output']>
  /** Either `image` or `video`. */
  mediaType: Maybe<Scalars['String']['output']>
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']['output']>
  /** Array of image types. It can have the following values: image, small_image, thumbnail. */
  types: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The unique ID for a `MediaGalleryEntry` object. */
  uid: Scalars['ID']['output']
  /** Details about the content of a video item. */
  videoContent: Maybe<ProductMediaGalleryEntriesVideoContent>
}

/** Contains basic information about a product image or video. */
export interface MediaGalleryInterface {
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']['output']>
  /** The label of the product image or video. */
  label: Maybe<Scalars['String']['output']>
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']['output']>
  /** The URL of the product image or video. */
  url: Maybe<Scalars['String']['output']>
}

export interface MessageStyleLogo {
  __typename?: 'MessageStyleLogo'
  /** The type of logo for the PayPal Pay Later messaging */
  type: Maybe<Scalars['String']['output']>
}

export interface MessageStyles {
  __typename?: 'MessageStyles'
  /** The message layout */
  layout: Maybe<Scalars['String']['output']>
  /** The message logo */
  logo: Maybe<MessageStyleLogo>
}

/** Defines a monetary value, including a numeric value and a currency code. */
export interface Money {
  __typename?: 'Money'
  /** A three-letter currency code, such as USD or EUR. */
  currency: CurrencyEnum
  /** A number expressing a monetary value. */
  value: Scalars['Float']['output']
}

export interface Mutation {
  __typename?: 'Mutation'
  /** Add comment */
  addAdvComment: Maybe<AddAdvCommentOutput>
  /** Add review */
  addAdvReview: Maybe<AddAdvReviewOutput>
  /** Add vote */
  addAdvVote: Maybe<AddAdvVoteOutput>
  /** Add one or more bundle products to the specified cart. We recommend using `addProductsToCart` instead. */
  addBundleProductsToCart: Maybe<AddBundleProductsToCartOutput>
  /** Add one or more configurable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addConfigurableProductsToCart: Maybe<AddConfigurableProductsToCartOutput>
  /** Add one or more downloadable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addDownloadableProductsToCart: Maybe<AddDownloadableProductsToCartOutput>
  /** Add any type of product to the cart. */
  addProductsToCart: Maybe<AddProductsToCartOutput>
  /** Add products to the specified compare list. */
  addProductsToCompareList: Maybe<CompareList>
  /** Add one or more products to the specified wish list. This mutation supports all product types. */
  addProductsToWishlist: Maybe<AddProductsToWishlistOutput>
  /** Add one or more simple products to the specified cart. We recommend using `addProductsToCart` instead. */
  addSimpleProductsToCart: Maybe<AddSimpleProductsToCartOutput>
  /** Add one or more virtual products to the specified cart. We recommend using `addProductsToCart` instead. */
  addVirtualProductsToCart: Maybe<AddVirtualProductsToCartOutput>
  /** Add items in the specified wishlist to the customer's cart. */
  addWishlistItemsToCart: Maybe<AddWishlistItemsToCartOutput>
  /** Apply a pre-defined coupon code to the specified cart. */
  applyCouponToCart: Maybe<ApplyCouponToCartOutput>
  /** Assign the specified compare list to the logged in customer. */
  assignCompareListToCustomer: Maybe<AssignCompareListToCustomerOutput>
  /** Assign a logged-in customer to the specified guest shopping cart. */
  assignCustomerToGuestCart: Cart
  /** Cancel the specified customer order. */
  cancelOrder: Maybe<CancelOrderOutput>
  /** Change the password for the logged-in customer. */
  changeCustomerPassword: Maybe<Customer>
  /** Confirms the email address for a customer. */
  confirmEmail: Maybe<CustomerOutput>
  /** Send a 'Contact Us' email to the merchant. */
  contactUs: Maybe<ContactUsOutput>
  /** Create a new compare list. The compare list is saved for logged in customers. */
  createCompareList: Maybe<CompareList>
  /** Use `createCustomerV2` instead. */
  createCustomer: Maybe<CustomerOutput>
  /** Create a billing or shipping address for a customer or guest. */
  createCustomerAddress: Maybe<CustomerAddress>
  /** Create a customer account. */
  createCustomerV2: Maybe<CustomerOutput>
  /**
   * Create an empty shopping cart for a guest or logged in user
   * @deprecated Use `Mutation.createGuestCart` or `Query.customerCart` for logged in customer
   */
  createEmptyCart: Maybe<Scalars['String']['output']>
  /** Create a new shopping cart */
  createGuestCart: Maybe<CreateGuestCartOutput>
  /** Initiate a transaction and receive a token. Use this mutation for Payflow Pro and Payments Pro payment methods */
  createPayflowProToken: Maybe<CreatePayflowProTokenOutput>
  /** Creates a payment order for further payment processing */
  createPaymentOrder: Maybe<CreatePaymentOrderOutput>
  /** Initiate an Express Checkout transaction and receive a token. Use this mutation for Express Checkout and Payments Standard payment methods. */
  createPaypalExpressToken: Maybe<PaypalExpressTokenOutput>
  /** Create Plate */
  createPlate: Maybe<Plate>
  /** Create a product review for the specified product. */
  createProductReview: CreateProductReviewOutput
  /** Delete the specified compare list. */
  deleteCompareList: Maybe<DeleteCompareListOutput>
  /** Delete customer account */
  deleteCustomer: Maybe<Scalars['Boolean']['output']>
  /** Delete the billing or shipping address of a customer. */
  deleteCustomerAddress: Maybe<Scalars['Boolean']['output']>
  /** Delete a customer's payment token. */
  deletePaymentToken: Maybe<DeletePaymentTokenOutput>
  /** Estimate shipping method(s) for cart based on address */
  estimateShippingMethods: Maybe<Array<Maybe<AvailableShippingMethod>>>
  /** Estimate totals for cart based on the address */
  estimateTotals: EstimateTotalsOutput
  /** Generate a token for specified customer. */
  generateCustomerToken: Maybe<CustomerToken>
  /** Handle a payment response and save the payment in Quote. Use this mutation for Payflow Pro and Payments Pro payment methods. */
  handlePayflowProResponse: Maybe<PayflowProResponseOutput>
  /** Transfer the contents of a guest cart into the cart of a logged-in customer. */
  mergeCarts: Cart
  ping: Maybe<Scalars['String']['output']>
  /** Convert the quote into an order. */
  placeOrder: Maybe<PlaceOrderOutput>
  placeOrderAndGetPaymentInformation: Maybe<PlaceOrderAndGetPaymentInformationOutput>
  /** Refresh Token of the authorized customer. */
  refreshCustomerToken: RefreshCustomerTokenOutput
  /** Register Product */
  registerProduct: Maybe<RegistrationData>
  /** Remove a previously-applied coupon from the cart. The cart must contain at least one item in order to remove the coupon. */
  removeCouponFromCart: Maybe<RemoveCouponFromCartOutput>
  /** Delete the entire quantity of a specified item from the cart. If you remove all items from the cart, the cart continues to exist. */
  removeItemFromCart: Maybe<RemoveItemFromCartOutput>
  /** Remove products from the specified compare list. */
  removeProductsFromCompareList: Maybe<CompareList>
  /** Remove one or more products from the specified wish list. */
  removeProductsFromWishlist: Maybe<RemoveProductsFromWishlistOutput>
  /** Add all products from a customer's previous order to the cart. */
  reorderItems: Maybe<ReorderItemsOutput>
  /** Request an email with a reset password token for the registered customer identified by the specified email. */
  requestPasswordResetEmail: Maybe<Scalars['Boolean']['output']>
  /** Reset a customer's password using the reset password token that the customer received in an email after requesting it using `requestPasswordResetEmail`. */
  resetPassword: Maybe<Scalars['Boolean']['output']>
  /** Restore Cart */
  restoreCart: Maybe<Scalars['String']['output']>
  /** Revoke the customer token. */
  revokeCustomerToken: Maybe<RevokeCustomerTokenOutput>
  /** Send a message on behalf of a customer to the specified email addresses. */
  sendEmailToFriend: Maybe<SendEmailToFriendOutput>
  /** Set the billing address on a specific cart. */
  setBillingAddressOnCart: Maybe<SetBillingAddressOnCartOutput>
  /** Assign the email address of a guest to the cart. */
  setGuestEmailOnCart: Maybe<SetGuestEmailOnCartOutput>
  /**
   * Set the cart payment method and convert the cart into an order.
   * @deprecated Should use setPaymentMethodOnCart and placeOrder mutations in single request.
   */
  setPaymentMethodAndPlaceOrder: Maybe<PlaceOrderOutput>
  /** Apply a payment method to the cart. */
  setPaymentMethodOnCart: Maybe<SetPaymentMethodOnCartOutput>
  /** Set one or more shipping addresses on a specific cart. */
  setShippingAddressesOnCart: Maybe<SetShippingAddressesOnCartOutput>
  /** Set one or more delivery methods on a cart. */
  setShippingMethodsOnCart: Maybe<SetShippingMethodsOnCartOutput>
  /** Subscribe the specified email to the store's newsletter. */
  subscribeEmailToNewsletter: Maybe<SubscribeEmailToNewsletterOutput>
  /** Synchronizes the payment order details for further payment processing */
  syncPaymentOrder: Maybe<Scalars['Boolean']['output']>
  /** Modify items in the cart. */
  updateCartItems: Maybe<UpdateCartItemsOutput>
  /** Use `updateCustomerV2` instead. */
  updateCustomer: Maybe<CustomerOutput>
  /** Update the billing or shipping address of a customer or guest. */
  updateCustomerAddress: Maybe<CustomerAddress>
  /** Change the email address for the logged-in customer. */
  updateCustomerEmail: Maybe<CustomerOutput>
  /** Update the customer's personal information. */
  updateCustomerV2: Maybe<CustomerOutput>
  /** Update one or more products in the specified wish list. */
  updateProductsInWishlist: Maybe<UpdateProductsInWishlistOutput>
}

export interface MutationAddAdvCommentArgs {
  input: InputMaybe<AddAdvCommentInput>
}

export interface MutationAddAdvReviewArgs {
  input: InputMaybe<AddAdvReviewInput>
}

export interface MutationAddAdvVoteArgs {
  input: InputMaybe<AddAdvVoteInput>
}

export interface MutationAddBundleProductsToCartArgs {
  input: InputMaybe<AddBundleProductsToCartInput>
}

export interface MutationAddConfigurableProductsToCartArgs {
  input: InputMaybe<AddConfigurableProductsToCartInput>
}

export interface MutationAddDownloadableProductsToCartArgs {
  input: InputMaybe<AddDownloadableProductsToCartInput>
}

export interface MutationAddProductsToCartArgs {
  cartId: Scalars['String']['input']
  cartItems: Array<CartItemInput>
}

export interface MutationAddProductsToCompareListArgs {
  input: InputMaybe<AddProductsToCompareListInput>
}

export interface MutationAddProductsToWishlistArgs {
  wishlistId: Scalars['ID']['input']
  wishlistItems: Array<WishlistItemInput>
}

export interface MutationAddSimpleProductsToCartArgs {
  input: InputMaybe<AddSimpleProductsToCartInput>
}

export interface MutationAddVirtualProductsToCartArgs {
  input: InputMaybe<AddVirtualProductsToCartInput>
}

export interface MutationAddWishlistItemsToCartArgs {
  wishlistId: Scalars['ID']['input']
  wishlistItemIds: InputMaybe<Array<Scalars['ID']['input']>>
}

export interface MutationApplyCouponToCartArgs {
  input: InputMaybe<ApplyCouponToCartInput>
}

export interface MutationAssignCompareListToCustomerArgs {
  uid: Scalars['ID']['input']
}

export interface MutationAssignCustomerToGuestCartArgs {
  cart_id: Scalars['String']['input']
}

export interface MutationCancelOrderArgs {
  input: CancelOrderInput
}

export interface MutationChangeCustomerPasswordArgs {
  currentPassword: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}

export interface MutationConfirmEmailArgs {
  input: ConfirmEmailInput
}

export interface MutationContactUsArgs {
  input: ContactUsInput
}

export interface MutationCreateCompareListArgs {
  input: InputMaybe<CreateCompareListInput>
}

export interface MutationCreateCustomerArgs {
  input: CustomerInput
}

export interface MutationCreateCustomerAddressArgs {
  input: CustomerAddressInput
}

export interface MutationCreateCustomerV2Args {
  input: CustomerCreateInput
}

export interface MutationCreateEmptyCartArgs {
  input: InputMaybe<CreateEmptyCartInput>
}

export interface MutationCreateGuestCartArgs {
  input: InputMaybe<CreateGuestCartInput>
}

export interface MutationCreatePayflowProTokenArgs {
  input: PayflowProTokenInput
}

export interface MutationCreatePaymentOrderArgs {
  input: CreatePaymentOrderInput
}

export interface MutationCreatePaypalExpressTokenArgs {
  input: PaypalExpressTokenInput
}

export interface MutationCreatePlateArgs {
  plate: PlateInput
}

export interface MutationCreateProductReviewArgs {
  input: CreateProductReviewInput
}

export interface MutationDeleteCompareListArgs {
  uid: Scalars['ID']['input']
}

export interface MutationDeleteCustomerAddressArgs {
  id: Scalars['Int']['input']
}

export interface MutationDeletePaymentTokenArgs {
  public_hash: Scalars['String']['input']
}

export interface MutationEstimateShippingMethodsArgs {
  input: EstimateTotalsInput
}

export interface MutationEstimateTotalsArgs {
  input: EstimateTotalsInput
}

export interface MutationGenerateCustomerTokenArgs {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export interface MutationHandlePayflowProResponseArgs {
  input: PayflowProResponseInput
}

export interface MutationMergeCartsArgs {
  destination_cart_id: InputMaybe<Scalars['String']['input']>
  source_cart_id: Scalars['String']['input']
}

export interface MutationPlaceOrderArgs {
  input: InputMaybe<PlaceOrderInput>
}

export interface MutationPlaceOrderAndGetPaymentInformationArgs {
  input: InputMaybe<PlaceOrderAndGetPaymentInformationInput>
}

export interface MutationRefreshCustomerTokenArgs {
  input: RefreshCustomerTokenInput
}

export interface MutationRegisterProductArgs {
  registration: RegistrationInput
}

export interface MutationRemoveCouponFromCartArgs {
  input: InputMaybe<RemoveCouponFromCartInput>
}

export interface MutationRemoveItemFromCartArgs {
  input: InputMaybe<RemoveItemFromCartInput>
}

export interface MutationRemoveProductsFromCompareListArgs {
  input: InputMaybe<RemoveProductsFromCompareListInput>
}

export interface MutationRemoveProductsFromWishlistArgs {
  wishlistId: Scalars['ID']['input']
  wishlistItemsIds: Array<Scalars['ID']['input']>
}

export interface MutationReorderItemsArgs {
  orderNumber: Scalars['String']['input']
}

export interface MutationRequestPasswordResetEmailArgs {
  email: Scalars['String']['input']
}

export interface MutationResetPasswordArgs {
  email: Scalars['String']['input']
  newPassword: Scalars['String']['input']
  resetPasswordToken: Scalars['String']['input']
}

export interface MutationRestoreCartArgs {
  maskedOrderId: InputMaybe<Scalars['String']['input']>
  maskedQuoteId: InputMaybe<Scalars['String']['input']>
}

export interface MutationSendEmailToFriendArgs {
  input: InputMaybe<SendEmailToFriendInput>
}

export interface MutationSetBillingAddressOnCartArgs {
  input: InputMaybe<SetBillingAddressOnCartInput>
}

export interface MutationSetGuestEmailOnCartArgs {
  input: InputMaybe<SetGuestEmailOnCartInput>
}

export interface MutationSetPaymentMethodAndPlaceOrderArgs {
  input: InputMaybe<SetPaymentMethodAndPlaceOrderInput>
}

export interface MutationSetPaymentMethodOnCartArgs {
  input: InputMaybe<SetPaymentMethodOnCartInput>
}

export interface MutationSetShippingAddressesOnCartArgs {
  input: InputMaybe<SetShippingAddressesOnCartInput>
}

export interface MutationSetShippingMethodsOnCartArgs {
  input: InputMaybe<SetShippingMethodsOnCartInput>
}

export interface MutationSubscribeEmailToNewsletterArgs {
  email: Scalars['String']['input']
}

export interface MutationSyncPaymentOrderArgs {
  input: InputMaybe<SyncPaymentOrderInput>
}

export interface MutationUpdateCartItemsArgs {
  input: InputMaybe<UpdateCartItemsInput>
}

export interface MutationUpdateCustomerArgs {
  input: CustomerInput
}

export interface MutationUpdateCustomerAddressArgs {
  id: Scalars['Int']['input']
  input: InputMaybe<CustomerAddressInput>
}

export interface MutationUpdateCustomerEmailArgs {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export interface MutationUpdateCustomerV2Args {
  input: CustomerUpdateInput
}

export interface MutationUpdateProductsInWishlistArgs {
  wishlistId: Scalars['ID']['input']
  wishlistItems: Array<WishlistItemUpdateInput>
}

/** Contains an error message when an invalid UID was specified. */
export interface NoSuchEntityUidError extends ErrorInterface {
  __typename?: 'NoSuchEntityUidError'
  /** The returned error message. */
  message: Scalars['String']['output']
  /** The specified invalid unique ID of an object. */
  uid: Scalars['ID']['output']
}

/** Contains the order ID. */
export interface Order {
  __typename?: 'Order'
  maskedOrderId: Maybe<Scalars['String']['output']>
  /** @deprecated Use `order_number` instead. */
  orderId: Maybe<Scalars['String']['output']>
  /** The unique ID for an `Order` object. */
  orderNumber: Scalars['String']['output']
}

/** Contains detailed information about an order's billing and shipping addresses. */
export interface OrderAddress {
  __typename?: 'OrderAddress'
  /** The city or town. */
  city: Scalars['String']['output']
  /** The customer's company. */
  company: Maybe<Scalars['String']['output']>
  /** The customer's country. */
  countryCode: Maybe<CountryCodeEnum>
  /** The fax number. */
  fax: Maybe<Scalars['String']['output']>
  /** The first name of the person associated with the shipping/billing address. */
  firstname: Scalars['String']['output']
  /** The family name of the person associated with the shipping/billing address. */
  lastname: Scalars['String']['output']
  /** The middle name of the person associated with the shipping/billing address. */
  middlename: Maybe<Scalars['String']['output']>
  /** The customer's ZIP or postal code. */
  postcode: Maybe<Scalars['String']['output']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>
  /** The state or province name. */
  region: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Region` object of a pre-defined region. */
  regionId: Maybe<Scalars['ID']['output']>
  /** An array of strings that define the street number and name. */
  street: Array<Maybe<Scalars['String']['output']>>
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>
  /** The telephone number. */
  telephone: Maybe<Scalars['String']['output']>
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vatId: Maybe<Scalars['String']['output']>
}

/** Input to retrieve an order based on details. */
export interface OrderInformationInput {
  /** Order billing address email. */
  email: Scalars['String']['input']
  /** Order number. */
  number: Scalars['String']['input']
  /** Order billing address postcode. */
  postcode: Scalars['String']['input']
}

export interface OrderItem extends OrderItemInterface {
  __typename?: 'OrderItem'
  /** The total refunded amount */
  amountRefunded: Money
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output']
  /** The ProductInterface object, which contains details about the base product */
  product: Maybe<ProductInterface>
  /** Product image */
  productImage: Maybe<ProductImage>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>
  /** The total row amount */
  rowTotalIncludingTax: Money
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>
}

/** Order item details. */
export interface OrderItemInterface {
  /** The total refunded amount */
  amountRefunded: Money
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output']
  /** The ProductInterface object, which contains details about the base product */
  product: Maybe<ProductInterface>
  /** Product image */
  productImage: Maybe<ProductImage>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>
  /** The total row amount */
  rowTotalIncludingTax: Money
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>
}

/** Represents order item options like selected or entered. */
export interface OrderItemOption {
  __typename?: 'OrderItemOption'
  /** The name of the option. */
  label: Scalars['String']['output']
  /** The value of the option. */
  value: Scalars['String']['output']
}

/** Contains details about the payment method used to pay for the order. */
export interface OrderPaymentMethod {
  __typename?: 'OrderPaymentMethod'
  /** Additional data per payment method type. */
  additionalData: Maybe<Array<Maybe<KeyValue>>>
  /** The label that describes the payment method. */
  name: Scalars['String']['output']
  /** The payment method code that indicates how the order was paid for. */
  type: Scalars['String']['output']
}

/** Contains order shipment details. */
export interface OrderShipment {
  __typename?: 'OrderShipment'
  /** Comments added to the shipment. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>
  /** The unique ID for a `OrderShipment` object. */
  id: Scalars['ID']['output']
  /** An array of items included in the shipment. */
  items: Maybe<Array<Maybe<ShipmentItemInterface>>>
  /** The sequential credit shipment number. */
  number: Scalars['String']['output']
  /** An array of shipment tracking details. */
  tracking: Maybe<Array<Maybe<ShipmentTracking>>>
}

/** Input to retrieve an order based on token. */
export interface OrderTokenInput {
  /** Order token. */
  token: Scalars['String']['input']
}

/** Contains details about the sales total amounts used to calculate the final price. */
export interface OrderTotal {
  __typename?: 'OrderTotal'
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Money
  /** The applied discounts to the order. */
  discounts: Maybe<Array<Maybe<Discount>>>
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Money
  /** Details about the shipping and handling costs for the order. */
  shippingHandling: Maybe<ShippingHandling>
  /** The subtotal of the order, excluding shipping, discounts, and taxes. */
  subtotal: Money
  /** The order tax details. */
  taxes: Maybe<Array<Maybe<TaxItem>>>
  /** The shipping amount for the order. */
  totalShipping: Money
  /** The amount of tax applied to the order. */
  totalTax: Money
}

export interface ParentData {
  __typename?: 'ParentData'
  mainSlug: Maybe<Scalars['String']['output']>
  preselectOptions: Maybe<Array<Maybe<PreSelectedOption>>>
  product: Maybe<ProductInterface>
}

/** Contains required input for Payflow Express Checkout payments. */
export interface PayflowExpressInput {
  /** The unique ID of the PayPal user. */
  payer_id: Scalars['String']['input']
  /** The token returned by the createPaypalExpressToken mutation. */
  token: Scalars['String']['input']
}

/** A set of relative URLs that PayPal uses in response to various actions during the authorization process. Adobe Commerce prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods. */
export interface PayflowLinkInput {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']['input']
  /** The relative URL of the transaction error page that PayPal redirects to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String']['input']
  /** The relative URL of the order confirmation page that PayPal redirects to when the payment is successful and additional confirmation is not needed. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']['input']
}

/** Indicates the mode for payment. Applies to the Payflow Link and Payments Advanced payment methods. */
export enum PayflowLinkMode {
  LIVE = 'LIVE',
  TEST = 'TEST',
}

/** Contains information used to generate PayPal iframe for transaction. Applies to Payflow Link and Payments Advanced payment methods. */
export interface PayflowLinkToken {
  __typename?: 'PayflowLinkToken'
  /** The mode for the Payflow transaction. */
  mode: Maybe<PayflowLinkMode>
  /** The PayPal URL used for requesting a Payflow form. */
  paypalUrl: Maybe<Scalars['String']['output']>
  /** The secure token generated by PayPal. */
  secureToken: Maybe<Scalars['String']['output']>
  /** The secure token ID generated by PayPal. */
  secureTokenId: Maybe<Scalars['String']['output']>
}

/** Contains information required to fetch payment token information for the Payflow Link and Payments Advanced payment methods. */
export interface PayflowLinkTokenInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String']['input']
}

/** Contains input for the Payflow Pro and Payments Pro payment methods. */
export interface PayflowProInput {
  /** Required input for credit card related information. */
  cc_details: CreditCardDetailsInput
  /** Indicates whether details about the shopper's credit/debit card should be tokenized for later usage. Required only if Vault is enabled for the PayPal Payflow Pro payment integration. */
  is_active_payment_token_enabler?: InputMaybe<Scalars['Boolean']['input']>
}

/** Input required to complete payment. Applies to Payflow Pro and Payments Pro payment methods. */
export interface PayflowProResponseInput {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String']['input']
  /** The payload returned from PayPal. */
  paypal_payload: Scalars['String']['input']
}

export interface PayflowProResponseOutput {
  __typename?: 'PayflowProResponseOutput'
  /** The cart with the updated selected payment method. */
  cart: Cart
}

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export interface PayflowProToken {
  __typename?: 'PayflowProToken'
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  responseMessage: Scalars['String']['output']
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int']['output']
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  resultCode: Scalars['Int']['output']
  /** A secure token generated by PayPal. */
  secureToken: Scalars['String']['output']
  /** A secure token ID generated by PayPal. */
  secureTokenId: Scalars['String']['output']
}

/** Contains input required to fetch payment token information for the Payflow Pro and Payments Pro payment methods. */
export interface PayflowProTokenInput {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String']['input']
  /** A set of relative URLs that PayPal uses for callback. */
  urls: PayflowProUrlInput
}

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for the Payflow Pro and Payment Pro payment methods. */
export interface PayflowProUrlInput {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']['input']
  /** The relative URL of the transaction error page that PayPal redirects to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String']['input']
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']['input']
}

export interface PaymentCommonConfig extends PaymentConfigItem {
  __typename?: 'PaymentCommonConfig'
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>
}

/** Contains payment fields that are common to all types of payment methods. */
export interface PaymentConfigItem {
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>
}

/** Retrieves the payment configuration for a given location */
export interface PaymentConfigOutput {
  __typename?: 'PaymentConfigOutput'
  /** ApplePay payment method configuration */
  applePay: Maybe<ApplePayConfig>
  /** GooglePay payment method configuration */
  googlePay: Maybe<GooglePayConfig>
  /** Hosted fields payment method configuration */
  hostedFields: Maybe<HostedFieldsConfig>
  /** Smart Buttons payment method configuration */
  smartButtons: Maybe<SmartButtonsConfig>
}

export interface PaymentInformation {
  __typename?: 'PaymentInformation'
  gatewayRedirectFormAction: Maybe<Scalars['String']['output']>
  gatewayRedirectFormFields: Maybe<Array<Maybe<GatewayOrderResultFormField>>>
  gatewayRedirectUrl: Maybe<Scalars['String']['output']>
}

/** Defines the origin location for that payment request */
export enum PaymentLocation {
  ADMIN = 'ADMIN',
  CART = 'CART',
  CHECKOUT = 'CHECKOUT',
  MINICART = 'MINICART',
  PRODUCT_DETAIL = 'PRODUCT_DETAIL',
}

/** Defines the payment method. */
export interface PaymentMethodInput {
  additional_data?: InputMaybe<Array<InputMaybe<AdditionalDataField>>>
  /** The internal name for the payment method. */
  code: Scalars['String']['input']
  /** Required input for PayPal Hosted pro payments. */
  hosted_pro?: InputMaybe<HostedProInput>
  /** Required input for Payflow Express Checkout payments. */
  payflow_express?: InputMaybe<PayflowExpressInput>
  /** Required input for PayPal Payflow Link and Payments Advanced payments. */
  payflow_link?: InputMaybe<PayflowLinkInput>
  /** Required input for PayPal Payflow Pro and Payment Pro payments. */
  payflowpro?: InputMaybe<PayflowProInput>
  /** Required input for PayPal Payflow Pro vault payments. */
  payflowpro_cc_vault?: InputMaybe<VaultTokenInput>
  /** Required input for Apple Pay button */
  payment_services_paypal_apple_pay?: InputMaybe<ApplePayMethodInput>
  /** Required input for Google Pay button */
  payment_services_paypal_google_pay?: InputMaybe<GooglePayMethodInput>
  /** Required input for Hosted Fields */
  payment_services_paypal_hosted_fields?: InputMaybe<HostedFieldsInput>
  /** Required input for Smart buttons */
  payment_services_paypal_smart_buttons?: InputMaybe<SmartButtonMethodInput>
  /** Required input for vault */
  payment_services_paypal_vault?: InputMaybe<VaultMethodInput>
  /** Required input for Express Checkout and Payments Standard payments. */
  paypal_express?: InputMaybe<PaypalExpressInput>
  /** The purchase order number. Optional for most payment methods. */
  purchase_order_number?: InputMaybe<Scalars['String']['input']>
}

/** Contains the payment order details */
export interface PaymentOrderOutput {
  __typename?: 'PaymentOrderOutput'
  /** PayPal order ID */
  id: Maybe<Scalars['String']['output']>
  /** The order ID generated by Payment Services */
  mpOrderId: Maybe<Scalars['String']['output']>
  /** Details about the card used on the order */
  paymentSourceDetails: Maybe<PaymentSourceDetails>
  /** The status of the payment order */
  status: Maybe<Scalars['String']['output']>
}

export interface PaymentSdkParamsItem {
  __typename?: 'PaymentSdkParamsItem'
  /** The payment method code used in the order */
  code: Maybe<Scalars['String']['output']>
  /** The payment SDK parameters */
  params: Maybe<Array<Maybe<SdkParams>>>
}

export interface PaymentSourceDetails {
  __typename?: 'PaymentSourceDetails'
  /** Details about the card used on the order */
  card: Maybe<Card>
}

/** The stored payment method available to the customer. */
export interface PaymentToken {
  __typename?: 'PaymentToken'
  /** A description of the stored account details. */
  details: Maybe<Scalars['String']['output']>
  /** The payment method code associated with the token. */
  paymentMethodCode: Scalars['String']['output']
  /** The public hash of the token. */
  publicHash: Scalars['String']['output']
  /** Specifies the payment token type. */
  type: PaymentTokenTypeEnum
}

/** The list of available payment token types. */
export enum PaymentTokenTypeEnum {
  /** phpcs:ignore Magento2.GraphQL.ValidArgumentName */
  ACCOUNT = 'account',
  /** phpcs:ignore Magento2.GraphQL.ValidArgumentName */
  CARD = 'card',
}

/** Contains required input for Express Checkout and Payments Standard payments. */
export interface PaypalExpressInput {
  /** The unique ID of the PayPal user. */
  payer_id: Scalars['String']['input']
  /** The token returned by the `createPaypalExpressToken` mutation. */
  token: Scalars['String']['input']
}

/** Deprecated. Use `PaypalExpressTokenOutput` instead. */
export interface PaypalExpressToken {
  __typename?: 'PaypalExpressToken'
  /**
   * A set of URLs that allow the buyer to authorize payment and adjust checkout details.
   * @deprecated Use `PaypalExpressTokenOutput.paypal_urls` instead.
   */
  paypalUrls: Maybe<PaypalExpressUrlList>
  /**
   * The token returned by PayPal.
   * @deprecated Use `PaypalExpressTokenOutput.token` instead.
   */
  token: Maybe<Scalars['String']['output']>
}

/** Defines the attributes required to receive a payment token for Express Checkout and Payments Standard payment methods. */
export interface PaypalExpressTokenInput {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String']['input']
  /** The payment method code. */
  code: Scalars['String']['input']
  /** Indicates whether the buyer selected the quick checkout button. The default value is false. */
  express_button?: InputMaybe<Scalars['Boolean']['input']>
  /** A set of relative URLs that PayPal uses in response to various actions during the authorization process. */
  urls: PaypalExpressUrlsInput
  /** Indicates whether the buyer clicked the PayPal credit button. The default value is false. */
  use_paypal_credit?: InputMaybe<Scalars['Boolean']['input']>
}

/** Contains the token returned by PayPal and a set of URLs that allow the buyer to authorize payment and adjust checkout details. Applies to Express Checkout and Payments Standard payment methods. */
export interface PaypalExpressTokenOutput {
  __typename?: 'PaypalExpressTokenOutput'
  /** A set of URLs that allow the buyer to authorize payment and adjust checkout details. */
  paypalUrls: Maybe<PaypalExpressUrlList>
  /** The token returned by PayPal. */
  token: Maybe<Scalars['String']['output']>
}

/** Contains a set of URLs that allow the buyer to authorize payment and adjust checkout details for Express Checkout and Payments Standard transactions. */
export interface PaypalExpressUrlList {
  __typename?: 'PaypalExpressUrlList'
  /** The PayPal URL that allows the buyer to edit their checkout details. */
  edit: Maybe<Scalars['String']['output']>
  /** The URL to the PayPal login page. */
  start: Maybe<Scalars['String']['output']>
}

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Express Checkout and Payments Standard payment methods. */
export interface PaypalExpressUrlsInput {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']['input']
  /** The relative URL of the page that PayPal redirects to when the payment has been put on hold for additional review. This condition mostly applies to ACH transactions, and is not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success_pending.html, the relative URL is paypal/action/success_pending.html. */
  pending_url?: InputMaybe<Scalars['String']['input']>
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']['input']
  /** The relative URL of the order confirmation page that PayPal redirects to when the payment is successful and additional confirmation is not needed. Not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success.html, the relative URL is paypal/action/success.html. */
  success_url?: InputMaybe<Scalars['String']['input']>
}

/** Contains attributes specific to tangible products. */
export interface PhysicalProductInterface {
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>
}

export interface PlaceOrderAndGetPaymentInformationInput {
  cart_id: Scalars['String']['input']
}

export interface PlaceOrderAndGetPaymentInformationOutput {
  __typename?: 'PlaceOrderAndGetPaymentInformationOutput'
  order: Order
  paymentInformation: PaymentInformation
}

/** An error encountered while placing an order. */
export interface PlaceOrderError {
  __typename?: 'PlaceOrderError'
  /** An error code that is specific to place order. */
  code: PlaceOrderErrorCodes
  /** A localized error message. */
  message: Scalars['String']['output']
}

export enum PlaceOrderErrorCodes {
  CART_NOT_ACTIVE = 'CART_NOT_ACTIVE',
  CART_NOT_FOUND = 'CART_NOT_FOUND',
  GUEST_EMAIL_MISSING = 'GUEST_EMAIL_MISSING',
  UNABLE_TO_PLACE_ORDER = 'UNABLE_TO_PLACE_ORDER',
  UNDEFINED = 'UNDEFINED',
}

/** Specifies the quote to be converted to an order. */
export interface PlaceOrderInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
}

/** Contains the results of the request to place an order. */
export interface PlaceOrderOutput {
  __typename?: 'PlaceOrderOutput'
  /** An array of place order errors. */
  errors: Array<Maybe<PlaceOrderError>>
  /**
   * The ID of the order.
   * @deprecated Use `orderV2` instead.
   */
  order: Maybe<Order>
  /** Full order information. */
  orderV2: Maybe<CustomerOrder>
}

/** Plate */
export interface Plate {
  __typename?: 'Plate'
  /** Address line 1 */
  address1: Maybe<Scalars['String']['output']>
  /** Address line 2 */
  address2: Maybe<Scalars['String']['output']>
  /** Address line 3 */
  address3: Maybe<Scalars['String']['output']>
  /** City */
  city: Maybe<Scalars['String']['output']>
  /** Country */
  country: Maybe<Scalars['String']['output']>
  /** Timestamp indicating when the plate was created. */
  createdAt: Maybe<Scalars['String']['output']>
  /** Email Address */
  email: Maybe<Scalars['String']['output']>
  /** Is email sent */
  emailSent: Maybe<Scalars['Boolean']['output']>
  /** Customer name */
  name: Maybe<Scalars['String']['output']>
  /** The ID number assigned to the plate */
  plateId: Scalars['ID']['output']
  /** The plate text */
  plateText: Maybe<Scalars['String']['output']>
  /** The plate type */
  plateType: Maybe<PlateType>
  /** Postal Code */
  postalCode: Maybe<Scalars['String']['output']>
  /** Region */
  region: Maybe<Scalars['String']['output']>
  /** Unique Code */
  uniqueCode: Maybe<Scalars['String']['output']>
  /** Timestamp indicating when the plate was updated. */
  updatedAt: Maybe<Scalars['String']['output']>
}

/** Plate */
export interface PlateInput {
  /** Address line 1 */
  address_1: Scalars['String']['input']
  /** Address line 2 */
  address_2?: InputMaybe<Scalars['String']['input']>
  /** Address line 3 */
  address_3?: InputMaybe<Scalars['String']['input']>
  /** City */
  city?: InputMaybe<Scalars['String']['input']>
  /** Country */
  country: Scalars['String']['input']
  /** Email Address */
  email: Scalars['String']['input']
  /** Customer firstname */
  firstname: Scalars['String']['input']
  /** Customer lastname */
  lastname: Scalars['String']['input']
  /** The plate text */
  plate_text: Scalars['String']['input']
  /** The plate type */
  plate_type: Scalars['String']['input']
  /** Postal Code */
  postal_code: Scalars['String']['input']
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>
  /** Unique Code */
  unique_code: Scalars['String']['input']
}

/** The different plate types */
export enum PlateType {
  AUSTRIA = 'AUSTRIA',
  BELGIE = 'BELGIE',
  FRANCE = 'FRANCE',
  GB = 'GB',
  GERMAN = 'GERMAN',
  IRLAND = 'IRLAND',
  NL = 'NL',
  NONE = 'NONE',
  WHITE = 'WHITE',
  YELLOW = 'YELLOW',
}

/** Reponse for postcode lookup */
export interface PostcodeNlAddress {
  __typename?: 'PostcodeNLAddress'
  /** Type of this address. */
  addressType: Scalars['String']['output']
  /** Dutch term used in BAG: "adresseerbaar object id". Unique identification for objects which have 'building', 'house boat site', or 'mobile home site' as addressType. */
  bagAddressableObjectId: Maybe<Scalars['String']['output']>
  /** Dutch term used in BAG: "nummeraanduiding id". */
  bagNumberDesignationId: Maybe<Scalars['String']['output']>
  /**
   * Official city name in accordance with "BAG (Basisregistraties adressen en
   * gebouwen)". In capital and lowercase letters, including punctuation marks
   * and accents. This field is at most 80 characters in length.
   */
  city: Scalars['String']['output']
  /**
   * City name, shortened to fit a lower maximum length. In capital and lowercase
   * letters, including punctuation marks and accents. This field is at most 24
   * characters in length.
   */
  cityShort: Scalars['String']['output']
  /**
   * House number of a perceel. In case of a Postbus match the house number will
   * always be 0. Range: 0-99999
   */
  houseNumber: Scalars['Int']['output']
  /**
   * Addition of the house number to uniquely define a location. These additions
   * are officially recognized by the municipality. This field is at most
   * 6 characters in length and null if addition not found (see
   * houseNumberAdditions result field).
   */
  houseNumberAddition: Maybe<Scalars['String']['output']>
  /**
   * List of all house number additions having the postcode and houseNumber
   * which was input.
   */
  houseNumberAdditions: Array<Maybe<Scalars['String']['output']>>
  /** Latitude of address. Null for PO Boxes. */
  latitude: Maybe<Scalars['Float']['output']>
  /** Longitude of address. Null for PO Boxes. */
  longitude: Maybe<Scalars['Float']['output']>
  /**
   * Municipality name in accordance with "BAG (Basisregistraties adressen en
   * gebouwen)". In capital and lowercase letters, including punctuation marks
   * and accents. This field is at most 80 characters in length. Examples:
   * "Baarle-Nassau", "'s-Gravenhage", "Haarlemmerliede en Spaarnwoude".
   */
  municipality: Scalars['String']['output']
  /**
   * Municipality name, shortened to fit a lower maximum length. In capital and
   * lowercase letters, including punctuation marks and accents. This field is at
   * most 24 characters in length. Examples: "Baarle-Nassau", "'s-Gravenhage",
   * "Haarlemmerliede c.a.".
   */
  municipalityShort: Scalars['String']['output']
  /**
   * Four digit neighborhood code (first part of a postcode). Range: 1000-9999
   * plus two character letter combination (second part of a postcode).
   * Range: "AA"-"ZZ"
   */
  postcode: Scalars['String']['output']
  /** Official name of the province, correctly cased and with dashes where applicable. */
  province: Scalars['String']['output']
  /**
   * List of all purposes (Dutch: "gebruiksdoelen"). Null or an array of
   * text values.
   */
  purposes: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /**
   * X coordinate according to Dutch Rijksdriehoeksmeting "(EPSG)
   * 28992 Amersfoort / RD New". Values range from 0 to 300000 meters.
   * Null for PO Boxes.
   */
  rdX: Maybe<Scalars['Int']['output']>
  /**
   * Y coordinate according to Dutch Rijksdriehoeksmeting "(EPSG)
   * 28992 Amersfoort / RD New". Values range from 300000 to 620000 meters.
   * Null for PO Boxes.
   */
  rdY: Maybe<Scalars['Int']['output']>
  /**
   * Street name in accordance with "BAG (Basisregistraties adressen en
   * gebouwen)". In capital and lowercase letters, including punctuation marks
   * and accents. This field is at most 80 characters in length. Filled with
   * "Postbus" in case it is a range of PO boxes.
   */
  street: Scalars['String']['output']
  /**
   * Street name in NEN-5825 notation, which has a lower maximum length. In
   * capital and lowercase letters, including punctuation marks and accents.
   * This field is at most 24 characters in length. Filled with "Postbus" in case
   * it is a range of PO boxes.
   */
  streetNen: Scalars['String']['output']
  /** Surface in square meters. Null for PO Boxes. */
  surfaceArea: Maybe<Scalars['Float']['output']>
}

export interface PreOrder {
  __typename?: 'PreOrder'
  /** Indicates the expected availability date */
  atpDate: Maybe<Scalars['String']['output']>
  /** Shows locale formatted availability date */
  atpDateFormatted: Maybe<Scalars['String']['output']>
  /** Indicates whether this product is pre-orderable */
  isPreorder: Scalars['Boolean']['output']
}

export interface PreSelectedOption {
  __typename?: 'PreSelectedOption'
  attributeCode: Scalars['String']['output']
  label: Scalars['String']['output']
  uid: Scalars['ID']['output']
  value: Scalars['String']['output']
}

/** Deprecated. Use `ProductPrice` instead. Defines the price of a product as well as any tax-related adjustments. */
export interface Price {
  __typename?: 'Price'
  /**
   * An array that provides information about tax, weee, or weee_tax adjustments.
   * @deprecated Use `ProductPrice` instead.
   */
  adjustments: Maybe<Array<Maybe<PriceAdjustment>>>
  /**
   * The price of a product plus a three-letter currency code.
   * @deprecated Use `ProductPrice` instead.
   */
  amount: Maybe<Money>
}

/** Deprecated. Taxes will be included or excluded in the price. Defines the amount of money to apply as an adjustment, the type of adjustment to apply, and whether the item is included or excluded from the adjustment. */
export interface PriceAdjustment {
  __typename?: 'PriceAdjustment'
  /** The amount of the price adjustment and its currency code. */
  amount: Maybe<Money>
  /**
   * Indicates whether the adjustment involves tax, weee, or weee_tax.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  code: Maybe<PriceAdjustmentCodesEnum>
  /**
   * Indicates whether the entity described by the code attribute is included or excluded from the adjustment.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  description: Maybe<PriceAdjustmentDescriptionEnum>
}

/** `PriceAdjustment.code` is deprecated. */
export enum PriceAdjustmentCodesEnum {
  /** @deprecated `PriceAdjustmentCodesEnum` is deprecated. Tax is included or excluded in the price. Tax is not shown separately in Catalog. */
  TAX = 'TAX',
  /** @deprecated WEEE code is deprecated. Use `fixed_product_taxes.label` instead. */
  WEEE = 'WEEE',
  /** @deprecated Use `fixed_product_taxes` instead.  Tax is included or excluded in price. The tax is not shown separtely in Catalog. */
  WEEE_TAX = 'WEEE_TAX',
}

/** `PriceAdjustmentDescriptionEnum` is deprecated. States whether a price adjustment is included or excluded. */
export enum PriceAdjustmentDescriptionEnum {
  EXCLUDED = 'EXCLUDED',
  INCLUDED = 'INCLUDED',
}

/** Can be used to retrieve the main price details in case of bundle product */
export interface PriceDetails {
  __typename?: 'PriceDetails'
  /** The percentage of discount applied to the main product price */
  discountPercentage: Maybe<Scalars['Float']['output']>
  /** The final price after applying the discount to the main product */
  mainFinalPrice: Maybe<Scalars['Float']['output']>
  /** The regular price of the main product */
  mainPrice: Maybe<Scalars['Float']['output']>
}

/** Contains the price range for a product. If the product has a single price, the minimum and maximum price will be the same. */
export interface PriceRange {
  __typename?: 'PriceRange'
  /** The highest possible price for the product. */
  maximumPrice: Maybe<ProductPrice>
  /** The lowest possible price for the product. */
  minimumPrice: ProductPrice
}

/** Defines the price type. */
export enum PriceTypeEnum {
  DYNAMIC = 'DYNAMIC',
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
}

/** Defines whether a bundle product's price is displayed as the lowest possible value or as a range. */
export enum PriceViewEnum {
  AS_LOW_AS = 'AS_LOW_AS',
  PRICE_RANGE = 'PRICE_RANGE',
}

/** Contains a product attribute code and value. */
export interface ProductAttribute {
  __typename?: 'ProductAttribute'
  /** The unique identifier for a product attribute code. */
  code: Scalars['String']['output']
  /** The display value of the attribute. */
  value: Scalars['String']['output']
}

/** Defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export interface ProductAttributeFilterInput {
  /** Attribute label: Accessory type SGK */
  accessory_type_SGK?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: accessory_type_balancebike */
  accessory_type_balancebike?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: accessory_type_LGK */
  accessory_type_lgk?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: accessory type ride on car */
  accessory_type_rideoncar?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Accessory type scooter */
  accessory_type_scooter?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Accessory type trampoline */
  accessory_type_trampoline?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Module */
  add_on?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Age Recommended */
  age_recommended?: InputMaybe<FilterEqualTypeInput>
  /** Deprecated: use `category_uid` to filter product by category ID. */
  category_id?: InputMaybe<FilterEqualTypeInput>
  /** Filter product by the unique ID for a `CategoryInterface` object. */
  category_uid?: InputMaybe<FilterEqualTypeInput>
  /** Filter product by category URL path. */
  category_url_path?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Collaboration/license */
  collaboration_license?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Color */
  color?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: electrical_support */
  electrical_support?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: entrance_height */
  entrance_height?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: frame_playbase */
  frame_playbase?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Frame XL gokart */
  frame_xl_gokart?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Gears */
  gears?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Gender */
  gender?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Gokart Type */
  gokart_type?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Handbrake */
  handbrake?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Incl. Safetynet */
  incl_safetynet?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Jump_mat */
  jump_mat?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Length user */
  length_user?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Max. length user */
  max_length_user?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Max. user weight */
  max_user_weight?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Max. users */
  max_users?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Brake */
  mg_brake?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Clamp */
  mg_clamp?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Deck */
  mg_deck?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Front fork */
  mg_front_fork?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Grips */
  mg_grips?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Griptape */
  mg_griptape?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Handlebar */
  mg_handlebar?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Headset */
  mg_headset?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Wheels */
  mg_wheels?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Min. length user */
  min_length_user?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Model */
  model?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Product Name */
  name?: InputMaybe<FilterMatchTypeInput>
  /** Attribute label: pedals */
  pedals?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: possibility_for_2nd_seat */
  possibility_for_2nd_seat?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Price */
  price?: InputMaybe<FilterRangeTypeInput>
  /** Attribute label: push_rod */
  push_rod?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Quality line */
  quality_line?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Safety net */
  safetynet_type?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Short Description */
  short_description?: InputMaybe<FilterMatchTypeInput>
  /** Attribute label: Size */
  size_airtrack?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: size_beachwagon */
  size_beachwagon?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: size_clothing */
  size_clothing?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Size Jump mat */
  size_jump_mat?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: SKU */
  sku?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Special Price */
  special_price?: InputMaybe<FilterRangeTypeInput>
  /** Attribute label: Spring type */
  spring_type?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: testweight */
  testweight?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Thema PlayBase */
  theme_playbase?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Trampoline shape */
  trampoline_shape?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Trampoline type */
  trampoline_type?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Type_accessoire */
  type_accessory?: InputMaybe<FilterEqualTypeInput>
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: UV protection */
  uv_protection?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: wheel_lights */
  wheel_lights?: InputMaybe<FilterEqualTypeInput>
}

/** Specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. It's possible to sort products using searchable attributes with enabled 'Use in Filter Options' option */
export interface ProductAttributeSortInput {
  /** Attribute label: Product Name */
  name?: InputMaybe<SortEnum>
  /** Sort by the position assigned to each product. */
  position?: InputMaybe<SortEnum>
  /** Attribute label: Price */
  price?: InputMaybe<SortEnum>
  /** Sort by the search relevance score (default). */
  relevance?: InputMaybe<SortEnum>
}

/** Product custom attributes */
export interface ProductCustomAttributes {
  __typename?: 'ProductCustomAttributes'
  /** Errors when retrieving custom attributes metadata. */
  errors: Array<Maybe<AttributeMetadataError>>
  /** Requested custom attributes */
  items: Array<Maybe<AttributeValueInterface>>
}

/** Contains the discount applied to a product price. */
export interface ProductDiscount {
  __typename?: 'ProductDiscount'
  /** The actual value of the discount. */
  amountOff: Maybe<Scalars['Float']['output']>
  /** The discount expressed a percentage. */
  percentOff: Maybe<Scalars['Float']['output']>
}

export interface ProductDownload {
  __typename?: 'ProductDownload'
  /** The connected files */
  downloads: Maybe<Array<Maybe<Downloads>>>
  /** The group title */
  title: Maybe<Scalars['String']['output']>
}

/** ProductFilterInput is deprecated, use @ProductAttributeFilterInput instead. ProductFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export interface ProductFilterInput {
  /** The category ID the product belongs to. */
  category_id?: InputMaybe<FilterTypeInput>
  /** The product's country of origin. */
  country_of_manufacture?: InputMaybe<FilterTypeInput>
  /** The timestamp indicating when the product was created. */
  created_at?: InputMaybe<FilterTypeInput>
  /** The name of a custom layout. */
  custom_layout?: InputMaybe<FilterTypeInput>
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: InputMaybe<FilterTypeInput>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: InputMaybe<FilterTypeInput>
  /** Indicates whether a gift message is available. */
  gift_message_available?: InputMaybe<FilterTypeInput>
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: InputMaybe<FilterTypeInput>
  /** The relative path to the main image on the product page. */
  image?: InputMaybe<FilterTypeInput>
  /** The label assigned to a product image. */
  image_label?: InputMaybe<FilterTypeInput>
  /** A number representing the product's manufacturer. */
  manufacturer?: InputMaybe<FilterTypeInput>
  /** The numeric maximal price of the product. Do not include the currency code. */
  max_price?: InputMaybe<FilterTypeInput>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: InputMaybe<FilterTypeInput>
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: InputMaybe<FilterTypeInput>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: InputMaybe<FilterTypeInput>
  /** The numeric minimal price of the product. Do not include the currency code. */
  min_price?: InputMaybe<FilterTypeInput>
  /** The product name. Customers use this name to identify the product. */
  name?: InputMaybe<FilterTypeInput>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: InputMaybe<FilterTypeInput>
  /** The end date for new product listings. */
  news_to_date?: InputMaybe<FilterTypeInput>
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: InputMaybe<FilterTypeInput>
  /** The keyword required to perform a logical OR comparison. */
  or?: InputMaybe<ProductFilterInput>
  /** The price of an item. */
  price?: InputMaybe<FilterTypeInput>
  /** Indicates whether the product has required options. */
  required_options?: InputMaybe<FilterTypeInput>
  /** A short description of the product. Its use depends on the theme. */
  short_description?: InputMaybe<FilterTypeInput>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<FilterTypeInput>
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: InputMaybe<FilterTypeInput>
  /** The label assigned to a product's small image. */
  small_image_label?: InputMaybe<FilterTypeInput>
  /** The beginning date that a product has a special price. */
  special_from_date?: InputMaybe<FilterTypeInput>
  /** The discounted price of the product. Do not include the currency code. */
  special_price?: InputMaybe<FilterTypeInput>
  /** The end date that a product has a special price. */
  special_to_date?: InputMaybe<FilterTypeInput>
  /** The file name of a swatch image. */
  swatch_image?: InputMaybe<FilterTypeInput>
  /** The relative path to the product's thumbnail image. */
  thumbnail?: InputMaybe<FilterTypeInput>
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: InputMaybe<FilterTypeInput>
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: InputMaybe<FilterTypeInput>
  /** The timestamp indicating when the product was updated. */
  updated_at?: InputMaybe<FilterTypeInput>
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<FilterTypeInput>
  url_path?: InputMaybe<FilterTypeInput>
  /** The weight of the item, in units defined by the store. */
  weight?: InputMaybe<FilterTypeInput>
}

/** Contains product image information, including the image URL and label. */
export interface ProductImage extends MediaGalleryInterface {
  __typename?: 'ProductImage'
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']['output']>
  /** The label of the product image or video. */
  label: Maybe<Scalars['String']['output']>
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']['output']>
  /** The URL of the product image or video. */
  url: Maybe<Scalars['String']['output']>
}

/** Contains fields that are common to all types of products. */
export interface ProductInterface {
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeBalancebike: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeLgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeRideoncar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeScooter: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeSgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeTrampoline: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  addOn: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSeat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSteeringWheel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  aerowall: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ageRecommended: Maybe<Scalars['Int']['output']>
  /** Ambiance Product Images */
  ambianceGallery: Maybe<Array<Maybe<AmbianceGallery>>>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  categoryPlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ceLabel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  collaborationLicense: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** Color Variants */
  colorVariants: Maybe<Array<Maybe<ColorVariant>>>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  drive: Maybe<Scalars['Int']['output']>
  /** Filterable attribute with Text */
  dynamicAttributes: Maybe<Array<Maybe<DynamicAttribute>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  electricalSupport: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  entranceHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  framePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  frameXlGokart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gears: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['Int']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gokartType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  handbrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  heightTrampoline: Maybe<Scalars['Int']['output']>
  /** Highlight attributes */
  highlightedAttributes: Maybe<Array<Maybe<HighlightAttribute>>>
  /** Highlight attributes PLP */
  highlightedAttributesPlp: Maybe<Array<Maybe<HighlightAttribute>>>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Scalars['Int']['output']
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  inclSafetynet: Maybe<Scalars['Int']['output']>
  /** Whether the product is a main product or not. */
  isMainProduct: Scalars['Boolean']['output']
  /** @deprecated Use the `custom_attributes` field instead. */
  jumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  lengthUser: Maybe<Scalars['Int']['output']>
  /** A number representing the product's manufacturer. */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  material: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  materialTires: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUserWeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUsers: Maybe<Scalars['Int']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgBrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgClamp: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgDeck: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgFrontFork: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGrips: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGriptape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHandlebar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHeadset: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgWheels: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  model: Maybe<Scalars['Int']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Scalars['String']['output']
  /** @deprecated Use the `custom_attributes` field instead. */
  netWeight: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  numberOfSprings: Maybe<Scalars['Int']['output']>
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  pedals: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  playbaseSet: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  possibilityFor2ndSeat: Maybe<Scalars['Int']['output']>
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** Product downloads */
  productDownloads: Maybe<ProductDownload>
  productLabels: Maybe<Array<Maybe<AmLabelList>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  pushRod: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityLine: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityStars: Maybe<Scalars['Int']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** An array of products to be displayed in a Related Products block. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** @deprecated Use the `custom_attributes` field instead. */
  safetynetType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  seatHeight: Maybe<Scalars['Int']['output']>
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAccessories: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAirtrack: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeBeachwagon: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeClothing: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeGocart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeJumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeStep: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeWheelbarrow: Maybe<Scalars['Int']['output']>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Scalars['String']['output']
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /** Get list of spareparts per product(group), per year */
  sparepartLinks: Maybe<Array<Maybe<BergProduct>>>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  springType: Maybe<Scalars['Int']['output']>
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  testweight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  themePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  thicknessOfProtectiveEdge: Maybe<Scalars['Int']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineShape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  tuevGs: Maybe<Scalars['Int']['output']>
  /** Tweakwise ID */
  tweakwiseItemNumber: Maybe<Scalars['String']['output']>
  /** PreOrder info */
  tweakwiseTemplates: Maybe<TweakwiseTemplates>
  /** @deprecated Use the `custom_attributes` field instead. */
  typeAccessory: Maybe<Scalars['Int']['output']>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Scalars['String']['output']
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  uvProtection: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warrantyWoRegistration: Maybe<Scalars['Int']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelLights: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelSize: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  widthPadding: Maybe<Scalars['Int']['output']>
}

/** Contains fields that are common to all types of products. */
export interface ProductInterfaceCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Contains fields that are common to all types of products. */
export interface ProductInterfaceReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** An implementation of `ProductLinksInterface`. */
export interface ProductLinks extends ProductLinksInterface {
  __typename?: 'ProductLinks'
  /** One of related, associated, upsell, or crosssell. */
  linkType: Maybe<Scalars['String']['output']>
  /** The SKU of the linked product. */
  linkedProductSku: Maybe<Scalars['String']['output']>
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linkedProductType: Maybe<Scalars['String']['output']>
  /** The position within the list of product links. */
  position: Maybe<Scalars['Int']['output']>
  /** The identifier of the linked product. */
  sku: Maybe<Scalars['String']['output']>
}

/** Contains information about linked products, including the link type and product type of each item. */
export interface ProductLinksInterface {
  /** One of related, associated, upsell, or crosssell. */
  linkType: Maybe<Scalars['String']['output']>
  /** The SKU of the linked product. */
  linkedProductSku: Maybe<Scalars['String']['output']>
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linkedProductType: Maybe<Scalars['String']['output']>
  /** The position within the list of product links. */
  position: Maybe<Scalars['Int']['output']>
  /** The identifier of the linked product. */
  sku: Maybe<Scalars['String']['output']>
}

/** Contains an image in base64 format and basic information about the image. */
export interface ProductMediaGalleryEntriesContent {
  __typename?: 'ProductMediaGalleryEntriesContent'
  /** The image in base64 format. */
  base64EncodedData: Maybe<Scalars['String']['output']>
  /** The file name of the image. */
  name: Maybe<Scalars['String']['output']>
  /** The MIME type of the file, such as image/png. */
  type: Maybe<Scalars['String']['output']>
}

/** Contains a link to a video file and basic information about the video. */
export interface ProductMediaGalleryEntriesVideoContent {
  __typename?: 'ProductMediaGalleryEntriesVideoContent'
  /** Must be external-video. */
  mediaType: Maybe<Scalars['String']['output']>
  /** A description of the video. */
  videoDescription: Maybe<Scalars['String']['output']>
  /** Optional data about the video. */
  videoMetadata: Maybe<Scalars['String']['output']>
  /** Describes the video source. */
  videoProvider: Maybe<Scalars['String']['output']>
  /** The title of the video. */
  videoTitle: Maybe<Scalars['String']['output']>
  /** The URL to the video. */
  videoUrl: Maybe<Scalars['String']['output']>
}

/** Represents a product price. */
export interface ProductPrice {
  __typename?: 'ProductPrice'
  /** The price discount. Represents the difference between the regular and final price. */
  discount: Maybe<ProductDiscount>
  /** The final price of the product after applying discounts. */
  finalPrice: Money
  /** An array of the multiple Fixed Product Taxes that can be applied to a product price. */
  fixedProductTaxes: Maybe<Array<Maybe<FixedProductTax>>>
  /** The regular price of the product. */
  regularPrice: Money
}

/** Deprecated. Use `PriceRange` instead. Contains the regular price of an item, as well as its minimum and maximum prices. Only composite products, which include bundle, configurable, and grouped products, can contain a minimum and maximum price. */
export interface ProductPrices {
  __typename?: 'ProductPrices'
  /**
   * The highest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the `to` value.
   * @deprecated Use `PriceRange.maximum_price` instead.
   */
  maximalPrice: Maybe<Price>
  /**
   * The lowest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the `from` value.
   * @deprecated Use `PriceRange.minimum_price` instead.
   */
  minimalPrice: Maybe<Price>
  /**
   * The base price of a product.
   * @deprecated Use `regular_price` from `PriceRange.minimum_price` or `PriceRange.maximum_price` instead.
   */
  regularPrice: Maybe<Price>
}

/** The current selected attribute with option data */
export interface ProductRegistrationOption {
  __typename?: 'ProductRegistrationOption'
  /** The id of the attribute */
  attributeCode: Maybe<Scalars['String']['output']>
  /** The label of the attribute */
  attributeLabel: Maybe<Scalars['String']['output']>
  /** An array of selectable options */
  options: Maybe<Array<Maybe<ProductRegistrationOptionValue>>>
}

/** The current selected option */
export interface ProductRegistrationOptionInput {
  /** The attribute */
  attribute_code?: InputMaybe<Scalars['String']['input']>
  /** The selected option value */
  selected_option?: InputMaybe<Scalars['ID']['input']>
}

/** A pair of label and value */
export interface ProductRegistrationOptionValue {
  __typename?: 'ProductRegistrationOptionValue'
  /** The label of the option */
  label: Maybe<Scalars['String']['output']>
  /** Whether the option is selected or not */
  selected: Maybe<Scalars['Boolean']['output']>
  /** The value of the option */
  value: Maybe<Scalars['ID']['output']>
}

/** Contains metadata corresponding to the selected options. */
export interface ProductRegistrationOptionsSelection {
  __typename?: 'ProductRegistrationOptionsSelection'
  /** Contains the current selected options. */
  currentSelection: Maybe<Array<Maybe<ProductRegistrationOption>>>
  /** The options available for further selection based on the current selection. */
  optionsAvailableForSelection: ProductRegistrationOption
  /** Products matching the current filters */
  products: Maybe<Array<Maybe<ProductInterface>>>
}

/** Contains details of a product review. */
export interface ProductReview {
  __typename?: 'ProductReview'
  /** The average of all ratings for this product. */
  averageRating: Scalars['Float']['output']
  /** The date the review was created. */
  createdAt: Scalars['String']['output']
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String']['output']
  /** The reviewed product. */
  product: ProductInterface
  /** An array of ratings by rating category, such as quality, price, and value. */
  ratingsBreakdown: Array<Maybe<ProductReviewRating>>
  /** The summary (title) of the review. */
  summary: Scalars['String']['output']
  /** The review text. */
  text: Scalars['String']['output']
}

/** Contains data about a single aspect of a product review. */
export interface ProductReviewRating {
  __typename?: 'ProductReviewRating'
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String']['output']
  /** The rating value given by customer. By default, possible values range from 1 to 5. */
  value: Scalars['String']['output']
}

/** Contains the reviewer's rating for a single aspect of a review. */
export interface ProductReviewRatingInput {
  /** An encoded rating ID. */
  id: Scalars['String']['input']
  /** Rating value id. */
  option_id: Scalars['String']['input']
  /** Rating id. */
  rating_id: Scalars['String']['input']
  /** An encoded rating value ID. */
  value_id: Scalars['String']['input']
}

/** Contains details about a single aspect of a product review. */
export interface ProductReviewRatingMetadata {
  __typename?: 'ProductReviewRatingMetadata'
  /** An encoded rating ID. */
  id: Scalars['String']['output']
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String']['output']
  /** List of product review ratings sorted by position. */
  values: Array<Maybe<ProductReviewRatingValueMetadata>>
}

/** Contains details about a single value in a product review. */
export interface ProductReviewRatingValueMetadata {
  __typename?: 'ProductReviewRatingValueMetadata'
  /** A ratings scale, such as the number of stars awarded. */
  value: Scalars['String']['output']
  /** An encoded rating value ID. */
  valueId: Scalars['String']['output']
}

/** Contains an array of metadata about each aspect of a product review. */
export interface ProductReviewRatingsMetadata {
  __typename?: 'ProductReviewRatingsMetadata'
  /** An array of product reviews sorted by position. */
  items: Array<Maybe<ProductReviewRatingMetadata>>
}

/** Contains an array of product reviews. */
export interface ProductReviews {
  __typename?: 'ProductReviews'
  /** An array of product reviews. */
  items: Array<Maybe<ProductReview>>
  /** Metadata for pagination rendering. */
  pageInfo: SearchResultPageInfo
}

/** Deprecated. Use `ProductAttributeSortInput` instead. Specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export interface ProductSortInput {
  /** The product's country of origin. */
  country_of_manufacture?: InputMaybe<SortEnum>
  /** The timestamp indicating when the product was created. */
  created_at?: InputMaybe<SortEnum>
  /** The name of a custom layout. */
  custom_layout?: InputMaybe<SortEnum>
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: InputMaybe<SortEnum>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: InputMaybe<SortEnum>
  /** Indicates whether a gift message is available. */
  gift_message_available?: InputMaybe<SortEnum>
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: InputMaybe<SortEnum>
  /** The relative path to the main image on the product page. */
  image?: InputMaybe<SortEnum>
  /** The label assigned to a product image. */
  image_label?: InputMaybe<SortEnum>
  /** A number representing the product's manufacturer. */
  manufacturer?: InputMaybe<SortEnum>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: InputMaybe<SortEnum>
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: InputMaybe<SortEnum>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: InputMaybe<SortEnum>
  /** The product name. Customers use this name to identify the product. */
  name?: InputMaybe<SortEnum>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: InputMaybe<SortEnum>
  /** The end date for new product listings. */
  news_to_date?: InputMaybe<SortEnum>
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: InputMaybe<SortEnum>
  /** The price of the item. */
  price?: InputMaybe<SortEnum>
  /** Indicates whether the product has required options. */
  required_options?: InputMaybe<SortEnum>
  /** A short description of the product. Its use depends on the theme. */
  short_description?: InputMaybe<SortEnum>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<SortEnum>
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: InputMaybe<SortEnum>
  /** The label assigned to a product's small image. */
  small_image_label?: InputMaybe<SortEnum>
  /** The beginning date that a product has a special price. */
  special_from_date?: InputMaybe<SortEnum>
  /** The discounted price of the product. */
  special_price?: InputMaybe<SortEnum>
  /** The end date that a product has a special price. */
  special_to_date?: InputMaybe<SortEnum>
  /** Indicates the criteria to sort swatches. */
  swatch_image?: InputMaybe<SortEnum>
  /** The relative path to the product's thumbnail image. */
  thumbnail?: InputMaybe<SortEnum>
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: InputMaybe<SortEnum>
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: InputMaybe<SortEnum>
  /** The timestamp indicating when the product was updated. */
  updated_at?: InputMaybe<SortEnum>
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<SortEnum>
  url_path?: InputMaybe<SortEnum>
  /** The weight of the item, in units defined by the store. */
  weight?: InputMaybe<SortEnum>
}

/** This enumeration states whether a product stock status is in stock or out of stock */
export enum ProductStockStatus {
  IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
}

/** Deprecated. Use `TierPrice` instead. Defines a tier price, which is a quantity discount offered to a specific customer group. */
export interface ProductTierPrices {
  __typename?: 'ProductTierPrices'
  /**
   * The ID of the customer group.
   * @deprecated Not relevant for the storefront.
   */
  customerGroupId: Maybe<Scalars['String']['output']>
  /**
   * The percentage discount of the item.
   * @deprecated Use `TierPrice.discount` instead.
   */
  percentageValue: Maybe<Scalars['Float']['output']>
  /**
   * The number of items that must be purchased to qualify for tier pricing.
   * @deprecated Use `TierPrice.quantity` instead.
   */
  qty: Maybe<Scalars['Float']['output']>
  /**
   * The price of the fixed price item.
   * @deprecated Use `TierPrice.final_price` instead.
   */
  value: Maybe<Scalars['Float']['output']>
  /**
   * The ID assigned to the website.
   * @deprecated Not relevant for the storefront.
   */
  websiteId: Maybe<Scalars['Float']['output']>
}

/** Contains information about a product video. */
export interface ProductVideo extends MediaGalleryInterface {
  __typename?: 'ProductVideo'
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']['output']>
  /** The label of the product image or video. */
  label: Maybe<Scalars['String']['output']>
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']['output']>
  /** The URL of the product image or video. */
  url: Maybe<Scalars['String']['output']>
  /** Contains a `ProductMediaGalleryEntriesVideoContent` object. */
  videoContent: Maybe<ProductMediaGalleryEntriesVideoContent>
}

/** Contains the results of a `products` query. */
export interface Products {
  __typename?: 'Products'
  /** A bucket that contains the attribute code and label for each filterable option. */
  aggregations: Maybe<Array<Maybe<Aggregation>>>
  /**
   * Layered navigation filters array.
   * @deprecated Use `aggregations` instead.
   */
  filters: Maybe<Array<Maybe<LayerFilter>>>
  /** An array of products that match the specified search criteria. */
  items: Maybe<Array<Maybe<ProductInterface>>>
  /** An object that includes the page_info and currentPage values specified in the query. */
  pageInfo: Maybe<SearchResultPageInfo>
  /** An object that includes the default sort field and all available sort fields. */
  sortFields: Maybe<SortFields>
  /** An array of search suggestions for case when search query have no results. */
  suggestions: Maybe<Array<Maybe<SearchSuggestion>>>
  /** The number of products that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  totalCount: Maybe<Scalars['Int']['output']>
}

/** Contains the results of a `products` query. */
export interface ProductsAggregationsArgs {
  filter: InputMaybe<AggregationsFilterInput>
}

export interface Query {
  __typename?: 'Query'
  /** Entries within the “Account” section. */
  accountEntries: Maybe<Array<Maybe<CraftAccountSectionEntryUnion>>>
  /**
   * Get an address based on its unique combination of postcode, house number and
   * house number addition.
   *
   * Coordinate and surfaceArea values are null values if no location is known,
   * i.e. if the address is a PO Box.
   *
   * You must always check the houseNumberAddition result field, as a successful
   * response is also returned if only the input addition isn't found.
   */
  address: Maybe<PostcodeNlAddress>
  advreview: Maybe<ReviewsData>
  amLabelProvider: Maybe<Array<Maybe<AmLabelList>>>
  amLabelSetting: Maybe<AmLabelSetting>
  amReviewSetting: Maybe<AmReviewSetting>
  amReviewWidget: Maybe<AmReviewWidget>
  /** This query is used to query for a single asset. */
  asset: Maybe<CraftAssetInterface>
  /** This query is used to return the number of assets. */
  assetCount: Scalars['Int']['output']
  /** This query is used to query for assets. */
  assets: Maybe<Array<Maybe<CraftAssetInterface>>>
  /** Retrieve EAV attributes associated to a frontend form. Use countries query provided by DirectoryGraphQl module to retrieve region_id and country_id attribute options. */
  attributesForm: AttributesFormOutput
  /** Returns a list of attributes metadata for a given entity type. */
  attributesList: Maybe<AttributesMetadataOutput>
  /**
   * Get a list of autocomplete matches based on a single (partial) address term. Depending on the
   * input different types of matches may be returned, such as streets, postal codes or a specific address.
   *
   * This method is intended for interactive address entry where a user enters (part) of an address
   * and matching suggestions are shown. Depending on the matches a user may keep typing, or may
   * select a match after which additional input can still be entered.
   *
   * The matching algorithm takes into account possible typos as well as missing or irrelevant words.
   */
  autocompleteAddress: Maybe<AutocompleteAddressResult>
  autocompleteAddressDetails: Maybe<AutocompleteAddressDetailsResult>
  /** The Payment method interface */
  availablePaymentMethods: Maybe<AvailablePaymentMethods>
  /** Get a list of available store views and their config information. */
  availableStores: Maybe<Array<Maybe<StoreConfig>>>
  /** Entries within the “Blog” section. */
  blogEntries: Maybe<Array<Maybe<CraftBlogSectionEntryUnion>>>
  /** Entries within the “Blog overview” section. */
  blogOverviewEntries: Maybe<Array<Maybe<CraftBlogOverviewSectionEntryUnion>>>
  /** Return information about the specified shopping cart. */
  cart: Maybe<Cart>
  /** Return a list of categories that match the specified filter. */
  categories: Maybe<CategoryResult>
  /**
   * Search for categories that match the criteria specified in the `search` and `filter` attributes.
   * @deprecated Use `categories` instead.
   */
  category: Maybe<CategoryTree>
  /** Retrieve a category by UID */
  categoryByUid: Maybe<CategoryInterface>
  /** Entries within the “Category” section. */
  categoryEntries: Maybe<Array<Maybe<CraftCategorySectionEntryUnion>>>
  /** Entries within the “Category filter explanations” section. */
  categoryFilterExplanationsEntries: Maybe<
    Array<Maybe<CraftCategoryFilterExplanationsSectionEntryUnion>>
  >
  /**
   * Return an array of categories based on the specified filters.
   * @deprecated Use `categories` instead.
   */
  categoryList: Maybe<Array<Maybe<CategoryTree>>>
  /** Entries within the “Category page content” section. */
  categoryPageContentEntries: Maybe<
    Array<Maybe<CraftCategoryPageContentSectionEntryUnion>>
  >
  /** Return Terms and Conditions configuration information. */
  checkoutAgreements: Maybe<Array<Maybe<CheckoutAgreement>>>
  /** Return information about CMS blocks. */
  cmsBlocks: Maybe<CmsBlocks>
  /** Return details about a CMS page. */
  cmsPage: Maybe<CmsPage>
  /** Return products that have been added to the specified compare list. */
  compareList: Maybe<CompareList>
  /** Entries within the “Contact” section. */
  contactEntries: Maybe<Array<Maybe<CraftContactSectionEntryUnion>>>
  /** The countries query provides information for all countries. */
  countries: Maybe<Array<Maybe<Country>>>
  /** The countries query provides information for a single country. */
  country: Maybe<Country>
  /** Return information about the store's currency. */
  currency: Maybe<Currency>
  /**
   * Return the attribute type, given an attribute code and entity type.
   * @deprecated Use `customAttributeMetadataV2` query instead.
   */
  customAttributeMetadata: Maybe<CustomAttributeMetadata>
  /** Retrieve EAV attributes metadata. */
  customAttributeMetadataV2: AttributesMetadataOutput
  /** Return detailed information about a customer account. */
  customer: Maybe<Customer>
  /** Return information about the customer's shopping cart. */
  customerCart: Cart
  /** Return a list of downloadable products the customer has purchased. */
  customerDownloadableProducts: Maybe<CustomerDownloadableProducts>
  /** @deprecated Use the `customer` query instead. */
  customerOrders: Maybe<CustomerOrders>
  /** Return a list of customer payment tokens stored in the vault. */
  customerPaymentTokens: Maybe<CustomerPaymentTokens>
  /** Entries within the “Customer service” section. */
  customerServiceEntries: Maybe<
    Array<Maybe<CraftCustomerServiceSectionEntryUnion>>
  >
  /** Entries within the “Customer service overview” section. */
  customerServiceOverviewEntries: Maybe<
    Array<Maybe<CraftCustomerServiceOverviewSectionEntryUnion>>
  >
  /** This query is used to query for entries. */
  entries: Maybe<Array<Maybe<CraftEntryInterface>>>
  /** This query is used to query for a single entry. */
  entry: Maybe<CraftEntryInterface>
  /** This query is used to return the number of entries. */
  entryCount: Scalars['Int']['output']
  /** Entries within the “Filter explanation lists” section. */
  filterExplanationListsEntries: Maybe<
    Array<Maybe<CraftFilterExplanationListsSectionEntryUnion>>
  >
  /** Entries within the “Forgot password” section. */
  forgotPasswordEntries: Maybe<
    Array<Maybe<CraftForgotPasswordSectionEntryUnion>>
  >
  /** Entries within the “Generic block links” section. */
  genericBlockLinksEntries: Maybe<
    Array<Maybe<CraftGenericBlockLinksSectionEntryUnion>>
  >
  /** Get Code info */
  getCode: Maybe<UniqueCode>
  /** Retrieve the secure PayPal URL for a Payments Pro Hosted Solution transaction. */
  getHostedProUrl: Maybe<HostedProUrl>
  /** Get active banklist */
  getMultisafepayIssuers: Maybe<Array<Maybe<Issuer>>>
  /** Retrieve payment credentials for a transaction. Use this query for Payflow Link and Payments Advanced payment methods. */
  getPayflowLinkToken: Maybe<PayflowLinkToken>
  /** Retrieves the payment configuration for a given location */
  getPaymentConfig: Maybe<PaymentConfigOutput>
  /** Retrieves the payment details for the order */
  getPaymentOrder: Maybe<PaymentOrderOutput>
  /** Gets the payment SDK urls and values */
  getPaymentSdk: Maybe<GetPaymentSdkOutput>
  /** An array of options to render for selection of product */
  getProductRegistrationOptions: Maybe<ProductRegistrationOptionsSelection>
  /** Get product registration */
  getRegistration: Maybe<RegistrationData>
  /** This query is used to query for a single global set. */
  globalSet: Maybe<CraftGlobalSetInterface>
  /** This query is used to query for global sets. */
  globalSets: Maybe<Array<Maybe<CraftGlobalSetInterface>>>
  googleMapsGeocode: Maybe<Array<Maybe<GoogleMapsGeocodeResult>>>
  googleMapsReverseGeocode: Maybe<Array<Maybe<GoogleMapsGeocodeResult>>>
  googleTravelDistance: Maybe<Array<Maybe<GoogleMapsTravelDistance>>>
  googleTravelDistances: Maybe<Array<Maybe<GoogleMapsDistanceDestination>>>
  /** Retrieve guest order details based on number, email and postcode. */
  guestOrder: CustomerOrder
  /** Retrieve guest order details based on token. */
  guestOrderByToken: CustomerOrder
  /** Entries within the “Homepage” section. */
  homepageEntries: Maybe<Array<Maybe<CraftHomepageSectionEntryUnion>>>
  /** Entries within the “Information balance bikes” section. */
  informationBalanceBikesEntries: Maybe<
    Array<Maybe<CraftInformationBalanceBikesSectionEntryUnion>>
  >
  /** Entries within the “Information go carts” section. */
  informationGoCartsEntries: Maybe<
    Array<Maybe<CraftInformationGoCartsSectionEntryUnion>>
  >
  /** Entries within the “Information item subjects” section. */
  informationItemSubjectsEntries: Maybe<
    Array<Maybe<CraftInformationItemSubjectsSectionEntryUnion>>
  >
  /** Entries within the “Information overview” section. */
  informationOverviewEntries: Maybe<
    Array<Maybe<CraftInformationOverviewSectionEntryUnion>>
  >
  /** Entries within the “Information playground equipment” section. */
  informationPlaygroundEquipmentEntries: Maybe<
    Array<Maybe<CraftInformationPlaygroundEquipmentSectionEntryUnion>>
  >
  /** Entries within the “Information ride-on cars” section. */
  informationRideOnCarsEntries: Maybe<
    Array<Maybe<CraftInformationRideOnCarsSectionEntryUnion>>
  >
  /** Entries within the “Information scooters” section. */
  informationScootersEntries: Maybe<
    Array<Maybe<CraftInformationScootersSectionEntryUnion>>
  >
  /** Entries within the “Information subject balance bikes” section. */
  informationSubjectBalanceBikesEntries: Maybe<
    Array<Maybe<CraftInformationSubjectBalanceBikesSectionEntryUnion>>
  >
  /** Entries within the “Information subject go carts” section. */
  informationSubjectGoCartsEntries: Maybe<
    Array<Maybe<CraftInformationSubjectGoCartsSectionEntryUnion>>
  >
  /** Entries within the “Information subject playground equipment” section. */
  informationSubjectPlaygroundEquipmentEntries: Maybe<
    Array<Maybe<CraftInformationSubjectPlaygroundEquipmentSectionEntryUnion>>
  >
  /** Entries within the “Information subject ride-on cars” section. */
  informationSubjectRideOnCarsEntries: Maybe<
    Array<Maybe<CraftInformationSubjectRideOnCarsSectionEntryUnion>>
  >
  /** Entries within the “Information subject scooters” section. */
  informationSubjectScootersEntries: Maybe<
    Array<Maybe<CraftInformationSubjectScootersSectionEntryUnion>>
  >
  /** Entries within the “Information subject trampolines” section. */
  informationSubjectTrampolinesEntries: Maybe<
    Array<Maybe<CraftInformationSubjectTrampolinesSectionEntryUnion>>
  >
  /** Entries within the “Information trampolines” section. */
  informationTrampolinesEntries: Maybe<
    Array<Maybe<CraftInformationTrampolinesSectionEntryUnion>>
  >
  /** Check whether the specified email has already been used to create a customer account. */
  isEmailAvailable: Maybe<IsEmailAvailableOutput>
  /** Landing page */
  landingPage: Maybe<LandingPage>
  /** The landingpage URL */
  landingPageUrls: Maybe<Array<Maybe<LandingPageUrl>>>
  /** Entries within the “Login” section. */
  loginEntries: Maybe<Array<Maybe<CraftLoginSectionEntryUnion>>>
  /** Entries within the “Menu items” section. */
  menuItemsEntries: Maybe<Array<Maybe<CraftMenuItemsSectionEntryUnion>>>
  order: Maybe<CustomerOrder>
  /** Entries within the “Page” section. */
  pageEntries: Maybe<Array<Maybe<CraftPageSectionEntryUnion>>>
  ping: Maybe<Scalars['String']['output']>
  /** Get Plate By Code */
  plateByCode: Maybe<Plate>
  /** Query MyGoKart Plates. */
  plates: Maybe<Array<Maybe<Plate>>>
  /** @deprecated id is deprecated, use productByUid */
  product: Maybe<ProductInterface>
  /** Retrieve a product by UID */
  productByUid: Maybe<ProductInterface>
  /** Entries within the “Product page content” section. */
  productPageContentEntries: Maybe<
    Array<Maybe<CraftProductPageContentSectionEntryUnion>>
  >
  /** Entries within the “Product page FAQ items” section. */
  productPageFaqItemsEntries: Maybe<
    Array<Maybe<CraftProductPageFaqItemsSectionEntryUnion>>
  >
  /** Entries within the “Product page generic items” section. */
  productPageGenericItemsEntries: Maybe<
    Array<Maybe<CraftProductPageGenericItemsSectionEntryUnion>>
  >
  /** Entries within the “Product registration” section. */
  productRegistrationEntries: Maybe<
    Array<Maybe<CraftProductRegistrationSectionEntryUnion>>
  >
  /** Return the active ratings attributes and the values each rating can have. */
  productReviewRatingsMetadata: ProductReviewRatingsMetadata
  /** Entries within the “Product types” section. */
  productTypesEntries: Maybe<Array<Maybe<CraftProductTypesSectionEntryUnion>>>
  /** Search for products that match the criteria specified in the `search` and `filter` attributes. */
  products: Maybe<Products>
  /** Get List of all products by year */
  productsByGroupAndYear: Maybe<Array<Maybe<ProductInterface>>>
  /** Get list of spareparts per product(group), per year */
  productsBySkuOrName: Maybe<Array<Maybe<BergProduct>>>
  /** Entries within the “Products” section. */
  productsEntries: Maybe<Array<Maybe<CraftProductsSectionEntryUnion>>>
  /** Returns details about Google reCAPTCHA V3-Invisible configuration. */
  recaptchaV3Config: Maybe<ReCaptchaConfigurationV3>
  /** Entries within the “Request license plate” section. */
  requestLicensePlateEntries: Maybe<
    Array<Maybe<CraftRequestLicensePlateSectionEntryUnion>>
  >
  /** Entries within the “Review” section. */
  reviewEntries: Maybe<Array<Maybe<CraftReviewSectionEntryUnion>>>
  /** Return the full details for a specified product, category, or CMS page. */
  route: Maybe<RoutableInterface>
  /** Entries within the “Search” section. */
  searchEntries: Maybe<Array<Maybe<CraftSearchSectionEntryUnion>>>
  /** Get list of group names */
  sparepartGroups: Maybe<Array<Maybe<SparepartGroup>>>
  /** Get Valid list of years */
  sparepartYearList: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  /** Entries within the “Spareparts” section. */
  sparepartsEntries: Maybe<Array<Maybe<CraftSparepartsSectionEntryUnion>>>
  /** Return details about the store's configuration. */
  storeConfig: Maybe<StoreConfig>
  /** Entries within the “Store locator” section. */
  storeLocatorEntries: Maybe<Array<Maybe<CraftStoreLocatorSectionEntryUnion>>>
  storesByDistance: Maybe<Array<Maybe<CraftStoreEntry>>>
  /** Entries within the “Stores” section. */
  storesEntries: Maybe<Array<Maybe<CraftStoresSectionEntryUnion>>>
  tweakwiseAdvisor: Maybe<TweakwiseAdvisor>
  /** Service that returns product results and search suggestions based on a search term. This service can be called as soon as the user begins to type a search term. */
  tweakwiseAutocomplete: TweakwiseAutocompleteResult
  tweakwiseFunnel: Maybe<TweakwiseFunnel>
  tweakwiseNavigation: TweakwiseNavigationResult
  /**
   * Returns featured recommended products, to use for example on a homepage.
   *
   * Will return `null` when the template could not be found.
   */
  tweakwiseRecommendationsFeatured: Maybe<Array<TweakwiseNavigationItem>>
  /**
   * Returns recommended products for a product, such as for cross sell or up sell.
   *
   * Will return `null` when the template could not be found.
   */
  tweakwiseRecommendationsProduct: Maybe<Array<TweakwiseNavigationItem>>
  /**
   * Returns recommended products for a product, such as for cross sell or up sell.
   *
   * Will use a group Key from tweakwise
   */
  tweakwiseRecommendationsProductGroup: Maybe<Array<TweakwiseRecommendation>>
  /**
   * Service that returns all available templates for a product. Each template will contain all items matching the template.
   *
   * If a lot of rules have been set up, it is not efficient to request all rules for all products. With these web services, all products of a product can be retrieved in one go for all rules.
   *
   * Will return `null` when the product could not be found in the Tweakwise store.
   */
  tweakwiseRecommendationsProductTemplates: Maybe<
    Array<TweakwiseProductRecommendationsTemplate>
  >
  tweakwiseSearchBanners: Array<TweakwiseSearchBanner>
  /**
   * Return the relative URL for a specified product, category or CMS page.
   * @deprecated Use the `route` query instead.
   */
  urlResolver: Maybe<EntityUrl>
  /**
   * Return the contents of a customer's wish list.
   * @deprecated Moved under `Customer.wishlist`.
   */
  wishlist: Maybe<WishlistOutput>
}

export interface QueryAccountEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryAddressArgs {
  houseNumber: Scalars['Int']['input']
  houseNumberAddition: InputMaybe<Scalars['String']['input']>
  postcode: Scalars['String']['input']
}

export interface QueryAdvreviewArgs {
  amreviewDir: InputMaybe<Scalars['String']['input']>
  amreviewSort: InputMaybe<Scalars['String']['input']>
  isRecommended: InputMaybe<Scalars['Boolean']['input']>
  page: Scalars['Int']['input']
  productId: InputMaybe<Scalars['Int']['input']>
  productUid: Scalars['ID']['input']
  stars: InputMaybe<Scalars['Int']['input']>
  verifiedBuyer: InputMaybe<Scalars['Boolean']['input']>
  withImages: InputMaybe<Scalars['Boolean']['input']>
}

export interface QueryAmLabelProviderArgs {
  mode: InputMaybe<AmLabelMode>
  productIds: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export interface QueryAmReviewWidgetArgs {
  categoryId: InputMaybe<Scalars['Int']['input']>
  productId: InputMaybe<Scalars['Int']['input']>
  widgetId: Scalars['Int']['input']
}

export interface QueryAssetArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface QueryAssetCountArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface QueryAssetsArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateModified: InputMaybe<Scalars['String']['input']>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  folderId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasAlt: InputMaybe<Scalars['Boolean']['input']>
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uploader: InputMaybe<Scalars['CraftQueryArgument']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  volumeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface QueryAttributesFormArgs {
  formCode: Scalars['String']['input']
}

export interface QueryAttributesListArgs {
  entityType: AttributeEntityTypeEnum
  filters: InputMaybe<AttributeFilterInput>
}

export interface QueryAutocompleteAddressArgs {
  context: Scalars['String']['input']
  language: InputMaybe<AutocompleteAddressLanguages>
  session: Scalars['String']['input']
  term: Scalars['String']['input']
}

export interface QueryAutocompleteAddressDetailsArgs {
  context: Scalars['String']['input']
  dispatchCountry: InputMaybe<Scalars['String']['input']>
  session: Scalars['String']['input']
}

export interface QueryAvailablePaymentMethodsArgs {
  language?: InputMaybe<Scalars['String']['input']>
  storeId: InputMaybe<Scalars['Int']['input']>
}

export interface QueryAvailableStoresArgs {
  useCurrentGroup: InputMaybe<Scalars['Boolean']['input']>
}

export interface QueryBlogEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryBlogOverviewEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryCartArgs {
  cart_id: Scalars['String']['input']
}

export interface QueryCategoriesArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filters: InputMaybe<CategoryFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export interface QueryCategoryArgs {
  id: InputMaybe<Scalars['Int']['input']>
}

export interface QueryCategoryByUidArgs {
  uid: Scalars['ID']['input']
}

export interface QueryCategoryEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryCategoryFilterExplanationsEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryCategoryListArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filters: InputMaybe<CategoryFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export interface QueryCategoryPageContentEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryCmsBlocksArgs {
  identifiers: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface QueryCmsPageArgs {
  id: InputMaybe<Scalars['Int']['input']>
  identifier: InputMaybe<Scalars['String']['input']>
}

export interface QueryCompareListArgs {
  uid: Scalars['ID']['input']
}

export interface QueryContactEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryCountryArgs {
  id: InputMaybe<Scalars['String']['input']>
}

export interface QueryCustomAttributeMetadataArgs {
  attributes: Array<AttributeInput>
}

export interface QueryCustomAttributeMetadataV2Args {
  attributes: InputMaybe<Array<AttributeInput>>
}

export interface QueryCustomerServiceEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryCustomerServiceOverviewEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryEntryArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryEntryCountArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  field: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fieldId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ownerId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  sectionId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryFilterExplanationListsEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryForgotPasswordEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryGenericBlockLinksEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryGetCodeArgs {
  uniqueCode: InputMaybe<Scalars['String']['input']>
}

export interface QueryGetHostedProUrlArgs {
  input: HostedProUrlInput
}

export interface QueryGetPayflowLinkTokenArgs {
  input: PayflowLinkTokenInput
}

export interface QueryGetPaymentConfigArgs {
  location: PaymentLocation
}

export interface QueryGetPaymentOrderArgs {
  cartId: Scalars['String']['input']
  id: Scalars['String']['input']
}

export interface QueryGetPaymentSdkArgs {
  location: PaymentLocation
}

export interface QueryGetProductRegistrationOptionsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  selectedOptions: InputMaybe<Array<InputMaybe<ProductRegistrationOptionInput>>>
}

export interface QueryGetRegistrationArgs {
  warranty_code: Scalars['String']['input']
}

export interface QueryGlobalSetArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  handle: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaKeywords: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface QueryGlobalSetsArgs {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  handle: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaKeywords: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export interface QueryGoogleMapsGeocodeArgs {
  search: Scalars['String']['input']
}

export interface QueryGoogleMapsReverseGeocodeArgs {
  coordinates: GoogleMapsGeocodeLocationInput
}

export interface QueryGoogleTravelDistanceArgs {
  from: GoogleMapsGeocodeLocationInput
  to: GoogleMapsGeocodeLocationInput
}

export interface QueryGoogleTravelDistancesArgs {
  input: GoogleMapsDistanceDestinationsInput
}

export interface QueryGuestOrderArgs {
  input: OrderInformationInput
}

export interface QueryGuestOrderByTokenArgs {
  input: OrderTokenInput
}

export interface QueryHomepageEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationBalanceBikesEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationGoCartsEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationItemSubjectsEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationOverviewEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationPlaygroundEquipmentEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationRideOnCarsEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationScootersEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationSubjectBalanceBikesEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationSubjectGoCartsEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationSubjectPlaygroundEquipmentEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationSubjectRideOnCarsEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationSubjectScootersEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationSubjectTrampolinesEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryInformationTrampolinesEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryIsEmailAvailableArgs {
  email: Scalars['String']['input']
}

export interface QueryLandingPageArgs {
  url_path: Scalars['String']['input']
}

export interface QueryLandingPageUrlsArgs {
  current_uri: InputMaybe<Scalars['String']['input']>
  facets: Array<InputMaybe<FacetInput>>
  selected_facets: InputMaybe<Array<InputMaybe<FacetInput>>>
  tweakwise_item_number: Array<InputMaybe<Scalars['Int']['input']>>
  uid: Scalars['ID']['input']
}

export interface QueryLoginEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryMenuItemsEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryOrderArgs {
  orderMaskId: Scalars['String']['input']
}

export interface QueryPageEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryPlateByCodeArgs {
  uniqueCode: InputMaybe<Scalars['String']['input']>
}

export interface QueryPlatesArgs {
  plate_id: InputMaybe<Scalars['ID']['input']>
}

export interface QueryProductArgs {
  id: Scalars['Int']['input']
}

export interface QueryProductByUidArgs {
  uid: Scalars['ID']['input']
}

export interface QueryProductPageContentEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryProductPageFaqItemsEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryProductPageGenericItemsEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryProductRegistrationEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryProductTypesEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryProductsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter: InputMaybe<ProductAttributeFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  search: InputMaybe<Scalars['String']['input']>
  sort: InputMaybe<ProductAttributeSortInput>
}

export interface QueryProductsByGroupAndYearArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  productGroup: Scalars['Int']['input']
  year: Scalars['Int']['input']
}

export interface QueryProductsBySkuOrNameArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  search: Scalars['String']['input']
  searchtype?: InputMaybe<Scalars['String']['input']>
}

export interface QueryProductsEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryRequestLicensePlateEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryReviewEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryRouteArgs {
  url: Scalars['String']['input']
}

export interface QuerySearchEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QuerySparepartsEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryStoreLocatorEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryStoresByDistanceArgs {
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  referenceLatitude: InputMaybe<Scalars['Float']['input']>
  referenceLongitude: InputMaybe<Scalars['Float']['input']>
  site: InputMaybe<Scalars['String']['input']>
}

export interface QueryStoresEntriesArgs {
  SpecificationValue: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  address2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressLocality: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressPostalCode: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  addressStreet: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  after: InputMaybe<Scalars['String']['input']>
  ancestorDist: InputMaybe<Scalars['Int']['input']>
  ancestorOf: InputMaybe<Scalars['Int']['input']>
  anchorLinkId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  answer: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  authorGroupId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  authorId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  background: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  bannerTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  before: InputMaybe<Scalars['String']['input']>
  blockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blockTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  blogPosts: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  buttonType: InputMaybe<Scalars['Boolean']['input']>
  categories: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  categoryId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  city: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  code: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  colorTheme: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  columnTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  containerWidth: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  country: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  descendantDist: InputMaybe<Scalars['Int']['input']>
  descendantOf: InputMaybe<Scalars['Int']['input']>
  description: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  deviatingUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  disclaimer: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  editable: InputMaybe<Scalars['Boolean']['input']>
  email: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  emailCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  emailStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  explanation: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  facebook: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  faqTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  featuredCategoriesTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  filterTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>
  fontSize: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fontWeight: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  fullWidth: InputMaybe<Scalars['Boolean']['input']>
  genericBlockLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  genericfaqitems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  groupTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>
  highlightedItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  id: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  image2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  imageAlignment: InputMaybe<Scalars['Boolean']['input']>
  imageAlignmentMobile: InputMaybe<Scalars['Boolean']['input']>
  inReverse: InputMaybe<Scalars['Boolean']['input']>
  informationSubject: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  instagram: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  isVisibleOnFilter: InputMaybe<Scalars['Boolean']['input']>
  itemLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  itemTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  latitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  leaves: InputMaybe<Scalars['Boolean']['input']>
  level: InputMaybe<Scalars['Int']['input']>
  lightswitchChecked: InputMaybe<Scalars['Boolean']['input']>
  lightswitchChecked2: InputMaybe<Scalars['Boolean']['input']>
  lightswitchUnchecked: InputMaybe<Scalars['Boolean']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  linkField: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkGroupTitleLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  linkedin: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  longitude: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  menuItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaDescription: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  metaTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsItems: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsSubtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  newsTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>
  number: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  numberUnformatted: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  offset: InputMaybe<Scalars['Int']['input']>
  orderBy: InputMaybe<Scalars['String']['input']>
  organizationUrl: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumber: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLink: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberLinkStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  phoneNumberStatic: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  placeholder: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  position: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  positionedAfter: InputMaybe<Scalars['Int']['input']>
  positionedBefore: InputMaybe<Scalars['Int']['input']>
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  preferSites: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>
  primaryOwnerId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  productTypes: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  products: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  question: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  quickFilterName: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readLessText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesMobile: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreLinesTabletAndDesktop: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  readMoreMobile: InputMaybe<Scalars['Boolean']['input']>
  readMoreTabletAndDesktop: InputMaybe<Scalars['Boolean']['input']>
  readMoreText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  relatedTo: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAll: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  relatedToAssets: InputMaybe<Array<InputMaybe<CraftAssetCriteriaInput>>>
  relatedToCategories: InputMaybe<Array<InputMaybe<CraftCategoryCriteriaInput>>>
  relatedToEntries: InputMaybe<Array<InputMaybe<CraftEntryCriteriaInput>>>
  relatedToTags: InputMaybe<Array<InputMaybe<CraftTagCriteriaInput>>>
  relatedToUsers: InputMaybe<Array<InputMaybe<CraftUserCriteriaInput>>>
  richText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richText2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple3: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  richTextSimple4: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  search: InputMaybe<Scalars['String']['input']>
  seoIntroductionRichText: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  seoIntroductionTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  show: InputMaybe<Scalars['Boolean']['input']>
  showIcon: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  showLabels: InputMaybe<Scalars['Boolean']['input']>
  showLink: InputMaybe<Scalars['Boolean']['input']>
  showOnFaqOverview: InputMaybe<Scalars['Boolean']['input']>
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  siteId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  siteSettingsId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  specificationLabel: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  startOnNewLine: InputMaybe<Scalars['Boolean']['input']>
  stepTitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  storeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  structureId: InputMaybe<Scalars['Int']['input']>
  subtitle: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  subtitle2: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneCustomerService: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  telephoneHeadquarters: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  text: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  text2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  textItem: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  textStatic1: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  time1: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  time2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  title2: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  title3: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  tweakwiseTemplateId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeId: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  unique: InputMaybe<Scalars['Boolean']['input']>
  uppercase: InputMaybe<Scalars['Boolean']['input']>
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  urlKey: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  uspItem: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  variant: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  website: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  weekDays: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  withStructure: InputMaybe<Scalars['Boolean']['input']>
  youtube: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
  youtubeId: InputMaybe<
    Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>
  >
  zipcode: InputMaybe<Array<InputMaybe<Scalars['CraftQueryArgument']['input']>>>
}

export interface QueryTweakwiseAdvisorArgs {
  params: TweakwiseAdvisorParams
}

export interface QueryTweakwiseAutocompleteArgs {
  params: InputMaybe<TweakwiseAutocompleteParams>
  query: Scalars['String']['input']
}

export interface QueryTweakwiseFunnelArgs {
  params: InputMaybe<TweakwiseFunnelParams>
}

export interface QueryTweakwiseNavigationArgs {
  params: InputMaybe<TweakwiseNavigationParams>
}

export interface QueryTweakwiseRecommendationsFeaturedArgs {
  params: InputMaybe<TweakwiseRecommendationsFeaturedParams>
  templateId: Scalars['ID']['input']
}

export interface QueryTweakwiseRecommendationsProductArgs {
  itemNo: Scalars['ID']['input']
  params: InputMaybe<TweakwiseRecommendationsFeaturedParams>
  templateId: Scalars['ID']['input']
}

export interface QueryTweakwiseRecommendationsProductGroupArgs {
  groupKey: Scalars['ID']['input']
  itemNo: Scalars['ID']['input']
  params: InputMaybe<TweakwiseRecommendationsFeaturedParams>
}

export interface QueryTweakwiseRecommendationsProductTemplatesArgs {
  itemNo: Scalars['ID']['input']
  params: InputMaybe<TweakwiseRecommendationsFeaturedParams>
}

export interface QueryTweakwiseSearchBannersArgs {
  params: InputMaybe<TweakwiseSearchBannerParams>
}

export interface QueryUrlResolverArgs {
  url: Scalars['String']['input']
}

/** Specifies the field to use for sorting quote items */
export interface QuoteItemsSortInput {
  /** Specifies the quote items field to sort by */
  field: SortQuoteItemsEnum
  /** Specifies the order of quote items' sorting */
  order: SortEnum
}

export interface Rating {
  __typename?: 'Rating'
  ratingCode: Scalars['String']['output']
  ratingId: Scalars['Int']['output']
  ratingOptions: Maybe<Array<Maybe<RatingOption>>>
}

export interface RatingOption {
  __typename?: 'RatingOption'
  optionId: Scalars['Int']['output']
  /** Votes. */
  options: Maybe<Array<Maybe<RatingOptionValues>>>
  /** Option name. */
  ratingCode: Maybe<Scalars['String']['output']>
  /** Option id. */
  ratingId: Maybe<Scalars['Int']['output']>
  value: Scalars['Int']['output']
}

export interface RatingOptionValues {
  __typename?: 'RatingOptionValues'
  /** Rating option code. */
  code: Maybe<Scalars['String']['output']>
  /** Rating option id. */
  optionId: Maybe<Scalars['Int']['output']>
  /** Rating option position. */
  position: Maybe<Scalars['Int']['output']>
  /** Rating option value. */
  value: Maybe<Scalars['Int']['output']>
}

export interface RatingVote {
  __typename?: 'RatingVote'
  /** Customer id. */
  customerId: Maybe<Scalars['Int']['output']>
  /** Product id. */
  entityPkValue: Maybe<Scalars['Int']['output']>
  /** Option id. */
  optionId: Maybe<Scalars['Int']['output']>
  /** Percent. */
  percent: Maybe<Scalars['Int']['output']>
  /** Rating code. */
  ratingCode: Maybe<Scalars['String']['output']>
  /** Rating id. */
  ratingId: Maybe<Scalars['Int']['output']>
  /** Review id. */
  reviewId: Maybe<Scalars['Int']['output']>
  /** Store id. */
  storeId: Maybe<Scalars['Int']['output']>
  /** Value. */
  value: Maybe<Scalars['Int']['output']>
  /** Vote id. */
  voteId: Maybe<Scalars['Int']['output']>
}

/** Contains reCAPTCHA V3-Invisible configuration details. */
export interface ReCaptchaConfigurationV3 {
  __typename?: 'ReCaptchaConfigurationV3'
  /** The position of the invisible reCAPTCHA badge on each page. */
  badgePosition: Scalars['String']['output']
  /** The message that appears to the user if validation fails. */
  failureMessage: Scalars['String']['output']
  /** A list of forms on the storefront that have been configured to use reCAPTCHA V3. */
  forms: Array<Maybe<ReCaptchaFormEnum>>
  /** Return whether recaptcha is enabled or not */
  isEnabled: Scalars['Boolean']['output']
  /** A two-character code that specifies the language that is used for Google reCAPTCHA text and messaging. */
  languageCode: Maybe<Scalars['String']['output']>
  /** The minimum score that identifies a user interaction as a potential risk. */
  minimumScore: Scalars['Float']['output']
  /** The website key generated when the Google reCAPTCHA account was registered. */
  websiteKey: Scalars['String']['output']
}

export enum ReCaptchaFormEnum {
  BRAINTREE = 'BRAINTREE',
  CONTACT = 'CONTACT',
  CUSTOMER_CREATE = 'CUSTOMER_CREATE',
  CUSTOMER_EDIT = 'CUSTOMER_EDIT',
  CUSTOMER_FORGOT_PASSWORD = 'CUSTOMER_FORGOT_PASSWORD',
  CUSTOMER_LOGIN = 'CUSTOMER_LOGIN',
  NEWSLETTER = 'NEWSLETTER',
  PLACE_ORDER = 'PLACE_ORDER',
  PRODUCT_REVIEW = 'PRODUCT_REVIEW',
  SENDFRIEND = 'SENDFRIEND',
}

export interface RefreshCustomerTokenInput {
  /** The customer's refresh token */
  refresh_token: Scalars['String']['input']
}

export interface RefreshCustomerTokenOutput {
  __typename?: 'RefreshCustomerTokenOutput'
  accessToken: Scalars['String']['output']
  refreshToken: Scalars['String']['output']
}

export interface Region {
  __typename?: 'Region'
  /** The two-letter code for the region, such as TX for Texas. */
  code: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Region` object. */
  id: Maybe<Scalars['Int']['output']>
  /** The name of the region, such as Texas. */
  name: Maybe<Scalars['String']['output']>
}

/** The data for registration */
export interface RegistrationData {
  __typename?: 'RegistrationData'
  /** The batch number */
  batchNumber: Scalars['String']['output']
  /** The url to the certificate */
  certificateFile: Maybe<Scalars['String']['output']>
  /** Customer city */
  city: Scalars['String']['output']
  /** Customer country */
  countryId: Scalars['String']['output']
  /** The date created */
  createdAt: Scalars['String']['output']
  /** Customer email */
  email: Scalars['String']['output']
  /** The entry ID */
  entityId: Scalars['ID']['output']
  /** The expiry date */
  expiryDate: Scalars['String']['output']
  /** Customer firstname */
  firstname: Scalars['String']['output']
  /** Customer lastname */
  lastname: Scalars['String']['output']
  /** Customer middlename */
  middlename: Maybe<Scalars['String']['output']>
  /** The order number */
  orderNumber: Maybe<Scalars['String']['output']>
  /** Postcode */
  postcode: Scalars['String']['output']
  /** The product SKU */
  productSku: Scalars['String']['output']
  /** The purchase date */
  purchaseDate: Scalars['String']['output']
  /** Receipt file */
  receiptFile: Scalars['String']['output']
  /** Region */
  region: Maybe<Scalars['String']['output']>
  /** Region id */
  regionId: Maybe<Scalars['String']['output']>
  /** Customer Street */
  street1: Scalars['String']['output']
  /** Customer Street 2 (e.g. house number */
  street2: Scalars['String']['output']
  /** Customer Street 3 */
  street3: Maybe<Scalars['String']['output']>
  /** Telephone number */
  telephone: Scalars['String']['output']
  /** The generated warranty code */
  warrantyCode: Scalars['String']['output']
}

/** The data for registration */
export interface RegistrationInput {
  /** The batch number */
  batch_number: Scalars['String']['input']
  /** Customer city */
  city: Scalars['String']['input']
  /** Customer country */
  country_id: Scalars['String']['input']
  /** Customer email */
  email: Scalars['String']['input']
  /** Customer firstname */
  firstname: Scalars['String']['input']
  /** Customer lastname */
  lastname: Scalars['String']['input']
  /** Customer middlename */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** The order number */
  order_number?: InputMaybe<Scalars['String']['input']>
  /** Postcode */
  postcode: Scalars['String']['input']
  /** The product SKU */
  product_sku: Scalars['String']['input']
  /** The purchase date */
  purchase_date: Scalars['String']['input']
  /** Receipt file */
  receipt_file?: InputMaybe<Scalars['String']['input']>
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>
  /** Region id */
  region_id?: InputMaybe<Scalars['String']['input']>
  /** Customer Street */
  street_1: Scalars['String']['input']
  /** Customer Street 2 (e.g. house number */
  street_2: Scalars['String']['input']
  /** Customer Street 3 */
  street_3?: InputMaybe<Scalars['String']['input']>
  /** Telephone number */
  telephone: Scalars['String']['input']
}

/** Specifies the cart from which to remove a coupon. */
export interface RemoveCouponFromCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
}

/** Contains details about the cart after removing a coupon. */
export interface RemoveCouponFromCartOutput {
  __typename?: 'RemoveCouponFromCartOutput'
  /** The cart after removing a coupon. */
  cart: Maybe<Cart>
}

/** Specifies which items to remove from the cart. */
export interface RemoveItemFromCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: InputMaybe<Scalars['Int']['input']>
  /** Required field. The unique ID for a `CartItemInterface` object. */
  cart_item_uid?: InputMaybe<Scalars['ID']['input']>
}

/** Contains details about the cart after removing an item. */
export interface RemoveItemFromCartOutput {
  __typename?: 'RemoveItemFromCartOutput'
  /** The cart after removing an item. */
  cart: Cart
}

/** Defines which products to remove from a compare list. */
export interface RemoveProductsFromCompareListInput {
  /** An array of product IDs to remove from the compare list. */
  products: Array<InputMaybe<Scalars['ID']['input']>>
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID']['input']
}

/** Contains the customer's wish list and any errors encountered. */
export interface RemoveProductsFromWishlistOutput {
  __typename?: 'RemoveProductsFromWishlistOutput'
  /** An array of errors encountered while deleting products from a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>
  /** Contains the wish list with after items were successfully deleted. */
  wishlist: Wishlist
}

/** Contains the cart and any errors after adding products. */
export interface ReorderItemsOutput {
  __typename?: 'ReorderItemsOutput'
  /** Detailed information about the customer's cart. */
  cart: Cart
  /** An array of reordering errors. */
  userInputErrors: Array<Maybe<CheckoutUserInputError>>
}

export interface Review {
  __typename?: 'Review'
  /** Answer. */
  answer: Maybe<Scalars['String']['output']>
  /** Comments. */
  comments: Maybe<Array<Maybe<Comment>>>
  /** Created at. */
  createdAt: Maybe<Scalars['String']['output']>
  /** Reviewer id. */
  customerId: Maybe<Scalars['Int']['output']>
  /** Review detail. */
  detail: Maybe<Scalars['String']['output']>
  /** Detail id. */
  detailId: Maybe<Scalars['Int']['output']>
  /** Entity code. */
  entityCode: Maybe<Scalars['String']['output']>
  /** Entity id. */
  entityId: Maybe<Scalars['Int']['output']>
  /** Product id. */
  entityPkValue: Maybe<Scalars['Int']['output']>
  /** Guest email. */
  guestEmail: Maybe<Scalars['String']['output']>
  /** Images. */
  images: Maybe<Array<Maybe<Images>>>
  /** Reomended. */
  isRecommended: Maybe<Scalars['Boolean']['output']>
  /** Advantages. */
  likeAbout: Maybe<Scalars['String']['output']>
  /** Minus review. */
  minusReview: Maybe<Scalars['String']['output']>
  /** Reviewer name. */
  nickname: Maybe<Scalars['String']['output']>
  /** Disadvantages. */
  notLikeAbout: Maybe<Scalars['String']['output']>
  /** Plus review. */
  plusReview: Maybe<Scalars['String']['output']>
  product: ProductInterface
  /** Rating votes. */
  ratingVotes: Maybe<Array<Maybe<RatingVote>>>
  /** Review id. */
  reviewId: Maybe<Scalars['Int']['output']>
  /** Status id. */
  statusId: Maybe<Scalars['Int']['output']>
  /** Review title. */
  title: Maybe<Scalars['String']['output']>
  /** Verified buyer. */
  verifiedBuyer: Maybe<Scalars['Int']['output']>
}

export interface ReviewFilter {
  __typename?: 'ReviewFilter'
  code: Scalars['String']['output']
  label: Scalars['String']['output']
}

export interface ReviewOrder {
  __typename?: 'ReviewOrder'
  code: Scalars['String']['output']
  label: Scalars['String']['output']
}

export interface ReviewsData {
  __typename?: 'ReviewsData'
  /** Detailed summary. */
  detailedSummary: Maybe<DetailedSummary>
  /** Reviews. */
  items: Maybe<Array<Maybe<Review>>>
  /** The product UID */
  productUid: Scalars['ID']['output']
  /** Votes. */
  ratingOptions: Maybe<Array<Maybe<RatingOption>>>
  /** Rating summary. */
  ratingSummary: Maybe<Scalars['Int']['output']>
  /** Rating summary value. */
  ratingSummaryValue: Maybe<Scalars['Float']['output']>
  /** Recomended percent. */
  recomendedPercent: Maybe<Scalars['Int']['output']>
  /** Total records. */
  totalRecords: Maybe<Scalars['Int']['output']>
  /** Total records with applied filters. */
  totalRecordsFiltered: Maybe<Scalars['Int']['output']>
}

/** Contains the result of a request to revoke a customer token. */
export interface RevokeCustomerTokenOutput {
  __typename?: 'RevokeCustomerTokenOutput'
  /** The result of a request to revoke a customer token. */
  result: Scalars['Boolean']['output']
}

/** Routable entities serve as the model for a rendered page. */
export interface RoutableInterface {
  /** Craft entry type */
  entryType: Maybe<Scalars['String']['output']>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
}

/** Contains details about a comment. */
export interface SalesCommentItem {
  __typename?: 'SalesCommentItem'
  /** The text of the message. */
  message: Scalars['String']['output']
  /** The timestamp of the comment. */
  timestamp: Scalars['String']['output']
}

export interface SalesItemInterface {
  __typename?: 'SalesItemInterface'
  /** The entered gift message for the order item */
  giftMessage: Maybe<GiftMessage>
}

/** This enumeration defines the scope type for customer orders. */
export enum ScopeTypeEnum {
  GLOBAL = 'GLOBAL',
  STORE = 'STORE',
  WEBSITE = 'WEBSITE',
}

/** Defines the name and value of a SDK parameter */
export interface SdkParams {
  __typename?: 'SdkParams'
  /** The name of the SDK parameter */
  name: Maybe<Scalars['String']['output']>
  /** The value of the SDK parameter */
  value: Maybe<Scalars['String']['output']>
}

/** Provides navigation for the query response. */
export interface SearchResultPageInfo {
  __typename?: 'SearchResultPageInfo'
  /** The specific page to return. */
  currentPage: Maybe<Scalars['Int']['output']>
  /** The maximum number of items to return per page of results. */
  pageSize: Maybe<Scalars['Int']['output']>
  /** The total number of pages in the response. */
  totalPages: Maybe<Scalars['Int']['output']>
}

/** A string that contains search suggestion */
export interface SearchSuggestion {
  __typename?: 'SearchSuggestion'
  /** The search suggestion of existing product. */
  search: Scalars['String']['output']
}

/** Contains details about a selected bundle option. */
export interface SelectedBundleOption {
  __typename?: 'SelectedBundleOption'
  /** @deprecated Use `uid` instead */
  id: Scalars['Int']['output']
  /** The display name of the selected bundle product option. */
  label: Scalars['String']['output']
  /** The type of selected bundle product option. */
  type: Scalars['String']['output']
  /** The unique ID for a `SelectedBundleOption` object */
  uid: Scalars['ID']['output']
  /** An array of selected bundle option values. */
  values: Array<Maybe<SelectedBundleOptionValue>>
}

/** Contains details about a value for a selected bundle option. */
export interface SelectedBundleOptionValue {
  __typename?: 'SelectedBundleOptionValue'
  /** Use `uid` instead */
  id: Scalars['Int']['output']
  /** The display name of the value for the selected bundle product option. */
  label: Scalars['String']['output']
  /** The price of the value for the selected bundle product option. */
  price: Scalars['Float']['output']
  /** The quantity of the value for the selected bundle product option. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `SelectedBundleOptionValue` object */
  uid: Scalars['ID']['output']
}

/** Contains details about a selected configurable option. */
export interface SelectedConfigurableOption {
  __typename?: 'SelectedConfigurableOption'
  /** The unique ID for a `ConfigurableProductOptions` object. */
  configurableProductOptionUid: Scalars['ID']['output']
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  configurableProductOptionValueUid: Scalars['ID']['output']
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_uid` instead. */
  id: Scalars['Int']['output']
  /** The display text for the option. */
  optionLabel: Scalars['String']['output']
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_value_uid` instead. */
  valueId: Scalars['Int']['output']
  /** The display name of the selected configurable option. */
  valueLabel: Scalars['String']['output']
}

/** Identifies a customized product that has been placed in a cart. */
export interface SelectedCustomizableOption {
  __typename?: 'SelectedCustomizableOption'
  /** The unique ID for a specific `CustomizableOptionInterface` object, such as a `CustomizableFieldOption`, `CustomizableFileOption`, or `CustomizableAreaOption` object. */
  customizableOptionUid: Scalars['ID']['output']
  /** @deprecated Use `SelectedCustomizableOption.customizable_option_uid` instead. */
  id: Scalars['Int']['output']
  /** Indicates whether the customizable option is required. */
  isRequired: Scalars['Boolean']['output']
  /** The display name of the selected customizable option. */
  label: Scalars['String']['output']
  /** A value indicating the order to display this option. */
  sortOrder: Scalars['Int']['output']
  /** The type of `CustomizableOptionInterface` object. */
  type: Scalars['String']['output']
  /** An array of selectable values. */
  values: Array<Maybe<SelectedCustomizableOptionValue>>
}

/** Identifies the value of the selected customized option. */
export interface SelectedCustomizableOptionValue {
  __typename?: 'SelectedCustomizableOptionValue'
  /** The unique ID for a value object that corresponds to the object represented by the `customizable_option_uid` attribute. */
  customizableOptionValueUid: Scalars['ID']['output']
  /** @deprecated Use `SelectedCustomizableOptionValue.customizable_option_value_uid` instead. */
  id: Scalars['Int']['output']
  /** The display name of the selected value. */
  label: Scalars['String']['output']
  /** The price of the selected customizable value. */
  price: CartItemSelectedOptionValuePrice
  /** The text identifying the selected value. */
  value: Scalars['String']['output']
}

/** Describes the payment method the shopper selected. */
export interface SelectedPaymentMethod {
  __typename?: 'SelectedPaymentMethod'
  /** The payment method code. */
  code: Scalars['String']['output']
  /** The purchase order number. */
  purchaseOrderNumber: Maybe<Scalars['String']['output']>
  /** The payment method title. */
  title: Scalars['String']['output']
}

/** Contains details about the selected shipping method and carrier. */
export interface SelectedShippingMethod {
  __typename?: 'SelectedShippingMethod'
  /** The cost of shipping using this shipping method. */
  amount: Maybe<Money>
  /** @deprecated The field should not be used on the storefront. */
  baseAmount: Maybe<Money>
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrierCode: Maybe<Scalars['String']['output']>
  /** The label for the carrier code. */
  carrierTitle: Maybe<Scalars['String']['output']>
  /** A shipping method code associated with a carrier. */
  methodCode: Maybe<Scalars['String']['output']>
  /** The label for the method code. */
  methodTitle: Maybe<Scalars['String']['output']>
  /** The cost of shipping using this shipping method, excluding tax. */
  priceExclTax: Money
  /** The cost of shipping using this shipping method, including tax. */
  priceInclTax: Money
}

/** Defines the referenced product and the email sender and recipients. */
export interface SendEmailToFriendInput {
  /** The ID of the product that the sender is referencing. */
  product_id: Scalars['Int']['input']
  /** An array containing information about each recipient. */
  recipients: Array<InputMaybe<SendEmailToFriendRecipientInput>>
  /** Information about the customer and the content of the message. */
  sender: SendEmailToFriendSenderInput
}

/** Contains information about the sender and recipients. */
export interface SendEmailToFriendOutput {
  __typename?: 'SendEmailToFriendOutput'
  /** An array containing information about each recipient. */
  recipients: Maybe<Array<Maybe<SendEmailToFriendRecipient>>>
  /** Information about the customer and the content of the message. */
  sender: Maybe<SendEmailToFriendSender>
}

/** An output object that contains information about the recipient. */
export interface SendEmailToFriendRecipient {
  __typename?: 'SendEmailToFriendRecipient'
  /** The email address of the recipient. */
  email: Scalars['String']['output']
  /** The name of the recipient. */
  name: Scalars['String']['output']
}

/** Contains details about a recipient. */
export interface SendEmailToFriendRecipientInput {
  /** The email address of the recipient. */
  email: Scalars['String']['input']
  /** The name of the recipient. */
  name: Scalars['String']['input']
}

/** An output object that contains information about the sender. */
export interface SendEmailToFriendSender {
  __typename?: 'SendEmailToFriendSender'
  /** The email address of the sender. */
  email: Scalars['String']['output']
  /** The text of the message to be sent. */
  message: Scalars['String']['output']
  /** The name of the sender. */
  name: Scalars['String']['output']
}

/** Contains details about the sender. */
export interface SendEmailToFriendSenderInput {
  /** The email address of the sender. */
  email: Scalars['String']['input']
  /** The text of the message to be sent. */
  message: Scalars['String']['input']
  /** The name of the sender. */
  name: Scalars['String']['input']
}

/** Contains details about the configuration of the Email to a Friend feature. */
export interface SendFriendConfiguration {
  __typename?: 'SendFriendConfiguration'
  /** Indicates whether the Email to a Friend feature is enabled. */
  enabledForCustomers: Scalars['Boolean']['output']
  /** Indicates whether the Email to a Friend feature is enabled for guests. */
  enabledForGuests: Scalars['Boolean']['output']
}

/** Sets the billing address. */
export interface SetBillingAddressOnCartInput {
  /** The billing address. */
  billing_address: BillingAddressInput
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
}

/** Contains details about the cart after setting the billing address. */
export interface SetBillingAddressOnCartOutput {
  __typename?: 'SetBillingAddressOnCartOutput'
  /** The cart after setting the billing address. */
  cart: Cart
}

/** Defines the guest email and cart. */
export interface SetGuestEmailOnCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** The email address of the guest. */
  email: Scalars['String']['input']
}

/** Contains details about the cart after setting the email of a guest. */
export interface SetGuestEmailOnCartOutput {
  __typename?: 'SetGuestEmailOnCartOutput'
  /** The cart after setting the guest email. */
  cart: Cart
}

/** Applies a payment method to the quote. */
export interface SetPaymentMethodAndPlaceOrderInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** The payment method data to apply to the cart. */
  payment_method: PaymentMethodInput
}

/** Applies a payment method to the cart. */
export interface SetPaymentMethodOnCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** The payment method data to apply to the cart. */
  payment_method: PaymentMethodInput
}

/** Contains details about the cart after setting the payment method. */
export interface SetPaymentMethodOnCartOutput {
  __typename?: 'SetPaymentMethodOnCartOutput'
  /** The cart after setting the payment method. */
  cart: Cart
}

/** Specifies an array of addresses to use for shipping. */
export interface SetShippingAddressesOnCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** An array of shipping addresses. */
  shipping_addresses: Array<InputMaybe<ShippingAddressInput>>
}

/** Contains details about the cart after setting the shipping addresses. */
export interface SetShippingAddressesOnCartOutput {
  __typename?: 'SetShippingAddressesOnCartOutput'
  /** The cart after setting the shipping addresses. */
  cart: Cart
}

/** Applies one or shipping methods to the cart. */
export interface SetShippingMethodsOnCartInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** An array of shipping methods. */
  shipping_methods: Array<InputMaybe<ShippingMethodInput>>
}

/** Contains details about the cart after setting the shipping methods. */
export interface SetShippingMethodsOnCartOutput {
  __typename?: 'SetShippingMethodsOnCartOutput'
  /** The cart after setting the shipping methods. */
  cart: Cart
}

/** Defines whether bundle items must be shipped together. */
export enum ShipBundleItemsEnum {
  SEPARATELY = 'SEPARATELY',
  TOGETHER = 'TOGETHER',
}

export interface ShipmentItem extends ShipmentItemInterface {
  __typename?: 'ShipmentItem'
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of shipped items. */
  quantityShipped: Scalars['Float']['output']
}

/** Order shipment item details. */
export interface ShipmentItemInterface {
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID']['output']
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>
  /** The sale price for the base product. */
  productSalePrice: Money
  /** The SKU of the base product. */
  productSku: Scalars['String']['output']
  /** The number of shipped items. */
  quantityShipped: Scalars['Float']['output']
}

/** Contains order shipment tracking details. */
export interface ShipmentTracking {
  __typename?: 'ShipmentTracking'
  /** The shipping carrier for the order delivery. */
  carrier: Scalars['String']['output']
  /** The tracking number of the order shipment. */
  number: Maybe<Scalars['String']['output']>
  /** The shipment tracking title. */
  title: Scalars['String']['output']
}

/** Defines a single shipping address. */
export interface ShippingAddressInput {
  /** Defines a shipping address. */
  address?: InputMaybe<CartAddressInput>
  /** An ID from the customer's address book that uniquely identifies the address to be used for shipping. */
  customer_address_id?: InputMaybe<Scalars['Int']['input']>
  /** Text provided by the shopper. */
  customer_notes?: InputMaybe<Scalars['String']['input']>
  /** The code of Pickup Location which will be used for In-Store Pickup. */
  pickup_location_code?: InputMaybe<Scalars['String']['input']>
}

/** Contains shipping addresses and methods. */
export interface ShippingCartAddress extends CartAddressInterface {
  __typename?: 'ShippingCartAddress'
  /** An array that lists the shipping methods that can be applied to the cart. */
  availableShippingMethods: Maybe<Array<Maybe<AvailableShippingMethod>>>
  /** @deprecated Use `cart_items_v2` instead. */
  cartItems: Maybe<Array<Maybe<CartItemQuantity>>>
  /** An array that lists the items in the cart. */
  cartItemsV2: Maybe<Array<Maybe<CartItemInterface>>>
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['output']
  /** The company specified for the billing or shipping address. */
  company: Maybe<Scalars['String']['output']>
  /** An object containing the country label and code. */
  country: CartAddressCountry
  /** Text provided by the shopper. */
  customerNotes: Maybe<Scalars['String']['output']>
  /** The customer's fax number. */
  fax: Maybe<Scalars['String']['output']>
  /** The first name of the customer or guest. */
  firstname: Scalars['String']['output']
  /** @deprecated This information should not be exposed on the frontend. */
  itemsWeight: Maybe<Scalars['Float']['output']>
  /** The last name of the customer or guest. */
  lastname: Scalars['String']['output']
  /** The middle name of the person associated with the billing/shipping address. */
  middlename: Maybe<Scalars['String']['output']>
  pickupLocationCode: Maybe<Scalars['String']['output']>
  /** The ZIP or postal code of the billing or shipping address. */
  postcode: Maybe<Scalars['String']['output']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>
  /** An object containing the region label and code. */
  region: Maybe<CartAddressRegion>
  /** An object that describes the selected shipping method. */
  selectedShippingMethod: Maybe<SelectedShippingMethod>
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']['output']>>
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>
  /** The telephone number for the billing or shipping address. */
  telephone: Maybe<Scalars['String']['output']>
  /** The unique id of the customer address. */
  uid: Scalars['String']['output']
  /** The VAT company number for billing or shipping address. */
  vatId: Maybe<Scalars['String']['output']>
}

/** Defines an individual shipping discount. This discount can be applied to shipping. */
export interface ShippingDiscount {
  __typename?: 'ShippingDiscount'
  /** The amount of the discount. */
  amount: Money
}

/** Contains details about shipping and handling costs. */
export interface ShippingHandling {
  __typename?: 'ShippingHandling'
  /** The shipping amount, excluding tax. */
  amountExcludingTax: Maybe<Money>
  /** The shipping amount, including tax. */
  amountIncludingTax: Maybe<Money>
  /** The applied discounts to the shipping. */
  discounts: Maybe<Array<Maybe<ShippingDiscount>>>
  /** Details about taxes applied for shipping. */
  taxes: Maybe<Array<Maybe<TaxItem>>>
  /** The total amount for shipping. */
  totalAmount: Money
}

/** Defines the shipping carrier and method. */
export interface ShippingMethodInput {
  /** A string that identifies a commercial carrier or an offline delivery method. */
  carrier_code: Scalars['String']['input']
  /** A string that indicates which service a commercial carrier will use to ship items. For offline delivery methods, this value is similar to the label displayed on the checkout page. */
  method_code: Scalars['String']['input']
}

/** An implementation for simple product cart items. */
export interface SimpleCartItem extends CartItemInterface {
  __typename?: 'SimpleCartItem'
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** The entered gift message for the cart item */
  giftMessage: Maybe<GiftMessage>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export interface SimpleProduct
  extends CustomizableProductInterface,
    PhysicalProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'SimpleProduct'
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeBalancebike: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeLgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeRideoncar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeScooter: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeSgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeTrampoline: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  addOn: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSeat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSteeringWheel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  aerowall: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ageRecommended: Maybe<Scalars['Int']['output']>
  /** Ambiance Product Images */
  ambianceGallery: Maybe<Array<Maybe<AmbianceGallery>>>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  categoryPlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ceLabel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  collaborationLicense: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** Color Variants */
  colorVariants: Maybe<Array<Maybe<ColorVariant>>>
  /** Configurable Link */
  configurableLink: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  drive: Maybe<Scalars['Int']['output']>
  /** Filterable attribute with Text */
  dynamicAttributes: Maybe<Array<Maybe<DynamicAttribute>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  electricalSupport: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  entranceHeight: Maybe<Scalars['Int']['output']>
  /** Craft entry type */
  entryType: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  framePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  frameXlGokart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gears: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['Int']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gokartType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  handbrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  heightTrampoline: Maybe<Scalars['Int']['output']>
  /** Highlight attributes */
  highlightedAttributes: Maybe<Array<Maybe<HighlightAttribute>>>
  /** Highlight attributes PLP */
  highlightedAttributesPlp: Maybe<Array<Maybe<HighlightAttribute>>>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Scalars['Int']['output']
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  inclSafetynet: Maybe<Scalars['Int']['output']>
  /** Whether the product is a main product or not. */
  isMainProduct: Scalars['Boolean']['output']
  /** @deprecated Use the `custom_attributes` field instead. */
  jumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  lengthUser: Maybe<Scalars['Int']['output']>
  /** A number representing the product's manufacturer. */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  material: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  materialTires: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUserWeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUsers: Maybe<Scalars['Int']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgBrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgClamp: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgDeck: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgFrontFork: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGrips: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGriptape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHandlebar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHeadset: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgWheels: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  model: Maybe<Scalars['Int']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Scalars['String']['output']
  /** @deprecated Use the `custom_attributes` field instead. */
  netWeight: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  numberOfSprings: Maybe<Scalars['Int']['output']>
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** Configurable Link */
  parentData: Maybe<ParentData>
  /** @deprecated Use the `custom_attributes` field instead. */
  pedals: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  playbaseSet: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  possibilityFor2ndSeat: Maybe<Scalars['Int']['output']>
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** Product downloads */
  productDownloads: Maybe<ProductDownload>
  productLabels: Maybe<Array<Maybe<AmLabelList>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  pushRod: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityLine: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityStars: Maybe<Scalars['Int']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of products to be displayed in a Related Products block. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** @deprecated Use the `custom_attributes` field instead. */
  safetynetType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  seatHeight: Maybe<Scalars['Int']['output']>
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAccessories: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAirtrack: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeBeachwagon: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeClothing: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeGocart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeJumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeStep: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeWheelbarrow: Maybe<Scalars['Int']['output']>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Scalars['String']['output']
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /** Get list of spareparts per product(group), per year */
  sparepartLinks: Maybe<Array<Maybe<BergProduct>>>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  springType: Maybe<Scalars['Int']['output']>
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  testweight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  themePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  thicknessOfProtectiveEdge: Maybe<Scalars['Int']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineShape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  tuevGs: Maybe<Scalars['Int']['output']>
  /** Tweakwise ID */
  tweakwiseItemNumber: Maybe<Scalars['String']['output']>
  /** PreOrder info */
  tweakwiseTemplates: Maybe<TweakwiseTemplates>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /** @deprecated Use the `custom_attributes` field instead. */
  typeAccessory: Maybe<Scalars['Int']['output']>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Scalars['String']['output']
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  uvProtection: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warrantyWoRegistration: Maybe<Scalars['Int']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelLights: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelSize: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  widthPadding: Maybe<Scalars['Int']['output']>
}

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export interface SimpleProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export interface SimpleProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines a single product to add to the cart. */
export interface SimpleProductCartItemInput {
  /** An array that defines customizable options for the product. */
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: CartItemInput
}

/** Contains a simple product wish list item. */
export interface SimpleWishlistItem extends WishlistItemInterface {
  __typename?: 'SimpleWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

/** Smart button payment inputs */
export interface SmartButtonMethodInput {
  /** The payment source for the payment method */
  payment_source?: InputMaybe<Scalars['String']['input']>
  /** The payment services order ID */
  payments_order_id?: InputMaybe<Scalars['String']['input']>
  /** PayPal order ID */
  paypal_order_id?: InputMaybe<Scalars['String']['input']>
}

export interface SmartButtonsConfig extends PaymentConfigItem {
  __typename?: 'SmartButtonsConfig'
  /** The styles for the PayPal Smart Button configuration */
  buttonStyles: Maybe<ButtonStyles>
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>
  /** Indicates whether to display the PayPal Pay Later message */
  displayMessage: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether to display Venmo */
  displayVenmo: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>
  /** Contains details about the styles for the PayPal Pay Later message */
  messageStyles: Maybe<MessageStyles>
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>
}

/** Indicates whether to return results in ascending or descending order. */
export enum SortEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

/** Defines a possible sort field. */
export interface SortField {
  __typename?: 'SortField'
  /** The label of the sort field. */
  label: Maybe<Scalars['String']['output']>
  /** The attribute code of the sort field. */
  value: Maybe<Scalars['String']['output']>
}

/** Contains a default value for sort fields and all available sort fields. */
export interface SortFields {
  __typename?: 'SortFields'
  /** The default sort field value. */
  default: Maybe<Scalars['String']['output']>
  /** An array of possible sort fields. */
  options: Maybe<Array<Maybe<SortField>>>
}

/** Specifies the field to use for sorting quote items */
export enum SortQuoteItemsEnum {
  BASE_DISCOUNT_AMOUNT = 'BASE_DISCOUNT_AMOUNT',
  BASE_DISCOUNT_TAX_COMPENSATION_AMOUNT = 'BASE_DISCOUNT_TAX_COMPENSATION_AMOUNT',
  BASE_PRICE = 'BASE_PRICE',
  BASE_PRICE_INC_TAX = 'BASE_PRICE_INC_TAX',
  BASE_ROW_TOTAL = 'BASE_ROW_TOTAL',
  BASE_ROW_TOTAL_INC_TAX = 'BASE_ROW_TOTAL_INC_TAX',
  BASE_TAX_AMOUNT = 'BASE_TAX_AMOUNT',
  BASE_TAX_BEFORE_DISCOUNT = 'BASE_TAX_BEFORE_DISCOUNT',
  CREATED_AT = 'CREATED_AT',
  CUSTOM_PRICE = 'CUSTOM_PRICE',
  DESCRIPTION = 'DESCRIPTION',
  DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT',
  DISCOUNT_PERCENT = 'DISCOUNT_PERCENT',
  DISCOUNT_TAX_COMPENSATION_AMOUNT = 'DISCOUNT_TAX_COMPENSATION_AMOUNT',
  FREE_SHIPPING = 'FREE_SHIPPING',
  ITEM_ID = 'ITEM_ID',
  NAME = 'NAME',
  ORIGINAL_CUSTOM_PRICE = 'ORIGINAL_CUSTOM_PRICE',
  PRICE = 'PRICE',
  PRICE_INC_TAX = 'PRICE_INC_TAX',
  PRODUCT_ID = 'PRODUCT_ID',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  QTY = 'QTY',
  ROW_TOTAL = 'ROW_TOTAL',
  ROW_TOTAL_INC_TAX = 'ROW_TOTAL_INC_TAX',
  ROW_TOTAL_WITH_DISCOUNT = 'ROW_TOTAL_WITH_DISCOUNT',
  ROW_WEIGHT = 'ROW_WEIGHT',
  SKU = 'SKU',
  TAX_AMOUNT = 'TAX_AMOUNT',
  TAX_BEFORE_DISCOUNT = 'TAX_BEFORE_DISCOUNT',
  TAX_PERCENT = 'TAX_PERCENT',
  UPDATED_AT = 'UPDATED_AT',
  WEIGHT = 'WEIGHT',
}

export interface SparepartGroup {
  __typename?: 'SparepartGroup'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

/** Contains information about a store's configuration. */
export interface StoreConfig {
  __typename?: 'StoreConfig'
  /** Indicates whether guest users can write product reviews. Possible values: 1 (Yes) and 0 (No). */
  allowGuestsToWriteProductReviews: Maybe<Scalars['String']['output']>
  /** The value of the Allow Gift Messages for Order Items option */
  allowItems: Maybe<Scalars['String']['output']>
  /** The value of the Allow Gift Messages on Order Level option */
  allowOrder: Maybe<Scalars['String']['output']>
  /** Indicates whether to enable autocomplete on login and forgot password forms. */
  autocompleteOnStorefront: Maybe<Scalars['Boolean']['output']>
  /** The base currency code. */
  baseCurrencyCode: Maybe<Scalars['String']['output']>
  /** A fully-qualified URL that is used to create relative links to the `base_url`. */
  baseLinkUrl: Maybe<Scalars['String']['output']>
  /** The fully-qualified URL that specifies the location of media files. */
  baseMediaUrl: Maybe<Scalars['String']['output']>
  /** The fully-qualified URL that specifies the location of static view files. */
  baseStaticUrl: Maybe<Scalars['String']['output']>
  /** The store’s fully-qualified base URL. */
  baseUrl: Maybe<Scalars['String']['output']>
  /** Extended Config Data - checkout/cart/delete_quote_after */
  cartExpiresInDays: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - checkout/cart_link/use_qty */
  cartSummaryDisplayQuantity: Maybe<Scalars['Int']['output']>
  /** The default sort order of the search results list. */
  catalogDefaultSortBy: Maybe<Scalars['String']['output']>
  /** Corresponds to the 'Display Prices In Product Lists' field in the Admin. It indicates how FPT information is displayed on category pages. */
  categoryFixedProductTaxDisplaySetting: Maybe<FixedProductTaxDisplaySettings>
  /** The suffix applied to category pages, such as `.htm` or `.html`. */
  categoryUrlSuffix: Maybe<Scalars['String']['output']>
  /** Indicates whether only specific countries can use this payment method. */
  checkMoneyOrderEnableForSpecificCountries: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the Check/Money Order payment method is enabled. */
  checkMoneyOrderEnabled: Maybe<Scalars['Boolean']['output']>
  /** The name of the party to whom the check must be payable. */
  checkMoneyOrderMakeCheckPayableTo: Maybe<Scalars['String']['output']>
  /** The maximum order amount required to qualify for the Check/Money Order payment method. */
  checkMoneyOrderMaxOrderTotal: Maybe<Scalars['String']['output']>
  /** The minimum order amount required to qualify for the Check/Money Order payment method. */
  checkMoneyOrderMinOrderTotal: Maybe<Scalars['String']['output']>
  /** The status of new orders placed using the Check/Money Order payment method. */
  checkMoneyOrderNewOrderStatus: Maybe<Scalars['String']['output']>
  /** A comma-separated list of specific countries allowed to use the Check/Money Order payment method. */
  checkMoneyOrderPaymentFromSpecificCountries: Maybe<
    Scalars['String']['output']
  >
  /** The full street address or PO Box where the checks are mailed. */
  checkMoneyOrderSendCheckTo: Maybe<Scalars['String']['output']>
  /** A number indicating the position of the Check/Money Order payment method in the list of available payment methods during checkout. */
  checkMoneyOrderSortOrder: Maybe<Scalars['Int']['output']>
  /** The title of the Check/Money Order payment method displayed on the storefront. */
  checkMoneyOrderTitle: Maybe<Scalars['String']['output']>
  /** The name of the CMS page that identifies the home page for the store. */
  cmsHomePage: Maybe<Scalars['String']['output']>
  /** A specific CMS page that displays when cookies are not enabled for the browser. */
  cmsNoCookies: Maybe<Scalars['String']['output']>
  /** A specific CMS page that displays when a 404 'Page Not Found' error occurs. */
  cmsNoRoute: Maybe<Scalars['String']['output']>
  /**
   * A code assigned to the store to identify it.
   * @deprecated Use `store_code` instead.
   */
  code: Maybe<Scalars['String']['output']>
  /** Indicates whether the `parent` or child (`itself`) thumbnail should be used in the cart for configurable products. */
  configurableThumbnailSource: Maybe<Scalars['String']['output']>
  /** Indicates whether the Contact Us form in enabled. */
  contactEnabled: Scalars['Boolean']['output']
  /** Extended Config Data - general/region/state_required */
  countriesWithRequiredRegion: Maybe<Scalars['String']['output']>
  /** Indicates if the new accounts need confirmation. */
  createAccountConfirmation: Maybe<Scalars['Boolean']['output']>
  /** Customer access token lifetime. */
  customerAccessTokenLifetime: Maybe<Scalars['Float']['output']>
  /** Extended Config Data - general/country/default */
  defaultCountry: Maybe<Scalars['String']['output']>
  defaultCountryId: Maybe<Scalars['String']['output']>
  /** The default display currency code. */
  defaultDisplayCurrencyCode: Maybe<Scalars['String']['output']>
  disableEcommerce: Maybe<Scalars['Boolean']['output']>
  /** Extended Config Data - general/region/display_all */
  displayStateIfOptional: Maybe<Scalars['Boolean']['output']>
  /** The landing page that is associated with the base URL. */
  front: Maybe<Scalars['String']['output']>
  /** The default number of products per page in Grid View. */
  gridPerPage: Maybe<Scalars['Int']['output']>
  /** A list of numbers that define how many products can be displayed in Grid View. */
  gridPerPageValues: Maybe<Scalars['String']['output']>
  /** GTM Container IDs, optional a comma separated list of Container IDs */
  gtmContainerIds: Maybe<Scalars['String']['output']>
  hrefLang: Maybe<Scalars['String']['output']>
  /**
   * The ID number assigned to the store.
   * @deprecated Use `store_code` instead.
   */
  id: Maybe<Scalars['Int']['output']>
  /** Indicates whether the store view has been designated as the default within the store group. */
  isDefaultStore: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the store group has been designated as the default within the website. */
  isDefaultStoreGroup: Maybe<Scalars['Boolean']['output']>
  /** Extended Config Data - checkout/options/guest_checkout */
  isGuestCheckoutEnabled: Maybe<Scalars['Boolean']['output']>
  /** Extended Config Data - checkout/options/onepage_checkout_enabled */
  isOnePageCheckoutEnabled: Maybe<Scalars['Boolean']['output']>
  /** The format of the search results list. */
  listMode: Maybe<Scalars['String']['output']>
  /** The default number of products per page in List View. */
  listPerPage: Maybe<Scalars['Int']['output']>
  /** A list of numbers that define how many products can be displayed in List View. */
  listPerPageValues: Maybe<Scalars['String']['output']>
  /** The store locale. */
  locale: Maybe<Scalars['String']['output']>
  /** Indicates whether wishlists are enabled (1) or disabled (0). */
  magentoWishlistGeneralIsEnabled: Maybe<Scalars['String']['output']>
  /** Extended Config Data - checkout/options/max_items_display_count */
  maxItemsInOrderSummary: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - checkout/sidebar/display */
  minicartDisplay: Maybe<Scalars['Boolean']['output']>
  /** Extended Config Data - checkout/sidebar/count */
  minicartMaxItems: Maybe<Scalars['Int']['output']>
  /** The minimum number of characters required for a valid password. */
  minimumPasswordLength: Maybe<Scalars['String']['output']>
  /** Indicates whether newsletters are enabled. */
  newsletterEnabled: Scalars['Boolean']['output']
  /** The default page that displays when a 404 'Page not Found' error occurs. */
  noRoute: Maybe<Scalars['String']['output']>
  /** Extended Config Data - general/country/optional_zip_countries */
  optionalZipCountries: Maybe<Scalars['String']['output']>
  /** Indicates whether orders can be cancelled by customers or not. */
  orderCancellationEnabled: Scalars['Boolean']['output']
  /** An array containing available cancellation reasons. */
  orderCancellationReasons: Array<Maybe<CancellationReason>>
  /** Payflow Pro vault status. */
  paymentPayflowproCcVaultActive: Maybe<Scalars['String']['output']>
  /** Corresponds to the 'Display Prices On Product View Page' field in the Admin. It indicates how FPT information is displayed on product pages. */
  productFixedProductTaxDisplaySetting: Maybe<FixedProductTaxDisplaySettings>
  /** Indicates whether product reviews are enabled. Possible values: 1 (Yes) and 0 (No). */
  productReviewsEnabled: Maybe<Scalars['String']['output']>
  /** The suffix applied to product pages, such as `.htm` or `.html`. */
  productUrlSuffix: Maybe<Scalars['String']['output']>
  /** The number of different character classes (lowercase, uppercase, digits, special characters) required in a password. */
  requiredCharacterClassesNumber: Maybe<Scalars['String']['output']>
  /** Magento 2 Theme Robots.txt value */
  robotsTxt: Maybe<Scalars['String']['output']>
  /**
   * The ID of the root category.
   * @deprecated Use `root_category_uid` instead.
   */
  rootCategoryId: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `CategoryInterface` object. */
  rootCategoryUid: Maybe<Scalars['ID']['output']>
  /** Corresponds to the 'Display Prices In Sales Modules' field in the Admin. It indicates how FPT information is displayed on cart, checkout, and order pages. */
  salesFixedProductTaxDisplaySetting: Maybe<FixedProductTaxDisplaySettings>
  /** Search engine robot values. 'INDEX, FOLLOW', 'NOINDEX, FOLLOW' etc. */
  searchEngineRobots: Maybe<Scalars['String']['output']>
  /** A secure fully-qualified URL that is used to create relative links to the `base_url`. */
  secureBaseLinkUrl: Maybe<Scalars['String']['output']>
  /** The secure fully-qualified URL that specifies the location of media files. */
  secureBaseMediaUrl: Maybe<Scalars['String']['output']>
  /** The secure fully-qualified URL that specifies the location of static view files. */
  secureBaseStaticUrl: Maybe<Scalars['String']['output']>
  /** The store’s fully-qualified secure base URL. */
  secureBaseUrl: Maybe<Scalars['String']['output']>
  /** Email to a Friend configuration. */
  sendFriend: Maybe<SendFriendConfiguration>
  /** Extended Config Data - tax/cart_display/full_summary */
  shoppingCartDisplayFullSummary: Maybe<Scalars['Boolean']['output']>
  /** Extended Config Data - tax/cart_display/grandtotal */
  shoppingCartDisplayGrandTotal: Maybe<Scalars['Boolean']['output']>
  /** Extended Config Data - tax/cart_display/price */
  shoppingCartDisplayPrice: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - tax/cart_display/shipping */
  shoppingCartDisplayShipping: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - tax/cart_display/subtotal */
  shoppingCartDisplaySubtotal: Maybe<Scalars['Int']['output']>
  /** Extended Config Data - tax/cart_display/gift_wrapping */
  shoppingCartDisplayTaxGiftWrapping: Maybe<TaxWrappingEnum>
  /** Extended Config Data - tax/cart_display/zero_tax */
  shoppingCartDisplayZeroTax: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether a breadcrumb trail appears on all CMS pages in the catalog. 0 (No) or 1 (Yes). */
  showCmsBreadcrumbs: Maybe<Scalars['Int']['output']>
  socialTrustpilotBusinessUnitId: Maybe<Scalars['String']['output']>
  storeCity: Maybe<Scalars['String']['output']>
  /** The unique ID of the store view. In the Admin, this is called the Store View Code. When making a GraphQL call, assign this value to the `Store` header to provide the scope. */
  storeCode: Maybe<Scalars['ID']['output']>
  storeCountryId: Maybe<Scalars['String']['output']>
  /** The unique ID assigned to the store group. In the Admin, this is called the Store Name. */
  storeGroupCode: Maybe<Scalars['ID']['output']>
  /** The label assigned to the store group. */
  storeGroupName: Maybe<Scalars['String']['output']>
  storeHours: Maybe<Scalars['String']['output']>
  storeMerchantVatNumber: Maybe<Scalars['String']['output']>
  /** The label assigned to the store view. */
  storeName: Maybe<Scalars['String']['output']>
  storePhone: Maybe<Scalars['String']['output']>
  storePostcode: Maybe<Scalars['String']['output']>
  storeRegionId: Maybe<Scalars['String']['output']>
  /** The store view sort order. */
  storeSortOrder: Maybe<Scalars['Int']['output']>
  storeStreetLine1: Maybe<Scalars['String']['output']>
  storeStreetLine2: Maybe<Scalars['String']['output']>
  /** The time zone of the store. */
  timezone: Maybe<Scalars['String']['output']>
  /** The character that separates the category name and subcategory in the browser title bar. */
  titleSeparator: Maybe<Scalars['String']['output']>
  /** Indicates whether the store code should be used in the URL. */
  useStoreInUrl: Maybe<Scalars['Boolean']['output']>
  /** The unique ID for the website. */
  websiteCode: Maybe<Scalars['ID']['output']>
  /**
   * The ID number assigned to the website store.
   * @deprecated The field should not be used on the storefront.
   */
  websiteId: Maybe<Scalars['Int']['output']>
  /** The label assigned to the website. */
  websiteName: Maybe<Scalars['String']['output']>
  /** The unit of weight. */
  weightUnit: Maybe<Scalars['String']['output']>
  /** Indicates whether only specific countries can use this payment method. */
  zeroSubtotalEnableForSpecificCountries: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the Zero Subtotal payment method is enabled. */
  zeroSubtotalEnabled: Maybe<Scalars['Boolean']['output']>
  /** The status of new orders placed using the Zero Subtotal payment method. */
  zeroSubtotalNewOrderStatus: Maybe<Scalars['String']['output']>
  /** When the new order status is 'Processing', this can be set to `authorize_capture` to automatically invoice all items that have a zero balance. */
  zeroSubtotalPaymentAction: Maybe<Scalars['String']['output']>
  /** A comma-separated list of specific countries allowed to use the Zero Subtotal payment method. */
  zeroSubtotalPaymentFromSpecificCountries: Maybe<Scalars['String']['output']>
  /** A number indicating the position of the Zero Subtotal payment method in the list of available payment methods during checkout. */
  zeroSubtotalSortOrder: Maybe<Scalars['Int']['output']>
  /** The title of the Zero Subtotal payment method displayed on the storefront. */
  zeroSubtotalTitle: Maybe<Scalars['String']['output']>
}

/** Indicates where an attribute can be displayed. */
export interface StorefrontProperties {
  __typename?: 'StorefrontProperties'
  /** The relative position of the attribute in the layered navigation block. */
  position: Maybe<Scalars['Int']['output']>
  /** Indicates whether the attribute is filterable with results, without results, or not at all. */
  useInLayeredNavigation: Maybe<UseInLayeredNavigationOptions>
  /** Indicates whether the attribute is displayed in product listings. */
  useInProductListing: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the attribute can be used in layered navigation on search results pages. */
  useInSearchResultsLayeredNavigation: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the attribute is displayed on product pages. */
  visibleOnCatalogPages: Maybe<Scalars['Boolean']['output']>
}

/** Contains the result of the `subscribeEmailToNewsletter` operation. */
export interface SubscribeEmailToNewsletterOutput {
  __typename?: 'SubscribeEmailToNewsletterOutput'
  /** The status of the subscription request. */
  status: Maybe<SubscriptionStatusesEnum>
}

/** Indicates the status of the request. */
export enum SubscriptionStatusesEnum {
  NOT_ACTIVE = 'NOT_ACTIVE',
  SUBSCRIBED = 'SUBSCRIBED',
  UNCONFIRMED = 'UNCONFIRMED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

/** Describes the swatch type and a value. */
export interface SwatchData {
  __typename?: 'SwatchData'
  /** The type of swatch filter item: 1 - text; 2 - image. */
  type: Maybe<Scalars['String']['output']>
  /** The value for the swatch item. It could be text or an image link. */
  value: Maybe<Scalars['String']['output']>
}

export interface SwatchDataInterface {
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']['output']>
}

/** Swatch attribute metadata input types. */
export enum SwatchInputTypeEnum {
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  DROPDOWN = 'DROPDOWN',
  FILE = 'FILE',
  GALLERY = 'GALLERY',
  HIDDEN = 'HIDDEN',
  IMAGE = 'IMAGE',
  MEDIA_IMAGE = 'MEDIA_IMAGE',
  MULTILINE = 'MULTILINE',
  MULTISELECT = 'MULTISELECT',
  PRICE = 'PRICE',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  UNDEFINED = 'UNDEFINED',
  VISUAL = 'VISUAL',
  WEIGHT = 'WEIGHT',
}

export interface SwatchLayerFilterItem
  extends LayerFilterItemInterface,
    SwatchLayerFilterItemInterface {
  __typename?: 'SwatchLayerFilterItem'
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount: Maybe<Scalars['Int']['output']>
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label: Maybe<Scalars['String']['output']>
  /** Data required to render a swatch filter item. */
  swatchData: Maybe<SwatchData>
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString: Maybe<Scalars['String']['output']>
}

export interface SwatchLayerFilterItemInterface {
  /** Data required to render a swatch filter item. */
  swatchData: Maybe<SwatchData>
}

/** Synchronizes the payment order details */
export interface SyncPaymentOrderInput {
  /** The customer cart ID */
  cartId: Scalars['String']['input']
  /** PayPal order ID */
  id: Scalars['String']['input']
}

export interface TaggedTweakwiseItemData {
  __typename?: 'TaggedTweakwiseItemData'
  id: Scalars['ID']['output']
  store: Maybe<Scalars['Int']['output']>
  type: Scalars['String']['output']
}

/** Contains tax item details. */
export interface TaxItem {
  __typename?: 'TaxItem'
  /** The amount of tax applied to the item. */
  amount: Money
  /** The rate used to calculate the tax. */
  rate: Scalars['Float']['output']
  /** A title that describes the tax. */
  title: Scalars['String']['output']
}

export enum TaxWrappingEnum {
  DISPLAY_EXCLUDING_TAX = 'DISPLAY_EXCLUDING_TAX',
  DISPLAY_INCLUDING_TAX = 'DISPLAY_INCLUDING_TAX',
  DISPLAY_TYPE_BOTH = 'DISPLAY_TYPE_BOTH',
}

export interface TextSwatchData extends SwatchDataInterface {
  __typename?: 'TextSwatchData'
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']['output']>
}

/** Defines a price based on the quantity purchased. */
export interface TierPrice {
  __typename?: 'TierPrice'
  /** The price discount that this tier represents. */
  discount: Maybe<ProductDiscount>
  /** The price of the product at this tier. */
  finalPrice: Maybe<Money>
  /** The minimum number of items that must be purchased to qualify for this price tier. */
  quantity: Maybe<Scalars['Float']['output']>
}

export interface TweakwiseAdvisor {
  __typename?: 'TweakwiseAdvisor'
  code: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  items: Maybe<Array<Maybe<TweakwiseAdvisorItem>>>
  questions: Maybe<Array<Maybe<TweakwiseAdvisorQuestion>>>
  title: Maybe<Scalars['String']['output']>
}

export interface TweakwiseAdvisorAnswer {
  __typename?: 'TweakwiseAdvisorAnswer'
  answer: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image: Maybe<Scalars['String']['output']>
  isSelected: Maybe<Scalars['Boolean']['output']>
  nextQuestionId: Maybe<Scalars['ID']['output']>
  subtext: Maybe<Scalars['String']['output']>
  summary: Maybe<Scalars['String']['output']>
}

export interface TweakwiseAdvisorAnswerInput {
  answerIds: Array<InputMaybe<Scalars['Int']['input']>>
  questionId: Scalars['Int']['input']
}

export interface TweakwiseAdvisorItem {
  __typename?: 'TweakwiseAdvisorItem'
  brand: Maybe<Scalars['String']['output']>
  itemNo: Maybe<Scalars['String']['output']>
  price: Maybe<Scalars['Float']['output']>
  properties: Maybe<Array<Maybe<TweakwiseAdvisorItemProperties>>>
  title: Maybe<Scalars['String']['output']>
}

export interface TweakwiseAdvisorItemProperties {
  __typename?: 'TweakwiseAdvisorItemProperties'
  attributes: Maybe<Array<Maybe<Scalars['String']['output']>>>
  isFiltered: Maybe<Scalars['Boolean']['output']>
  isMatch: Maybe<Scalars['Boolean']['output']>
  propertyId: Maybe<Scalars['Int']['output']>
  title: Maybe<Scalars['String']['output']>
}

export interface TweakwiseAdvisorParams {
  answers?: InputMaybe<Array<InputMaybe<TweakwiseAdvisorAnswerInput>>>
  code: Scalars['String']['input']
  pageSize?: InputMaybe<Scalars['Int']['input']>
  skippedQuestions?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export interface TweakwiseAdvisorQuestion {
  __typename?: 'TweakwiseAdvisorQuestion'
  answers: Maybe<Array<Maybe<TweakwiseAdvisorAnswer>>>
  attributeId: Maybe<Scalars['Int']['output']>
  current: Maybe<Scalars['Boolean']['output']>
  explanation: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image: Maybe<Scalars['String']['output']>
  isMultiSelect: Maybe<Scalars['Boolean']['output']>
  question: Maybe<Scalars['String']['output']>
  skiplabel: Maybe<Scalars['String']['output']>
  skippable: Maybe<Scalars['Boolean']['output']>
}

export interface TweakwiseAutocompleteParams {
  /** The category that should contain the filtered results. If you want to provide the entire path, you can provide multiple ids. */
  categoryId?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Use instant search. Defaults to false. */
  instant?: InputMaybe<Scalars['Boolean']['input']>
  /** Specify the keyboard to use for the search algorithms. */
  keyboard?: InputMaybe<TweakwiseKeyboardLayout>
  /** Specify the language to use for the search algorithms. */
  language?: InputMaybe<Scalars['String']['input']>
  /** The number of results that will be returned per component. Defaults to 6. Max 20. */
  maxResults?: InputMaybe<Scalars['Int']['input']>
  /**
   * Hidden parameters can be added to the URL by using the parameter 'tn_parameters'.
   *
   * When filtering by property 'brand' with value 'Coca Cola' or with value 'Pepsi' the value of tn_parameters should be: brand=Coca Cola&brand=Pepsi
   *
   * This value should then be URL encoded.
   */
  parameters?: InputMaybe<Array<TweakwiseNavigationParameter>>
}

export interface TweakwiseAutocompleteResult {
  __typename?: 'TweakwiseAutocompleteResult'
  instantSearchTerm: Maybe<Scalars['String']['output']>
  isInstantSearchActive: Maybe<Scalars['Boolean']['output']>
  items: Array<TweakwiseNavigationItem>
  keywords: Array<Scalars['String']['output']>
}

export interface TweakwiseFunnel {
  __typename?: 'TweakwiseFunnel'
  code: Maybe<Scalars['String']['output']>
  count: Maybe<Scalars['Int']['output']>
  filterTemplateId: Maybe<Scalars['Int']['output']>
  items: Maybe<Array<Maybe<TweakwiseFunnelItem>>>
  name: Maybe<Scalars['String']['output']>
  navigationUrl: Maybe<Scalars['String']['output']>
  questions: Maybe<Array<Maybe<TweakwiseFunnelQuestion>>>
}

export interface TweakwiseFunnelAnswer {
  __typename?: 'TweakwiseFunnelAnswer'
  answer: Maybe<Scalars['String']['output']>
  answerId: Scalars['ID']['output']
  caption: Maybe<Scalars['String']['output']>
  count: Maybe<Scalars['Int']['output']>
  imageUrl: Maybe<Scalars['String']['output']>
  isSelected: Maybe<Scalars['Boolean']['output']>
  nextQuestionId: Maybe<Scalars['ID']['output']>
  summary: Maybe<Scalars['String']['output']>
}

export interface TweakwiseFunnelAnswerInput {
  answerIds: Array<InputMaybe<Scalars['ID']['input']>>
  questionId: Scalars['ID']['input']
}

export interface TweakwiseFunnelItem {
  __typename?: 'TweakwiseFunnelItem'
  attributes: Maybe<Array<Maybe<Scalars['String']['output']>>>
  brand: Maybe<Scalars['String']['output']>
  imageUrl: Maybe<Scalars['String']['output']>
  itemNo: Scalars['ID']['output']
  labels: Maybe<Scalars['String']['output']>
  price: Maybe<Scalars['Float']['output']>
  title: Maybe<Scalars['String']['output']>
  url: Maybe<Scalars['String']['output']>
}

export interface TweakwiseFunnelParams {
  answers?: InputMaybe<Array<InputMaybe<TweakwiseFunnelAnswerInput>>>
  code: Scalars['String']['input']
  pageSize?: InputMaybe<Scalars['Int']['input']>
  skipProducts?: InputMaybe<Scalars['Int']['input']>
  skippedQuestions?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export interface TweakwiseFunnelQuestion {
  __typename?: 'TweakwiseFunnelQuestion'
  answers: Maybe<Array<Maybe<TweakwiseFunnelAnswer>>>
  current: Maybe<Scalars['Boolean']['output']>
  explanation: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image: Maybe<Scalars['String']['output']>
  isMultiSelect: Maybe<Scalars['Boolean']['output']>
  question: Maybe<Scalars['String']['output']>
  skipLabel: Maybe<Scalars['String']['output']>
  skippable: Maybe<Scalars['Boolean']['output']>
}

export enum TweakwiseKeyboardLayout {
  AZERTY = 'azerty',
  QWERTY = 'qwerty',
  QWERTZ = 'qwertz',
}

export interface TweakwiseNavigationAttribute {
  __typename?: 'TweakwiseNavigationAttribute'
  attributeId: Maybe<Scalars['Int']['output']>
  children: Maybe<Scalars['TODO']['output']>
  colorSwatch: Maybe<ColorSwatch>
  facetAttributeName: Scalars['ID']['output']
  imageTag: Maybe<Scalars['String']['output']>
  isSelected: Scalars['Boolean']['output']
  nrOfResults: Scalars['Int']['output']
  title: Scalars['String']['output']
  url: Maybe<Scalars['String']['output']>
}

export interface TweakwiseNavigationFacet {
  __typename?: 'TweakwiseNavigationFacet'
  attributes: Array<TweakwiseNavigationAttribute>
  facetSettings: FacetSettings
}

export interface TweakwiseNavigationFacetFilter {
  key: Scalars['String']['input']
  values: Array<Scalars['String']['input']>
}

export interface TweakwiseNavigationItem {
  __typename?: 'TweakwiseNavigationItem'
  brand: Maybe<Scalars['String']['output']>
  image: Maybe<Scalars['String']['output']>
  itemNo: Scalars['ID']['output']
  price: Scalars['Float']['output']
  product: Maybe<ProductInterface>
  taggedData: Maybe<TaggedTweakwiseItemData>
  title: Scalars['String']['output']
}

export interface TweakwiseNavigationParameter {
  property: Scalars['String']['input']
  value: Scalars['String']['input']
}

export interface TweakwiseNavigationParams {
  /** The category ID to query. When filtering by a subcategory, providing the parent categoryIds will keep the category facet accurate. */
  categoryId?: InputMaybe<Array<Scalars['ID']['input']>>
  /** The facets with which the results are filtered by. */
  facets?: InputMaybe<Array<TweakwiseNavigationFacetFilter>>
  /** Overruling the linked filter template with the specified template. */
  filterTemplateId?: InputMaybe<Scalars['Int']['input']>
  /** Specify the keyboard to use for the search algorithms. */
  keyboard?: InputMaybe<TweakwiseKeyboardLayout>
  /** The page number of the page that must be displayed. */
  page?: InputMaybe<Scalars['Int']['input']>
  /** The number of products that must be displayed per page. The standard value is configured at shop level. Only use this parameter to overrule this standard configured quantity. */
  pageSize?: InputMaybe<Scalars['Int']['input']>
  /** Hidden parameters. */
  parameters?: InputMaybe<Array<TweakwiseNavigationParameter>>
  /**
   * Personal parameters.
   *
   * Personal parameters are similar to hidden parameters, except they only apply to recommendations to which the filters are assigned. This is typically useful when you are using personal merchandising and the filters should only be applied to select components.
   */
  personalParameters?: InputMaybe<Array<TweakwiseNavigationParameter>>
  /**
   * Search term entered by the user.
   *
   * This executes a text based search on the Tweakwise rest api. Passing this parameter also queries the `navigate-search` endpoint instead of the `navigate` endpoint.
   */
  query?: InputMaybe<Scalars['String']['input']>
  /** Sort direction when sortKey is set. */
  sortDirection?: InputMaybe<TweakwiseSortDirection>
  /** Sorting the results. The XML returns the values which can be used for sorting. */
  sortKey?: InputMaybe<Scalars['String']['input']>
  /** Overruling the linked sorting template with the specified template. */
  sortTemplateId?: InputMaybe<Scalars['Int']['input']>
  /**
   * The visitor key is an identifier for the person browsing your website who is initiating the navigation requests. This same identifier should also be used to identify the visitor when sending events to our Analytics API. Based on the sent events, the visitor can receive personalized results.
   *
   * The visitor key can be any string as long as it is unique to the visitor. Generate for example a Universally Unique Identifier (UUID), a new id per visitor or use an account id.
   */
  visitorKey?: InputMaybe<Scalars['String']['input']>
}

export interface TweakwiseNavigationProperties {
  __typename?: 'TweakwiseNavigationProperties'
  currentPage: Scalars['Int']['output']
  isDirectSearch: Scalars['Boolean']['output']
  isRootCategory: Scalars['Boolean']['output']
  nrOfItems: Scalars['Int']['output']
  nrOfPages: Scalars['Int']['output']
  pageSize: Scalars['Int']['output']
  pageUrl: Scalars['String']['output']
  resetUrl: Scalars['String']['output']
  searchTerm: Maybe<Scalars['String']['output']>
  selectedCategory: Scalars['Int']['output']
  sortFields: Array<TweakwiseNavigationPropertiesSortField>
  suggestedSearchTerm: Maybe<Scalars['String']['output']>
}

export interface TweakwiseNavigationPropertiesSortField {
  __typename?: 'TweakwiseNavigationPropertiesSortField'
  displayTitle: Scalars['String']['output']
  isSelected: Scalars['Boolean']['output']
  order: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export interface TweakwiseNavigationRedirect {
  __typename?: 'TweakwiseNavigationRedirect'
  url: Scalars['String']['output']
}

export interface TweakwiseNavigationResult {
  __typename?: 'TweakwiseNavigationResult'
  facets: Array<TweakwiseNavigationFacet>
  items: Array<TweakwiseNavigationItem>
  properties: TweakwiseNavigationProperties
  redirects: Array<TweakwiseNavigationRedirect>
}

export interface TweakwiseProductRecommendationsTemplate {
  __typename?: 'TweakwiseProductRecommendationsTemplate'
  id: Scalars['ID']['output']
  items: Array<TweakwiseNavigationItem>
  name: Scalars['String']['output']
}

export interface TweakwiseRecommendation {
  __typename?: 'TweakwiseRecommendation'
  id: Scalars['ID']['output']
  items: Array<TweakwiseNavigationItem>
  name: Maybe<Scalars['String']['output']>
}

export interface TweakwiseRecommendationsFeaturedParams {
  /** The category that should contain the filtered results. If you want to provide the entire path, you can provide multiple ids. */
  categoryId?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * Hidden parameters can be added to the URL by using the parameter 'tn_parameters'.
   *
   * When filtering by property 'brand' with value 'Coca Cola' or with value 'Pepsi' the value of tn_parameters should be: brand=Coca Cola&brand=Pepsi
   *
   * This value should then be URL encoded.
   */
  parameters?: InputMaybe<Array<TweakwiseNavigationParameter>>
}

export interface TweakwiseSearchBanner {
  __typename?: 'TweakwiseSearchBanner'
  categoryId: Scalars['Int']['output']
  clickTarget: Maybe<Scalars['String']['output']>
  clickUrl: Maybe<Scalars['String']['output']>
  imageUrl: Maybe<Scalars['String']['output']>
  location: Maybe<Scalars['String']['output']>
  type: Maybe<Scalars['String']['output']>
}

export interface TweakwiseSearchBannerParams {
  /** The category ID to query. When filtering by a subcategory, providing the parent categoryIds will keep the search accurate. */
  categoryId?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * Search term entered by the user.
   * This executes a text based search on the Tweakwise rest api for search banners that have this keyword assigned.
   */
  query?: InputMaybe<Scalars['String']['input']>
}

export enum TweakwiseSortDirection {
  ASCENDING = 'Ascending',
  DESCENDING = 'Descending',
}

export interface TweakwiseTemplates {
  __typename?: 'TweakwiseTemplates'
  featuredProductsTemplate: Maybe<Scalars['Int']['output']>
  filterWhitelist: Maybe<Scalars['String']['output']>
  filterWhitelistValues: Maybe<Scalars['String']['output']>
  productCrosssellGroupCode: Maybe<Scalars['String']['output']>
  productCrosssellTemplate: Maybe<Scalars['Int']['output']>
  productUpsellGroupCode: Maybe<Scalars['String']['output']>
  productUpsellTemplate: Maybe<Scalars['Int']['output']>
  relatedProductsGroupCode: Maybe<Scalars['String']['output']>
  relatedProductsTemplate: Maybe<Scalars['Int']['output']>
}

/** Unique Code */
export interface UniqueCode {
  __typename?: 'UniqueCode'
  /** Timestamp indicating when the plate was created. */
  createdAt: Scalars['String']['output']
  /** Status of the code */
  status: CodeStatus
  /** Unique Code */
  uniqueCode: Scalars['String']['output']
  /** Timestamp indicating when the plate was updated. */
  updatedAt: Scalars['String']['output']
}

/** Modifies the specified items in the cart. */
export interface UpdateCartItemsInput {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String']['input']
  /** An array of items to be updated. */
  cart_items: Array<InputMaybe<CartItemUpdateInput>>
}

/** Contains details about the cart after updating items. */
export interface UpdateCartItemsOutput {
  __typename?: 'UpdateCartItemsOutput'
  /** The cart after updating products. */
  cart: Cart
}

/** Contains the customer's wish list and any errors encountered. */
export interface UpdateProductsInWishlistOutput {
  __typename?: 'UpdateProductsInWishlistOutput'
  /** An array of errors encountered while updating products in a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>
  /** Contains the wish list with all items that were successfully updated. */
  wishlist: Wishlist
}

/** Contains URL rewrite details. */
export interface UrlRewrite {
  __typename?: 'UrlRewrite'
  /** An array of request parameters. */
  parameters: Maybe<Array<Maybe<HttpQueryParameter>>>
  /** The request URL. */
  url: Maybe<Scalars['String']['output']>
}

/** This enumeration defines the entity type. */
export enum UrlRewriteEntityTypeEnum {
  CATEGORY = 'CATEGORY',
  CMS_PAGE = 'CMS_PAGE',
  CRAFT = 'CRAFT',
  LANDINGPAGE = 'LANDINGPAGE',
  PRODUCT = 'PRODUCT',
}

/** Defines whether the attribute is filterable in layered navigation. */
export enum UseInLayeredNavigationOptions {
  FILTERABLE_NO_RESULT = 'FILTERABLE_NO_RESULT',
  FILTERABLE_WITH_RESULTS = 'FILTERABLE_WITH_RESULTS',
  NO = 'NO',
}

/** Defines a customer attribute validation rule. */
export interface ValidationRule {
  __typename?: 'ValidationRule'
  /** Validation rule name applied to a customer attribute. */
  name: Maybe<ValidationRuleEnum>
  /** Validation rule value. */
  value: Maybe<Scalars['String']['output']>
}

/** List of validation rule names applied to a customer attribute. */
export enum ValidationRuleEnum {
  DATE_RANGE_MAX = 'DATE_RANGE_MAX',
  DATE_RANGE_MIN = 'DATE_RANGE_MIN',
  FILE_EXTENSIONS = 'FILE_EXTENSIONS',
  INPUT_VALIDATION = 'INPUT_VALIDATION',
  MAX_FILE_SIZE = 'MAX_FILE_SIZE',
  MAX_IMAGE_HEIGHT = 'MAX_IMAGE_HEIGHT',
  MAX_IMAGE_WIDTH = 'MAX_IMAGE_WIDTH',
  MAX_TEXT_LENGTH = 'MAX_TEXT_LENGTH',
  MIN_TEXT_LENGTH = 'MIN_TEXT_LENGTH',
}

/** Vault payment inputs */
export interface VaultMethodInput {
  /** The payment source for the payment method */
  payment_source?: InputMaybe<Scalars['String']['input']>
  /** The payment services order ID */
  payments_order_id?: InputMaybe<Scalars['String']['input']>
  /** PayPal order ID */
  paypal_order_id?: InputMaybe<Scalars['String']['input']>
  /** The public hash of the token. */
  public_hash?: InputMaybe<Scalars['String']['input']>
}

/** Contains required input for payment methods with Vault support. */
export interface VaultTokenInput {
  /** The public hash of the payment token. */
  public_hash: Scalars['String']['input']
}

/** An implementation for virtual product cart items. */
export interface VirtualCartItem extends CartItemInterface {
  __typename?: 'VirtualCartItem'
  /** An array containing customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output']
  /** True if requested quantity is less than available stock, false otherwise. */
  isAvailable: Scalars['Boolean']['output']
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>
  /** Details about an item in the cart. */
  product: ProductInterface
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output']
}

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export interface VirtualProduct
  extends CustomizableProductInterface,
    ProductInterface,
    RoutableInterface {
  __typename?: 'VirtualProduct'
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeBalancebike: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeLgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeRideoncar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeScooter: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeSgk: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  accessoryTypeTrampoline: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  addOn: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSeat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  adjustableSteeringWheel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  aerowall: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ageRecommended: Maybe<Scalars['Int']['output']>
  /** Ambiance Product Images */
  ambianceGallery: Maybe<Array<Maybe<AmbianceGallery>>>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  categoryPlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  ceLabel: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  collaborationLicense: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  color: Maybe<Scalars['Int']['output']>
  /** Color Variants */
  colorVariants: Maybe<Array<Maybe<ColorVariant>>>
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** Product custom attributes. */
  customAttributesV2: Maybe<ProductCustomAttributes>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  drive: Maybe<Scalars['Int']['output']>
  /** Filterable attribute with Text */
  dynamicAttributes: Maybe<Array<Maybe<DynamicAttribute>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  ean: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  electricalSupport: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  entranceHeight: Maybe<Scalars['Int']['output']>
  /** Craft entry type */
  entryType: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  framePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  frameXlGokart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gears: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['Int']['output']>
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  gokartType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  handbrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  heightTrampoline: Maybe<Scalars['Int']['output']>
  /** Highlight attributes */
  highlightedAttributes: Maybe<Array<Maybe<HighlightAttribute>>>
  /** Highlight attributes PLP */
  highlightedAttributesPlp: Maybe<Array<Maybe<HighlightAttribute>>>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Scalars['Int']['output']
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>
  /** @deprecated Use the `custom_attributes` field instead. */
  inclSafetynet: Maybe<Scalars['Int']['output']>
  /** Whether the product is a main product or not. */
  isMainProduct: Scalars['Boolean']['output']
  /** @deprecated Use the `custom_attributes` field instead. */
  jumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  lengthUser: Maybe<Scalars['Int']['output']>
  /** A number representing the product's manufacturer. */
  manufacturer: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  material: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  materialTires: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUserWeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  maxUsers: Maybe<Scalars['Int']['output']>
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgBrake: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgClamp: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgDeck: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgFrontFork: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGrips: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgGriptape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHandlebar: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgHeadset: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  mgWheels: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minHandlebarHeight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  minLengthUser: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  model: Maybe<Scalars['Int']['output']>
  /** The product name. Customers use this name to identify the product. */
  name: Scalars['String']['output']
  /** @deprecated Use the `custom_attributes` field instead. */
  netWeight: Maybe<Scalars['String']['output']>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  numberOfSprings: Maybe<Scalars['Int']['output']>
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  pedals: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  playbaseSet: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  possibilityFor2ndSeat: Maybe<Scalars['Int']['output']>
  /** PreOrder info */
  preorder: Maybe<PreOrder>
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>
  /** The range of prices for the product */
  priceRange: PriceRange
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>
  /** Product downloads */
  productDownloads: Maybe<ProductDownload>
  productLabels: Maybe<Array<Maybe<AmLabelList>>>
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  pushRod: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityLine: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  qualityStars: Maybe<Scalars['Int']['output']>
  /** The average of all the ratings given to the product. */
  ratingSummary: Scalars['Float']['output']
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output']
  /** An array of products to be displayed in a Related Products block. */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** @deprecated Use the `custom_attributes` field instead. */
  safetynetType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  seatHeight: Maybe<Scalars['Int']['output']>
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAccessories: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeAirtrack: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeBeachwagon: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeClothing: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeGocart: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeJumpMat: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeStep: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  sizeWheelbarrow: Maybe<Scalars['Int']['output']>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Scalars['String']['output']
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>
  /** Get list of spareparts per product(group), per year */
  sparepartLinks: Maybe<Array<Maybe<BergProduct>>>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  springType: Maybe<Scalars['Int']['output']>
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  testweight: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  themePlaybase: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  thicknessOfProtectiveEdge: Maybe<Scalars['Int']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineShape: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  trampolineType: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  tuevGs: Maybe<Scalars['Int']['output']>
  /** Tweakwise ID */
  tweakwiseItemNumber: Maybe<Scalars['String']['output']>
  /** PreOrder info */
  tweakwiseTemplates: Maybe<TweakwiseTemplates>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>
  /** @deprecated Use the `custom_attributes` field instead. */
  typeAccessory: Maybe<Scalars['Int']['output']>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  urlKey: Scalars['String']['output']
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  uvProtection: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  warrantyWoRegistration: Maybe<Scalars['Int']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelLights: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  wheelSize: Maybe<Scalars['Int']['output']>
  /** @deprecated Use the `custom_attributes` field instead. */
  widthPadding: Maybe<Scalars['Int']['output']>
}

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export interface VirtualProductCustomAttributesV2Args {
  filters: InputMaybe<AttributeFilterInput>
}

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export interface VirtualProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines a single product to add to the cart. */
export interface VirtualProductCartItemInput {
  /** An array that defines customizable options for the product. */
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: CartItemInput
}

/** Contains a virtual product wish list item. */
export interface VirtualWishlistItem extends WishlistItemInterface {
  __typename?: 'VirtualWishlistItem'
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

/** Deprecated. It should not be used on the storefront. Contains information about a website. */
export interface Website {
  __typename?: 'Website'
  /**
   * A code assigned to the website to identify it.
   * @deprecated The field should not be used on the storefront.
   */
  code: Maybe<Scalars['String']['output']>
  /**
   * The default group ID of the website.
   * @deprecated The field should not be used on the storefront.
   */
  defaultGroupId: Maybe<Scalars['String']['output']>
  /**
   * The ID number assigned to the website.
   * @deprecated The field should not be used on the storefront.
   */
  id: Maybe<Scalars['Int']['output']>
  /**
   * Indicates whether this is the default website.
   * @deprecated The field should not be used on the storefront.
   */
  isDefault: Maybe<Scalars['Boolean']['output']>
  /**
   * The website name. Websites use this name to identify it easier.
   * @deprecated The field should not be used on the storefront.
   */
  name: Maybe<Scalars['String']['output']>
  /**
   * The attribute to use for sorting websites.
   * @deprecated The field should not be used on the storefront.
   */
  sortOrder: Maybe<Scalars['Int']['output']>
}

/** An error encountered while performing operations with WishList. */
export interface WishListUserInputError {
  __typename?: 'WishListUserInputError'
  /** A wish list-specific error code. */
  code: WishListUserInputErrorType
  /** A localized error message. */
  message: Scalars['String']['output']
}

/** A list of possible error types. */
export enum WishListUserInputErrorType {
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  UNDEFINED = 'UNDEFINED',
}

/** Contains a customer wish list. */
export interface Wishlist {
  __typename?: 'Wishlist'
  /** The unique ID for a `Wishlist` object. */
  id: Maybe<Scalars['ID']['output']>
  /** @deprecated Use the `items_v2` field instead. */
  items: Maybe<Array<Maybe<WishlistItem>>>
  /** The number of items in the wish list. */
  itemsCount: Maybe<Scalars['Int']['output']>
  /** An array of items in the customer's wish list. */
  itemsV2: Maybe<WishlistItems>
  /** An encrypted code that Magento uses to link to the wish list. */
  sharingCode: Maybe<Scalars['String']['output']>
  /** The time of the last modification to the wish list. */
  updatedAt: Maybe<Scalars['String']['output']>
}

/** Contains a customer wish list. */
export interface WishlistItemsV2Args {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Contains details about errors encountered when a customer added wish list items to the cart. */
export interface WishlistCartUserInputError {
  __typename?: 'WishlistCartUserInputError'
  /** An error code that describes the error encountered. */
  code: WishlistCartUserInputErrorType
  /** A localized error message. */
  message: Scalars['String']['output']
  /** The unique ID of the `Wishlist` object containing an error. */
  wishlistId: Scalars['ID']['output']
  /** The unique ID of the wish list item containing an error. */
  wishlistItemId: Scalars['ID']['output']
}

/** A list of possible error types. */
export enum WishlistCartUserInputErrorType {
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  NOT_SALABLE = 'NOT_SALABLE',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  UNDEFINED = 'UNDEFINED',
}

/** Contains details about a wish list item. */
export interface WishlistItem {
  __typename?: 'WishlistItem'
  /** The time when the customer added the item to the wish list. */
  addedAt: Maybe<Scalars['String']['output']>
  /** The customer's comment about this item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItem` object. */
  id: Maybe<Scalars['Int']['output']>
  /** Details about the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item */
  qty: Maybe<Scalars['Float']['output']>
}

/** Defines the items to add to a wish list. */
export interface WishlistItemInput {
  /** An array of options that the customer entered. */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** For complex product types, the SKU of the parent product. */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  /** The amount or number of items to add. */
  quantity: Scalars['Float']['input']
  /** An array of strings corresponding to options the customer selected. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The SKU of the product to add. For complex product types, specify the child product SKU. */
  sku: Scalars['String']['input']
}

/** The interface for wish list items. */
export interface WishlistItemInterface {
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output']
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output']
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output']
}

/** Defines updates to items in a wish list. */
export interface WishlistItemUpdateInput {
  /** Customer-entered comments about the item. */
  description?: InputMaybe<Scalars['String']['input']>
  /** An array of options that the customer entered. */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** The new amount or number of this item. */
  quantity?: InputMaybe<Scalars['Float']['input']>
  /** An array of strings corresponding to options the customer selected. */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The unique ID for a `WishlistItemInterface` object. */
  wishlist_item_id: Scalars['ID']['input']
}

/** Contains an array of items in a wish list. */
export interface WishlistItems {
  __typename?: 'WishlistItems'
  /** A list of items in the wish list. */
  items: Array<Maybe<WishlistItemInterface>>
  /** Contains pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>
}

/** Deprecated: Use the `Wishlist` type instead. */
export interface WishlistOutput {
  __typename?: 'WishlistOutput'
  /**
   * An array of items in the customer's wish list
   * @deprecated Use the `Wishlist.items` field instead.
   */
  items: Maybe<Array<Maybe<WishlistItem>>>
  /**
   * The number of items in the wish list.
   * @deprecated Use the `Wishlist.items_count` field instead.
   */
  itemsCount: Maybe<Scalars['Int']['output']>
  /**
   * When multiple wish lists are enabled, the name the customer assigns to the wishlist.
   * @deprecated This field is related to Commerce functionality and is always `null` in Open Source.
   */
  name: Maybe<Scalars['String']['output']>
  /**
   * An encrypted code that links to the wish list.
   * @deprecated Use the `Wishlist.sharing_code` field instead.
   */
  sharingCode: Maybe<Scalars['String']['output']>
  /**
   * The time of the last modification to the wish list.
   * @deprecated Use the `Wishlist.updated_at` field instead.
   */
  updatedAt: Maybe<Scalars['String']['output']>
}
